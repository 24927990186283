<template lang="pug">
.screen-queues-min.mb-3
  .card 
    .card-body
      h5.card-title.mb-3.ps-2 {{this.filters.title}}
      show-queue-min(v-for="queue in queues" :queue="queue" :showQueue="showQueue")
              
</template>
<script>
  import ShowQueueMin from '../queues/ShowQueueMin.vue'
  export default {
    name: 'ScreenQueues',
    components: {
      ShowQueueMin
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        showQueue: false,
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      queues() {
        return this.$store.state.queueModule.queues
      }
    },
    mounted() {
        if(this.filters && this.filters.showOnlyMy) {
          this.$store.dispatch("getMyQueues")
        } else if(this.filters && this.filters.departments_id) {
          this.$store.dispatch("getDepartmentsQueues", {
            departments_id: this.filters.departments_id
          })
        }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>