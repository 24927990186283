<template lang="pug">
.list-email-messages.p-1
  .card.mb-2
    .card-body
      .email-item-content.border-bottom.p-1(v-for="emailMessage in emailMessages")
        .email-item.ps-2.pt-1.pb-2(:class="emailMessage.unread ? 'unread' : ''" @click="setCurrentEmailMessage(emailMessage)")
          .d-flex.align-items-center
            .flex-grow-1
              .d-block.mb-0
                strong.text-secondary {{ printFrom(emailMessage) }}
                strong.text-secondary.ms-2 &middot;
                strong.text-primary.ms-2(v-if="emailMessage.account.name") {{ emailMessage.account.name }}
                strong.text-secondary.ms-2(v-else) Sin cuenta
                strong.text-secondary.ms-2 &middot;
                span.text-secondary.ms-2 {{ emailMessage.sended_at | formatFullTime}}
              strong.email-subject {{ emailMessage.subject }}
            .ps-2
              .btn-group
                button.btn.btn-outline-warning.btn-sm(@click.stop="assignEmailMessage(emailMessage)")
                  font-awesome-icon(icon="handshake")
                button.btn.btn-outline-secondary.btn-sm(v-if="emailMessage.unread" @click.stop="changeUnreadEmailMessage(emailMessage, 0)")
                  font-awesome-icon(icon="envelope-open")
                button.btn.btn-outline-secondary.btn-sm(v-else @click.stop="changeUnreadEmailMessage(emailMessage, 1)")
                  font-awesome-icon(icon="envelope")
          .d-flex.justify-content-between
            div
              span.badge.bg-secondary {{ emailMessage.email_config.name }}
            div(v-if="emailMessage.owners")
              span.badge.bg-primary.ms-1(v-for="user in emailMessage.owners") {{user.name}}

          //pre {{ emailMessage }}



</template>
<script>
  import ListEmailMessageItem from './ListEmailMessageItem.vue'
  export default {
    name: 'ListEmailMessages',
    components: {
      ListEmailMessageItem
    },
    props: {
      emailConfigId: { type: Number, default: 1 },
    },
    data () {
      return {
        tab: 'all',
        filter: '',
        extension: '',
        selectedChatId: 0,
        displayNotification: false
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      emailMessages() {
        return this.$store.state.emailConfigModule.emailMessages
      },
      emailMessagesFiltered() {
        let emailMessagesFiltered = this.emailMessages.filter((emailMessage) => !emailMessage.email_message_id)

        if (this.filter) {
          emailMessagesFiltered = emailMessagesFiltered.filter((emailMessage) => emailMessage.account.name.toLowerCase().includes(this.filter.toLowerCase()) || emailMessage.email_message_body.subject.toLowerCase().includes(this.filter.toLowerCase()))  
        }

        if (this.tab == 'new') {
          emailMessagesFiltered = emailMessagesFiltered.filter((emailMessage) => {
            let index = emailMessage.users.findIndex((user) => user.id == this.currentUser.id && user.pivot.unread > 0)

            return index > -1
          })
        } else if (this.tab == 'my') {
          emailMessagesFiltered = emailMessagesFiltered.filter((emailMessage) => {
            let index = emailMessage.owners.findIndex((owner) => owner.id == this.currentUser.id)

            return index > -1
          })
        }

        return emailMessagesFiltered
      },
      sortedEmailMessages() {
        let sortedEmailMessages = this.emailMessagesFiltered
        return sortedEmailMessages.sort((a, b) => {
            if (a.id > b.id) {
              return -1
            } else {
              return 1
            }
          })
      },
      emailConfigMessagesPage() {
        return this.$store.state.emailConfigModule.page
      },
      
    },
    watch: {
      
    },
    mounted() {
      
        
    },
    methods: {
      setCurrentEmailMessage(emailMessage) {
        this.$store.commit("setEmailConfigCurrentEmailMessage", emailMessage)
        this.changeUnreadEmailMessage(emailMessage, 0)
      },
      changeUnreadEmailMessage(emailMessage, unread) {
        this.$store.dispatch("changeUnreadEmailMessage", {
          emailConfigId: this.emailConfigId,
          path: 'INBOX',
          emailMessageId: emailMessage.id,
          unread: unread
        }).then(() => {
          this.$store.dispatch("getEmailConfigMessages", {
            emailConfigId: this.emailConfigId,
            path: 'INBOX',
            page: this.emailConfigMessagesPage
          })
        })
      },
      assignEmailMessage(emailMessage) {
        this.$store.dispatch("assignEmailMessage", {
          id: emailMessage.id,
          value: 1,
          userId: this.currentUser.id
        }).then(() => {
          this.$store.dispatch("getEmailConfigMessages", {
            emailConfigId: this.emailConfigId,
            path: 'INBOX'
          })
        })
      },
      printFrom(emailMessage) {
        let froms = emailMessage.email_message_recipients.filter(r => r.type == 'from')

        let fromStr = ''
        froms.forEach((r) => {
          fromStr += r.email + ' '
        })

        return fromStr

      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";
.email-item-content:hover {
  background: $light;
}
.email-item.unread {
  border-left: 4px solid $primary;
}
.email-subject {
  font-size: 1.1em;
}
</style>