<template lang="pug">
  .login
    .row
      .col-md-4
      .col-md-4(style="min-height: 600px;")
        .text-center.mb-3(style="margin-top:50px;")
          img.mb-3(src="/media/logo.png" style="max-width:60%;")
        .card
          .card-body(v-if="showRecovery")
            form(name='form' @submit.prevent='recovery')
              .form-group
                label(for='username') Usuario
                input.form-control(v-model='username' type='text' name='username')
              hr
              .d-grid.gap-2
                button.btn.btn-primary(:disabled='loading')
                  span.spinner-border.spinner-border-sm(v-show='loading')
                  span Recuperar
              hr
              .d-grid.gap-2
                button.btn.btn-secondary(type="button" @click="showRecovery = false")
                  span Volver al login
              .alert.alert-success.mt-2(v-if='successMessage' role='alert') {{successMessage}}
          .card-body(v-else-if="!user.id")
            form(name='form' @submit.prevent='login')
              .form-group
                label(for='username') Usuario
                input.form-control(v-model='username' type='text' name='username')
              .form-group
                label(for='password') Password
                input.form-control(v-model='password' type='password' name='password')
              hr
              .d-grid.gap-2
                button.btn.btn-primary(:disabled='loading')
                  span.spinner-border.spinner-border-sm(v-show='loading')
                  span Login
              hr
              .d-grid.gap-2
                button.btn.btn-secondary(type="button" @click="showRecovery = true")
                  span Olvidé la contraseña
              .form-group
                .alert.alert-danger(v-if='message' role='alert') {{message}}
          .card-body(v-else-if="isChangePassword")
            p.alert.alert-info
              strong Es necesario modificar la contraseña
              br
              span La contraseña debe tener al menos
              ul
                 li 8 caracteres
                 li 1 letra mayúscula
                 li 1 letra minúscula
                 li 1 número 
                 li 1 carácter especial  
            form(name='form' @submit.prevent='changePassword')
              .form-group
                label(for='username') Password
                input.form-control(v-model='password' type='password' name='password')
              .form-group
                label(for='password') Confirmar
                input.form-control(v-model='confirm' type='password' name='confirm')
              hr
              .d-grid.gap-2
                button.btn.btn-primary(:disabled='loading')
                  span.spinner-border.spinner-border-sm(v-show='loading')
                  span Actualizar password
              .form-group
                .alert.alert-danger(v-if='message' role='alert') {{message}}
          .card-body(v-else)
            form(name='form' @submit.prevent='setExtension')
              .form-group
                label(for='extension_id') Seleccione una extensión
                v-select(:options="extensions" :reduce="label => label.id"  label="label" v-model="extensionId" placeholder="Seleccione una extensión")
              hr
              .d-grid.gap-2
                button.btn.btn-primary(:disabled='loading')
                  span.spinner-border.spinner-border-sm(v-show='loading')
                  span Login

</template>

<script>

export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      message: '',
      successMessage: '',
      username: '',
      password: '',
      confirm: '',
      user: {},
      extensions: [],
      extensionId: null,
      showRecovery: false,
      isChangePassword: false
    }
  },
  beforeCreate() {
    if(localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'))
      console.log(this.user)
      if(this.user.user.user_type.start_url) {
        this.$router.push(this.user.user.user_type.start_url);
      } else {
        this.$router.push('/dashboards');
      }
    }
  },
  methods: {
    recovery() {
      if(!this.username) {
        this.message = "Es necesario indicar un usuario"
      } else {
        this.$store.dispatch("recovery",{
          username: this.username
        }).then((res) => {
          if(res.error) {
            this.message = "No se pudo realizar la petición"
          } else {
            this.successMessage = "Hemos enviado un correo para poder recuperar tu contraseña. Valida tu buzón."
          }
        })
      }
    },
    login() {
      
      if (this.username && this.password) {
        this.$store.dispatch('login', {
          username: this.username,
          password: this.password
        }).then(
            (res) => {
              if (res.access_token) {
                this.user = res.user

                if(this.user.extensions.length > 1) {
                  this.extensions = []
                  this.user.extensions.forEach((ext) => {
                    this.extensions.push({
                      id: ext.id,
                      label: ext.name + ' - ' + ext.number
                    })
                  })
                } else {
                  if(this.user.last_password_change) {
                    location.reload()
                  } else {
                    this.isChangePassword = true;
                    this.password = ''
                  }
                }
              }
            },
            error => {
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
      }
    },
    setExtension() {
      if(this.user.id && this.extensionId) {
        this.$store.dispatch("changeExtension", { 
          username: this.username,
          password: this.password,
          extensionId: this.extensionId
        })
        .then(() => {
          if(this.user.last_password_change) {
                    location.reload()
                  } else {
                    this.isChangePassword = true;
                    this.password = ''
                  }
        })
      }
    },
    changePassword() {
this.message = ''
    if(this.password  == '' || this.confirm == '') {
        this.message = 'Es necesario indicar un password'
        return 
    } else if (this.password == this.confirm) {
        const passwordRegex = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/;

        if (!passwordRegex.test(this.password)) {
                this.message = 'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial'
            return;
        } else {
            this.$store.dispatch("changePassword", {password: this.password, confirm: this.confirm}).then((res) => {
                if (res.error) {
                        this.message = 'No es posible utilizar este password'
                } else {
                    location.reload();
                }
            })
        }
    } else {
            this.message = 'Los password no coinciden'
        return 
    }
}
  }

}
</script>

<style lang="scss">
  .login {
    overflow: hidden;
  }
</style>
