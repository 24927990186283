import authService from '../services/api/auth'

const authModule = {
    state: {
        user: {},
        backendUrl: '',
    },
    mutations: {
        setUser  (state, user) {
            state.user = user
        },
        setBackendUrl  (state, backendUrl) {
            state.backendUrl = backendUrl
        }
    },
    actions: {
        keepAlive () {
            return new Promise((resolve, reject) => {
                authService.keepAlive()
                    .then(res => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        me () {
            return new Promise((resolve, reject) => {
                authService.me()
                    .then(user => {
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
        login (context, payload) {
            return new Promise((resolve, reject) => {
                authService.login(payload.username, payload.password)
                    .then(user => {
                        context.commit("setUser", user.user)
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
        changePassword (context, payload) {
            return new Promise((resolve, reject) => {
                authService.changePassword(payload.password, payload.confirm)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        recovery (context, payload) {
            return new Promise((resolve, reject) => {
                authService.recovery(payload.username)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        resetPassword (context, payload) {
            return new Promise((resolve, reject) => {
                authService.resetPassword(payload.token, payload.password, payload.confirm)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        logout (context) {
            return new Promise((resolve, reject) => {
                authService.logout()
                    .then(user => {
                        context.commit("setUser", {})
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
        changeCompany (context, payload) {
            return new Promise((resolve, reject) => {
                authService.changeCompany(payload.companyId)
                    .then(user => {
                        context.commit("setUser", user)
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
        forceLogout (context, payload) {
            return new Promise((resolve, reject) => {
                authService.forceLogout(payload.userId)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        changeExtension (context, payload) {
            return new Promise((resolve, reject) => {
                authService.changeExtension(payload.username, payload.password, payload.extensionId)
                    .then(user => {
                        context.commit("setUser", user)
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
    }
}

export default authModule
