// import Vue from 'vue'
import whatsappNumbersService from '../services/api/whatsapp_numbers'

const whatsappNumberModule = {
    actions: {
        getMyWhatsappNumbers () {
            return new Promise((resolve, reject) => {
                whatsappNumbersService.myWhatsappNumbers()
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        whatsappNumberAddUser (context, payload) {
            return new Promise((resolve, reject) => {
                whatsappNumbersService.addUser(payload.whatsappNumberId, payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        whatsappNumberRemoveUser (context, payload) {
            return new Promise((resolve, reject) => {
                whatsappNumbersService.removeUser(payload.whatsappNumberId, payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default whatsappNumberModule