<template lang="pug">
  .report-total-call-by-call-status
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title {{$t(reportReportItem.name)}}
          .btn-group
            button.btn.btn-sm(@click="showTab = 'in'" :class="showTab == 'in' ? 'btn-primary' : 'btn-outline-primary'") {{$t('In')}}
            button.btn.btn-sm(@click="showTab = 'out'" :class="showTab == 'out' ? 'btn-primary' : 'btn-outline-primary'") {{$t('Out')}}
            button.btn.btn-sm(@click="showTab = 'internal'" :class="showTab == 'internal' ? 'btn-primary' : 'btn-outline-primary'") {{$t('Internal')}}
        template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
          chart-columns(v-if="series.length > 0" :series="series" :categories="labels" :colors="colors")
       
        table.table
          thead
            tr
              th {{$t('Usuario')}}
              th(v-if="showTab == 'in'") {{$t('In total Success')}}
              th(v-if="showTab == 'in'") {{$t('In total Lost')}}
              th(v-if="showTab == 'in'") {{$t('In duration')}}
              th(v-if="showTab == 'in'") {{$t('In media')}}
              th(v-if="showTab == 'out'") {{$t('Out total Success')}}
              th(v-if="showTab == 'out'") {{$t('Out total Lost')}}
              th(v-if="showTab == 'out'") {{$t('Out duration')}}
              th(v-if="showTab == 'out'") {{$t('Out media')}}
              th(v-if="showTab == 'internal'") {{$t('Internal total Success')}}
              th(v-if="showTab == 'internal'") {{$t('Internal total Lost')}}
              th(v-if="showTab == 'internal'") {{$t('Internal duration')}}
              th(v-if="showTab == 'internal'") {{$t('Internal media')}}
          tbody
            tr(v-for="row in data.dataTable")
              td {{row.user}}
              td(v-if="showTab == 'in'") {{row.in_success_total}}
              td(v-if="showTab == 'in'") {{row.in_lost_total}}
              td(v-if="showTab == 'in'") {{row.in_duration | timeInHoursMin  }}
              td(v-if="showTab == 'in'") {{row.in_media | timeInHoursMin}}
              td(v-if="showTab == 'out'") {{row.out_success_total}}
              td(v-if="showTab == 'out'") {{row.out_lost_total}}
              td(v-if="showTab == 'out'") {{row.out_duration | timeInHoursMin}}
              td(v-if="showTab == 'out'") {{row.out_media | timeInHoursMin}}
              td(v-if="showTab == 'internal'") {{row.internal_success_total}}
              td(v-if="showTab == 'internal'") {{row.internal_lost_total}}
              td(v-if="showTab == 'internal'") {{row.internal_duration | timeInHoursMin}}
              td(v-if="showTab == 'internal'") {{row.internal_media | timeInHoursMin}}
              
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  export default {
    name: 'ReportTotalCallSummaryUser',
    components: {
      ChartColumns
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f82649", "#f7b731", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      series() {
        if(this.data.dataChart && this.data.dataChart.length > 0 ) {
          let series = []

          series.push({
            name: this.$t("Correcta"),
            data: []
          })

          series.push({
            name: this.$t("No atendida"),
            data: []
          })

          this.data.dataChart.forEach((col) => {
            
              if(this.showTab == 'in') {
                series[0].data.push(col.in_success_total)
                series[1].data.push(col.in_lost_total)
              } else if(this.showTab == 'out') {
                series[0].data.push(col.out_success_total)
                series[1].data.push(col.out_lost_total)
              } else {
                series[0].data.push(col.internal_success_total)
                series[1].data.push(col.internal_lost_total)
              }
          })

          return series
        } else {
          return []
        }
      },
      labels() {
        let labels = []
        if(this.data.dataChart && this.data.dataChart.length > 0 ) {
          this.data.dataChart.forEach((row) => {
            labels.push(row.user)
          })
        }

        return labels
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>