<template lang="pug">
.chat-room-list-content
  .row.bg-secondary-opacity-25
    .col.m-1
      .p-2
        .input-group
          button.btn(@click="showFilter = !showFilter" :class="onlyOwn || filterMessageEnd ? 'btn-primary' : 'btn-secondary'")
            font-awesome-icon(icon="filter")
          input.form-control(type="text" :placeholder="$t('Buscar cuenta')" v-model="filter")
      .p-3.bg-secondary-opacity-25(v-show="showFilter")
        .input-group
          button.btn.btn-secondary
            font-awesome-icon(icon="filter")
          input.form-control(type="text" :placeholder="$t('Buscar final')" v-model="filterMessageEnd")
        .form-check.form-switch.mb-2.mt-2
          input#onlyOwn.form-check-input(type="checkbox" role="switch" v-model="onlyOwn")
          label.form-check-label(for="onlyOwn") Mostrar solo mís chats
  .chat-room-list
    .chat-room-item.p-1.border-bottom(v-for="chatRoom in sortedChatRooms" @click="setSelectedChatId(chatRoom.id)" :key="'chatRoom' + chatRoom.id" :class="getChatRoomListBackground(chatRoom)")
      .d-flex.mb-0.pb-0
        .chat-room-item-icon.p-1.text-center
          span.chat-room-icon {{ chatRoom.account.name | string2Initials }}
        .chat-room-item-name.p-1.flex-grow-1
          strong.chat-room-item-name-title {{chatRoom.account.name}}
          //span.chat-room-item-name-code {{chatRoom.account.code}}
          span.chat-room-item-name-code {{chatRoom.from}}
        .chat-room-item-info.p-1
          span.badge.bg-danger.rounded-pill.unread-messages(v-if="chatRoom.unread") {{chatRoom.unread}}
            font-awesome-icon.ms-2(icon="envelope")
      .d-flex.mt-0.pt-0
        .chat-room-item-icon.p-1.text-center
          span.badge.rounded-pill(:key="'chatRoomWindow' + chatRoom.id + counterKey" :class="getWindowBackground(chatRoom.last_account_message)") {{chatRoom.last_account_message | diffToNow | timeInHoursShort}}
        .chat-room-item-name.p-1.flex-grow-1
          span.badge.bg-info.rounded-pill.me-2 {{chatRoom.whatsapp_number.name}}
          span.badge.bg-primary.rounded-pill(v-if="chatRoom.message_end") {{chatRoom.message_end.name}}
        .chat-room-item-info.p-1
          span.badge.bg-info.rounded-pill.owners(v-if="chatRoom.owner_user" data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-html="true" :title="chatRoom.owner_user.name") {{chatRoom.owner_user.name | string2Initials }}
</template>
<script>
import { Tooltip } from 'bootstrap'
  export default {
    name: 'ChatRoomsList',
    props: {
      selectedChatId: {type: Number, default: 0},
    },
    data () {
      return {
        filter: '',
        filterMessageEnd: '',
        onlyOwn: false,
        showFilter: false
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      chatRooms() {
        if(this.onlyOwn) {
          let chatRooms = []

          this.$store.state.chatRoomModule.chatRooms.forEach((cr) => {
            if(!cr.owner_user_id || cr.owner_user_id == this.currentUser.id) {
              chatRooms.push(cr)
            }
          })

          return chatRooms
        } else {
          return this.$store.state.chatRoomModule.chatRooms
        }
      },
      filteredChatRooms() {
        return this.chatRooms.filter((cr) => {
          if(this.filter && this.filterMessageEnd) {
            return (
              (cr.account && cr.account.code && cr.account.code.toLowerCase().includes(this.filter.toLowerCase())) 
              || (cr.account && cr.account.name.toLowerCase().includes(this.filter.toLowerCase())) 
              || (cr.from.includes(this.filter) || cr.to.includes(this.filter))
              || (cr.message_end && cr.message_end.name.toLowerCase().includes(this.filterMessageEnd.toLowerCase()))
            )
          }

          if(this.filter) {
            return (
              (cr.account && cr.account.code && cr.account.code.toLowerCase().includes(this.filter.toLowerCase())) 
              || (cr.account && cr.account.name.toLowerCase().includes(this.filter.toLowerCase())) 
              || (cr.from.includes(this.filter) || cr.to.includes(this.filter))
            )
          }

          if(this.filterMessageEnd) {
            return (cr.message_end && cr.message_end.name.toLowerCase().includes(this.filterMessageEnd.toLowerCase()))
          }

          return cr
            
        })
      },
      sortedChatRooms() {
        let sortedChatRooms = this.filteredChatRooms
        return sortedChatRooms.sort((a, b) => {
            if (a.last_message_at && !b.last_message_at) {
              return -1
            } else if (!a.last_message_at && b.last_message_at) {
              return 1
            } else if (a.last_message_at > b.last_message_at) {
                return -1
            } else if (a.last_message_at < b.last_message_at) {
                return 1
            }
            
            return 0
        })
      },
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
    },
    watch: {
      chatRooms() {
        let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        tooltipList
      }
    },
    mounted() {
      let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
      tooltipList
    },
    methods: {
      getWindowBackground(lastAccountMessage) {
        let windowTime = this.$options.filters.diffToNow(lastAccountMessage)
        windowTime = this.$options.filters.timeInHoursShort(windowTime)
        
        let background = 'bg-success'
        if(windowTime == '+24h') {
          background = 'bg-dark';
        } else if(windowTime == '23h') {
          background = 'bg-danger';
        } else if(windowTime == '22h') {
          background = 'bg-warning';
        }

        return background;
      },
      getChatRoomListBackground(chatRoom) {
        let background = 'bg-warning-opacity-25 border-left-warning-10 border-right-warning-2'
        
        if(this.selectedChatId && chatRoom.id == this.selectedChatId) {
          background = 'bg-primary-opacity-25 border-left-primary-10 border-right-primary-2'
        } else if(chatRoom.owner_user_id && chatRoom.owner_user_id != this.currentUser.id) {
          background = 'bg-secondary-opacity-25 border-left-secondary-10 border-right-secondary-2 '
        } else if(chatRoom.owner_user_id && chatRoom.owner_user_id == this.currentUser.id) {
          background = 'bg-white border-left-primary-10 border-right-primary-2'
        }

        return background;
      },
      setSelectedChatId(id) {
        if(id > 0) {
          let index = this.chatRooms.findIndex((cr) => cr.id == id)
          if(index > -1) {
              let chatRoom = this.chatRooms[index]
              this.$set(chatRoom, "transfer", false)
              this.$store.commit("updateChatRoom", chatRoom)
          }


          this.$store.dispatch("chatRoomUserMarkAsRead", {chatRoomId: id})
        }
        this.$emit('setSelectedChatId', id)

        if(this.companyConfigs && this.companyConfigs.chatroom_capture_open && this.companyConfigs.chatroom_capture_open == 1) {
          //RC: Tenemos que capturar el chatroom
          this.$store.dispatch("captureChatRoom", {
            chatRoomId: id,
          })
        }
      }
    }
  }
</script>
<style lang="scss">
@import "../../scss/colors.scss";

.chat-room-item-icon {
  width: 61px;
}
.chat-room-item-icon span.chat-room-icon {
  font-size: 20px;
  color: $info;
  border: 2px solid $info;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
  width: 45px;
  height: 45px;
}
.chat-room-item-name-title {
  display:block;
}
.chat-room-item-name-code {
  display:block;
}
.chat-room-item-info .unread-messages {  
  display: block;
}
.chat-room-list {
  position: absolute;
  height: calc(100% - 105px);
  overflow-y: auto;
  width: 100%;
}
.chat-room-list {
  scrollbar-width: none;
}
.chat-room-list::-webkit-scrollbar {
  width: 8px;
}
</style>