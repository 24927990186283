import appService from "./appService";

const emailMessagesService = {
    sendEmailMessage(emailConfigId, data) {
        return appService.post('/email_configs/' + emailConfigId + '/send', data)
            .then((res) => res.data)
    },
    getMyEmailConfigs() {
        return appService.get('/email_configs/own')
            .then((res) => res.data)
    },
    getMessages(emailConfigId, path, page) {
        return appService.get('/email_configs/' + emailConfigId + '/folders/' + path + '/messages/10/' + page)
            .then((res) => res.data)
    },
    changeUnreadEmailMessage(emailConfigId, path, emailMessageId, unread) {
        return appService.patch('/email_configs/' + emailConfigId + '/folders/' + path + '/messages/' + emailMessageId + '/unread/' + unread)
            .then((res) => res.data)
    },
    getAllPreviousMessage(emailConfigId, emailMessageId) {
        return appService.get('/email_configs/' + emailConfigId + '/messages/' + emailMessageId + '/get_previous')
            .then((res) => res.data)
    },
    getAlllatersMessage(emailConfigId, emailMessageId) {
        return appService.get('/email_configs/' + emailConfigId + '/messages/' + emailMessageId + '/get_laters')
            .then((res) => res.data)
    }
}

export default emailMessagesService;