import Vue from 'vue'
import scheduleCallsService from '../services/api/schedule_calls'

const scheduleCallModule = {
    state: {
        scheduleCall: {},
        scheduleCalls: [],
        
    },
    mutations: {
        setScheduleCall (state, scheduleCall) {
            state.scheduleCall = scheduleCall
        },
        setScheduleCalls (state, scheduleCalls) {
            state.scheduleCalls = scheduleCalls
        },
        addScheduleCalls (state, scheduleCall) {
            state.scheduleCalls.push(scheduleCall)
        },
        updateScheduleCalls (state, scheduleCall) {
            let index = state.scheduleCalls.findIndex((t) => t.id == scheduleCall.id)

            if(index > -1) {
                Vue.set(state.scheduleCalls, index, scheduleCall)
            }
        },
        removeScheduleCalls (state, scheduleCall) {
            let index = state.scheduleCalls.findIndex((t) => t.id == scheduleCall.id)

            if(index > -1) {
                state.scheduleCalls.splice(index, 1)
            }
        },
    },
    actions: {
        getLastsScheduleCalls (context) {
            return new Promise((resolve, reject) => {
                scheduleCallsService.lasts()
                    .then((res) => {
                        context.commit("setScheduleCalls", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        cancelScheduleCall (context, payload) {
            return new Promise((resolve, reject) => {
                scheduleCallsService.cancel(payload.id)
                    .then((res) => {
                        context.commit("setScheduleCalls", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default scheduleCallModule