<template lang="pug">
.task-priority
  .btn-group
    button.btn.btn-sm.dropdown-toggle.pt-0.pb-0.ps-1.pe-1(data-bs-toggle="dropdown" aria-expanded="false" :style="'background:' + backgroundColor + ';color:' + task.task_priority.color + ';'") 
      span {{task.task_priority.name}}&nbsp;
    ul.dropdown-menu
      li(v-for="taskPriority in taskPriorities")
        a.dropdown-item(@click="update(taskPriority.id)") {{taskPriority.label}}
</template>

<script>
  export default {
    name: 'TaskPriority',
    props: {
      task: {type: Object, default: function() { return {}}},
    },
    data () {
      return {
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      taskPriorities() {
        return this.$store.state.taskModule.taskPriorityLists
      },
      backgroundColor() {
        return this.$options.filters.hexToRgba(this.task.task_priority.color, 0.2)
      },
      
    },
    beforeMount() {
    },
    methods: {
      update(value) {
        let task = this.task
        this.$set(task, 'task_priority_id', value)

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'tasks',
          id: this.task.id,
          data: task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.$store.commit("updateTasks", res)
            this.$store.commit("updateUnfinishedTasks", res)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.card.card-custom-v2 .card-header h5.title-card, .card.card-custom-v2 .card-footer h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.task-priority .btn {
  font-size: 0.8em;
}
</style>