<template lang="pug">
  .report-total-rest-times-summary-users
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title {{$t(reportReportItem.name)}}
        template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
          chart-columns(v-if="series.length > 0" :series="series" :categories="labels" :colors="colors")
       
        table.table.text-center
          thead
            tr
              th {{$t('Usuario')}}
              th(v-for="(header, index) in data.headers") {{header}}
          tbody
            tr(v-for="(row, index) in data.dataTable")
              td {{row.user}}
              td(v-for="col in row.rest_times") 
                span(:title="col.total") {{col.duration | timeInHours}}
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  export default {
    name: 'ReportTotalRestTimesSummaryUsers',
    components: {
      ChartColumns
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      series() {
        return []
        /*if(this.data.dataChart && this.data.dataChart.length > 0 && this.data.headers) {
          let series = []
          Object.keys(this.data.headers).forEach((index) => {
            console.log(index)
            series.push({
              name: index,
              data: []
            })
          })

          this.data.dataChart.forEach((row) => {
            row.rest_times.forEach((col) => {
              series[0].data.push(col.in_total)
            })
          })

          return series
        } else {
          return []
        }*/
      },
      labels() {
        let labels = []
        /*this.data.headers.forEach((h) => {
          labels.push(h)
        })*/

        return labels
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>