import appService from "./appService";

const scheduleCallsService = {
    lasts() {
        return appService.get('/schedule_calls/lasts')
            .then((res) => res.data)
    },
    cancel(id) {
        return appService.patch('/schedule_calls/' + id + '/cancel')
            .then((res) => res.data)
    },
}

export default scheduleCallsService;