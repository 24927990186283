<template lang="pug">
.task-modal
  #taskModal.modal.fade(tabindex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style="z-index: 2000")
    .modal-dialog.modal-xl
      .modal-content(v-if="taskId")
        button#taskModalClose.btn-close.d-none(type='button' data-bs-dismiss='modal' aria-label='Close')
        task-view(
          :taskId="taskId"
          @closeModal="closeModal"
          )


                  
</template>

<script>
import jQuery from 'jquery'
import TaskView from './TaskView.vue'
  export default {
    name: 'TaskModal',
    components: {
      TaskView
    },
    computed: {
      taskId() {
        return this.$store.state.taskModule.modalTaskId
      }
    },
    methods: {
      closeModal() {
        jQuery('#taskModalClose').trigger('click')
      },
    }
  }
</script>