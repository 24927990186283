<template lang="pug">
.call-modal-tasks
  .p-2
    .row
      .col-lg-10
        input.form-control.p-1(type="text" placeholder="Añadir una nueva tarea" v-model="taskName" @keyup.enter="createTask")
      .col-lg-2.col-md-2
        button.btn.btn-primary.btn-sm(@click="createTask")
          font-awesome-icon.me-2(icon="save")
          span Crear
  .container-fluid.task-list-items
    task-list-item(v-for="task in unfineshedTasks" :key="'taskListItem' + task.id" :task="task")
</template>

<script>
  import TaskListItem from '../tasks/TaskListItem.vue'
  export default {
    name: 'ChatModalTask',
    components: {
      TaskListItem
    },
    props: {
      accountId: {type: Number, default: 0},
      chatRoomId: {type: Number, default: 0},
    },
    data () {
      return {
        taskName: '',
        taskGroupId: null,
        taskTypeId: 1,
      }
    },
    computed: {
      unfineshedTasks() {
        return this.$store.state.accountModule.unfineshedTasks
      },
      fineshedTasks() {
        return this.$store.state.accountModule.fineshedTasks
      },
    },
    watch: {
      accountId(newVal, oldVal) {
        if(!oldVal && newVal != oldVal) {
          //RC: Limpiamos las tareas
          this.$store.commit("setUnfinishedTasks", [])
          this.$store.commit("setFinishedTasks", [])

          if(newVal) {
            //RC: Recuperamos las tareas  de la cuenta
            this.$store.dispatch("getAccountTasks", {accountId: newVal, finished: 0})
          }
        }
      }
    },
    mounted() {
      this.$store.commit("setUnfinishedTasks", [])
      this.$store.commit("setFinishedTasks", [])

      if(this.accountId) {
        //RC: Recuperamos las tareas  de la cuenta
        this.$store.dispatch("getAccountTasks", {accountId: this.accountId, finished: 0})
      }
    },
    methods: {
      createTask() {
        if(this.taskName) {
          this.$store.dispatch('createRegister', {
            moduleUrl: 'tasks',
            data: {
              name: this.taskName,
              task_type_id: this.taskTypeId,
              task_group_id: this.taskGroupId,
              account_id: this.accountId
            }
          }).then((res) => {
            this.$store.commit("addTasks", res)
            this.$store.commit("setUnfinishedTasks", [])
            this.$store.commit("setFinishedTasks", [])

            this.$store.dispatch("getAccountTasks", {accountId: this.accountId, finished: 0})
            this.taskName = ''

            this.$store.dispatch("assignMessageToTask", {
              chatRoomId: this.chatRoomId,
                taskId: res.id
              })
          }) 
        }
      },
    }
  }
</script>
<style lang="scss">
  
</style>