<template lang="pug">
#callViewItem
  .card-gc-border.mb-2
    .card-gc-body.border-primary
      .mb-0.pb-0 
        span.me-2.icon.text-primary(v-if="call.call_type_id == 1")
          font-awesome-icon(icon="arrow-right")
        span.me-2.icon.text-success(v-else-if="call.call_type_id == 2")
          font-awesome-icon(icon="arrow-left")
        span.me-2.icon.text-warning(v-else)
          font-awesome-icon(icon="redo")
        span {{[call.start, "unix"] | unixToDateTime}}
      .d-flex.justify-content-between.mb-1
        div
          span.me-2() {{call.from}} - {{call.to}}
        div
          span.me-2 W: {{call.duration_wait | timeInHoursMin}}
          span D: {{call.duration | timeInHoursMin}}
      .d-flex.justify-content-between.mb-1
        div
          strong.badge.me-2.bg-secondary() {{call.call_status.name}}
          span.badge.bg-info(v-if="call.call_end_id") {{call.call_end.name}}
          span.badge.bg-secondary(v-else) Sin final
        div
          span.ms-1(v-for="user in call.answered_users") {{user.name}}
</template>
<script>


export default {
  name: 'CallViewItem',
  props: {
    call: { type: Object, default: () => { } },
  },
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    
  }
}
</script>
<style scoped>
p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>
