<template lang="pug">
.card-gc.mb-2
  .card-gc-body(:style="'border-color: ' + queue.color + ';'")
    h5.card-title.mb-0 {{queue.name}}
    .d-flex.text-center.pt-2.pb-0
      .flex-fill.pe-2
        .bg-info-opacity-25.rounded.pt-2.pb-2(:class="totalQueueCalls > 0 ? 'blink' : ''")
          h5.mb-0.text-info 
            font-awesome-icon.me-1(icon="sign-in-alt") 
            span {{totalQueueCalls}}
      .flex-fill.ps-2.pe-2
        .bg-secondary-opacity-25.rounded.pt-2.pb-2
          h5.mb-0.text-secondary 
            font-awesome-icon.me-1(icon="thumbs-down") 
            span {{queue.report_today.total_lost_calls}}
      .flex-fill.ps-2.pe-2
        .bg-primary-opacity-25.rounded.pt-2.pb-2
          h5.mb-0.text-primary 
            font-awesome-icon.me-1(icon="thumbs-up") 
            span {{queue.report_today.total_calls - queue.report_today.total_lost_calls}}
      .flex-fill.ps-2.pe-2
        .bg-warning-opacity-25.rounded.pt-2.pb-2
          h5.mb-0.text-warning 
            font-awesome-icon.me-1(icon="hourglass-half") 
            span {{Math.round(queue.report_today.last_hour_wait_time)}}
      .flex-fill.ps-2
        .bg-success-opacity-25.rounded.pt-2.pb-2
          h5.mb-0.text-success 
            font-awesome-icon.me-1(icon="users") 
            span {{totalActiveQueueUsers}}
</template>
<script>
  import UserStatus from '../users/UserStatus.vue'
  import ExtensionUserStatus from '../extensions/ExtensionUserStatus.vue'
  export default {
    name: 'MyQueue',
    components: {
      UserStatus,
      ExtensionUserStatus
    },
    data () {
      return {
      }
    },
    props: {
      queue: {type: Object, default: function() {return {} }},
      showQueue: Boolean
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      queues() {
        return this.$store.state.queueModule.queues
      },
      extensions() {
        return this.$store.state.extensionModule.extensions
      },
      users() {
        return this.$store.state.userModule.users
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      extensionCalls() {
        let extensionCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.extensions.findIndex((extension) => extension.number == connected)

            if(index > -1) {
              if(!extensionCalls[this.extensions[index].id]) {
                extensionCalls[this.extensions[index].id] = {}
                this.$set(extensionCalls[this.extensions[index].id], 'calls', [])
              }

              extensionCalls[this.extensions[index].id].calls.push(call)
            }
          })
        })

        return extensionCalls
      },
      queueExtensions() {
        let queueExtensions = []
        this.extensions.forEach((ext) => {
          let index = this.queue.queue_extensions.findIndex((qe) => qe.extension_id == ext.id)

          if(index > -1) {
            queueExtensions.push(ext)
          }
        })

        return queueExtensions
      },
      queueExtensionUsers() {
        let queueExtensionUsers = []

        this.queueExtensions.forEach((qe) => {
          let users = this.users.filter(u => u.extension_id && u.extension_id == qe.id)

          this.$set(qe, 'users', users)
          

          queueExtensionUsers.push(qe)
        })

        return queueExtensionUsers
      },
      queueUsers() {
        let queueUsers = []
        this.users.forEach((u) => {
          let index = this.queueExtensions.findIndex((qe) => u.extension_id && u.extension_id == qe.id)

          if(index > -1) {
            queueUsers.push(u)
          }
        })

        return queueUsers
      },
      activeQueueUsers() {
        let activeQueueUsers = []

        this.queueExtensionUsers.forEach((qeu) => {
          if(qeu.users && !this.extensionCalls[qeu.id] && qeu.extension_status_id == 3) {
            let allPaused = true
            qeu.users.forEach((u) => {
              if(u.active_user_session && !u.active_rest_time) {
                allPaused = false
              }
            })

            if(!allPaused) {
              activeQueueUsers.push(qeu)
            }
          }
        })

        return activeQueueUsers
      },
      queueUsersCalls() {
        let userCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.queueUsers.findIndex((u) => ((u.extension && u.extension.number == connected) || (u.secondary_extension && u.secondary_extension.number == connected)))

            if(index > -1) {
              if(!userCalls[this.users[index].id]) {
                userCalls[this.users[index].id] = {}
                this.$set(userCalls[this.users[index].id], 'calls', [])
              }

              userCalls[this.users[index].id].calls.push(call)
            }
          })
        })

        return userCalls
      },
      totalActiveQueueUsers() {
        return this.activeQueueUsers.length
      },
      totalQueueUsersCalls() {
        
        return Object.keys(this.queueUsersCalls).length
      },
      totalIdleExtensions() {
        let idleExtensions = this.queueExtensions.filter((qe) => qe.extension_status_id == 3)

        return idleExtensions.length
      },
      totalInUseExtensions() {
        let idleExtensions = this.queueExtensions.filter((qe) => qe.extension_status_id == 7 || qe.extension_status_id == 8)

        return idleExtensions.length
      },
      queueCalls() {
        return this.calls.filter((x) => x.queue == this.queue.number)
      },
      totalQueueCalls() {
        return this.queueCalls.length
      },
      firstQueueCall() {
        if(this.totalQueueCalls > 0) {
          return this.queueCalls[0]
        } else {
          return null
        }
      }
    },
    mounted() {
    },
    methods: {
      backgroundColor(color) {
        return this.$options.filters.hexToRgba(color, 0.2)
      }
    }
  }
</script>
<style lang="scss">
</style>