<template lang="pug">
  .pbx-calendar
    .container-fluid
      .row
        .col
          .d-flex.justify-content-between
            .ps-3.pe-3.pt-3
              h1.h3 {{pbxCalendarGroup.name}}
              select.form-select(v-model="calendarIndex")
                option(v-for="(option, index) in pbxCalendarGroups" :value="index") {{option.name}}
            div
      .row
        .col
          .p-3
            .d-flex.justify-content-end.mb-2
              //.btn-group
                button.btn(:class="!isRange ? 'btn-primary' : 'btn-secondary'" @click="isRange = false") Seleccionar un día
                button.btn(:class="isRange ? 'btn-primary' : 'btn-secondary'" @click="isRange = true") Seleccionar un rango
            vc-date-picker(is-expanded :rows="2" :columns="2" :attributes="attributes" :is-range="isRange" v-model="selectedDate")
        .col
          .p-3
            .card(v-if="selectedDate")
              .card-body
                h5.card-title {{selectedDate | formatDate}}
                template(v-if="selectedDateElements")
                  
                ul.nav.nav-tabs.mt-2
                  li.nav-item(v-if="selectedDateElements.length > 0" :class="formTab == 'actualBranches' ? 'active' : ''" @click="formTab = 'actualBranches'")
                    a.nav-link(:class="formTab == 'actualBranches' ? 'active' : ''" @click="formTab = 'actualBranches'")
                      strong Horarios actuales
                  li.nav-item
                    a.nav-link(:class="formTab == 'assignBranch' ? 'active' : ''" @click="formTab = 'assignBranch'")
                      strong Seleccionar un horario
                  //li.nav-item
                    a.nav-link(:class="formTab == 'newBranch' ? 'active' : ''"  @click="formTab = 'newBranch'")
                      strong Generar un nuevo horario
                .p-2(v-if="formTab == 'actualBranches'")
                  h5.card-title.mb-1 Horarios asignados
                  .p-2.border.rounded-3(v-for="element in selectedDateElements" :style="'border-left: 8px solid' + element.pbx_calendar_flow_color + ' !important;'")
                    .d-flex.justify-content-between.align-items-center
                      div
                        span {{element.pbx_calendar_flow_name}}
                        br
                        template(v-if="element.hour_start || element.hour_end || element.minute_start || element.minute_end")
                          span {{element.hour_start | format2Digits}}:{{element.minute_start | format2Digits}} - {{element.hour_end | format2Digits}}:{{element.minute_end | format2Digits}} 
                        template(v-else)
                          span Aplicado a todo el día
                      button.btn.btn-danger.m-0.pt-1.pb-1(@click="removeTimeGroupOption(element.time_group_id, element.time_group_option_id)")
                        font-awesome-icon(icon="trash")
                .p-2(v-if="formTab == 'assignBranch'")
                  h5.card-title Asignar un horario
                  .row
                    .col
                      label Tipo de destino
                      v-select(:options="branchesId" :reduce="label => label.id"  label="label" v-model="branchId")
                      .form-check.form-switch.mt-2
                        input.form-check-input(type="checkbox" v-model="allDay")
                        label.form-check-label Aplicar a todo el día
                      .mb-2(v-if="!allDay")
                        .row
                          .col
                            strong Hora de inicio (hh:mm)
                            .p-2.border
                              .row 
                                .col
                                  select.form-select(placeholder="hh" v-model="hour_start")
                                    option(v-for="i in 24" :value="i - 1") {{(i - 1) | format2Digits}}
                                .col
                                  select.form-select(placeholder="mm" v-model="minute_start")
                                    option(v-for="i in 60" :value="i - 1") {{(i - 1) | format2Digits}}
                          .col
                            strong Hora de fin (hh:mm)
                            .p-2.border
                              .row 
                                .col
                                  select.form-select(placeholder="hh" v-model="hour_end")
                                    option(v-for="i in 24" :value="i - 1") {{(i - 1) | format2Digits}}
                                .col
                                  select.form-select(placeholder="mm" v-model="minute_end")
                                    option(v-for="i in 60" :value="i - 1") {{(i - 1) | format2Digits}}
                  .row
                    .col.pt-2
                      .d-flex.justify-content-between
                        button.btn.btn-primary(@click="addTimeGroupOption()") Guardar Configuración
                        //button.btn.btn-secondary(type="button") Modificar Horario
                      
                //.p-2(v-if="formTab == 'newBranch'")
                  h5.card-title Generar un nuevo horario
                  .row.mb-2
                    .col-8
                      input.form-control(v-model="branchLabel" placeholder="Nombre")
                    .col-4
                      input(type="color" v-model="branchColor")
                  .row.mb-2
                    .col  
                      .form-check.form-switch.mt-2
                        input.form-check-input(type="checkbox" role="switch" v-model="allDay")
                        label.form-check-label Seleccionar todo el día
                  template(v-if="!allDay")
                    .row
                      .col
                    .row
                      .col
                        label Inicio
                        .row
                          .col
                            select.form-select(v-model="hourStart")
                              option(v-for="i in 24") {{i-1}}
                          .col
                            select.form-select(v-model="minuteStart")
                              option(v-for="i in 60") {{i-1}}
                      .col
                        label Fin
                        .row
                          .col
                            select.form-select(v-model="hourEnd")
                              option(v-for="i in 24") {{i-1}}
                          .col
                            select.form-select(v-model="minuteEnd")
                              option(v-for="i in 60") {{i-1}}
                  .row.mt-2
                    .col
                      h5.card-title.mb-0.mt-2 Destino cumple horario
                  .row.mb-2
                    .col
                      label Tipo de destino
                      v-select(:options="destinationTypes" :reduce="label => label.id"  label="label" v-model="destinationTypeId")
                    .col
                      template(v-model="destinationTypeId")
                        label Seleccione un destino
                        v-select(:options="destinationOptions[destinationTypeId]" :reduce="label => label.id"  label="label" v-model="destinationOptionId")

                  .row.mt-2(v-if="!allDay")
                    .col
                      h5.card-title.mb-0.mt-2 Destino no cumple horario
                  .row(v-if="!allDay")
                    .col
                      label Tipo de destino
                      v-select(:options="destinationTypes" :reduce="label => label.id"  label="label" v-model="destinationTypeIdNo")
                    .col
                      template(v-model="destinationTypeId")
                        label Seleccione un destino
                        v-select(:options="destinationOptions[destinationTypeIdNo]" :reduce="label => label.id"  label="label" v-model="destinationOptionIdNo")
                  .row
                    .col.pt-2
                      button.btn.btn-primary(@click="createBranch()") Añadir horario


</template>
<script>
//import { tsvFormat } from 'd3-dsv'

  export default {
    name: 'PbxCalendar',
    data () {
      return {
        pbxCalendarGroup: {},
        pbxCalendarGroups: [],
        selectedDate: null,
        branchId: null,
        titles: [
          '',
          'Festivos Barcelona',
          'Festivos Bilbao',
          'Festivos Espanya',
          'Festivos Francia',
          'Festivos Madrid',
          'Festivos Portugal',
          'Festivos Valencia',
        ],
        formTab: 'assignBranch',
        isRange: false,
        allDay: true,
        hour_start: 0,
        minute_start: 0,
        hour_end: 0,
        minute_end: 0,
        calendarIndex: 0

      }
    },
    computed: {
        allDates() {
          let dates = []

          if(this.pbxCalendarGroup && this.pbxCalendarGroup.pbx_calendar_flow) {
            this.pbxCalendarGroup.pbx_calendar_flow.forEach((pcf) => {
              pcf.calendar_time_group.time_group_options.forEach((tgo) => {
                let index = dates.findIndex((d) => d.date == tgo.date)
                  
                if(index == -1) {
                  dates.push({
                    date: tgo.date,
                    elements: [{
                      time_group_option_id: tgo.id,
                      time_group_id: tgo.time_group_id,
                      pbx_calendar_flow_id: pcf.id,
                      pbx_calendar_flow_name: pcf.name,
                      pbx_calendar_flow_color: pcf.color,
                      hour_start: tgo.hour_start,
                      minute_start: tgo.minute_start,
                      hour_end: tgo.hour_end,
                      minute_end: tgo.minute_end
                    }]
                  })
                } else {
                  dates[index].elements.push({
                    time_group_option_id: tgo.id,
                    time_group_id: tgo.time_group_id,
                    pbx_calendar_flow_id: pcf.id,
                    hour_start: tgo.hour_start,
                    minute_start: tgo.minute_start,
                    hour_end: tgo.hour_end,
                    minute_end: tgo.minute_end
                  })
                }
              })
            })
          }

          return dates
        },
        selectedDateElements() {
          let elements = []
          if(this.selectedDate) {
            let formatedDate = this.$moment(this.selectedDate).clone().format('YYYY-MM-DD');
            let index = this.allDates.findIndex((d) => d.date == formatedDate)

            if(index > -1) {
              elements = this.allDates[index].elements
            }
          }

          return elements
        },
        branchesId() {
          let branches = []

          if(this.pbxCalendarGroup && this.pbxCalendarGroup.pbx_calendar_flow) {
            this.pbxCalendarGroup.pbx_calendar_flow.forEach((pcf) => {
              branches.push({
                id: pcf.id,
                label: pcf.name
              })
            })
          }

          return branches
        },
        pbxCalendarGroupBranches() {
          let branches = []

          if(this.pbxCalendarGroup && this.pbxCalendarGroup.pbx_calendar_flow) {
            //RC: Definimos la etiqueta
            this.pbxCalendarGroup.pbx_calendar_flow.forEach((pcf) => {
              let branch = {}
              branch.id = pcf.id
              branch.label = pcf.name
              branch.color = pcf.color
              branch.allDay = true
              branch.dates = []

              //RC: Registramos las fechas
              pcf.calendar_time_group.time_group_options.forEach((tgo) => {
                branch.dates.push({
                  date: tgo.date
                })
              })

              //RC: Lo añadimos a las ramas
              branches.push(branch)
            })
          }

          return branches
        },
        attributes() {
          let attributes = []

          this.pbxCalendarGroupBranches.forEach((b) => {
            let object = {
              bar: {
                style: {
                  "background-color": b.color,
                }
              },
              popover: {
                label: b.label
              }
            }

            let dates = []
            b.dates.forEach((d) => {
              if(d.date) {
                dates.push(d.date)
              } else {
                dates.push({start: d.dateStart, end: d.dateEnd})
              }
            })

            this.$set(object, "dates", dates)

            attributes.push(object)
          })

          return attributes
        }
    },
    mounted() {
      this.getPbxCalendarGroupGetStructure()
      this.$store.dispatch("pbxCalendarGroupGetAll").then((res) => {
        console.log(res)
        this.pbxCalendarGroups = res
      })
    },
    watch: {
      selectedDate(newVal) {
        if(newVal) {
          let formatedDate = this.$moment(newVal).clone().format('YYYY-MM-DD');
          let index = this.allDates.findIndex((d) => d.date == formatedDate)

          if(index > -1) {
            this.formTab = 'actualBranches'
          } else {
            this.formTab = 'assignBranch'
          }
          this.branchId = null
        }
      },
      calendarIndex(newVal) {
        if(this.pbxCalendarGroups[newVal]) {
          //this.pbxCalendarGroup = this.pbxCalendarGroups[newVal]
          this.$router.push("/pbx/calendar_group/" + this.pbxCalendarGroups[newVal].id)
        }
      },
      '$route.params.id': {
        handler: function() {
           setTimeout(()=> {
            this.getPbxCalendarGroupGetStructure()
           }, 500)
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      getPbxCalendarGroupGetStructure() {
        if(parseInt(this.$route.params.id)) {
          this.$store.dispatch("pbxCalendarGroupGetStructure",{
            "pbxCalendarGroupId": parseInt(this.$route.params.id)
          }).then((res) => {
            this.pbxCalendarGroup = res
          })
        }
      },
      addTimeGroupOption() {
        if(this.branchId && this.selectedDate) {
          if(this.pbxCalendarGroup && this.pbxCalendarGroup.pbx_calendar_flow) {
            let index = this.pbxCalendarGroup.pbx_calendar_flow.findIndex((pcf) => pcf.id == this.branchId)

            if(index > -1) {
              let timeGroupId = this.pbxCalendarGroup.pbx_calendar_flow[index].calendar_time_group_id

              let data  = {
                  date: this.selectedDate,
                  every_year: true,
                  saveOnPbx: true
              }

              if(!this.allDay) {
                data.hour_start = this.hour_start
                data.minute_start = this.minute_start
                data.hour_end = this.hour_end
                data.minute_end = this.minute_end
              }

              this.$store.dispatch("createTimeGroupOption", {
                timeGroupId: timeGroupId,
                data: data
              }).then(() => {
                this.selectedDate = null
                this.allDay = true
                this.hour_start = 0
                this.minute_start = 0
                this.hour_end = 0
                this.minute_end = 0
                this.getPbxCalendarGroupGetStructure()
              })
            }
          }
        }
      },
      removeTimeGroupOption(timeGroupId, timeGroupOptionId) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result && result.isConfirmed) {
            this.$store.dispatch("deleteTimeGroupOption", {
              timeGroupId: timeGroupId,
              timeGroupOptionId: timeGroupOptionId,
              data: {
                saveOnPbx: true
              }
            }).then(() => {
              this.selectedDate = null
              this.getPbxCalendarGroupGetStructure()
            })
          }
        }); 
      },
      addCurrentBranchDate() {
        

        let index = this.branches.findIndex((b) => b.id == this.branchId)

        if(index > -1) {
          let object = {}
          if(this.isRange) {
            object = {dateStart: this.$moment(this.selectedDate.start).clone().format('YYYY-MM-DD'), dateEnd: this.$moment(this.selectedDate.end).clone().format('YYYY-MM-DD')}
          } else {
            object = {date: this.$moment(this.selectedDate).clone().format('YYYY-MM-DD')}
          }
          this.branches[index].dates.push(object)
          this.branchId = null
          this.selectedDate = null
        }
      },
      createBranch() {
        if(this.selectedDate && this.branchLabel) {
          let object = {
            dates: []
          }

          this.$set(object, "label", this.branchLabel)

          if(this.branchColor) {
            this.$set(object, "color", this.branchColor)
          } else {
            this.$set(object, "color", "#6259ca")
          }

          if(this.isRange) {
            object.dates.push({dateStart: this.$moment(this.selectedDate.start).clone().format('YYYY-MM-DD'), dateEnd: this.$moment(this.selectedDate.end).clone().format('YYYY-MM-DD')})
          } else {
            object.dates.push({date: this.$moment(this.selectedDate).clone().format('YYYY-MM-DD')})
          }

          if(!this.allDay) {
            this.$set(object, "hourStart", this.hourStart)
            this.$set(object, "minuteStart", this.minuteStart)
            this.$set(object, "hourEnd", this.hourEnd)
            this.$set(object, "minuteEnd", this.minuteEnd)
            
          }

          this.$set(object, "allDay", this.allDay)
          this.$set(object, "destinationTypeId", this.destinationTypeId)
          this.$set(object, "destinationOptionId", this.destinationOptionId)
          
          
          this.branches.push(object)

          this.branchLabel = ''
          this.branchColor = ''
          this.selectedDate = null
          this.hourStart = null
          this.minuteStart = null
          this.hourEnd = null
          this.minuteEnd = null
          this.allDay = true
          this.destinationTypeId = null
          this.destinationOptionId = null
        }
      }
    }
  }
</script>
<style lang="scss">
.vc-weeks {
  padding: 15px !important;
}
</style>