<template lang="pug">
tr.my-queue-full
  td.text-end
    h5
      a(href="#!")
        span.badge.bg-secondary Total
  td.text-center.pb-0.position-relative
    h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="angle-double-right")
    h1.mt-1.mb-2.text-info {{totalQueueCalls}}
  td.text-center.pb-0.position-relative
    h5.text-secondary-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="exclamation-triangle")
    h1.mt-1.mb-2.text-secondary {{totalQueuesLostCalls}}
  td.text-center.pb-0.position-relative
    h5.text-primary-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="phone")
    h1.mt-1.mb-2.text-primary {{totalQueuesCalls}}
  td.text-center.pb-0.position-relative
    h5.text-warning-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="calendar")
    h1.mt-1.mb-2.text-warning {{Math.round(totalQueuesWaitTime)}}
  td.text-center.pb-0.position-relative
    h5.text-success-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="check")
    h1.mt-1.mb-2.text-success {{totalActiveQueueUsers}}
  td.text-center.pb-0.position-relative
    h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="thumbs-up")
    h1.mt-1.mb-2.text-info {{totalQueuesTargetWaitTime}}%
  td.text-center.pb-0.position-relative
    h5.text-dark-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="clipboard-check")
    h1.mt-1.mb-2.text-dark {{totalQueuesPickupRate}}%
</template>
<script>
  export default {
    name: 'MyQueueFullTotal',
    data () {
      return {
      }
    },
    props: {
      departments: {type: Array, default: function() {return [] }},
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      extensions() {
        return this.$store.state.extensionModule.extensions
      },
      users() {
        return this.$store.state.userModule.users
      },
      queues() {
        let allQueues = this.$store.state.queueModule.queues
        let queues = []

        this.departments.forEach((d) => {
          allQueues.forEach((q) => {
            if (q.department_id == d.id) {
              queues.push(q)
            }
          })
        })

        return queues
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      extensionCalls() {
        let extensionCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.extensions.findIndex((extension) => extension.number == connected)

            if(index > -1) {
              if(!extensionCalls[this.extensions[index].id]) {
                extensionCalls[this.extensions[index].id] = {}
                this.$set(extensionCalls[this.extensions[index].id], 'calls', [])
              }

              extensionCalls[this.extensions[index].id].calls.push(call)
            }
          })
        })

        return extensionCalls
      },
      queueExtensions() {
        let queueExtensions = []
        this.extensions.forEach((ext) => {
          this.queues.forEach((queue) => {
            let index = queue.queue_extensions.findIndex((qe) => qe.extension_id == ext.id && qe.penalty < 20)

            if(index > -1) {
              queueExtensions.push(ext)
            }
          })
        })

        return queueExtensions
      },
      queueExtensionUsers() {
        let queueExtensionUsers = []

        this.queueExtensions.forEach((qe) => {
          let extensionUsers = this.users.filter(u => u.extension_id == qe.id)

          this.$set(qe, 'users', extensionUsers)
          
          queueExtensionUsers.push(qe)
        })

        return queueExtensionUsers
      },
      queueUsers() {
        let queueUsers = []
        this.users.forEach((u) => {
          let index = this.queueExtensions.findIndex((qe) => u.extension_id && u.extension_id == qe.id)

          if(index > -1) {
            queueUsers.push(u)
          }
        })

        return queueUsers
      },
      activeQueueUsers() {
        let activeQueueUsers = []

        this.queueExtensionUsers.forEach((qeu) => {

          if(qeu.users && !this.extensionCalls[qeu.id] && qeu.extension_status_id == 3) {
            let allPaused = true
            qeu.users.forEach((u) => {
              if(u.active_user_session && !u.active_rest_time) {
                allPaused = false
              }
            })

            if(!allPaused) {
              let index = activeQueueUsers.findIndex((aqu) => aqu.id == qeu.id)
              if(index == -1) {
                activeQueueUsers.push(qeu)
              }
            }
          }
        })

        return activeQueueUsers
      },
      queueExtensionUsersCalls() {
        let queueExtensionUsersCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.queueExtensionUsers.findIndex((qeu) => qeu.number == connected)

            if(index > -1) {
              if(!queueExtensionUsersCalls[this.queueExtensionUsers[index].id]) {
                queueExtensionUsersCalls[this.queueExtensionUsers[index].id] = {}
                this.$set(queueExtensionUsersCalls[this.queueExtensionUsers[index].id], 'calls', [])
              }

              queueExtensionUsersCalls[this.queueExtensionUsers[index].id].calls.push(call)
            }
          })
        })

        return queueExtensionUsersCalls
      },
      totalActiveQueueUsers() {
        return this.activeQueueUsers.length
      },
      totalQueueUsersCalls() {
        
        return Object.keys(this.queueUsersCalls).length
      },
      totalIdleExtensions() {
        let idleExtensions = this.queueExtensions.filter((qe) => qe.extension_status_id == 3)

        return idleExtensions.length
      },
      totalInUseExtensions() {
        let idleExtensions = this.queueExtensions.filter((qe) => qe.extension_status_id == 7 || qe.extension_status_id == 8)

        return idleExtensions.length
      },
      queueCalls() {
        let calls = []

        this.calls.forEach((call) => {
          let index = this.queues.findIndex((q) => q.number == call.queue)

          if(index > -1) {
            calls.push(call)
          }
        })
        return calls
      },
      totalQueueCalls() {
        return this.queueCalls.length
      },
      totalQueuesLostCalls() {
        let lostCalls = 0
        this.departments.forEach((department) => {
          lostCalls = lostCalls + department.report_today.total_lost_calls
        })

        return lostCalls
      },
      totalQueuesCalls() {
        let total = 0
        this.departments.forEach((department) => {
          total = total + department.report_today.total_calls + department.report_today.total_calls_so - department.report_today.total_lost_calls_so
        })

        return total
      },
      totalQueuesWaitTime() {
        let total = 0
        this.departments.forEach((department) => {
          let successCalls = department.report_today.total_calls - department.report_today.total_lost_calls + department.report_today.total_calls_so - department.report_today.total_lost_calls_so
          
          total = total + (successCalls * parseFloat(department.report_today.wait_time)) 
        })

        if((this.totalQueuesCalls - this.totalQueuesLostCalls) > 0) {
          total = Math.round((total / (this.totalQueuesCalls - this.totalQueuesLostCalls)) * 100) / 100
        }

        return total
      },
      totalQueuesTargetWaitTime() {
        let total = 0
        let i = 0
        this.departments.forEach((department) => {
          if ((department.report_today.total_calls + department.report_today.total_calls_so - department.report_today.total_lost_calls_so) > 0) {
            total = total + Math.round(department.report_today.target_wait_time)
            i++
          }
          
        })

        if(i > 0) {
          total = Math.round(total / i)
        }

        return total
      },
      totalQueuesPickupRate() {
        //return Math.round((((this.totalQueuesCalls - this.totalQueuesLostCalls) / this.totalQueuesCalls) * 100))
        let total = 0
        let i = 0
        this.departments.forEach((department) => {
          if ((department.report_today.total_calls + department.report_today.total_calls_so - department.report_today.total_lost_calls_so) > 0) {
            total = total + Math.round(department.report_today.pickup_rate)
            i++
          }
        })

        if(i > 0) {
          total = Math.round(total / i)
        }

        return total
      }
    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>
  .h1, h1 {
    font-size: 3rem;
  }
</style>