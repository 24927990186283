<template lang="pug">
.webmail
  .row
    .col-3
      .card.mt-3
        .card-body
          .d-grid.mb-3 
            v-select(:options="emailConfigs" v-model="emailConfigId" :reduce="emailConfig => emailConfig.id"  label="name")
          .d-grid.mb-3 
            button.btn.btn-primary(@click="composeEmail()") 
              font-awesome-icon.me-1(icon="edit")
              span Redactar
          //.d-grid.gap-1
            button.btn.btn-primary.text-start Bandeja de entrada
            button.btn.btn-outline-primary.text-start Categoría 1
            button.btn.btn-outline-primary.text-start Categoría 2
            button.btn.btn-outline-primary.text-start Categoría 3


    .col
      .card.mb-2.mt-3
        .card-body
          .d-flex.align-items-center
            .flex-grow-1.pe-2
              .input-group 
                button.btn.btn-outline-primary(@click="getFolderMessages()")
                  font-awesome-icon(icon="sync-alt")
                input.form-control(type="text" placeholder="Buscar...")
            .ps-2
              span Mostrando de 
              strong {{ emailConfigMessagesStart }} a {{ emailConfigMessagesEnd }} 
              span de {{ emailConfigMessagesTotal }}
            .ps-2
              .input-group
                button.btn.btn-outline-primary(@click="decreasePage()") 
                  span <
                select.select.ps-2(v-model="currentPage")
                  option(v-for="i in emailConfigMessagesTotalPages") {{ i }}
                button.btn.btn-outline-primary(@click="increasePage()")
                  span >
      list-email-message.mt-2(v-if="!currentEmailMessage.subject && !compose" :emailConfigId="emailConfigId") 
      .bg-white.p-3.mt-2(v-else-if="!compose")
        .row
          .overflow-x-auto(:class="showTask ? 'col-8' : 'col'")
            .email-item.ps-2.pt-1.pb-2(:class="currentEmailMessage.unread ? 'unread' : ''")
              .d-flex.align-items-center.ps-1.pe-1.pt-2.pb-2.bg-light.border-bottom(v-for="previousMessage in previousMessages" @click="setCurrentEmailMessage(previousMessage)")
                .flex-grow-1
                  .d-block.mb-0
                    strong.email-subject {{ previousMessage.subject }}
                    div
                      span.text-secondary.me-2 De:
                      strong.text-secondary {{ printFrom(previousMessage) }}
                      strong.text-secondary.ms-2 &middot;
                      strong.text-primary.ms-2 {{ previousMessage.account.name }}
                      strong.text-secondary.ms-2 &middot;
                      span.text-secondary.ms-2 {{ previousMessage.sended_at | formatDateMin}}
              .d-flex.align-items-center.mt-2
                .flex-grow-1
                  .d-block.mb-0
                    strong.email-subject {{ currentEmailMessage.subject }}
                    div
                      span.text-secondary.me-2 De:
                      strong.text-secondary {{ printFrom(currentEmailMessage) }}
                      strong.text-secondary.ms-2 &middot;
                      strong.text-primary.ms-2 {{ currentEmailMessage.account.name }}
                      strong.text-secondary.ms-2 &middot;
                      span.text-secondary.ms-2 {{ currentEmailMessage.sended_at | formatDateMin}}
                    div
                      span.text-secondary.me-2 Para:
                      span.me-2
                        strong.text-secondary.me-1(v-for="emailTo in emailTos") {{emailTo.email}}&nbsp;
                    div
                      span.text-secondary.me-2 Cc:
                      span.me-2
                        strong.text-secondary.me-1(v-for="emailCc in emailCcs" style="display:inline-block;") {{emailCc.email}}&nbsp;
                .ps-2
                  .d-block.text-end.mb-2
                    .btn-group.ms-2
                      a.btn.btn-outline-secondary.btn-sm(@click="closeEmailMessage" href="#reply")
                        font-awesome-icon.me-1(icon="times")
                        span Cerrar
                  .d-block.text-end
                    .btn-group.me-2
                      button.btn.btn-outline-primary.btn-sm(@click="openReply()")
                        font-awesome-icon.me-1(icon="reply")
                        span Responder
                      button.btn.btn-outline-primary.btn-sm(@click="openReplyAll()")
                        font-awesome-icon.me-1(icon="reply-all")
                        span Responder a todos
                      button.btn.btn-outline-primary.btn-sm(@click="openForward()")
                        font-awesome-icon.me-1(icon="share")
                        span Reennviar
                    .btn-group
                      button.btn.btn-outline-warning.btn-sm(v-if="!ownEmailMessage" @click.stop="assignEmailMessage(currentEmailMessage)")
                        font-awesome-icon(icon="handshake")
                      button.btn.btn-outline-warning.btn-sm(v-else @click.stop="unassignEmailMessage(currentEmailMessage)")
                        font-awesome-icon(icon="handshake-slash")
                      button.btn.btn-outline-secondary.btn-sm(v-if="currentEmailMessage.unread" @click.stop="changeUnreadEmailMessage(currentEmailMessage, 1)")
                        font-awesome-icon(icon="envelope")
                      button.btn.btn-outline-secondary.btn-sm(v-else  @click.stop="changeUnreadEmailMessage(currentEmailMessage, 0)")
                        font-awesome-icon(icon="envelope-open")
                      button.btn.btn-outline-info.btn-sm(@click="showTask = !showTask")
                        font-awesome-icon(:icon="['fas', 'tasks']")
              .d-flex.justify-content-between
                div
                  span.badge.bg-secondary {{ currentEmailMessage.email_config.name }}
                div
                  span.badge.bg-primary.ms-1(v-for="user in currentEmailMessage.owners") {{user.name}}
            hr.mt-2.mb-2
            a.btn.btn-outline-primary.me-2(v-if="currentEmailMessage.email_message_attachments" v-for="attachment in currentEmailMessage.email_message_attachments" :href="attachment.path" target="_blank")
              font-awesome-icon.me-2(icon="cloud-download-alt")
              span {{attachment.name}}
            hr.mt-2.mb-2(v-if="currentEmailMessage.email_message_attachments.length")
            div(v-html="currentEmailMessage.email_message_body.body")
            .d-flex.align-items-center.ps-1.pe-1.pt-2.pb-2.bg-light.border-bottom(v-for="laterMessage in laterMessages" @click="setCurrentEmailMessage(laterMessage)")
                .flex-grow-1
                  .d-block.mb-0
                    strong.email-subject {{ laterMessage.subject }}
                    div
                      span.text-secondary.me-2 De:
                      strong.text-secondary {{ printFrom(laterMessage) }}
                      strong.text-secondary.ms-2 &middot;
                      strong.text-primary.ms-2 {{ laterMessage.account.name }}
                      strong.text-secondary.ms-2 &middot;
                      span.text-secondary.ms-2 {{ laterMessage.sended_at | formatDateMin}}
          .col-4(v-if="showTask")
            .p-2
              strong.text-primary Listado de tareas
              task-create(:accountId="currentEmailMessage.account_id")
              .task-list-items
                task-list-item(v-for="task in accountTasks" :key="'listTaskListItem' + task.id" :task="task" :emailMessageId="currentEmailMessage.id")
      .bg-white.p-2#reply(v-if="showReply")
        hr.mt-2.mb-2
        .input-group.mb-2
          v-select.form-control(type="text" v-model="to" multiple="multiple")
        .input-group.mb-2
          input.form-control(type="text" v-model="subject")
        ckeditor(:config="{}" v-model="body")
        .mt-2
          button.btn.btn-primary.me-2(@click="sendEmailMessage()")
            font-awesome-icon.me-1(icon="paper-plane")
            span Enviar
          button.btn.btn-secondary(@click="closeReply")
            font-awesome-icon.me-1(icon="trash")
            span Cancelar
</template>
<script>
  import ListEmailMessage from '../../components/email_messages/ListEmailMessage.vue'
  import ShowEmailMessage from '../../components/email_messages/ShowEmailMessage.vue'
  import TaskListItem from '../../components/tasks/TaskListItem.vue'
  import TaskCreate from '../../components/tasks/TaskCreate.vue'

export default {
  name: 'Webmail',
  components: {
    ListEmailMessage,
    ShowEmailMessage,
    TaskListItem,
    TaskCreate
  },
  data() {
    return {
      showTask: false,
      showReply: false,
      compose: false,
      to: [],
      emailConfigId: 1,
      subject: '',
      body: '',
      currentPage: 1,
      previousMessages: [],
      laterMessages: []
    }
  },
  computed: {
    counterKey() {
      return this.$store.state.counterKey
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    companyConfigs() {
      return this.$store.state.companyConfigs
    },
    currentEmailMessage() {
      return this.$store.state.emailConfigModule.currentEmailMessage
    },
    emailConfigMessagesStart() {
      return this.$store.state.emailConfigModule.start
    },
    emailConfigMessagesLimit() {
      return this.$store.state.emailConfigModule.limit
    },
    emailConfigMessagesTotal() {
      return this.$store.state.emailConfigModule.total
    },
    emailConfigMessagesEnd() {
      return parseInt(this.emailConfigMessagesStart) + parseInt(this.emailConfigMessagesLimit)
    },
    emailConfigMessagesTotalPages() {
      return Math.ceil(this.emailConfigMessagesTotal / this.emailConfigMessagesLimit)
    },
    emailConfigs() {
      return this.$store.state.emailConfigModule.emailConfigs
    },
    ownEmailMessage() {
      if (this.currentEmailMessage) {
        return this.currentEmailMessage.owners.findIndex((u) => u.id == this.currentUser.id) > -1
      } else {
        return false
      }
    },
    tasks() {
      return this.$store.state.taskModule.tasks
    },
    accountTasks() {
      return this.tasks.filter(t => t.account_id == this.currentEmailMessage.account_id)
    },
    emailFroms() {
      if (this.currentEmailMessage && this.currentEmailMessage.email_message_recipients) {
        return this.currentEmailMessage.email_message_recipients.filter(r => r.type == 'from')
      } else {
        return []
      }
    },
    emailTos() {
      if (this.currentEmailMessage && this.currentEmailMessage.email_message_recipients) {
        return this.currentEmailMessage.email_message_recipients.filter(r => r.type == 'to')
      } else {
        return []
      }
    },
    emailCcs() {
      if (this.currentEmailMessage && this.currentEmailMessage.email_message_recipients) {
        return this.currentEmailMessage.email_message_recipients.filter(r => r.type == 'cc')
      } else {
        return []
      }
    }
  },
  watch: {
    messageTempalateId(newVal, oldVal) {
      let params = []
      if (oldVal != newVal) {
        if (newVal != 0) {
          let index = this.messageTemplates.findIndex((mt) => mt.id == newVal)

          if (index > -1) {
            let messageTemplate = this.messageTemplates[index]

            messageTemplate.message_template_params.forEach((param) => {
              if (param.default) {
                params.push(param.default)
              } else {
                params.push('')
              }
            })
          }
        }

        this.params = params
      }

    },
    emailConfigId() {
      this.currentPage = 1
    },
    currentPage() {
      this.getFolderMessages()
    },
    currentEmailMessage(newValue, oldValue) {
      console.log("test")
      if (newValue.id) {
        if (!oldValue || newValue.id != oldValue.id) {
          this.getPreviousMessage()
          this.getLatersMessages()
        }
      } else {
        this.previousMessages = []
      }
    }
  },
  mounted() {
    this.getFolderMessages();
    this.$store.dispatch("getMyEmailConfigs").then((res) => {
      if (res.length > 0) {
        this.emailConfigId = res[0].id
      } else {
        this.emailConfigId = 0
      }
    })
  },
  methods: {
    getPreviousMessage() {
      this.$store.dispatch("getAllPreviousEmailMessage", {
        emailConfigId: this.emailConfigId,
        emailMessageId: this.currentEmailMessage.id
      }).then((res) => {
        this.previousMessages = res
      })
    },
    getLatersMessages() {
      this.$store.dispatch("getAlllatersEmailMessage", {
        emailConfigId: this.emailConfigId,
        emailMessageId: this.currentEmailMessage.id
      }).then((res) => {
        this.laterMessages = res
      })
    },
    increasePage() {
      this.currentPage +=1
    },
    decreasePage() {
      this.currentPage -=1
    },
    composeEmail() {
      this.compose = true
      this.showReply = true
      this.subject = ''
      this.body = ''
      this.to = []
      
    },
    openReply() {
      this.showReply = true
      this.subject = 'Re: ' + this.currentEmailMessage.subject
      this.to = []
      this.to.push(this.currentEmailMessage.from)
    },
    openForward() {
      this.showReply = true
      this.subject = 'Fwd: ' + this.currentEmailMessage.subject
      this.body = this.currentEmailMessage.body
      this.to = []
      
    },
    openReplyAll() {
      this.showReply = true
      this.subject = 'Re: ' + this.currentEmailMessage.subject
      this.to = []
      this.to.push(this.currentEmailMessage.from)
      this.currentEmailMessage.to.forEach((to) => {
        let index = this.to.findIndex((t) => t == to)
        if (index == -1) {
          this.to.push(to)
        }
      })
      this.currentEmailMessage.cc.forEach((to) => {
        let index = this.to.findIndex((t) => t == to)
        if (index == -1) {
          this.to.push(to)
        }
      })
    },
    closeReply() {
      this.compose = false
      this.showReply = false
      this.subject = ''
      this.to = []
    },
    getFolderMessages() {
      this.$store.dispatch("getEmailConfigMessages", {
        emailConfigId: this.emailConfigId,
        path: 'INBOX',
        page: this.currentPage
      })
    },
    closeEmailMessage() {
      this.closeReply()
      this.$store.commit("setEmailConfigCurrentEmailMessage", {})
    },
    changeUnreadEmailMessage(emailMessage, unread) {
      this.$store.dispatch("changeUnreadEmailMessage", {
        emailConfigId: this.emailConfigId,
        path: 'INBOX',
        emailMessageId: emailMessage.id,
        unread:unread
      }).then(() => {
        this.$store.dispatch("getEmailConfigMessages", {
          emailConfigId: this.emailConfigId,
          path: 'INBOX'
        }).then((res) => {

          let index = res.findIndex((e) => e.id == emailMessage.id)

          if (index > -1) {
            this.$store.commit("setEmailConfigCurrentEmailMessage", res[index])
          }
        })
      })
    },
    assignEmailMessage(emailMessage) {
      this.$store.dispatch("assignEmailMessage", {
        id: emailMessage.id,
        value: 1,
        userId: this.currentUser.id
      }).then(() => {
        this.$store.dispatch("getEmailConfigMessages", {
          emailConfigId: this.emailConfigId,
          path: 'INBOX'
        }).then((res) => {
          console.log(res)
          let index = res.findIndex((e) => e.id == emailMessage.id)

          if (index > -1) {
            this.$store.commit("setEmailConfigCurrentEmailMessage", res[index])
          }
        })
      })
    },
    unassignEmailMessage(emailMessage) {
      this.$store.dispatch("assignEmailMessage", {
        id: emailMessage.id,
        value: 0,
        userId: this.currentUser.id
      }).then(() => {
        this.$store.dispatch("getEmailConfigMessages", {
          emailConfigId: this.emailConfigId,
          path: 'INBOX'
        }).then((res) => {
          
          let index = res.findIndex((e) => e.id == emailMessage.id)

          if (index > -1) {
            this.$store.commit("setEmailConfigCurrentEmailMessage", res[index])
          }
        })
      })
    },
    sendEmailMessage() {
      let data = {
        recipients: this.to,
        body: this.body,
        subject: this.subject,
        email_config_id: this.emailConfigId
      }

      this.$store.dispatch("sendEmailConfirMessage", data).then(() => {
        this.closeReply()
      })
    },
    printFrom(emailMessage) {
      let froms = emailMessage.email_message_recipients.filter(r => r.type == 'from')

      let fromStr = ''
      froms.forEach((r) => {
        fromStr += r.email + ' '
      })

      return fromStr

    },
    setCurrentEmailMessage(emailMessage) {
      this.previousMessages = []
      this.laterMessages = []
      this.$store.commit("setEmailConfigCurrentEmailMessage", emailMessage)
      this.changeUnreadEmailMessage(emailMessage, 0)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";
.email-subject {
  font-size: 1.1em;
}
.unread {
  border-left: 4px solid $primary;
}
</style>