<template lang="pug">
  .parking_calls
    call-item(
      v-for="call in onParkingCalls" 
      :call="call" 
      :user="null" 
      :channelToRedirect="channelToRedirect" 
      :channelToTransfer="channelToTransfer" 
      @setChannelToRedirect="setChannelToRedirect"
      @setChannelToTransfer="setChannelToTransfer"
    )
</template>
<script>
  import CallItem from './CallItem.vue'
  export default {
    name: 'ParkingCalls',
    components: {
      CallItem
    },
    props: { 
      channelToRedirect: String,
      channelToTransfer: String,
      departmentsId: Array,
    },
    data () {
      return {
        
      }
    },
    computed: {
      calls() {
        return this.$store.state.callModule.calls
      },
      onParkingCalls() {
        let parkedCalls = this.calls.filter((c) => {return c.parked})
        if(this.departmentsId && this.departmentsId.length > 0) {
          parkedCalls = parkedCalls.filter((c) => this.departmentsId.findIndex((d) => d == c.departmentId) > -1)
        }

        return parkedCalls
      }
    },
    mounted() {
      
    },
    methods: {
      setChannelToRedirect(channel) {
        this.$emit("setChannelToRedirect", channel)
      },
      setChannelToTransfer(channel) {
        this.$emit("setChannelToTransfer", channel)
      },
    }
  }
</script>
<style lang="scss">
</style>