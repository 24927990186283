<template lang="pug">
.report-total-call-by-call-status
  .card.mb-3
    .card-body
      .d-flex.justify-content-between
        h5.card-title {{$t(reportReportItem.name)}}
      .row.mb-3
        .col.text-center 
          h1.h1.text-primary {{ totalInbound }}
        .col.text-center 
          h1.h1.text-success {{ totalOutbound }}
      table.table.table-striped
        thead
          tr
            th {{$t('Usuario')}}
            th.text-primary {{$t('In total Success')}}
            th.text-primary {{$t('In duration')}}
            th.text-success {{$t('Out total Success')}}
            th.text-success {{$t('Out duration')}}
            th.text-success {{$t('Without end')}}
        tbody
          tr(v-for="row in data.dataTable")
            td {{row.user}}
            td.text-center.text-primary {{row.in_success_total}}
            td.text-center.text-primary {{row.in_duration | timeInHoursMin  }}
            td.text-center.text-success {{row.out_success_total}}
            td.text-center.text-success {{row.out_duration | timeInHoursMin}}
            td.text-center.text-success {{parseInt(row.without_end_in) + parseInt(row.without_end_out)}}
              
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  export default {
    name: 'ReportTotalCallSummaryUser2',
    components: {
      ChartColumns
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f82649", "#f7b731", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      totalInbound() {
        if(this.data.dataTable) {
          let total = 0

          this.data.dataTable.forEach((u) => {
            total = total + parseInt(u.in_success_total)
          })

          return total
        } else {
          
          return '-'
        }
      },
      totalOutbound() {
        if(this.data.dataTable) {
          let total = 0

          this.data.dataTable.forEach((u) => {
            total = total + parseInt(u.out_success_total)
          })

          return total
        } else {

          return '-'
        }
      },
      series() {
        if(this.data.dataChart && this.data.dataChart.length > 0 ) {
          let series = []

          series.push({
            name: this.$t("Correcta"),
            data: []
          })

          series.push({
            name: this.$t("No atendida"),
            data: []
          })

          this.data.dataChart.forEach((col) => {
            
              if(this.showTab == 'in') {
                series[0].data.push(col.in_success_total)
                series[1].data.push(col.in_lost_total)
              } else if(this.showTab == 'out') {
                series[0].data.push(col.out_success_total)
                series[1].data.push(col.out_lost_total)
              } else {
                series[0].data.push(col.internal_success_total)
                series[1].data.push(col.internal_lost_total)
              }
          })

          return series
        } else {
          return []
        }
      },
      labels() {
        let labels = []
        if(this.data.dataChart && this.data.dataChart.length > 0 ) {
          this.data.dataChart.forEach((row) => {
            labels.push(row.user)
          })
        }

        return labels
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>