import Vue from 'vue'
import taskGroupsService from '../services/api/task_groups'

const taskGroupModule = {
    state: {
        taskGroup: {},
        taskGroups: [],
    },
    mutations: {
        setTaskGroup  (state, taskGroup) {
            state.taskGroup = taskGroup
        },
        setTaskGroups (state, taskGroups) {
            state.taskGroups = taskGroups
        },
        addTaskGroups (state, taskGroup) {
            let index = state.taskGroups.findIndex(x => x.id === taskGroup.id)

            if(index == -1) {
                state.taskGroups.push(taskGroup)
            }
        },
        updateTaskGroups (state, taskGroup) {
            let index = state.taskGroups.findIndex(x => x.id === taskGroup.id)

            if(index > -1) {
                Vue.set(state.taskGroups, index, taskGroup)
            }
        },
        deleteTaskGroups (state, taskGroup) {
            let index = state.taskGroups.findIndex(x => x.id === taskGroup.id)

            if(index > -1) {
                state.taskGroups.splice(index,1)
            }
        },
    },
    actions: {
        getMyTaskGroups(context) {
            return new Promise((resolve, reject) => {
                taskGroupsService.myTaskGroups()
                    .then((taskGroups) => {
                        context.commit('setTaskGroups', taskGroups)
                        resolve(taskGroups)
                    })
                    .catch(reject)
            })
        },
    }
}

export default taskGroupModule