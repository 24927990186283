import Vue from 'vue'
import userService from '../services/api/users'

const userModule = {
    state: {
        users: [],
        reportUsers: []
    },
    mutations: {
        setUsers (state, users) {
            state.users = users
        },
        addUser (state, user) {
            let index = state.users.findIndex(x => x.id === user.id)

            if(index == -1) {
                state.users.push(user)
            }
        },
        updateUser (state, user) {
            let index = state.users.findIndex(x => x.id === user.id)

            if(index > -1) {
                Vue.set(state.users, index, user)
            }
        },
        deleteUser (state, user) {
            let index = state.users.findIndex(x => x.id === user.id)

            if(index > -1) {
                state.users.splice(index,1)
            }
        },
        deleteUserByIndex (state, index) {
            if(index > -1) {
                state.users.splice(index,1)
            }
        },
        setReportUsers (state, reportUsers) {
            state.reportUsers = reportUsers
        },
        addReportUser (state, reportUser) {
            let index = state.reportUsers.findIndex(x => x.user_id === reportUser.user_id)

            if(index == -1) {
                state.reportUsers.push(reportUser)
            }
        },
        updateReportUser (state, reportUser) {
            let index = state.reportUsers.findIndex(x => x.user_id === reportUser.user_id)

            if(index > -1) {
                Vue.set(state.reportUsers, index, reportUser)
            }
        },
        deleteReportUser (state, reportUser) {
            let index = state.reportUsers.findIndex(x => x.user_id === reportUser.user_id)

            if(index > -1) {
                state.reportUsers.splice(index,1)
            }
        },
        deleteReportUserByIndex (state, index) {
            if(index > -1) {
                state.reportUsers.splice(index,1)
            }
        },
    },
    actions: {
        getUserReportTodays (context) {
            return new Promise((resolve, reject) => {
                userService.getReportTodays()
                    .then((res) => {
                        context.commit("setReportUsers", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default userModule