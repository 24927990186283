import Vue from 'vue'
import emailMessagesService from '../services/api/email_messages'

const emailMessageModule = {
    state: {
        emailMessages: [],
        currentEmailMessage: {}
    },
    mutations: {
        setEmailMessages (state, emailMessages) {
            state.emailMessages = emailMessages
        },
        setCurrentEmailMessage (state, currentEmailMessage) {
            state.currentEmailMessage = currentEmailMessage
        },
        saveEmailMessage(state, emailMessage) {
            let index = state.emailMessages.findIndex((em) => em.id == emailMessage.id)

            if(index == -1) {
                state.emailMessages.push(emailMessage)
            } else {
                Vue.set(state.emailMessages, index, emailMessage)
            }
        }
    },
    actions: {
        sendEmailMessage (context, payload) {
            return new Promise((resolve, reject) => {
                emailMessagesService.sendEmailMessage(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getAllEmailMessages(context) {
            return new Promise((resolve, reject) => {
                emailMessagesService.getAll()
                    .then((res) => {
                        context.commit("setEmailMessages", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        assignEmailMessage(context, payload) {
            return new Promise((resolve, reject) => {
                emailMessagesService.assignEmailMessage(payload.id, payload.value, payload.userId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        markUnreadEmailMessage(context, payload) {
            return new Promise((resolve, reject) => {
                emailMessagesService.markUnreadEmailMessage(payload.id, payload.value)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        
    }
}

export default emailMessageModule