<template lang="pug">
.task-type
  h5.title-card.text-secondary.me-3(@click="tooglePopup")
    font-awesome-icon(:icon="task.task_type.icon")
    span.ms-1(v-if="!reduced") {{task.task_type.name}}
  .backgroundPopup(v-if="showForm" @click="closePopup")
  .popup-form(v-if="showForm")
    .card.card-custom-v2.shadow-lg
      .card-header.pt-1.pb-1 
        .d-flex.justify-content-between.align-items-center 
          span.text-primary.text-uppercase Tipo de tarea
          font-awesome-icon.text-secondary(icon="times" @click="closePopup")
      .card-body.p-2
        v-select(:options="taskTypes" :reduce="taskType => taskType.id"  label="label" v-model="taskTypeId")
</template>

<script>
  export default {
    name: 'TaskType',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, default: false},
    },
    data () {
      return {
        showForm: false,
        taskTypeId: 0
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      taskTypes() {
        return this.$store.state.taskModule.taskTypeLists
      },
      openedDays() {
        let windowTime = this.$options.filters.diffToNow(this.task.created_at)
        windowTime = this.$options.filters.timeInHoursShort(windowTime)

        return windowTime
      },
      bgOpenedDays() {
        let background = 'bg-info'
        
        if(this.openedDays == '+48h') {
          background = 'bg-danger blink'
        } else if(this.openedDays == '+24h') {
          background = 'bg-warning'
        }

        return background
      }
      
    },
    watch: {
      taskTypeId(newVal, oldVal) {
        if(oldVal != 0) {
          this.update(newVal)
        }
      }
    },
    beforeMount() {
      this.taskTypeId = this.task.task_type_id
    },
    methods: {
      tooglePopup() {
        this.showForm = !this.showForm
      },
      closePopup() {
        this.showForm = false
      },
      update(value) {
        let task = this.task
        this.$set(task, 'task_type_id', value)

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'tasks',
          id: this.task.id,
          data: task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.$store.commit("updateTasks", res)
            this.$store.commit("updateUnfinishedTasks", res)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import "../../scss/colors.scss";
  
  .backgroundPopup {
    background: transparent;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  
  .card.card-custom-v2 .card-header {
    background: #ffffff;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .card.card-custom-v2 .card-footer {
    background: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .card.card-custom-v2 .card-header h5.title-card, .card.card-custom-v2 .card-footer h5.title-card {
    font-size: 0.85em;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .card.card-custom-v2 .card-body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .card.card-custom-v2 .card-body h1.task-title {
    border-left: 3px solid $primary;
    padding-left: 10px;
    font-size: 1em;
    display: block;
    margin-bottom: 0px;
  }
  .card.card-custom-v2 .card-body h1.task-title small {
    display:block;
    font-weight: 300;
    font-size: 0.8em;
    text-transform: uppercase;
  }
  .task-type {
    max-width: 160px;
    position: relative;
  }
  h5.title-card {
    font-size: 0.85em;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .popup-form {
    position:absolute;
    top: 20px;
    z-index: 200;
    width: 160px;
    font-size: 0.85em;
  }
  .popup-form > .card {
    border: 1px solid $light;
  }
  </style>