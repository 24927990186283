<template lang="pug">
.task-list.container-fluid.pt-2
  .row
    .col-9
      .card 
        .card-body
          task-create.mb-3
          task-calendar(:tasks="sortedTasks" )
    .col-3
      .card
        .card-body 
          .row
            .col-12
              h5.card-title.mb-0 Filtrar
            .col-12.mt-2
              label Buscar por nombre
              input.form-control.p-1(type="text" v-model="filter" placeholder="Buscar...")
            .col-12.mt-2
              label Filtrar por grupo
              v-select.bg-white(:options="taskGroups" :reduce="label => label.id"  label="name" v-model="filterTaskGroup" placeholder="Todos")
            .col-12.mt-2
              label Filtrar por final
              v-select.bg-white(:options="taskEnds" :reduce="label => label.id"  label="name" v-model="filterTaskEnd" placeholder="Todos")
            .col-12.mt-2
              label Filtrar por cuenta
              v-select(:options="accountOptions" :reduce="account => account.id"  label="label" @search="accountsAjaxSearch" placeholder="Todas" v-model="filterAccount")
            .col-12.mt-2
              label Filtrar por tipo
              v-select.bg-white(:options="taskTypes" :reduce="label => label.id"  label="label" v-model="filterTaskTypes" placeholder="Todos" multiple="multiple")
            .col-12.mt-2
              label Filtrar por prioridad
              v-select.bg-white(:options="taskPriorities" :reduce="label => label.id"  label="label" v-model="filterTaskPriority" placeholder="Todos")
            .col-12.mt-2
              label Filtrar por estado
              v-select.bg-white(:options="taskStatuses" :reduce="label => label.id"  label="label" v-model="filterTaskStatus" placeholder="Todos")
            .col-12.mt-2
              label Filtrar por usuario
              v-select.bg-white(:options="users" :reduce="label => label.id"  label="name" v-model="filterUsers" placeholder="Todos" multiple="multiple")
            .col-6.mt-2
              label Fecha de inicio 
              input.form-control(type="date" v-model="filterDateStart")
            .col-6.mt-2
              label Fecha de fin 
              input.form-control(type="date" v-model="filterDateEnd")
            .col-12.mt-2
              .form-check.form-switch
                input.form-check-input#showOnlyMy(type="checkbox" v-model="showOnlyMy")
                label.form-check-label(for="showOnlyMy") Ver sólo mis tareas
            .col-12.mt-2
              .form-check.form-switch
                input.form-check-input#showOnlyToday(type="checkbox" v-model="showOnlyToday")
                label.form-check-label(for="showOnlyToday") Ver sólo de hoy
            .col-12.mt-2
              .form-check.form-switch
                input.form-check-input#showFinished(type="checkbox" v-model="showFinished")
                label.form-check-label(for="showFinished") Ver tareas finalizadas
</template>

<script>
  import TaskCreate from '../../components/tasks/TaskCreate.vue'
  import TaskCalendar from '../../components/tasks/TaskCalendar.vue'
  import { createPopper } from '@popperjs/core'
  import {Modal} from 'bootstrap'
  export default {
    name: 'Calendar',
    components: {
      TaskCalendar,
      TaskCreate,
    },
    beforeMount() {
      this.$store.dispatch("getMyTasks").then(() => {
        if (this.$route.params.id) {
          this.showTask(parseInt(this.$route.params.id))
        }
      })
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'tasks'})
        .then((res) => {
          this.currentModule = res
        })

      /*this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_groups',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.taskGroups = res
        })*/
    },
    data () {
      return {
        filter: '',
        filterTaskStatus: '',
        filterTaskGroup: '',
        filterTaskEnd: '',
        filterTaskTypes: [],
        filterUsers: [],
        filterTaskPriority: '',
        filterAccount: '',
        filterDateStart: null,
        filterDateEnd: null,
        currentModule: {},
        taskName: '',
        taskGroupId: null,
        taskTypeId: 1,
        allTaskStatuses: [],
        accountOptions: [],
        taskDateStart: null,
        placement: 'top',
        showOnlyMy: false,
        showFinished: false,
        showOnlyToday: false,
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      accounts() {
        return this.$store.state.accountModule.accounts
      },
      users() {
        return this.$store.state.userModule.users
      },
      tasks() {
        return this.$store.state.taskModule.tasks
      },
      taskStatuses() {
        return this.$store.state.taskModule.taskStatusLists
      },
      taskTypes() {
        return this.$store.state.taskModule.taskTypeLists
      },
      taskPriorities() {
        return this.$store.state.taskModule.taskPriorityLists
      },
      taskGroups() {
        return this.$store.state.taskGroupModule.taskGroups
      },
      taskEnds() {
        return this.$store.state.taskModule.taskEnds
      },
      taskStatusesCounter() {
        let taskStatusCounter = []

        this.allTaskStatuses.forEach((ts) => {
          if(ts.finish == 0) {
            let total = this.tasks.filter((t) => t.task_status_id == ts.id).length

            taskStatusCounter.push({
              name: ts.name,
              total: total,
              color: ts.color
            })
          }
        })

        return taskStatusCounter
      },
      filteredTasks() {
        let filteredTasks = this.tasks

        filteredTasks = filteredTasks.filter(task => {
          return (task.code && task.code.toLowerCase().includes(this.filter.toLowerCase())) || task.task_description.name.toLowerCase().includes(this.filter.toLowerCase()) || task.task_description.description.toLowerCase().includes(this.filter.toLowerCase())
        })

        if(this.filterTaskGroup) {
          filteredTasks = filteredTasks.filter(task => {
            return task.task_group_id == this.filterTaskGroup
          })
        }
        if (this.filterTaskEnd) {
          filteredTasks = filteredTasks.filter(task => {
            return task.task_end_id == this.filterTaskEnd
          })
        }

        if(this.filterTaskStatus) {
          filteredTasks = filteredTasks.filter(task => {
            return task.task_status_id == this.filterTaskStatus
          })
        }

        if(this.filterTaskTypes.length > 0) {
          filteredTasks = filteredTasks.filter(task => {
            return this.filterTaskTypes.findIndex(ftt => ftt == task.task_type_id) > -1
          })
        }
        
        if(this.filterTaskPriority) {
          filteredTasks = filteredTasks.filter(task => {
            return task.task_priority_id == this.filterTaskPriority
          })
        }
        
        if(this.filterAccount) {
          filteredTasks = filteredTasks.filter(task => {
            return task.account_id == this.filterAccount
          })
        }
        
        if(this.filterUsers.length > 0) {
          filteredTasks = filteredTasks.filter(task => {
            let value = false

            task.users.forEach((u) => {
              value = value ||  this.filterUsers.findIndex(ftu => ftu == u.id) > -1
            })

            return value
          })
        }
        
        if(this.showOnlyMy) {
          filteredTasks = filteredTasks.filter(task => {
            return task.users.findIndex(u => u.id == this.currentUser.id) > -1
          })
        }

        if(this.showOnlyToday) {
          filteredTasks = filteredTasks.filter(task => {
            return task.today || (task.start_date && this.$moment(this.$moment(new Date()).format('YYYY-MM-DD')).isSameOrAfter(task.start_date))
          })
        }

        if(this.filterDateStart) {
          filteredTasks = filteredTasks.filter(task => {
            return (task.start_date && this.$moment(this.$moment(task.start_date)).isSameOrAfter(this.filterDateStart) || task.created_at && this.$moment(this.$moment(task.created_at)).isSameOrAfter(this.filterDateStart))
          })
        }

        if(this.filterDateEnd) {
          filteredTasks = filteredTasks.filter(task => {
            return (task.start_date && this.$moment(this.$moment(task.start_date)).isSameOrBefore(this.filterDateEnd) || task.created_at && this.$moment(this.$moment(task.created_at)).isSameOrBefore(this.filterDateEnd))
          })
        }

        filteredTasks = filteredTasks.filter(task => {
          return task.finished == this.showFinished
        })

        return filteredTasks
      },
      sortedTasks() {
        
        let tasks = this.filteredTasks

        return tasks.sort((a, b) => {
          if(a.today && !b.today) return -1
          if(!a.today && b.today) return 1
          if(a.start_date && b.start_date && a.start_date > b.start_date) return 1
          if(a.start_date && b.start_date && a.start_date < b.start_date) return -1
          if(a.start_date && !b.start_date ) return -1
          if(!a.start_date && b.start_date ) return 1
          if(a.task_priority.weight > b.task_priority.weight) return -1
          if(a.task_priority.weight < b.task_priority.weight) return 1
          
          return 0
        })
      }
    },
    methods: {
      showTask(taskId) {
        this.$store.commit("setModalTaskId", taskId)
        let taskModal = document.getElementById('taskModal')
        if(taskModal.style.display != 'block') {
          new Modal(taskModal).show() 
        }
      },
      accountsAjaxSearch(search, loading) {
        let filter = {
              filter: search,
              moduleUrl: 'accounts',
              columns: 'id,name',
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.accountOptions = res
              loading(false)
          })
      },
      setTab(tab) {
        this.currentTab = tab
      },
      createTask() {
        if(this.taskName) {
          this.$store.dispatch('createRegister', {
            moduleUrl: 'tasks',
            data: {
              name: this.taskName,
              task_type_id: this.taskTypeId,
              task_group_id: this.taskGroupId,
              start_date: this.taskDateStart
            }
          }).then((res) => {
            this.$store.commit("addTasks", res)
            this.taskName = ''
          }) 
        }
      },
      createAdvancedTask(task) {
        this.$store.commit("addTasks", task)
      },
      deleteTask() {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este element?',
          icon: 'warning',
          showCancelButton: true
        }).then(function (result) {
          console.log(result)
        });
      },
      withPopper(dropdownList, component, { width }) {
        dropdownList.style.width = width

        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1],
              },
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn({ state }) {
                component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
                )
              },
            },
          ],
        })

        return () => popper.destroy()
      },
    }
  }
</script>
<style scoped lang="scss">
  @import "../../scss/colors.scss";
  .task-list-items {
    height: calc(100vh - 155px);
    overflow-y: auto;
  }
  .task-list-items::-webkit-scrollbar {
    width: 8px;
  }

</style>