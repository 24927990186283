<template lang="pug">
  .screen-user-statuses-large
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 {{ $t('Estado usuarios') }} 
          a.text-dark(v-if="showUsers" @click="showUsers = !showUsers" href="#!")
            font-awesome-icon(icon="compress-alt")
          a.text-dark(v-else  @click="showUsers = !showUsers"  href="#!")
            font-awesome-icon(icon="expand-alt")
        table.table.text-center
          thead
            tr
              th(style="width:25%") 
                strong
                  font-awesome-icon.me-1(icon="check")
                br
                span {{ $t('ACTIVOS') }}
              th(style="width:25%") 
                strong
                  font-awesome-icon.me-1(icon="phone")
                br
                span {{ $t('EN LLAMADA') }}
              th(style="width:25%") 
                strong
                  font-awesome-icon.me-1(icon="coffee")
                br
                span {{ $t('EN DESCANSO') }}
              th(style="width:25%") 
                strong
                  font-awesome-icon.me-1(icon="times-circle")
                br
                span {{ $t('DESCONECTADO') }}
          tbody
            tr
              td
                h1.mb-0.text-success {{totalUsersOnline}}
              td
                h1.mb-0.text-primary {{totalUsersInCall}}
              td
                h1.mb-0.text-warning {{totalUsersInRestTime}}
              td
                h1.mb-0.text-secondary {{totalUsersOffline}}
            tr(v-if="showUsers")
              td(colspan="4")
                .row.row-cols-xl-3(:class="filters.classColumn ? filters.classColumn : 'row-cols-xxl-4'")
                  .col.text-start(v-for="user in sortedUsers" :key="'gc-user-user' + user.id")
                    user-status(
                      :user="user" 
                      :calls="userCalls[user.id] ? userCalls[user.id].calls : []" 
                      :showCalls="false" 
                      :showImage="false"
                      :showExtensionName="showExtensionName"
                    )



</template>
<script>
  import UserStatus from '../users/UserStatus.vue'
  export default {
    name: 'ScreenUserStatusesLarge',
    components: {
      UserStatus
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        showUsers: true,
      }
    },
  computed: {
    showExtensionName() {
      if (this.filters.showUserName) {
        return false  
      }
      
      return true  
    },
      users() {
        if(this.filters.departments_id) {
          let users = []
          this.$store.state.userModule.users.forEach((u) => {
            if(u.department_id) {
              let index = this.filters.departments_id.findIndex((dId) => dId == u.department_id )

              if(index > -1) {
                users.push(u)
              }
            }
          })

          return users
        } else {
          return this.$store.state.userModule.users
        }
      },
      sortedUsers () {
        let users = this.users
        return users.sort((a, b) => {
            
            if (a.name < b.name) {
                return -1
            } else if (a.name > b.name) {
                return 1
            }
            
            return 0
        })
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      userCalls() {
        let userCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.users.findIndex((u) => u.extension && u.extension.number == connected)

            if(index > -1) {
              if(!userCalls[this.users[index].id]) {
                userCalls[this.users[index].id] = {}
                this.$set(userCalls[this.users[index].id], 'calls', [])
              }

              userCalls[this.users[index].id].calls.push(call)
            }
          })
        })

        return userCalls
      },
      totalUsers() {
        return this.users.length
      },
      totalUsersOnline() {
        let i = 0
        this.users.forEach((u) => {
          if(u.active_user_session && !u.active_rest_time && !this.userCalls[u.id]) {
            i = i + 1
          }
        })

        return i
      },
      totalUsersInCall() {
        let i = 0
        this.users.forEach((u) => {
          if(this.userCalls[u.id]) {
            i = i + 1
          }
        })
    
        return i
      },
      totalUsersInRestTime() {
        let i = 0
        this.users.forEach((u) => {
          if(u.active_rest_time  && !this.userCalls[u.id]) {
            i = i + 1
          }
        })

        return i
      },
      totalUsersOffline() {
        return this.totalUsers - this.totalUsersOnline - this.totalUsersInCall - this.totalUsersInRestTime
      }
    },
    mounted() {
      this.$store.dispatch("getRegisters", {moduleUrl: "users"})
        .then((users) => {
          this.$store.commit("setUsers", users)
        })
        
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>