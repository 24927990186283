<template lang="pug">
.task-window
  span.badge(:class="bgOpenedDays") {{openedDays}}
</template>

<script>
  export default {
    name: 'TaskWindow',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, default: false},
    },
    data () {
      return {
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      openedDays() {
        let windowTime = this.$options.filters.diffToNow(this.task.created_at)
        windowTime = this.$options.filters.timeInHoursShort(windowTime)

        return windowTime
      },
      bgOpenedDays() {
        let background = 'bg-info-opacity-50 text-white'
        
        if(this.openedDays == '+48h') {
          background = 'bg-danger-opacity-50 blink text-white'
        } else if(this.openedDays == '+24h') {
          background = 'bg-warning-opacity-50 text-secondary'
        }

        return background
      }
      
    },
    beforeMount() {
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.badge {
  font-weight: 400;
}
</style>