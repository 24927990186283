<template lang="pug">
.chat-room-v2-list-item
  .card-gc.mb-2(@click="selectChatRoom()" :class="selectedChatRoom && selectedChatRoom.id == chatRoom.id ? 'bg-primary-opacity-50' : ''")
    .card-gc-body(:class="getBorderColor")
      .d-flex
        .flex-grow-1
          strong {{chatRoom.account.name}}
        div
          span.badge.bg-success.rounded-pill(v-if="chatRoom.unread")
            font-awesome-icon.me-1(icon="envelope")
            span {{chatRoom.unread}}
      div
        span {{chatRoom.from}}
      .d-flex.mt-1.justify-content-between  
        div
          span.badge.bg-success.me-2 {{chatRoom.last_account_message | diffToNow | timeInHoursShort}}
          span.badge.bg-primary.me-2 {{chatRoom.whatsapp_number.name}}
          span.badge.bg-warning.me-2(v-if="chatRoom.message_end") {{chatRoom.message_end.name}}
        div
          span.badge.bg-dark(v-if="chatRoom.owner_user" data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-html="true" :title="chatRoom.owner_user.name") {{chatRoom.owner_user.name | string2Initials }}
      .d-flex.align-items-center.mt-1(v-if="chatRoom.account.opened_tasks && chatRoom.account.opened_tasks.length > 0")  
        font-awesome-icon.me-1(icon="tasks")
        span {{chatRoom.account.opened_tasks[0].task_description.name.substring(0, 40) }}
        span(v-if="chatRoom.account.opened_tasks[0].task_description.name.length > 40") ...
                  
          
</template>
<script>
  export default {
    name: 'ChatRoomV2',
    props: {
      reduced: { type: Boolean, default: false },
      chatRoom: { type: Object, default: function () { return {} } },
    },
    data () {
      return {
        
      }
    },
    computed: {
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      chatRooms() {
        return this.$store.state.chatRoomModule.chatRooms
      },
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedChatRoom
      },
      getBorderColor() {
        let border = 'border-info'
        if (this.chatRoom.owner_user_id && this.chatRoom.owner_user_id == this.currentUser.id) {
          border = 'border-primary'
        } else if (this.chatRoom.owner_user_id && this.chatRoom.owner_user_id != this.currentUser.id) {
          border = 'border-secondary'
        }

        return border
      },
    },
    mounted() {
      
    },
    methods: {
      
      getWindowBackground(lastAccountMessage) {
        let windowTime = this.$options.filters.diffToNow(lastAccountMessage)
        windowTime = this.$options.filters.timeInHoursShort(windowTime)

        let background = 'bg-success'
        if (windowTime == '+48h') {
          background = 'bg-dark';
        } else if (windowTime == '+24h') {
          background = 'bg-dark';
        } else if (windowTime == '23h') {
          background = 'bg-danger';
        } else if (windowTime == '22h') {
          background = 'bg-warning';
        }

        return background;
      },
      selectChatRoom() {
        let chatRoom = this.chatRoom
        this.$set(chatRoom, "transfer", false)
        this.$store.commit("updateChatRoom", this.chatRoom)
        this.$store.dispatch("chatRoomUserMarkAsRead", { chatRoomId: this.chatRoom.id })
        this.$store.commit("setSelectedChatRoom", this.chatRoom)

        if (this.companyConfigs && this.companyConfigs.chatroom_capture_open && this.companyConfigs.chatroom_capture_open == 1) {
          //RC: Tenemos que capturar el chatroom
          this.$store.dispatch("captureChatRoom", {
            chatRoomId: this.chatRoom.id,
          })
        }
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>