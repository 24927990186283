<template lang="pug">
tr.my-queue-full
  td.text-end
    h5
      a(data-bs-toggle="modal" :data-bs-target="'#extensionsQueue' + department.id")
        span.badge(:style="'background: ' + department.color + ';'" v-if="department.short_name") {{department.short_name}}
        span.badge(:style="'background: ' + department.color + ';'" v-else) {{department.name}}
    .modal.fade(:id="'extensionsQueue' + department.id" tabindex='-1' :aria-labelledby="'extensionsQueue' + department.id + 'Label'" aria-hidden='true')
      .modal-dialog.modal-xl
        .modal-content
          .modal-header
            h5.modal-title(:id="'extensionsQueue' + department.id + 'Label'") Agentes 
            button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
          .modal-body
            .row.row-cols-xxl-4.row-cols-xl-3
              .col.text-start(v-for="extension in sortedQueueExtensionUsers")
                extension-user-status(
                    :extension="extension" 
                    :calls="extensionCalls[extension.id] ? extensionCalls[extension.id].calls : []" 
                    :showCalls="false" 
                  )
  td.text-center.pb-0.position-relative
    h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="angle-double-right")
    h1.mt-1.mb-2.text-info {{totalQueueCalls}}
    .bg-info-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
      font-awesome-icon.me-1(icon="clock")
      span(v-if="firstQueueCall" :key="'durationFirstCall' + counterKey") {{[firstQueueCall.start, "unix"] | unixToDateTimeEn | diffToNow | timeInHoursMin}}
  td.text-center.pb-0.position-relative
    h5.text-secondary-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="exclamation-triangle")
    h1.mt-1.mb-2.text-secondary {{department.report_today.total_lost_calls}}
    .bg-secondary-opacity-25.small.pt-1.pb-1.text-center
      strong SO: {{department.report_today.total_lost_calls_so}}
  td.text-center.pb-0.position-relative
    h5.text-primary-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="phone")
    h1.mt-1.mb-2.text-primary {{department.report_today.total_calls + department.report_today.total_calls_so - department.report_today.total_lost_calls_so}}
  td.text-center.pb-0.position-relative
    h5.text-warning-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="calendar")
    h1.mt-1.mb-2.text-warning {{Math.round(department.report_today.wait_time)}}
    .bg-warning-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
      font-awesome-icon.me-1(icon="history")
      span {{Math.round(department.report_today.last_hour_wait_time)}}
  td.text-center.pb-0.position-relative
    h5.text-success-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="check")
    h1.mt-1.mb-2.text-success {{totalActiveQueueUsers}}
    .bg-success-opacity-25.text-end.pe-2.small.pt-1.pb-1(v-if="showQueue")
      font-awesome-icon.me-1(icon="phone")
      span {{totalQueueExtensionUsersCalls}}
  td.text-center.pb-0.position-relative
    h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="thumbs-up")
    h1.mt-1.mb-2.text-info(v-if="department.disable_statistics") -
    h1.mt-1.mb-2.text-info(v-else) {{Math.round(department.report_today.target_wait_time)}}%
  td.text-center.pb-0.position-relative
    h5.text-dark-opacity-20.position-absolute(style="top: 3px;left: 15px")
      font-awesome-icon(icon="clipboard-check")
    h1.mt-1.mb-2.text-dark(v-if="department.disable_statistics") -
    h1.mt-1.mb-2.text-dark(v-else) {{Math.round(department.report_today.pickup_rate)}}%
    .bg-secondary-opacity-25.small.pt-1.pb-1.text-center
      strong SO: {{Math.round(department.report_today.pickup_rate_so)}}%
</template>
<script>
  import ExtensionUserStatus from '../extensions/ExtensionUserStatus.vue'
  export default {
    name: 'DepartmentQueueFullPenalty',
    components: {
      ExtensionUserStatus
    },
    data () {
      return {
        audio: new Audio('/media/UpAndDown_rt.wav'),
        isPlaying: false, 
        showQueue: false
      }
    },
    props: {
      department: {type: Object, default: function() {return {} }}
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      counterKey() {
        return this.$store.state.counterKey
      },
      queues() {
        return this.$store.state.queueModule.queues.filter(q => q.department_id == this.department.id)
      },
      extensions() {
        return this.$store.state.extensionModule.extensions
      },
      users() {
        return this.$store.state.userModule.users
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      extensionCalls() {
        let extensionCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.extensions.findIndex((extension) => extension.number == connected)

            if(index > -1) {
              if(!extensionCalls[this.extensions[index].id]) {
                extensionCalls[this.extensions[index].id] = {}
                this.$set(extensionCalls[this.extensions[index].id], 'calls', [])
              }

              extensionCalls[this.extensions[index].id].calls.push(call)
            }
          })
        })

        return extensionCalls
      },
      queueExtensions() {
        let queueExtensions = []
        this.extensions.forEach((ext) => {
          this.queues.forEach((queue) => {
            let index = queue.queue_extensions.findIndex((qe) => qe.extension_id == ext.id && qe.penalty < 20)

            if (index > -1) {
              queueExtensions.push(ext)
            }
          })
        })

        return queueExtensions
      },
      queueExtensionUsers() {
        let queueExtensionUsers = []
        this.queueExtensions.forEach((qe) => {
          let extensionUsers = this.users.filter(u => u.extension_id == qe.id)

          this.$set(qe, 'users', extensionUsers)
          
          queueExtensionUsers.push(qe)
        })

        return queueExtensionUsers
      },
      sortedQueueExtensionUsers(){
        let sortedQueueExtensionUsers = this.queueExtensionUsers

        return sortedQueueExtensionUsers.sort((a, b) => {
            if (a.users.length > 0 && b.users.length == 0) {
              return -1
            } else if (a.users.length == 0 && b.users.length > 0) {
              return 1
            } else if (a.name < b.name) {
              return -1
            } else if (a.name > b.name) {
              return 1
            } 
            
            return 0
          })
      },
      queueUsers() {
        let queueUsers = []
        this.users.forEach((u) => {
          let index = this.queueExtensions.findIndex((qe) => u.extension_id && u.extension_id == qe.id)

          if(index > -1) {
            queueUsers.push(u)
          }
        })

        return queueUsers
      },
      activeQueueUsers() {
        let activeQueueUsers = []

        this.queueExtensionUsers.forEach((qeu) => {
          if(qeu.users && !this.extensionCalls[qeu.id] && qeu.extension_status_id == 3) {
            let allPaused = true
            qeu.users.forEach((u) => {
              if(u.active_user_session && !u.active_rest_time) {
                allPaused = false
              }
            })

            if(!allPaused) {
              activeQueueUsers.push(qeu)
            }
          }
        })

        return activeQueueUsers
      },
      queueExtensionUsersCalls() {
        let queueExtensionUsersCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.queueExtensionUsers.findIndex((qeu) => qeu.number == connected)

            if(index > -1) {
              if(!queueExtensionUsersCalls[this.queueExtensionUsers[index].id]) {
                queueExtensionUsersCalls[this.queueExtensionUsers[index].id] = {}
                this.$set(queueExtensionUsersCalls[this.queueExtensionUsers[index].id], 'calls', [])
              }

              queueExtensionUsersCalls[this.queueExtensionUsers[index].id].calls.push(call)
            }
          })
        })

        return queueExtensionUsersCalls
      },
      totalActiveQueueUsers() {
        return this.activeQueueUsers.length
      },
      totalQueueExtensionUsersCalls() {
        
        return Object.keys(this.queueExtensionUsersCalls).length
      },
      totalIdleExtensions() {
        let idleExtensions = this.queueExtensions.filter((qe) => qe.extension_status_id == 3)

        return idleExtensions.length
      },
      totalInUseExtensions() {
        let idleExtensions = this.queueExtensions.filter((qe) => qe.extension_status_id == 7 || qe.extension_status_id == 8)

        return idleExtensions.length
      },
      queueCalls() {
        let queueCalls = []
        this.calls.forEach((c) => {
          let index = this.queues.findIndex(q => q.number == c.queue)

          if (index > -1) {
            queueCalls.push(c)
          }
        })

        return queueCalls
      },
      totalQueueCalls() {
        return this.queueCalls.length
      },
      firstQueueCall() {
        if(this.totalQueueCalls > 0) {
          return this.queueCalls[0]
        } else {
          return null
        }
      }
    },
    watch: {
      totalQueueCalls(newVal) {
        if(this.currentUser.user_type_id == 5) {
          if(newVal > 0 && this.totalActiveQueueUsers == 0) {
            this.isPlaying = true
            this.audio.loop = true
            this.audio.play()
          } else if(newVal == 0) {
            if(this.isPlaying) {
              this.isPlaying = false
              this.audio.loop = false
              this.audio.pause();
              this.audio.currentTime = 0;
            }
          }
        }
      },
      totalActiveQueueUsers(newVal) {
        if(newVal > 0 && this.isPlaying) {
          this.isPlaying = false
            this.audio.loop = false
            this.audio.pause();
            this.audio.currentTime = 0;
        }
      }
    },
    mounted() {
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>
  .h1, h1 {
    font-size: 3rem;
  }
</style>