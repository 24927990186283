<template lang="pug">
.report-total-call-by-country
  .card.mb-3
    .card-body
      .d-flex.justify-content-between
        h5.card-title {{$t(reportReportItem.name)}} {{$t('in')}}
      
      table.table
        thead
          tr
            th {{$t('País')}}
            th {{$t('In total')}}
            th {{$t('In duration')}}
            th {{$t('In media')}}
        tbody
          tr(v-for="row in data.dataTable")
            td {{row.country}}
            td {{row.in_total}}
            td {{row.in_duration | timeInHoursMin  }}
            td {{row.in_media | timeInHoursMin}}
  .card.mb-3
    .card-body
      .d-flex.justify-content-between
        h5.card-title {{$t(reportReportItem.name)}}  {{$t('out')}}
      
      table.table
        thead
          tr
            th {{$t('País')}}
            th {{$t('Out total')}}
            th {{$t('Out duration')}}
            th {{$t('Out media')}}
        tbody
          tr(v-for="row in data.dataTable")
            td {{row.country}}
            td {{row.out_total}}
            td {{row.out_duration | timeInHoursMin}}
            td {{row.out_media | timeInHoursMin}}
              
</template>
<script>
  import ChartDonut from '../chart/ChartDonut.vue'
  export default {
    name: 'ReportTotalCallByCountry',
    components: {
      ChartDonut
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      series() {
        if(this.data.dataChart && this.data.dataChart[this.showTab].series) {
          let series = []
          this.data.dataChart[this.showTab].series.forEach((s) => {
            series.push(parseInt(s))
          })
          return series
        } else {
          return []
        }
      },
      labels() {
        if (this.data.dataChart && this.data.dataChart[this.showTab].labels) {
          let labels = []

          this.data.dataChart[this.showTab].labels.forEach((label) => {
            labels.push(this.$t(label))
          })

          return labels
        } else {
          return []
        }
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>