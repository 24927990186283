<template lang="pug">
  .report-total-call-by-call-type
    .card.mb-3
      .card-body
        h5.card-title {{$t(reportReportItem.name)}}
        template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
          chart-donut(:series="series" :labels="labels" :colors="colors")
        table.table
          thead
            tr
              th {{$t('Tipo')}}
              th {{$t('Total')}}
              th {{$t('Duración')}}
              th {{$t('Duración media')}}
          tbody
            tr(v-for="row in data.dataTable")
              td {{row[0]}}
              td {{row[1]}}
              td {{row[2] | timeInHoursMin}}
              td {{row[3] | timeInHoursMin}}
        
              
</template>
<script>
  import ChartDonut from '../chart/ChartDonut.vue'
  export default {
    name: 'ReportTotalCallByCallType',
    components: {
      ChartDonut
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        colors: ["#6259ca", "#09ad95", "#f7b731"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      series() {
        if(this.data.dataChart && this.data.dataChart.series) {
          let series = []
          this.data.dataChart.series.forEach((s) => {
            series.push(parseInt(s))
          })
          return series
        } else {
          return []
        }
      },
      labels() {
        if (this.data.dataChart && this.data.dataChart.labels) {
          let labels = []

          this.data.dataChart.labels.forEach((label) => {
            labels.push(this.$t(label))
          })

          return labels
        } else {
          return []
        }
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>