<template lang="pug">
.task-my-today-report.mb-3
  .card
    .card-body
      h5.card-title.mb-2 Resumen
      .row.g-2
        .col
          .card-gc.bg-success-opacity-25
            .card-body-gc.text-end.p-2
              h5.text-success.mb-1 
                font-awesome-icon.me-2(icon="clock")
                span {{taskReport.duration | timeInHoursMin}}
              span.text-success Tiempo dedicado
        .col
          .card-gc.bg-primary-opacity-25
            .card-body-gc.text-end.p-2
              h5.text-primary.mb-1 
                font-awesome-icon.me-2(icon="flag")
                span {{taskReport.total_finished}} / {{taskReport.total}}
              span.text-primary Pendientes finalizar
</template>

<script>
  export default {
    name: 'TaskMyTodayReport',
    data () {
      return {
        
      }
    },
    computed: {
      taskReport() {
        return this.$store.state.taskModule.todayReport
      }
    },
    beforeMount() {
      
    },
    watch: {
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>

</style>