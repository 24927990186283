<template lang="pug">
#timelineShow
    h5.card-title.text-primary Actividad
    .d-flex.justify-content-center
      .btn-group.mb-3.text-center
        button.btn(:class="showCalls ? 'btn-primary' :  'btn-outline-primary'" @click="showCalls = !showCalls")
          font-awesome-icon.me-1(icon="phone")
          span Llamadas
        button.btn(:class="showWhatsapps ? 'btn-success' :  'btn-outline-success'" @click="showWhatsapps = !showWhatsapps")
          font-awesome-icon.me-1(:icon="['fab', 'whatsapp']")
          span Whatsapps
        button.btn(:class="showEmails ? 'btn-info' :  'btn-outline-info'" @click="showEmails = !showEmails")
          font-awesome-icon.me-1(icon="envelope")
          span Email
        button.btn(:class="showComments ? 'btn-secondary' :  'btn-outline-secondary'" @click="showComments = !showComments")
          font-awesome-icon.me-1(icon="comments")
          span Comentarios
        button.btn(:class="showTimes ? 'btn-warning' :  'btn-outline-warning'" @click="showTimes = !showTimes") 
          font-awesome-icon.me-1(:icon="['fas', 'stopwatch']")
          span Tiempos

    div
      .mb-2(v-for="timelineItem in timeline" :key="'timelineItem' + timelineItem.type + timelineItem.start")
        template(v-if="timelineItem.type == 'comment' && showComments")
          .d-flex
            .me-2
              .rounded.bg-secondary.text-white.pt-1.pb-1.ps-2.pe-2
                font-awesome-icon(icon="comments")
            .flex-grow-1
              comment-view-item(:comment="timelineItem.data")
            .ms-2
        template(v-else-if="timelineItem.type == 'message' && showWhatsapps")
          .d-flex
            .me-2(v-if="timelineItem.message.user_id")
              .rounded.bg-success.text-white.pt-1.pb-1.ps-2.pe-2
                font-awesome-icon(:icon="['fab', 'whatsapp']")
            .flex-grow-1
              whatsapp-view-item(:message="timelineItem.message")
            .ms-2(v-if="!timelineItem.message.user_id")
              .rounded.bg-success.text-white.pt-1.pb-1.ps-2.pe-2
                font-awesome-icon(:icon="['fab', 'whatsapp']")
        template(v-else-if="timelineItem.type == 'email' && showEmails")
          .d-flex
            .me-2(v-if="timelineItem.email_message.write_user_id")
              .rounded.bg-info.text-white.pt-1.pb-1.ps-2.pe-2
                font-awesome-icon(icon="envelope")
            .flex-grow-1
              email-view-item(:email="timelineItem.email_message")
            .ms-2(v-if="!timelineItem.email_message.write_user_id")
              .rounded.bg-info.text-white.pt-1.pb-1.ps-2.pe-2
                font-awesome-icon(icon="envelope")
        template(v-else-if="timelineItem.type == 'call' && showCalls")
          .d-flex
            .me-2(v-if="timelineItem.call.call_type_id == 2")
              .rounded.bg-primary.text-white.pt-1.pb-1.ps-2.pe-2
                font-awesome-icon(icon="phone")
            .flex-grow-1
              call-view-item(:call="timelineItem.call")
            .ms-2(v-if="timelineItem.call.call_type_id == 1")
              .rounded.bg-primary.text-white.pt-1.pb-1.ps-2.pe-2
                font-awesome-icon(icon="phone")
        template(v-else-if="timelineItem.type == 'taskTime' && showTimes")
          .d-flex
            .me-2
              .rounded.bg-warning.text-white.pt-1.pb-1.ps-2.pe-2
                font-awesome-icon(:icon="['fas', 'stopwatch']")
            .flex-grow-1
              task-time-view-item(:taskTime="timelineItem.data")
</template>
<script>

import CommentViewItem from './CommentViewItem.vue'
import WhatsappViewItem from './WhatsappViewItem.vue'
import EmailViewItem from './EmailViewItem.vue'
import CallViewItem from './CallViewItem.vue'
import TaskTimeViewItem from './TaskTimeViewItem.vue'
export default {
  name: 'timeline',
  components: {
    CommentViewItem,
    WhatsappViewItem,
    EmailViewItem,
    CallViewItem,
    TaskTimeViewItem
  },
  props: {
    timeline: { type: Array, default: () => [] },
  },
  data() {
    return {
      showCalls: true,
      showWhatsapps: true,
      showEmails: true,
      showComments: true,
      showTimes: true,
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    newComment(comment) {
      this.$emit("newComment", comment)
    }
  }
}
</script>
<style scoped>
p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>