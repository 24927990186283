<template lang="pug">
#taskHeader
  template(v-if="startedTasks.length == 0")
    a(href="#" data-bs-toggle='offcanvas' data-bs-target='#offcanvasRight' aria-controls='offcanvasRight' @click="openTasks()")
      font-awesome-icon(:icon="['fas', 'stopwatch']")
  template(v-else)
    a(data-bs-toggle='offcanvas' href='#offcanvasHeaderTask' role='button' aria-controls='offcanvasHeaderTask')
      font-awesome-icon(:icon="['fas', 'stopwatch']")
      span.ps-2 {{intervalTasks.length}}
  #offcanvasHeaderTask.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasHeaderTaskLabel')
    .offcanvas-header
      h5#offcanvasHeaderTaskLabel.offcanvas-title Mis tareas activas
      a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
        font-awesome-icon(icon='times')
    .offcanvas-body
      template(v-for="taskHeader in intervalTasks")
        .card.mb-2
          .card-body.p-2
            strong.text-dark {{taskHeader.name}}
            br
            button.btn.btn-danger.btn-round.mt-2(@click="endTask(taskHeader)")
              font-awesome-icon.me-2(icon="stop") 
              span(:key="'task' + taskHeader.id + counterKey") {{taskHeader.start | diffToNow | timeInHours}}
</template>
<script>
export default {
  name: 'TaskHeader',
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
    counterKey() {
      return this.$store.state.counterKey
    },
    currentUser() {
      if(localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'))
        return user.user
      } else {
        return {}
      }
    },
    startedTasks() {
      if(this.$store.state.taskModule.startedTasks) {
        return this.$store.state.taskModule.startedTasks
      } else {
        return []
      }
    },
    intervalTasks() {
      let intervalTasks = []
      this.startedTasks.forEach((task) => {
        task.task_times.forEach((taskTime) => {
          if(taskTime.user_id == this.currentUser.id && !taskTime.duration) {
            intervalTasks.push({
              id: task.id,
              name: task.task_description.name,
              start: taskTime.start
            })
          }
        })
      })

      return intervalTasks
    },
    totalDuration() {
      let duration = 0;

      this.intervalTasks.forEach((task) => {
        duration = duration + this.$options.filters.diffToNow(task.start)
      })

      return duration;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getMyTasks")
    this.$store.dispatch("getMyCurrentTasks")
    this.$store.dispatch("getMyTaskGroups")
    this.$store.dispatch("getTodayReport")
    
    this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_types',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.$store.commit("setTaskTypeLists", res)
        })
    this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_priorities',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.$store.commit("setTaskPriorityLists", res)
        })
    this.$store.dispatch("getRegistersList", {
          moduleUrl: 'task_statuses',
          columns: 'id,name',
          conditions: ''
        }).then((res) => {
          this.$store.commit("setTaskStatusLists", res)
        })
    this.$store.dispatch("getRegisters", {
          moduleUrl: 'task_ends',
        }).then((res) => {
          this.$store.commit("setTaskEnds", res)
        })
  },
  mounted() {
    window.Echo.channel('App.Company.' + this.currentUser.company_id)
      .listen('TaskCreate', (e) => {
          this.taskCreate(e.task)
      })
      .listen('TaskUpdate', (e) => {
          this.taskUpdate(e.task)
      })
      .listen('TaskRemainder', (e) => {
        this.taskRemainder(e.task)
      })
  },
  beforeDestroy() {

  },
  methods: {
    endTask(task) {
      this.$store.dispatch("endTask", {
        taskId: task.id
      })
    },
    openTasks() {
      this.$router.push('/tasks/inbox')
    },
    taskCreate(task) {
      this.$store.commit("addTasks", task)
      this.$store.dispatch("getTodayReport")

      let index = task.users.findIndex((u) => u.id == this.currentUser.id)

      if(index > -1) {
        this.$store.commit("pushNotifications", {
          module: 'tasks',
          color: 'success',
          icon: 'tasks',
          title: "Nueva tarea #" + task.id,
          body: task.task_description.name
        })
      }
    },
    taskUpdate(task) {
      this.$store.commit("updateTasks", task)
      this.$store.dispatch("getTodayReport")

      let index = task.users.findIndex((u) => u.id == this.currentUser.id)

      if(index > -1) {
        this.$store.commit("pushNotifications", {
          module: 'tasks',
          color: 'info',
          icon: 'tasks',
          title: "Tarea modificada #" + task.id,
          body: task.task_description.name
        })
      }
    },
    taskRemainder(task) {
      let index = task.users.findIndex((u) => u.id == this.currentUser.id)

      if (index > -1) {
        console.log(task)
        let options = {
          body: "Recordatorio " + task.task_description.name,
          requireInteraction: true,
          icon: "/media/logo.png"
        }

        let n = new Notification("Recordatorio", options);
        n.onclick = ((event) => {
          event.preventDefault();
          window.focus()
          n.close()
        })
        n.onclose = (() => {
        })
      }
    }
  }
}
</script>
<style scoped>
  
</style>
