<template lang="pug">
.report-bs-kpi
  .row
    div(:class="expanded ? 'col-10' : 'col-6'")
      .card.mb-3
        .card-body
          .d-flex.justify-content-between
            h5.card-title Información general de llamadas
            //.form-check.form-switch
              input.form-check-input(type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="expanded")
              label.form-check-label(for="flexSwitchCheckDefault") Ampliar información
          table.table(v-if="parentDepartments")
            thead
              tr
                th
                th.bg-primary-opacity-50.text-center(colspan="7") In
                th.bg-success-opacity-50.text-center(colspan="2") Out
                th
              tr
                th
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Total of calls received in each 900/800 free numbe") #Total Incoming calls
                th.text-center.bg-secondary-opacity-50
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that don’t ring in any extension because the customer has hung during the voiceover, or there wasn’t any extension available, etc.") #Total Non-offered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that ring in one extension at least") #Total offered calls
                th.text-center.bg-secondary-opacity-50
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Offered calls that have rung on an extension but haven’t been picked") #Total not answered calls
                th.text-center.bg-primary-opacity-50
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total incoming answered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in relation to total incoming calls") %T answered calls/incoming calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Answered calls in relation to offered calls (calls that have rung on an extension)") %T answered calls/offered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total outgoing calls
                th.text-center.bg-success-opacity-50
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total outgoing answered calls
                th
            tbody
              tr(v-for="department in parentDepartments")
                td {{department.name}}
                //td.text-center {{department.incomming_calls}}
                td.text-center {{printIncommingCalls(department)}}
                td.text-center.bg-secondary-opacity-25 {{department.incomming_no_offered_calls}}
                td.text-center {{printOfferedCalls(department)}}
                td.text-center.bg-secondary-opacity-25 {{printNoAnsweredCalls(department)}}
                td.text-center.bg-primary-opacity-25 {{department.incomming_answered_calls}}
                td.text-center {{department.incomming_answered_vs_incomming}}%
                td.text-center {{department.incomming_answered_vs_offered}}%
                td.text-center {{department.outgoing_calls}}
                td.text-center.bg-success-opacity-25 {{department.outgoing_answered_calls}}
                td
                  a.text-info(@click="setDepartment(department.department_id)" href="#!")
                    font-awesome-icon(v-if="clickCurrentDepartmentId.findIndex((d) => d == department.department_id) == -1" icon="angle-down")
                    font-awesome-icon(v-else icon="angle-up")
            //tfoot
              tr(v-if="data.dataTotal")
                td Total
                td.text-center {{data.dataTotal.incomming_calls}}
                td.text-center.bg-secondary-opacity-25(v-if="expanded" v-for="(label, index) in data.i_no_offered_calls_label")
                  span(v-if="data.dataTotal.no_offered_calls_more_info && data.dataTotal.no_offered_calls_more_info[index] && data.dataTotal.no_offered_calls_more_info[index].total") {{data.dataTotal.no_offered_calls_more_info[index].total}}
                  span(v-else) 0
                td.text-center.bg-secondary-opacity-25 {{data.dataTotal.no_offered_calls}}
                td.text-center {{data.dataTotal.offered_calls}}
                td.text-center(v-if="expanded" v-for="(label, index) in data.i_no_answered_calls_label"  :class="label == 'Correcta' ? 'bg-secondary-opacity-25' : 'bg-secondary-opacity-25'")
                  span(v-if="data.dataTotal.no_answered_calls_more_info && data.dataTotal.no_answered_calls_more_info[index] && data.dataTotal.no_answered_calls_more_info[index].total") {{data.dataTotal.no_answered_calls_more_info[index].total}}
                  span(v-else) 0
                td.text-center.bg-secondary-opacity-25 {{printNoAnsweredCalls(data.dataTotal)}}
                td.text-center {{data.dataTotal.answered_calls}}
                td.text-center {{data.dataTotal.answered_vs_incomming}}%
                td.text-center {{data.dataTotal.answered_vs_offered}}%
                td
  .row
    .col
      .card.mb-3(v-if="showCurrentDepartmentId")
        .card-body
          .d-flex.justify-content-between
            h5.card-title Información general de llamadas por departamento
          table.table.mb-3
            thead
              tr
                th
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Total of calls received in each 900/800 free number") #Total Incoming calls
                th.text-center.bg-secondary-opacity-50
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that don’t ring in any extension because the customer has hung during the voiceover, or there wasn’t any extension available, etc.") #Total Non-offered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that ring in one extension at least") #Total offered calls
                th.text-center.bg-secondary-opacity-50
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Offered calls that have rung on an extension but haven’t been picked") #Total not answered calls
                th.text-center.bg-primary-opacity-50
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total incoming answered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in relation to total incoming calls") %T answered calls/incoming calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Answered calls in relation to offered calls (calls that have rung on an extension)") % T Pick Up rate
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total outgoing calls
                th.text-center.bg-success-opacity-50
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total outgoing answered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="") T calls VoC
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="") T rated calls VoC
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="") avg VoC
                //th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls offered in this city, area or centre, with respect to the total of the country") % offered calls/total offered calls
                //th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls picked in this city, area or centre, with respect to the total of offered calls in the country") % answered calls/total offered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in less than 20 seconds in relation to the total offered  calls in each area") %Service level
                th
            tbody
              tr(v-for="department in childDepartments" v-if="showDepartmentIds.findIndex((d) => d == department.department_id) > -1" :class="getBackgroundColorByDeep(department.deep)")
                td(:class="'ps-' + department.deep")
                  span.me-2(v-if="department.deep > 1") -
                  span {{department.name}}
                td.text-center {{printIncommingCalls(department)}}
                td.text-center.bg-secondary-opacity-25 {{department.incomming_no_offered_calls}}
                td.text-center {{printOfferedCalls(department)}}
                td.text-center.bg-secondary-opacity-25 {{printNoAnsweredCalls(department)}}
                td.text-center.bg-primary-opacity-25 {{department.incomming_answered_calls}}
                td.text-center {{department.incomming_answered_vs_incomming}}%
                td.text-center {{department.incomming_answered_vs_offered}}%
                td.text-center {{department.outgoing_calls}}
                td.text-center.bg-success-opacity-25 {{department.outgoing_answered_calls}}
                //td.text-center {{department.incomming_offered_vs_total_offered}}%
                //td.text-center {{department.incomming_answered_vs_total_offered}}%
                td.text-center {{department.voc_calls}}
                td.text-center {{department.score_calls}}
                td.text-center {{department.avg_score}}
                td.text-center {{department.incomming_target_wait_vs_answered}}%
                td
                  a.text-info(@click="setDepartment(department.department_id)" href="#!")
                    font-awesome-icon(v-if="clickCurrentDepartmentId.findIndex((d) => d == department.department_id) == -1" icon="angle-down")
                    font-awesome-icon(v-else icon="angle-up")

          .pt-3
            table.table(v-if="kpiUsers.length")
              thead
                tr
                  th
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that ring in one extension at least") #Total offered calls
                  th.text-center.bg-primary-opacity-50
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total answered calls
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total outgoing calls
                  th.text-center.bg-success-opacity-50
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total outgoing answered calls
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in relation to total incoming calls") %T answered calls/incoming calls
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Answered calls in relation to offered calls (calls that have rung on an extension)") %T Pick Up rate
                  //th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls offered in this city, area or centre, with respect to the total of the country") % offered calls/total offered calls
                  //th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls picked in this city, area or centre, with respect to the total of offered calls in the country") % answered calls/total offered calls
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in less than 20 seconds in relation to the total offered calls in each area") %Service level
                  th.text-center
                    span Total time on the phone in (last agent)
                  th.text-center
                    span Total time on the phone out (last agent)
                  th.text-center
                    span Total time on the phone
                  th.text-center.bg-secondary-opacity-50
                    span Total time on the phone (internal calls)
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Average on-call time for this agent") Average handle time
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Average response time of this agent") Average answer time
                  th.text-center.bg-info-opacity-20
                    span Hours logged in the system
                  th.text-center.bg-danger-opacity-50
                    span Hours paused in the system
                  th.text-center.bg-warning-opacity-50
                    span Hours paused productive in the system
                  th.text-center.bg-info-opacity-50
                    span Hours active in the system
                  th.text-center.bg-info
                    span Hours productive in the system
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of time on the phone compared to time logged on the system") Total time on phone / hours logged
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of time on the phone compared to active time on the system") Total time on phone / hours active
                  th.text-center
                    span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of time on the phone compared to active time on the system") Total time on phone / hours productive
                  th.text-center
                    span T calls VoC
                  th.text-center
                    span VoC
              tbody
                tr(v-if="kpiUsers" v-for="kpiUser in kpiUsers")
                  td {{kpiUser.name}}
                  td.text-center {{kpiUser.incomming_offered_calls}}
                  td.text-center.bg-primary-opacity-25 {{kpiUser.incomming_answered_calls}}
                  td.text-center {{kpiUser.outgoing_calls}}
                  td.text-center.bg-success-opacity-25 {{kpiUser.outgoing_answered_calls}}
                  td.text-center {{kpiUser.incomming_answered_vs_incomming}}%
                  td.text-center {{kpiUser.incomming_answered_vs_offered}}%
                  //td.text-center {{kpiUser.offered_vs_total_offered}}%
                  //td.text-center {{kpiUser.answered_vs_total_offered}}%
                  td.text-center {{kpiUser.incomming_target_wait_vs_answered}}%
                  td.text-center {{kpiUser.time_on_phone_in_last | timeInHours}}
                  td.text-center {{kpiUser.time_on_phone_out_last | timeInHours}}
                  td.text-center {{kpiUser.time_on_phone | timeInHours}}
                  td.text-center.bg-secondary-opacity-25 {{kpiUser.time_on_phone_internal | timeInHours}}
                  td.text-center {{kpiUser.avg_time_on_phone | timeInHours}}
                  td.text-center {{kpiUser.avg_ring_time | timeInHours}}
                  td.text-center.bg-info-opacity-10 {{kpiUser.logged_time | timeInHours}}
                  td.text-center.bg-danger-opacity-20 {{kpiUser.paused_time | timeInHours}}
                  td.text-center.bg-warning-opacity-20 {{kpiUser.paused_productive_time | timeInHours}}
                  td.text-center.bg-info-opacity-25 {{kpiUser.active_time | timeInHours}}
                  td.text-center.bg-info-opacity-50 {{kpiUser.productive_time | timeInHours}}
                  td.text-center {{kpiUser.time_on_phone_vs_logged}}%
                  td.text-center {{kpiUser.time_on_phone_vs_active}}%
                  td.text-center {{kpiUser.time_on_phone_vs_productive}}%
                  td.text-center {{kpiUser.voc_calls}}
                  td.text-center {{kpiUser.score_calls}}
                  td.text-center {{kpiUser.avg_score}}

  //.row
    .col
      .card.mb-3(v-if="data.dataUsers.length > 0")
        .card-body
          .d-flex.justify-content-between
            h5.card-title Información general de llamadas por usuario
          table.table
            thead
              tr
                th
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Total of calls received in each 900/800 free number") Total Incoming calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls that ring in one extension at least") #Total offered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls offered and attended by an agent") #Total answered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in relation to total incoming calls") %T answered calls/incoming calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Answered calls in relation to offered calls (calls that have rung on an extension)") %T Pick Up rate
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls offered in this city, area or centre, with respect to the total of the country") % offered calls/total offered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of calls picked in this city, area or centre, with respect to the total of offered calls in the country") % answered calls/total offered calls
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Calls answered in less than 20 seconds in relation to the total offered  calls in each area") %Service level
                th.text-center
                  span Total time on the phone
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Average on-call time for this agent") Average handle time
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Average response time of this agent") Average answer time
                th.text-center
                  span Hours logged in the system
                th.text-center
                  span Hours paused in the system
                th.text-center
                  span Hours active in the system
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of time on the phone compared to time logged on the system") Total time on phone / hours logged
                th.text-center
                  span(data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage of time on the phone compared to active time on the system") Total time on phone / hours active
            tbody
              tr(v-for="kpiUser in data.dataUsers")
                td {{kpiUser.name}}
                td.text-center {{kpiUser.incomming_calls}}
                td.text-center {{kpiUser.offered_calls}}
                td.text-center {{kpiUser.answered_calls}}
                td.text-center {{kpiUser.answered_vs_incomming_calls}}%
                td.text-center {{kpiUser.answered_vs_offered_calls}}%
                td.text-center {{kpiUser.offered_vs_total_offered}}%
                td.text-center {{kpiUser.answered_vs_total_offered}}%
                td.text-center {{kpiUser.answered_less20}}%
                td.text-center {{kpiUser.time_on_phone | timeInHours}}
                td.text-center {{kpiUser.avg_time_on_phone | timeInHours}}
                td.text-center {{kpiUser.avg_ring_time | timeInHours}}
                td.text-center {{kpiUser.logged_time | timeInHours}}
                td.text-center {{kpiUser.paused_time | timeInHours}}
                td.text-center {{kpiUser.active_time | timeInHours}}
                td.text-center {{kpiUser.time_on_phone_vs_logged}}%
                td.text-center {{kpiUser.time_on_phone_vs_active}}%
</template>
<script>
import ChartColumns from '../chart/ChartColumns.vue'
import { Tooltip } from 'bootstrap'
export default {
  name: 'ReportBSKpi',
  components: {
    ChartColumns
  },
  props: {
    reportReportItem: { type: Object, default: function () { return {} } },
    filters: { type: Object, default: function () { return {} } },
  },
  data() {
    return {
      data: {},
      showTab: 'in',
      colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
      lastFilter: 0,
      months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      showExtensions: true,
      expanded: false,
      showNoAnsweredCorrecta: false,
      showDeep: 0,
      showDepartmentIds: [],
      showCurrentDepartmentId: 0,
      clickCurrentDepartmentId: [],
      kpiExtensions: [],
      kpiUsers: []

    }
  },
  computed: {
    series() {
      if (this.data.dataChart && Object.values(this.data.dataChart).length > 0) {
        let series = []
        series.push({
          name: "SLA",
          data: []
        })

        series.push({
          name: "Lost",
          data: []
        })

        Object.values(this.data.dataChart).forEach((row) => {
          series[0].data.push(row.sla)
          series[1].data.push(row.lost)
        })

        return series
      } else {
        return []
      }
    },
    labels() {
      return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    },
    parentDepartments() {
      if (this.data && this.data.dataTable) {
        return this.data.dataTable.filter(d => d.deep == 0)
      } else {
        return []
      }
    },
    childDepartments() {
      if (this.data && this.data.dataTable) {
        return this.data.dataTable.filter(d => d.deep != 0)
      } else {
        return []
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(newVal) {
        if (newVal.counter != this.lastFilter) {
          this.getData()
          this.lastFilter = newVal.counter
        }
      }
    },
    expanded() {
      setTimeout(() => {
        let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        console.log(tooltipList)
      }, 1000)
    },
    showDeep() {
      setTimeout(() => {
        let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        console.log(tooltipList)
      }, 1000)
    },
    showExtensions() {
      setTimeout(() => {
        let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        console.log(tooltipList)
      }, 1000)
    }
  },
  mounted() {
    let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    let tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
    console.log(tooltipList)
  },
  methods: {
    printIncommingCalls(department) {
      return department.incomming_calls
    },
    printOfferedCalls(department) {
      return department.incomming_offered_calls
    },
    printNoAnsweredCalls(department) {
      return department.incomming_no_answered_calls
    },
    getBackgroundColorByDeep(deep) {
      let background = ''
      if (deep == 2) {
        background = 'bg-primary-opacity-25'
      } else if (deep == 3) {
        background = 'bg-secondary-opacity-25'
      }

      return background
    },
    setDepartment(departmentId) {
      this.kpiUsers = []
      if (this.showCurrentDepartmentId == departmentId) {
        let index = this.data.dataTable.findIndex((r) => r.department_id == departmentId)

        if (index > -1) {
          departmentId = this.data.dataTable[index].parent_department_id
        } else {
          departmentId = 0
        }
      }

      this.showCurrentDepartmentId = departmentId
      this.showDepartmentIds = []
      this.clickCurrentDepartmentId = []
      this.clickCurrentDepartmentId.push(departmentId)

      if (departmentId) {
        let index = this.data.dataTable.findIndex((r) => r.department_id == departmentId)
        if (index > -1) {
          console.log(this.data.dataTable[index])
          if (this.data.dataTable[index].users && this.data.dataTable[index].users.length > 0) {
            this.kpiUsers = this.data.dataTable[index].users
          }
        }

        while (departmentId > 0) {
          let index = this.data.dataTable.findIndex((r) => r.department_id == departmentId)

          if (index > -1) {
            this.showDepartmentIds.push(departmentId)

            if (this.kpiExtensions.length == 0) {
              this.data.dataTable.filter(d => d.parent_department_id == departmentId).forEach((d) => {
                this.showDepartmentIds.push(d.department_id)
              })
            }

            if (this.data.dataTable[index].parent_department_id) {
              departmentId = this.data.dataTable[index].parent_department_id
              this.clickCurrentDepartmentId.push(departmentId)
            } else {
              departmentId = 0
            }
          } else {
            departmentId = 0
          }
        }
      }
  

    },
    setShowDeep(deep) {
      if (this.showDeep >= deep) {
        this.showDeep = deep - 1
      } else {
        this.showDeep = deep
      }
    },
    getData() {
      this.$store.dispatch("getReportReportItemData", {
        reportReportItemId: this.reportReportItem.id,
        data: {
          filters: this.filters
        }
      }).then((res) => {
        this.data = res
        if (this.filters.departments_id) {
          this.setDepartment(this.filters.departments_id[0])
        }
      })
    }
  }
}
</script>
<style lang="scss">

</style>
