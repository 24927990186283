import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "../views/Login.vue"
import ResetPassword from "../views/ResetPassword.vue"
import Logout from "../views/Logout.vue"

import AccountShow from "../views/accounts/Show.vue"
import AccountExportGoogle from "../views/accounts/ExportGoogle.vue"
import BudgetShow from "../views/budgets/Show.vue"
import OrderShow from "../views/orders/Show.vue"
import RefundShow from "../views/refunds/Show.vue"
import DeliveryNoteShow from "../views/delivery_notes/Show.vue"
import InDeliveryNoteShow from "../views/in_delivery_notes/Show.vue"
import InvoiceShow from "../views/invoices/Show.vue"
import InvoiceAccount from "../views/invoices/InvoiceAccount.vue"
import CreditNoteShow from "../views/credit_notes/Show.vue"
import ProjectShow from "../views/projects/Show.vue"
import TaskShow from "../views/tasks/Show.vue"
import TaskList from "../views/tasks/List.vue"
import TaskInbox from "../views/tasks/Inbox.vue"
import TaskCalendar from "../views/tasks/Calendar.vue"
import PbxShow from "../views/pbx/Show.vue"
import PbxChannels from "../views/pbx/Channels.vue"
import RateShow from "../views/rates/Show.vue"
import UserFop from "../views/users/Fop.vue"
import ExtensionFop from "../views/extensions/Fop.vue"
import ReportSample from "../views/reports/Sample.vue"
import ReportCompareSample from "../views/reports/CompareSample.vue"
import SampleWall1 from "../views/reports/SampleWall1.vue"
import SampleWall2 from "../views/reports/SampleWall2.vue"
import SampleWall3 from "../views/reports/SampleWall3.vue"
import CallList from "../views/calls/List.vue"
import DashboardShow from "../views/dashboards/Show.vue"
import DashboardControlShow from "../views/dashboards/ControlShow.vue"
import ReportShow from "../views/reports/Show.vue"
import Chat from "../views/messages/Chat.vue"
import ClosedChat from "../views/messages/ClosedChat.vue"
import ConfigWhatsappNumbers from "../views/messages/ConfigWhatsappNumbers.vue"
import QueueConfig from "../views/queues/ConfigExtensions.vue"
import TimeGroupShow from "../views/time_groups/Show.vue"
import PbxCalendar from "../views/pbx/Calendar.vue"
import Webmail from "../views/email_messages/Webmail.vue"

import ModuleList from "../views/modules/List.vue"
import ModuleAdd from "../views/modules/Add.vue"
import ModuleDuplicate from "../views/modules/Duplicate.vue"
import ModuleEdit from "../views/modules/Edit.vue"

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'dashboardShow', component: DashboardShow },
  { path: '/login', name: 'login', component: Login },
  { path: '/reset-password/:token', name: 'resetPassword', component: ResetPassword },
  { path: '/logout', name: 'logout', component: Logout },
  { path: '/calls', name: 'callsList', component: CallList },
  { path: '/pbx/show', name: 'pbxShow', component: PbxShow },
  { path: '/pbx/calendar_group/:id', name: 'pbxCalendar', component: PbxCalendar },
  { path: '/pbx/channels', name: 'pbxChannels', component: PbxChannels },
  { path: '/users/fop', name: 'usersFop', component: UserFop },
  { path: '/dashboards/:id?', name: 'dashboardShow', component: DashboardShow },
  { path: '/controls/:id?', name: 'dashboardControlShow', component: DashboardControlShow },
  { path: '/reports/:id?', name: 'reportShow', component: ReportShow },
  { path: '/extensions/fop', name: 'extensionsFop', component: ExtensionFop },
  { path: '/messages', name: 'messages', component: Chat },
  { path: '/messages/closed', name: 'closedChat', component: ClosedChat },
  { path: '/messages/config_whatsapp_numbers', name: 'configWhatsappNumbers', component: ConfigWhatsappNumbers },
  { path: '/messages/:chatRoomId', name: 'messages_chat_room_id', component: Chat },
  { path: '/messages/:chatRoomId/:messageTypeId/:phoneNumber/:departmentCode/:rgpd', name: 'messages_create', component: Chat },  
  
  { path: '/webmail', name: 'Webmail', component: Webmail},
  { path: '/queues/config', name: 'queueConfig', component: QueueConfig },
  { path: '/time_groups/:id', name: 'timeGroupShow', component: TimeGroupShow },

  { path: '/:moduleUrl', name: 'module_list', component: ModuleList },
  { path: '/:moduleUrl/add', name: 'module_add', component: ModuleAdd },
  { path: '/accounts/export-google', name: 'accountExportGoogle', component: AccountExportGoogle },
  { path: '/accounts/:id', name: 'accountsShow', component: AccountShow },
  { path: '/budgets/:id', name: 'budgetShow', component: BudgetShow },
  { path: '/orders/:id', name: 'orderShow', component: OrderShow },
  { path: '/refunds/:id', name: 'RefundShow', component: RefundShow },
  { path: '/tasks/list', name: 'taskList', component: TaskList },
  { path: '/tasks/inbox', name: 'taskInbox', component: TaskInbox },
  { path: '/tasks/calendar', name: 'taskCalendar', component: TaskCalendar },
  { path: '/tasks/:id', name: 'taskListShow', component: TaskList },
  { path: '/tasks/:id/show', name: 'taskShow', component: TaskShow },
  { path: '/delivery_notes/:id', name: 'deliveryNoteShow', component: DeliveryNoteShow },
  { path: '/in_delivery_notes/:id', name: 'inDeliveryNoteShow', component: InDeliveryNoteShow },
  { path: '/invoices/account', name: 'invoiceAccount', component: InvoiceAccount },
  { path: '/invoices/:id', name: 'invoiceShow', component: InvoiceShow },
  { path: '/credit_notes/:id', name: 'creditNoteShow', component: CreditNoteShow },
  { path: '/projects/:id', name: 'projectShow', component: ProjectShow },
  { path: '/rates/:id', name: 'rateShow', component: RateShow },
  { path: '/reports/sample/:legal_document_type_id?', name: 'reportSample', component: ReportSample },
  { path: '/reports/compare_sample', name: 'reportCompareSample', component: ReportCompareSample },
  { path: '/reports/sample-wall1', name: 'sampleWall1', component: SampleWall1 },
  { path: '/reports/sample-wall2', name: 'sampleWall2', component: SampleWall2 },
  { path: '/reports/sample-wall3', name: 'sampleWall3', component: SampleWall3 },
  { path: '/:moduleUrl/:id/duplicate', name: 'module_duplicate', component: ModuleDuplicate },
  { path: '/:moduleUrl/:id/edit', name: 'module_edit', component: ModuleEdit },
  { path: '/:parentModuleUrl/:parentId/:moduleUrl', name: 'child_module_list', component: ModuleList },
  { path: '/:parentModuleUrl/:parentId/:moduleUrl/add', name: 'child_module_add', component: ModuleAdd },
  // { path: '/:moduleUrl/:id', name: 'module_show', component: ModuleShow },
]

const router = new VueRouter({
  mode: 'history',
  routes
});
  
export default router

router.beforeEach((to, from, next) => {
  const publicPages = ['login', 'resetPassword'];
  console.log(to)
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});