<template lang="pug">
  .screen-department-queues-hour
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 {{ $t('Llamadas por horas') }}&nbsp;
            strong.text-primary {{title}}
          
          div(v-if="showChart")
            select.form-select.form-select-sm(v-model="rotationDepartment" )
              option(:value="-2") {{ $t('Agrupado') }}
              option(:value="-1") {{ $t('Rotación') }}
              option(v-for="(filterDepartment, index) in filteredDepartments" :value="index") {{filterDepartment.name}}
        
        template(v-if="showChart")
          .row
            .col
              chart-columns(:series="series" :categories="categories" :colors="['#09ad95', '#f82649']")
          .row
            .col
              table.table
                tr
                  td(style="width:50%")
                    chart-radial-bars(:series="seriesTargetWaitTime" :labels="labelsTargetWaitTime" :size="sizeTargetWaitTime" :colors="[colorTotalQueuesTargetWaitTime]")
                  td(style="width:50%")
                    chart-radial-bars(:series="seriesPickup" :labels="labelsPickup" :size="sizePickup" :colors="[colorTotalQueuesPickupRate]")


              
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  import ChartRadialBars from '../chart/ChartRadialBars.vue'
  export default {
    name: 'ScreenDepartmentQueuesHour',
    components: {
      ChartColumns,
      ChartRadialBars
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        indexDepartment: 0,
        rotationDepartment: -2,
        showChart: false,
        series: [],
        categories: [],
        seriesTargetWaitTime: [],
        labelsTargetWaitTime: '',
        sizeTargetWaitTime: '',
        seriesPickup: [],
        labelsPickup: '',
        sizePickup: '',
        title : '',
        drawChartPointer: null,
        grouped: true,
        filteredDepartments: []
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      queues() {
        return this.$store.state.queueModule.queues
      },
      totalQueuesTargetWaitTime() {
        let total = 0
        let i = 0
        this.filteredDepartments.forEach((department) => {
          if(department.report_today.total_calls > 0) {
            total = total + Math.round(department.report_today.target_wait_time)
            i++
          }
          
        })

        if(i > 0) {
          total = Math.round(total / i)
        }

        return total
      },
      totalQueuesPickupRate() {
        let total = 0
        let i = 0
        this.filteredDepartments.forEach((department) => {
           if(department.report_today.total_calls > 0) {
            total = total + Math.round(department.report_today.pickup_rate)
            i++
          }
        })

        if(i > 0) {
          total = Math.round(total / i)
        }

        return total
      },
      colorTotalQueuesTargetWaitTime() {
        if(this.totalQueuesTargetWaitTime >= 80) {
          return '#09ad95'
        } else if(this.totalQueuesTargetWaitTime >= 60) {
          return '#f7b731'
        } else {
          return '#f82649'
        }

      },
      colorTotalQueuesPickupRate() {
        if(this.totalQueuesPickupRate >= 80) {
          return '#09ad95'
        } else if(this.totalQueuesPickupRate >= 60) {
          return '#f7b731'
        } else {
          return '#f82649'
        }

      }
    },
    watch: {
      rotationDepartment(newVal) {
        if(newVal > -1) {
          this.indexDepartment = newVal
        }
      }
    },
  mounted() {
    this.$store.dispatch("getRegisters", {
      moduleUrl: 'departments'
    }).then((departments) => {
      if (this.filters.departments_id) {
        this.filters.departments_id.forEach((departmentId) => {
          let index = departments.findIndex((d) => d.id == departmentId)

          if (index > -1) {
            this.filteredDepartments.push(departments[index])
          }
        })

        this.drawChartPointer = setTimeout(() => {
          if (this.grouped) {
            this.drawGroupedCharts()
          } else {
            this.drawCharts()
          }
        }, 3000)
      }
    })
      
    },
    methods: {
      drawGroupedCharts() {
        //this.showChart = false
        let departments_id = []
        this.filteredDepartments.forEach((d) => {
          departments_id.push(d.id)
        })
        let start = 0;
        let end = 23;

        this.title = "Agrupado"

        if(this.filters.start) {
          start = this.filters.start
        }

        if(this.filters.end) {
          end = this.filters.end
        }
        
        this.$store.dispatch("getDepartmentsTotalCallsByHours", {
          departments_id: departments_id,
          start: start,
          end: end
        }).then((res) => {
          this.series = [{
            name: 'Success',
            data: []
          },{
            name: 'Lost',
            data: []
          }]
          this.categories = []
          for(let i = start; i <= end; i++) {
            this.categories.push(i)

            if(res[i]['success']) {
              this.series[0].data.push(res[i]['success'])
            } else {
              this.series[0].data.push(0)
            }
            if(res[i]['lost']) {
              this.series[1].data.push(res[i]['lost'])
            } else {
              this.series[1].data.push(0)
            }
          }

          this.seriesTargetWaitTime = [this.totalQueuesTargetWaitTime]
          this.sizeTargetWaitTime = this.totalQueuesTargetWaitTime + '%'
          this.labelsTargetWaitTime = ['Nivel de servicio']

          this.seriesPickup = [this.totalQueuesPickupRate]
          this.sizePickup = this.totalQueuesPickupRate + '%'
          this.labelsPickup = ['Pickup']

          this.showChart = true


          this.drawChartPointer = setTimeout(()=> {
            this.nextDrawChart()
          }, 15000)
        })

      },
      drawCharts() {
        //this.showChart = false
        let department = this.filteredDepartments[this.indexDepartment]
        let start = 0;
        let end = 23;

        this.title = department.name

        if(this.filters.start) {
          start = this.filters.start
        }

        if(this.filters.end) {
          end = this.filters.end
        }
        
        this.$store.dispatch("getDepartmentsTotalCallsByHours", {
          department_id: department.id,
          start: start,
          end: end
        }).then((res) => {
          this.series = [{
            name: 'Success',
            data: []
          },{
            name: 'Lost',
            data: []
          }]
          this.categories = []
          for(let i = start; i <= end; i++) {
            this.categories.push(i)

            if(res[i]['success']) {
              this.series[0].data.push(res[i]['success'])
            } else {
              this.series[0].data.push(0)
            }
            if(res[i]['lost']) {
              this.series[1].data.push(res[i]['lost'])
            } else {
              this.series[1].data.push(0)
            }
          }

          this.seriesTargetWaitTime = [department.report_today.target_wait_time]
          this.sizeTargetWaitTime = department.report_today.target_wait_time + '%'
          this.labelsTargetWaitTime = ['Nivel de servicio']

          this.seriesPickup = [department.report_today.pickup_rate]
          this.sizePickup = department.report_today.pickup_rate + '%'
          this.labelsPickup = ['Pickup']

          this.showChart = true


          this.drawChartPointer = setTimeout(()=> {
            this.nextDrawChart()
          }, 15000)
        })

      },
      pauseDrawChart() {
        if(this.drawChartPointer) {
          clearTimeout(this.drawChartPointer)
          this.drawChartPointer = null
        }
      },
      playDrawChart() {
        if(this.grouped) {
          this.drawGroupedCharts()
        } else {
          this.drawCharts()
        }
      },
      nextDrawChart() {
        this.pauseDrawChart()
        
        if(this.rotationDepartment == -1) {
          this.indexDepartment++
          if(!this.filteredDepartments[this.indexDepartment]) {
            this.indexDepartment = 0
          }
        }

        if(this.rotationDepartment == -2) {
          this.drawGroupedCharts()
        } else {
          this.drawCharts()
        }
      },
      prevDrawChart() {
        this.pauseDrawChart()

        if(this.indexDepartment == 0) {
          this.indexDepartment = this.filteredDepartments.length -1
        } else {
          this.indexDepartment--
        }

        this.drawCharts()
      },
    }
  }
</script>
<style lang="scss">
</style>