<template lang="pug">
  .queuesConfig
    .container-fluid.pt-3
      .row
        .col-xxl-2.col-lg-3.col-md-4
          .card
            .card-body
              input.form-control.mb-3(v-model="filterWhatsappNumber" :placeholder="$t('Buscar número')")
              .list-group
                a.list-group-item.list-group-item-action(v-for="whatsappNumber in whatsappNumbers" @click="setWhatsappNumber(whatsappNumber)" :class="selectedWhatsappNumber && whatsappNumber.id == selectedWhatsappNumber.id ? 'text-white bg-primary' : ''")
                  span {{whatsappNumber.name}}
                  br
                  small {{whatsappNumber.number}}
        .col-xxl-10.col-lg-9.col-md-8
          .row
            .col
              .card
                .card-body
                  .d-flex.justify-content-between
                    h5.card-title {{$t('Agentes asignados')}}
                  ul.list-group
                    li.list-group-item(v-for="(user, index) in selectedUsers" :key="'selectedUsersItem' + user.id")
                      .d-flex
                        div
                          font-awesome-icon.handle.me-2(icon="bars")
                        div.flex-grow-1.align-self-center
                          span {{user.name}} 
                        .btn-group
                          button.btn.btn-danger(@click="removeUser(index)")
                            font-awesome-icon.align-self-center(icon="times")
                  
            .col
              .card
                .card-body
                  h5.card-title {{$t('Usuarios disponibles')}}
                  input.mb-3.form-control(v-model="filterUser" placeholder="Buscar usuario")
                  ul.list-group
                    li.list-group-item(v-for="user in filteredUsers" @click="addUser(user)")
                      span {{user.name}}

</template>

<script>
  export default {
    name: 'confirWhatsappNumber',
    data () {
      return {
        selectedWhatsappNumber: {},
        whatsappNumbers: [],
        selectedUsers: [],
        newSelectedUsers: [],
        users: [],
        filterWhatsappNumber: '',
        filterUser: ''
      }
    },
    computed: {
      filteredWhatsappNumbers() {
        if(this.filterWhatsappNumber) {
          return this.whatsappNumbers.filter((q) => q.name.toLowerCase().includes(this.filterWhatsappNumber.toLowerCase()) || q.number.toLowerCase().includes(this.filterWhatsappNumber.toLowerCase()))
        } else {
          return this.whatsappNumbers
        }
      },
      freeUsers() {
        let freeUsers = []
        this.users.forEach((user) => {
          let index = this.selectedUsers.findIndex((se) => se.id == user.id)

          if(index == -1) {
            freeUsers.push(user)
          }
        })

        return freeUsers
      },
      filteredUsers() {
        if(this.filterUser) {
          return this.freeUsers.filter((fu) => fu.name.toLowerCase().includes(this.filterUser.toLowerCase()))
        } else {
          return this.freeUsers
        }
      },
    },
    beforeMount() {
      this.$store.dispatch("getRegisters", {moduleUrl: "whatsapp_numbers"})
        .then((res) => {
          this.whatsappNumbers = res
        })
      this.$store.dispatch("getRegisters", {moduleUrl: "users"})
        .then((users) => {
          this.users = users
        })
    },
    watch: {
      selectedWhatsappNumber(newVal) {
        this.selectedUsers = []
        
        newVal.users.forEach((wnu) => {
          let index = this.users.findIndex((u) => u.id == wnu.id)

          if(index > -1) {
            this.selectedUsers.push({
              id: wnu.id,
              name: this.users[index].name,
              code: this.users[index].code,
            })
          }
        })
      }
    },
    methods: {
      setWhatsappNumber(whatsappNumber) {
        this.selectedWhatsappNumber = whatsappNumber
      },
      addUser(user) {
        let whatsappNumberUser = {
          id: user.id,
          name: user.name,
          code: user.code
        }

        this.$store.dispatch("whatsappNumberAddUser", {
          whatsappNumberId: this.selectedWhatsappNumber.id,
          data: {
            user_id: user.id
          }
        }).then(() => {
          this.selectedUsers.push(whatsappNumberUser)
        })
      },
      removeUser(index) {
        let user = this.selectedUsers[index]

        this.$store.dispatch("whatsappNumberRemoveUser", {
          whatsappNumberId: this.selectedWhatsappNumber.id,
          data: {
            user_id: user.id
          }
        }).then(() => {
          this.selectedUsers.splice(index, 1)
        })
        
      },
    }
  }
</script>
<style scooped lang="scss">
</style>