<template lang="pug">
  .gc-account
    a#offcanvasAccountBtn(href="#" data-bs-toggle='offcanvas' data-bs-target='#offcanvasAccount' aria-controls='offcanvasAccount')
      font-awesome-icon(:icon="['fas', 'id-card']")
    #offcanvasAccount.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasAccountLabel')
      .offcanvas-header
        h5#offcanvasAccountLabel.offcanvas-title Cuentas
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        .row
          .col.m-1
            select.mb-2.form-select(v-model="accountTypeId" placeholder="")
                option(value="0") Todos los tipos de cuenta
                option(v-for="accountType in accountTypes" :value="accountType.id") {{accountType.label}}
            .input-group
              button.btn.btn-secondary
                font-awesome-icon(icon="filter")
              input.form-control(type="text" placeholder="Buscar cuenta" v-model="filter")
        .row
          .col
            .card.mt-2.border(v-for="account in accounts")
              .card-body.pt-1.pb-1.ps-2.pe-2
                span.badge.bg-primary(v-if="account.account_type_id") {{account.account_type.name}}
                br
                strong {{account.name}}
                div(v-if="account.tags")
                  span.badge.me-2(v-for="tag in account.tags" :style="'background:' + tag.background + ';color:' + tag.color + ';'") {{tag.name}}
              .card-footer.p-0
                .d-flex.border-top(v-for="accountPhone in account.account_phones")
                  .p-2.flex-grow-1 
                    span.mb-0.pb-0 {{accountPhone.fullphone}}
                    br
                    small.small {{accountPhone.name}}
                  .pt-2.pb-2.pe-3.ps-3.align-self-center
                    .btn-group
                      button.btn.btn-info.btn-sm(v-if="currentUser.secondary_extension_id" @click="makeCall(currentUser.secondary_extension.number, accountPhone.fullphone)")
                        font-awesome-icon(icon="phone")
                        small.ms-1 {{currentUser.secondary_extension.number}}
                      button.btn.btn-primary.btn-sm(v-if="currentUser.extension_id" @click="makeCall(currentUser.extension.number, accountPhone.fullphone)")
                        font-awesome-icon(icon="phone")
                        small.ms-1 {{currentUser.extension.number}}


</template>
<script>
  
  export default {
    name: 'GcAccount',
    data () {
      return {
        filter: '',
        filterPointer: null,
        accounts: [],
        hoverI: 0,
        page: 0,
        sortColumn: 'name',
        sortDirection: 1,
        limit: 50,
        totalPages: 0,
        limitStart: 0,
        limitEnd: 0,
        totalElements: 0,
        accountTypeId: 0,
        accountTypes: []
      }
    },
    computed: {
      users() {
        return this.$store.state.userModule.users
      },
      filteredUsers() {
        return this.users.filter((u) => {
          return (u.name.toLowerCase().includes(this.filter) || (u.extension && u.extension.number.includes(this.filter)));
        })
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      userCalls() {
        let userCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.users.findIndex((u) => u.extension && u.extension.number == connected)

            if(index > -1) {
              if(!userCalls[this.users[index].id]) {
                userCalls[this.users[index].id] = {}
                this.$set(userCalls[this.users[index].id], 'calls', [])
              }

              userCalls[this.users[index].id].calls.push(call)
            }
          })
        })

        return userCalls
      }
    },
    watch: {
      filter() {
       if(this.filterPointer) {
         clearTimeout(this.filterPointer)
       }

       setTimeout(()=> {this.search()}, 500)
      },
      accountTypeId() {
       if(this.filterPointer) {
         clearTimeout(this.filterPointer)
       }

       setTimeout(()=> {this.search()}, 500)
      }
    },
    mounted() {
      this.search()
      this.$store.dispatch("getRegistersList", {
        moduleUrl: 'account_types',
        columns: 'id,name',
        conditions: ''
      }).then((res) => {
        this.accountTypes = res
      })
    },
    methods: {
      search() {
        let advancedFilter = []

        if(this.accountTypeId && this.accountTypeId > 0) {
          advancedFilter.push({
            search_type_id: 1,
            search_table: "accounts",
            search_column: "account_type_id",
            filter: this.accountTypeId
          })
        }

        this.$store.dispatch("searchRegisters", {
          moduleUrl: 'accounts',
          page: this.page,
          data: {
            filter: this.filter,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            limit: this.limit,
            advancedFilter: advancedFilter
            
          }
        }).then((res) => {
          this.accounts = res.data
          this.totalPages = res.total_pages
          this.limit = res.limit
          this.limitStart = res.limit_start + 1
          this.limitEnd = res.limit_start + res.limit
          this.totalElements = res.total
          if(this.limitEnd > this.totalElements) {
            this.limitEnd = this.totalElements
          }
        })
      },
      prevPage() {
        this.page--
        this.search();
      },
      nextPage() {
        this.page++
        this.search();
      },
      makeCall(extension, phone) {
        this.$store.dispatch("makeCall", {
          phone: '0' + phone,
          extension: extension
        })
      }
    }
  }
</script>
<style lang="scss">
</style>