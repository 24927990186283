<template lang="pug">
.call-modal2-tasks
  .p-2
    task-create.mt-2.mb-2(:accountId="accountId" :linkedid="linkedid")
    task-list-item(v-for="task in filteredTasks" :key="'taskListItem' + task.id" :task="task")
</template>

<script>
  import TaskCreate from '../tasks/TaskCreate.vue'
  import TaskListItem from '../tasks/TaskListItem.vue'
  export default {
    name: 'CallModalTask',
    components: {
      TaskListItem,
      TaskCreate
    },
    props: {
      accountId: {type: Number, default: 0},
      linkedid: {type: String, default: ''},
    },
    data () {
      return {
        showFinished: false
      }
    },
    computed: {
      tasks() {
        return this.$store.state.taskModule.tasks
      },
      filteredTasks() {
        let filteredTasks = this.tasks

        /*filteredTasks = filteredTasks.filter(task => {
          return task.task_description.name.toLowerCase().includes(this.filter) || task.task_description.description.toLowerCase().includes(this.filter)
        })

        if(this.filterTaskGroup) {
          filteredTasks = filteredTasks.filter(task => {
            return task.task_group_id == this.filterTaskGroup
          })
        }

        if(this.filterTaskStatus) {
          filteredTasks = filteredTasks.filter(task => {
            return task.task_status_id == this.filterTaskStatus
          })
        }

        if(this.filterTaskTypes.length > 0) {
          filteredTasks = filteredTasks.filter(task => {
            return this.filterTaskTypes.findIndex(ftt => ftt == task.task_type_id) > -1
          })
        }
        
        if(this.filterTaskPriority) {
          filteredTasks = filteredTasks.filter(task => {
            return task.task_priority_id == this.filterTaskPriority
          })
        }*/
        
        if(this.accountId) {
          filteredTasks = filteredTasks.filter(task => {
            return task.account_id == this.accountId
          })
        }
        
        /*if(this.filterUsers.length > 0) {
          filteredTasks = filteredTasks.filter(task => {
            let value = false

            task.users.forEach((u) => {
              value = value ||  this.filterUsers.findIndex(ftu => ftu == u.id) > -1
            })

            return value
          })
        }
        
        if(this.showOnlyMy) {
          filteredTasks = filteredTasks.filter(task => {
            return task.users.findIndex(u => u.id == this.currentUser.id) > -1
          })
        }

        if(this.showOnlyToday) {
          filteredTasks = filteredTasks.filter(task => {
            return task.today || (task.start_date && this.$moment(this.$moment(new Date()).format('YYYY-MM-DD')).isSameOrAfter(task.start_date))
          })
        }

        if(this.filterDateStart) {
          filteredTasks = filteredTasks.filter(task => {
            return task.start_date && this.$moment(this.$moment(task.start_date)).isSameOrAfter(this.filterDateStart)
          })
        }

        if(this.filterDateEnd) {
          filteredTasks = filteredTasks.filter(task => {
            return task.start_date && this.$moment(this.$moment(task.start_date)).isSameOrBefore(this.filterDateEnd)
          })
        }*/

        filteredTasks = filteredTasks.filter(task => {
          return task.finished == this.showFinished
        })

        return filteredTasks
      },
    },
    watch: {
      /*accountId(newVal, oldVal) {
        if(!oldVal && newVal != oldVal) {
          //RC: Limpiamos las tareas
          this.$store.commit("setUnfinishedTasks", [])
          this.$store.commit("setFinishedTasks", [])

          if(newVal) {
            //RC: Recuperamos las tareas  de la cuenta
            this.$store.dispatch("getAccountTasks", {accountId: newVal, finished: 0})
          }
        }
      }*/
    },
    mounted() {
      /*this.$store.commit("setUnfinishedTasks", [])
      this.$store.commit("setFinishedTasks", [])

      if(this.accountId) {
        //RC: Recuperamos las tareas  de la cuenta
        this.$store.dispatch("getAccountTasks", {accountId: this.accountId, finished: 0})
      }*/
    },
    methods: {
    }
  }
</script>
<style lang="scss">
  
</style>