<template lang="pug">
#whatsappViewItem
  .card-gc-border.mb-2
    .card-gc-body.border-success
      strong.small(v-if="message.user") {{message.user.name}}
      strong.small(v-else) {{message.account.name}}
      div
        template(v-if="messageContentTypeId == 1")
          div(v-html="message.message_content.content")
        template(v-else-if="messageContentTypeId == 2")
          .figure(v-if="messageContentContent.url")
            a(:href="messageContentContent.url" target="_blank")
              img(
                :src="messageContentContent.url" 
                style="max-width: 200px"
              )
              p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
          .figure(v-else)
            a(:href="messageContentContent" target="_blank")
              img(
                :src="messageContentContent" 
                style="max-width: 200px"
              )
        template(v-if="messageContentTypeId === 3")
          p.has-text-centered(v-if="messageContentContent.url")
            a(:href="messageContentContent.url" target="_blank")
              a(
                :href="messageContentContent.url" 
                target="_blank"
              )
                font-awesome-icon(
                  icon="cloud-download-alt" 
                  size="4x"
                )
                br
                span {{messageContentContent.url.substring(messageContentContent.url.lastIndexOf('/')).replace('/', '')}}
              p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
          p.has-text-centered(v-else)
            a(
              :href="message.message_content.content" 
              target="_blank"
            )
              font-awesome-icon(
                icon="cloud-download-alt" 
                size="4x"
              )
              br
              span {{message.message_content.content.substring(message.message_content.content.lastIndexOf('/')).replace('/', '')}}
        template(v-else-if="messageContentTypeId == 4")
          a(:href="message.message_content.content" target="_blank").mb-0 {{message.message_content.content}}
        template(v-else-if="messageContentTypeId == 5")
          div(v-if="messageContentContent.url")
            audio(style="max-width: 100%" controls)
              source(:src="messageContentContent.url")
            p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
          div(v-else)
            audio(style="max-width: 100%" controls)
              source(:src="messageContentContent")
        template(v-else-if="messageContentTypeId == 6")
          div(v-if="messageContentContent.url")
            video(style="max-width: 100%" controls)
              source(:src="messageContentContent.url")
            p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
          div(v-else)
            video(style="max-width: 100%" controls)
              source(:src="messageContentContent")
      span.small.text-dark
        span {{message.sended_at | moment('DD-MM-YYYY hh:mm:ss')}}
</template>
<script>


export default {
  name: 'whatsappViewItem',
  props: {
    message: { type: Object, default: () => { } },
  },
  data() {
    return {
    }
  },
  computed: {
    messageContentTypeId() {
      return this.message.message_content.message_content_type_id
    },
    messageContentContent() {
      if (this.message && this.message.message_content && this.message.message_content.content) {
        if (this.IsJsonString(this.message.message_content.content)) {
          return JSON.parse(this.message.message_content.content)
        } else {
          return this.message.message_content.content
        }
      } else {
        return ''
      }
    },
  },
  mounted() {
  },
  methods: {
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }
  }
}
</script>
<style scoped>
p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>
