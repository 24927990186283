import Vue from 'vue'
import tasksService from '../services/api/tasks'

const taskModule = {
    state: {
        task: {},
        taskTypeLists: [],
        taskPriorityLists: [],
        taskStatusLists: [],
        taskEnds: [],
        startedTasks: [],
        tasks: [],
        todayReport: {},
        modalTaskId: null
    },
    mutations: {
        setTodayReport(state, todayReport) {
            state.todayReport = todayReport
        },
        setModalTaskId(state, taskId) {
            state.modalTaskId = taskId
        },
        addTasks (state, task) {
            let index = state.tasks.findIndex(x => x.id === task.id)

            if(index == -1) {
                state.tasks.push(task)
            } else {
                Vue.set(state.tasks, index, task)
            }
        },
        setTasks (state, tasks) {
            state.tasks = tasks
        },
        updateTasks (state, task) {
            let index = state.tasks.findIndex(x => x.id === task.id)

            if(index > -1) {
                Vue.set(state.tasks, index, task)
            }
        },
        deleteTasks (state, task) {
            let index = state.tasks.findIndex(x => x.id === task.id)

            if(index > -1) {
                state.tasks.splice(index,1)
            }
        },
        addStartedTasks (state, task) {
            let index = state.startedTasks.findIndex(x => x.id === task.id)

            if(index == -1) {
                state.startedTasks.push(task)
            }
        },
        setStartedTasks (state, tasks) {
            state.startedTasks = tasks
        },
        updateStartedTasks (state, task) {
            let index = state.startedTasks.findIndex(x => x.id === task.id)

            if(index > -1) {
                Vue.set(state.startedTasks, index, task)
            }
        },
        deleteStartedTasks (state, task) {
            let index = state.startedTasks.findIndex(x => x.id === task.id)

            if(index > -1) {
                state.startedTasks.splice(index,1)
            }
        },
        setTaskTypeLists (state, taskTypeLists) {
            state.taskTypeLists = taskTypeLists
        },
        setTaskPriorityLists (state, taskPriorityLists) {
            state.taskPriorityLists = taskPriorityLists
        },
        setTaskStatusLists (state, taskStatusLists) {
            state.taskStatusLists = taskStatusLists
        },
        setTaskEnds (state, taskEnds) {
            state.taskEnds = taskEnds
        },
        setTask  (state, task) {
            state.task = task
        }
    },
    actions: {
        getTodayReport (context) {
            return new Promise((resolve, reject) => {
                tasksService.myTodayReport()
                    .then((report) => {
                        context.commit('setTodayReport', report)
                        resolve(report)
                    })
                    .catch(reject)
            })
        },
        setTodayReport (context, payload) {
            return new Promise((resolve, reject) => {
                tasksService.today(payload.taskId, payload.today)
                    .then((task) => {
                        context.commit('updateTasks', task)
                        resolve(task)
                    })
                    .catch(reject)
            })
        },
        startTask (context, payload) {
            return new Promise((resolve, reject) => {
                tasksService.start(payload.taskId)
                    .then((task) => {
                        context.commit('addStartedTasks', task)
                        context.commit('updateTasks', task)
                        resolve(task)
                    })
                    .catch(reject)
            })
        },
        endTask (context, payload) {
            return new Promise((resolve, reject) => {
                tasksService.end(payload.taskId)
                    .then((task) => {
                        context.commit('deleteStartedTasks', task)
                        context.commit('updateTasks', task)
                        resolve(task)
                    })
                    .catch(reject)
            })
        },
        setTaskLastSeen (context, payload) {
            return new Promise((resolve, reject) => {
                tasksService.setLastSeen(payload.taskId)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        getMyTasks(context) {
            return new Promise((resolve, reject) => {
                tasksService.myTasks()
                    .then((tasks) => {
                        context.commit('setTasks', tasks)
                        resolve(tasks)
                    })
                    .catch(reject)
            })
        },
        getMyCurrentTasks(context) {
            return new Promise((resolve, reject) => {
                tasksService.myCurrentTasks()
                    .then((tasks) => {
                        context.commit('setStartedTasks', tasks)
                        resolve(tasks)
                    })
                    .catch(reject)
            })
        },
        getTaskCommunications(context, payload) {
            return new Promise((resolve, reject) => {
                tasksService.getTaskCommunications(payload.taskId)
                    .then((communications) => {
                        resolve(communications)
                    })
                    .catch(reject)
            })
        },
        assignTaskEmailMessage (context, payload) {
            return new Promise((resolve, reject) => {
                tasksService.assignTaskEmailMessage(payload.taskId, payload.emailMessageId)
                    .then((task) => {
                        context.commit('updateTasks', task)
                        resolve(task)
                    })
                    .catch(reject)
            })
        },
    }
}

export default taskModule