import Vue from 'vue'

const notificationModule = {
    state: {
        notifications: [],
        notificationId: 1
    },
    mutations: {
        setNotifications(state, notifications) {
            state.notifications = notifications
        },
        pushNotifications(state, notification) {
            Vue.set(notification, 'id', state.notificationId)
            state.notifications.push(notification)
            state.notificationId++
        },
        spliceNotifications(state, notification) {
            let index = state.notifications.findIndex(n => n.id == notification.id)

            if(index > -1) {
                state.notifications.splice(index, 1)
            }
        }
    },
    actions: {
        
    }
}

export default notificationModule