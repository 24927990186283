<template lang="pug">
.screen-total-active-tasks.mb-3
  .card
    .card-body
      h5.card-title.mb-2.ps-2 {{filters.title}}
      .d-flex.text-center.flex-wrap
        .flex-fill(v-for="taskStatusCounter in taskStatusesCounter" @click="setCurrentStatusId(taskStatusCounter.id)")
          .p-2.rounded.m-2(:style="'color:' + taskStatusCounter.color + ';background: ' + backgroundColor(taskStatusCounter.color, taskStatusCounter.id)")
            small.small {{taskStatusCounter.name}}
            div
              strong.h4.mb-0.pb-0 {{taskStatusCounter.total}}  
      mt-2(v-if="tasksLists.length > 0")
        .limit-scroll-50vh
          task-list-item(v-for="task in tasksLists" :key="'taskListItem' + task.id" :task="task" :reduced="true")     
      
</template>
<script>
  import TaskListItem from '../tasks/TaskListItem.vue'
  export default {
    name: 'ScreenTotalActiveTaskByStatus',
    components: {
      TaskListItem
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        allTaskStatuses: [],
        currentStatusId: null
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      allTasks() {
        return this.$store.state.taskModule.tasks
      },
      tasks() {
        return this.allTasks.filter(task => {
          let show = true
          if(this.filters.taskTypeIds) {
            show = show && this.filters.taskTypeIds.findIndex((tti) => tti == task.task_type_id) > -1
          }

          if(this.filters.showOnlyMy) {
            show = show && (task.users.findIndex(u => u.id == this.currentUser.id) > -1)
          }

          if(this.filters.showOnlyToday) {
            show = show && (task.today || (task.start_date && this.$moment(this.$moment(new Date()).format('YYYY-MM-DD')).isSameOrAfter(task.start_date)))
          }

          return show
        })
      },
      taskStatusesCounter() {
        let taskStatusCounter = []

        this.allTaskStatuses.forEach((ts) => {
          if(ts.finish == 0) {
            let total = this.tasks.filter((t) => t.task_status_id == ts.id).length

            taskStatusCounter.push({
              id: ts.id,
              name: ts.name,
              total: total,
              color: ts.color
            })
          }
        })

        return taskStatusCounter
      },
      tasksLists() {
        if(this.currentStatusId) {
          return this.tasks.filter((t) => t.task_status_id == this.currentStatusId)
        }

        return []
      }
      
    },
    mounted() {
      this.$store.dispatch("getRegisters", {
          moduleUrl: 'task_statuses',
        }).then((res) => {
          this.allTaskStatuses = res
        })
    },
    methods: {
      backgroundColor(color, statusId) {
        let opacity = 0.2

        if(statusId == this.currentStatusId) {
          opacity = 0.5
        }
        return this.$options.filters.hexToRgba(color, opacity)
      },
      setCurrentStatusId(statusId) {
        if(this.currentStatusId == statusId) {
          this.currentStatusId = null
        } else {
          this.currentStatusId = statusId
        }
      }
    }
  }
</script>
<style lang="scss">
</style>