import appService from "./appService";

const tasksService = {
    myCurrentTasks() {
        return appService.get('/tasks/my_current_tasks')
            .then((res) => res.data)
    },
    myTasks() {
        return appService.get('/tasks/my_tasks')
            .then((res) => res.data)
    },
    myTodayReport() {
        return appService.get('/tasks/my_today_report')
            .then((res) => res.data)
    },
    getTaskCommunications(taskId) {
        return appService.get('/tasks/' + taskId + '/get-communications')
            .then((res) => res.data)
    },
    start(taskId) {
        return appService.get('/tasks/' + taskId + '/start')
            .then((res) => res.data)
    },
    end(taskId) {
        return appService.get('/tasks/' + taskId + '/end')
            .then((res) => res.data)
    },
    setLastSeen(taskId) {
        return appService.patch('/tasks/' + taskId + '/set_last_seen')
            .then((res) => res.data)
    },
    today(taskId, today) {
        return appService.post('/tasks/' + taskId + '/today/' + today)
            .then((res) => res.data)
    },
    assignTaskEmailMessage(taskId, emailMessageId) {
        return appService.patch('/tasks/' + taskId + '/assign_email_message/' + emailMessageId)
            .then((res) => res.data)
    }
}

export default tasksService;