<template lang="pug">
.card-gc.mb-2
  .card-gc-body(:class="getBorderColorStatus(call.call_status_id)" @click.stop="setModalCall(call)")
    .mb-0.pb-0 
      span.me-2.icon.text-primary(v-if="call.call_type_id == 1")
        font-awesome-icon(icon="arrow-right")
      span.me-2.icon.text-success(v-else-if="call.call_type_id == 2")
        font-awesome-icon(icon="arrow-left")
      span.me-2.icon.text-warning(v-else)
        font-awesome-icon(icon="redo")
      span {{[call.start, "unix"] | unixToDateTime}}
    span.d-block(v-if="call.account_id && call.account.id") {{call.account.name}}
    .d-flex.justify-content-between.mb-1
      div
        span.me-2() {{call.from}} - {{call.to}}
      div
        span.me-2 W: {{call.duration_wait | timeInHoursMin}}
        span D: {{call.duration | timeInHoursMin}}
    .d-flex.justify-content-between.mb-1
      div
        strong.badge.me-2(:class="getBackgroundStatus(call.call_status_id)") {{call.call_status.name}}
        span.badge.bg-info(v-if="call.call_end_id") {{call.call_end.name}}
        span.badge.bg-secondary(v-else) Sin final
      div
        span.ms-1(v-for="user in call.answered_users") {{user.name}}

</template>

<script>
  import {Modal} from 'bootstrap'
  export default {
    name: 'CallListItemMin',
    props: { 
      call: Object,
    },
    data () {
      return {
      }
    },
    computed: {
      
    },
    watch: {
      
    },
    beforeMount() {
      
    },
    methods: {
      setModalCall(call) {
        this.$store.commit("setModalCall", call)
        let modalCall = document.getElementById('callModal')
        if(modalCall.style.display != 'block') {
          new Modal(modalCall).show() 
        }
      },
      getBackgroundStatus(callStatusId) {
        let callStatusClass = 'bg-primary'

        switch(callStatusId) {
          case 2:
            callStatusClass = 'bg-success'
            break
          case 3:
            callStatusClass = 'bg-warning'
            break
          case 4:
            callStatusClass = 'bg-danger'
            break
        }

        return callStatusClass
      },
      getBorderColorStatus(callStatusId) {
        let callStatusClass = 'border-primary'

        switch(callStatusId) {
          case 2:
            callStatusClass = 'border-success'
            break
          case 3:
            callStatusClass = 'border-warning'
            break
          case 4:
            callStatusClass = 'border-danger'
            break
        }

        return callStatusClass
      },
      
    }
  }
</script>
<style lang="scss">
</style>