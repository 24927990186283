<template lang="pug">
.task-status
  .btn-group
    button.btn.btn-sm.dropdown-toggle.pt-0.pb-0.ps-1.pe-1(data-bs-toggle="dropdown" aria-expanded="false" :style="'background:' + backgroundColor + ';color:' + task.task_status.color + ';'") 
      span {{task.task_status.name}}&nbsp;
    ul.dropdown-menu
      li(v-for="taskStatus in taskStatuses")
        a.dropdown-item(@click="update(taskStatus.id)") {{taskStatus.label}}
</template>

<script>
  export default {
    name: 'TaskStatus',
    props: {
      task: {type: Object, default: function() { return {}}},
    },
    data () {
      return {
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      taskStatuses() {
        return this.$store.state.taskModule.taskStatusLists
      },
      backgroundColor() {
        return this.$options.filters.hexToRgba(this.task.task_status.color, 0.2)
      },
      
    },
    beforeMount() {
    },
    methods: {
      update(value) {
        let task = this.task
        this.$set(task, 'task_status_id', value)

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'tasks',
          id: this.task.id,
          data: task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.$store.commit("updateTasks", res)
            this.$store.commit("updateUnfinishedTasks", res)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.task-status .btn {
  font-size: 0.8em;
}
</style>