<template lang="pug">
.account-export-google
  .header-actions
    .header-actions-content
      .d-flex
        .flex-grow-1.p-2
          p &nbsp;
        .p-2
          .btn-group.me-2
            a.btn.btn-outline-secondary(type='button' href="/accounts")
              font-awesome-icon.me-2(:icon="['fas', 'arrow-left']")
              span Volver
  .main-content
    .p-2
      .container-fluid
        .row
          .col-12
            .card
              .card-body
                p.text-center Estamos generando el fichero, en breves recibirá un email con el enlace al documento
                  
</template>

<script>
  
  export default {
    name: 'AccountExportGoogle',
    beforeMount() {
      this.$store.dispatch("exportAccountGoogle")
    },
    data () {
      
    },
    computed: {
      
    },
    methods: {
      
    }
  }
</script>