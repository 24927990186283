<template lang="pug">
  .queuesConfig
    .container-fluid.pt-3
      .row
        .col-xxl-2.col-lg-3.col-md-4
          .card
            .card-body
              input.form-control.mb-3(v-model="filterQueue" :placeholder="$t('Buscar cola')")
              .list-group
                a.list-group-item.list-group-item-action(v-for="queue in filteredQueues" @click="setQueue(queue)" :class="selectedQueue && queue.id == selectedQueue.id ? 'text-white bg-primary' : ''")
                  span {{queue.name}}
                  br
                  small {{queue.number}}
        .col-xxl-10.col-lg-9.col-md-8
          .row
            .col
              .card
                .card-body
                  .d-flex.justify-content-between
                    h5.card-title {{$t('Extensiones asignadas')}}
                    button.btn.btn-link(v-if="selectedQueue && !loading" @click="getQueueExtensions()")
                      font-awesome-icon(icon="sync")
                    template(v-if="loading")
                      .spinner-border
                  draggable.list-group(tag="ul" v-model="selectedExtensions" v-bind="dragOptions" :move="onMove" @start="needSave=true" handle=".handle")
                    transition-group(type="transition" :name="'flip-list'")
                      li.list-group-item(v-for="(extension, index) in selectedExtensions" :key="'selectedExtensionItem' + extension.id")
                        .d-flex
                          div
                            font-awesome-icon.handle.me-2(icon="bars")
                          div.flex-grow-1.align-self-center
                            span {{extension.name}} 
                            template(v-if="hasStatus")
                              small.badge.ms-2(:class="extension.paused  == '1' ? 'bg-danger' : 'bg-success'") {{extension.number}}
                            template(v-else)
                              small.ms-2 {{extension.number}}
                          input.form-control.form-control-sm.me-3(v-model="selectedExtensions[index]['penalty']" style="width:50px" @change="needSave = true")
                          .btn-group
                            .dropdown(v-if="hasStatus && extension.paused == '1'")
                              a.btn.btn-success.dropdown-toggle(href="#" role="button" :id="'dropdownUnPauseExtension' + extension.id" data-bs-toggle="dropdown" aria-expanded="false")
                                font-awesome-icon(icon="play")
                              ul.dropdown-menu(:aria-labelledby="'dropdownUnPauseExtension' + extension.id")
                                li
                                  a.dropdown-item(@click="unpauseExtensionQueue(extension.id)") {{$t('Quitar la pausa en esta cola')}}
                                li
                                  a.dropdown-item(@click="unpauseAllQueues(extension.id)") {{$t('Quitar la pausa en todas las colas')}}
                            .dropdown(v-if="hasStatus && extension.paused == '0'")
                              a.btn.btn-primary.dropdown-toggle(href="#" role="button" :id="'dropdownPauseExtension' + extension.id" data-bs-toggle="dropdown" aria-expanded="false")
                                font-awesome-icon(icon="pause")
                              ul.dropdown-menu(:aria-labelledby="'dropdownPauseExtension' + extension.id")
                                li
                                  a.dropdown-item(@click="pauseExtensionQueue(extension.id)") {{$t('Pausar en esta cola')}}
                                li
                                  a.dropdown-item(@click="pauseAllQueues(extension.id)") {{$t('Pausar en todas las colas')}}
                            button.btn.btn-danger(@click="removeExtension(index)")
                              font-awesome-icon.align-self-center(icon="times")
                  
                  .d-grid.mt-2.pb-3(v-if="needSave")
                    template(v-if="loading")
                      .spinner-border
                    template(v-else)
                      button.btn.btn-primary.btn-lg(@click="saveQueue()") {{$t('Guardar cambios')}}
                  
            .col
              .card
                .card-body
                  h5.card-title {{$t('Extensiones disponibles')}}
                  input.mb-3.form-control(v-model="filterExtension" placeholder="Buscar extensión")
                  ul.list-group
                    li.list-group-item(v-for="extension in filteredExtensions" @click="addExtension(extension)")
                      span {{extension.name}}

</template>

<script>
  export default {
    name: 'queuesConfig',
    data () {
      return {
        selectedQueue: {},
        queues: [],
        selectedExtensions: [],
        newSelectedExtensions: [],
        extensions: [],
        needSave: false,
        editable: true,
        filterQueue: '',
        filterExtension: '',
        loading: false,
        hasStatus: false
      }
    },
    computed: {
      filteredQueues() {
        if(this.filterQueue) {
          return this.queues.filter((q) => q.name.toLowerCase().includes(this.filterQueue.toLowerCase()) || q.number.toLowerCase().includes(this.filterQueue.toLowerCase()))
        } else {
          return this.queues
        }
      },
      filteredExtensions() {
        if(this.filterExtension) {
          return this.freeExtensions.filter((fe) => fe.name.toLowerCase().includes(this.filterExtension.toLowerCase()) || fe.number.toLowerCase().includes(this.filterExtension.toLowerCase()))
        } else {
          return this.freeExtensions
        }
      },
      dragOptions() {
        return {
          animation: 0,
          group: "description",
          disabled: !this.editable,
          ghostClass: "ghost"
        };
      },
      freeExtensions() {
        let freeExtensions = []
        this.extensions.forEach((extension) => {
          let index = this.selectedExtensions.findIndex((se) => se.id == extension.id)

          if(index == -1) {
            freeExtensions.push(extension)
          }
        })

        return freeExtensions
      }
    },
    beforeMount() {
      this.$store.dispatch("getRegisters", {moduleUrl: "queues"})
        .then((res) => {
          this.queues = res
        })
      this.$store.dispatch("getRegisters", {moduleUrl: "extensions"})
        .then((res) => {
          this.extensions = res
        })
    },
    watch: {
      selectedQueue(newVal) {
        this.hasStatus = false
        this.selectedExtensions = []
        
        newVal.queue_extensions.forEach((qe) => {
          let index = this.extensions.findIndex((e) => e.id == qe.extension_id)

          if(index > -1) {
            this.selectedExtensions.push({
              id: qe.extension_id,
              name: this.extensions[index].name,
              number: this.extensions[index].number,
              penalty: qe.penalty,
              elementId: qe.id
            })
          }
        })
      }
    },
    methods: {
      setQueue(queue) {
        if(!this.loading) {
          this.selectedQueue = queue
          this.getQueueExtensions()
        }
      },
      addExtension(extension) {
        if(!this.loading) {
          let queueExtension = {
            id: extension.id,
            name: extension.name,
            number: extension.number,
            penalty: 0,
            elementId: 0
            
          }

          this.selectedExtensions.push(queueExtension)

          this.needSave = true
        }
      },
      removeExtension(index) {
        if(!this.loading) {
          this.selectedExtensions.splice(index, 1)

          this.needSave = true
        }
      },
      onMove({ relatedContext, draggedContext }) {
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;

        return (
          (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
        );

        
      },
      saveQueue() {
        if(!this.loading) {
          this.loading = true
          this.needSave = false
          this.$store.dispatch("updateQueueExtensions", {
            queueId: this.selectedQueue.id,
            data: {
              selected_extensions: this.selectedExtensions
            }
          }).then(() => {
            this.$store.dispatch("getRegisters", {moduleUrl: "queues"})
              .then((res) => {
                this.queues = res
                this.getQueueExtensions()
              })
          })
        }
      },
      getQueueExtensions() {
        this.loading = true
        this.hasStatus = false
        this.$store.dispatch("getQueueExtensions", {queueId: this.selectedQueue.id})
          .then((res) => {
            res.forEach((queueExtension) => {
              let index = this.selectedExtensions.findIndex((extension) => extension.number == queueExtension.extension)

              if(index > -1) {
                console.log(queueExtension)
                let extension = this.selectedExtensions[index]

                this.$set(extension, 'paused', queueExtension.paused)
                this.$set(this.selectedExtensions, index, extension)
              }
            })

            console.log(this.selectedExtensions)
            
            this.loading = false
            this.hasStatus = true
          })
      },
      pauseAllQueues(extensionId) {
        this.loading = true
        this.$store.dispatch("pauseAllQueues", {
          extensionId: extensionId
        }).then((res) => {
          console.log(res)
          this.getQueueExtensions()
        })
      },
      unpauseAllQueues(extensionId) {
        this.loading = true
        this.$store.dispatch("unpauseAllQueues", {
          extensionId: extensionId
        }).then((res) => {
          console.log(res)
          this.getQueueExtensions()
        })
      },
      pauseExtensionQueue(extensionId) {
        this.loading = true
        this.$store.dispatch("pauseExtensionQueue", {
          extensionId: extensionId,
          queueId: this.selectedQueue.id
        }).then((res) => {
          console.log(res)
          this.getQueueExtensions()
        })
      },
      unpauseExtensionQueue(extensionId) {
        this.loading = true
        this.$store.dispatch("unpauseExtensionQueue", {
          extensionId: extensionId,
          queueId: this.selectedQueue.id
        }).then((res) => {
          console.log(res)
          this.getQueueExtensions()
        })
      }
    }
  }
</script>
<style scooped lang="scss">
</style>