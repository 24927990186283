import { render, staticRenderFns } from "./ChatRoomV2SendForm.vue?vue&type=template&id=4398719b&scoped=true&lang=pug&"
import script from "./ChatRoomV2SendForm.vue?vue&type=script&lang=js&"
export * from "./ChatRoomV2SendForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4398719b",
  null
  
)

export default component.exports