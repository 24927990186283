<template lang="pug">
.report-summary-department
  .card.mb-3
    .card-body
      .d-flex.justify-content-between
        h5.card-title {{reportReportItem.name}}
      table.table.text-center
        thead
          tr
            th Departamento
            th Total de llamadas
        tbody
          tr(v-for="register in data.dataTable")
            td {{register.name}}
            td {{register.total}}
              
</template>
<script>
  export default {
    name: 'ReportRGOverflowCC',
    components: {
      
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        voicemailsCalls: [],
        lostCalls: []
      }
    },
    computed: {
      series() {
        return []
      },
      labels() {
        return []
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
        })
      },
      getVoicemailCalls(departmentId) {
        this.$store.dispatch("searchCalls", {
          page: 1,
          data: {
            advancedFilter: {
              departments_id: [departmentId],
              call_types_id: [1],
              call_statuses_id: [5],
              start: {
                start: this.filters.start, 
                end: this.filters.end
              }
            },
            sortColumn: "calls.start",
            sortDirection: 1,
            limit: 100,
          }
        }).then((res) => {
          this.voicemailsCalls = res.data
        })
      },
      getLostCalls(departmentId) {
        this.$store.dispatch("searchCalls", {
          page: 1,
          data: {
            advancedFilter: {
              departments_id: [departmentId],
              call_types_id: [1],
              call_statuses_id: [4],
              start: {
                start: this.filters.start, 
                end: this.filters.end
              }
            },
            sortColumn: "calls.start",
            sortDirection: 1,
            limit: 100,
          }
        }).then((res) => {
          this.lostCalls = res.data
        })
      },
    }
  }
</script>
<style lang="scss">
</style>