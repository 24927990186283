import { render, staticRenderFns } from "./UserCallsMin.vue?vue&type=template&id=0e5b6f7b&lang=pug&"
import script from "./UserCallsMin.vue?vue&type=script&lang=js&"
export * from "./UserCallsMin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports