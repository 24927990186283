<template lang="pug">
.report-summary-department
  #modalVoicemail.modal.fade(tabindex='-1' aria-labelledby='modalVoicemailLabel' aria-hidden='true')
    .modal-dialog
      .modal-content
        .modal-header
          h5#modalVoicemailLabel.modal-title Llamadas en el buzón
          button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
        .modal-body
          table.table
            thead
              tr
                th Número
                th Fecha
                th Contestada
            tbody
              tr(v-for="call in voicemailsCalls")
                td {{call.from}}
                td {{[call.start, "unix"] | unixToDateTime}}
                td
                  strong.text-success(v-if="call.returned") Sí
                  strong.text-danger(v-else) No
                td
                  a.me-3(v-if="call.call_voicemail.path" :href="call.call_voicemail.path" target="_blank")
                    font-awesome-icon(icon="voicemail")
                  a.me-3(@click="makeCall(call.from)")
                    font-awesome-icon(icon="phone")
  #modalLost.modal.fade(tabindex='-1' aria-labelledby='modalLostLabel' aria-hidden='true')
    .modal-dialog
      .modal-content
        .modal-header
          h5#modalLostLabel.modal-title Llamadas perdidas
          button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
        .modal-body
          table.table
            thead
              tr
                th Número
                th Fecha
                th Contestada
            tbody
              tr(v-for="call in lostCalls")
                td {{call.from}}
                td {{[call.start, "unix"] | unixToDateTime}}
                td
                  strong.text-success(v-if="call.returned") Sí
                  strong.text-danger(v-else) No
                td
                  a.me-3(@click="makeCall(call.from)")
                    font-awesome-icon(icon="phone")
  #modalNoReturned.modal.fade(tabindex='-1' aria-labelledby='modalNoReturnedLabel' aria-hidden='true')
    .modal-dialog
      .modal-content
        .modal-header
          h5#modalNoReturnedLabel.modal-title Llamadas no retornadas
          button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
        .modal-body
          table.table
            thead
              tr
                th Número
                th Fecha
                th Contestada
            tbody
              tr(v-for="call in noReturnedCalls")
                td {{call.from}}
                td {{[call.start, "unix"] | unixToDateTime}}
                td
                  strong.text-success(v-if="call.returned") Sí
                  strong.text-danger(v-else) No
                td
                  a.me-3
                    font-awesome-icon(icon="phone")
  .card.mb-3
    .card-body
      .d-flex.justify-content-between
        h5.card-title {{reportReportItem.name}}
      table.table.text-center
        thead
          tr
            th Departamento
            th Pedir Cita
            th Modificar Cita
            th con. Horario
            th h. Asesora
            th horario + cita
            th total
            th buzón
            th perdida
            th no retornadas
            th 24 horas +
            th % buzón
        tbody
          tr(v-for="register in data.dataTable")
            td {{register.name}}
            td {{register.pedir_cita}}
            td {{register.modificar_cita}}
            td {{register.con_horario}}
            td {{register.horario_asesora}}
            td {{register.horario_asesora_cita}}
            td {{register.total}}
            td.bg-primary
              a.text-white(href='#' data-bs-toggle="modal" data-bs-target="#modalVoicemail" @click="getVoicemailCalls(register.department_id)") {{register.voicemail}}
            td.bg-warning 
              a.text-dark(href='#' data-bs-toggle="modal" data-bs-target="#modalLost" @click="getLostCalls(register.department_id)") {{register.lost}}
            td.bg-danger.text-white 
              a.text-white(href='#' data-bs-toggle="modal" data-bs-target="#modalNoReturned" @click="getNoReturnedCalls(register.department_id)") {{register.no_returned}}
            td.bg-danger.text-white {{register.no_returned_24}}
            td.bg-info.text-white -%
              
</template>
<script>
  export default {
    name: 'ReportSummaryDepartment',
    components: {
      
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        voicemailsCalls: [],
        lostCalls: [],
        noReturnedCalls: []
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      series() {
        return []
      },
      labels() {
        return []
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      makeCall(phone) {
        if(this.currentUser.extension.number) {
          this.$store.dispatch("makeCall", {
            phone: '0' + phone,
            extension: this.currentUser.extension.number
          })
        }
      },
      getData() {
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
        })
      },
      getVoicemailCalls(departmentId) {
        this.$store.dispatch("searchCalls", {
          page: 1,
          data: {
            advancedFilter: {
              departments_id: [departmentId],
              call_types_id: [1],
              call_statuses_id: [5],
              start: {
                start: this.filters.start, 
                end: this.filters.end
              }
            },
            sortColumn: "calls.start",
            sortDirection: 1,
            limit: 100,
          }
        }).then((res) => {
          this.voicemailsCalls = res.data
        })
      },
      getLostCalls(departmentId) {
        this.$store.dispatch("searchCalls", {
          page: 1,
          data: {
            advancedFilter: {
              departments_id: [departmentId],
              call_types_id: [1],
              call_statuses_id: [4],
              start: {
                start: this.filters.start, 
                end: this.filters.end
              }
            },
            sortColumn: "calls.start",
            sortDirection: 1,
            limit: 100,
          }
        }).then((res) => {
          this.lostCalls = res.data
        })
      },
      getNoReturnedCalls(departmentId) {
        this.$store.dispatch("searchCalls", {
          page: 1,
          data: {
            advancedFilter: {
              departments_id: [departmentId],
              call_types_id: [1],
              call_statuses_id: [4,5],
              returned: 0,
              start: {
                start: this.filters.start, 
                end: this.filters.end
              }
            },
            sortColumn: "calls.start",
            sortDirection: 1,
            limit: 100,
          }
        }).then((res) => {
          this.noReturnedCalls = res.data
        })
      },
    }
  }
</script>
<style lang="scss">
</style>