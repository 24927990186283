<template lang="pug">
.screen-user-active-tickets.mb-3
  .card 
    .card-body
      h5.card-title.mb-2.ps-2 {{filters.title}}
      .d-flex.text-center.flex-wrap
        .flex-fill
          .p-2.rounded.m-2.rounded.text-danger(:class="currentTab == '+48' ? 'bg-danger-opacity-50' : 'bg-danger-opacity-25'" @click="setTab('+48')")
            small.small > 48h
            div
              strong.h4.mb-0.pb-0 {{totalPlus48}}
        .flex-fill
          .p-2.rounded.m-2.rounded.text-warning(:class="currentTab == '+24' ? 'bg-warning-opacity-50' : 'bg-warning-opacity-25'" @click="setTab('+24')")
            small.small > 24h
            div
              strong.h4.mb-0.pb-0 {{totalPlus24}}
        .flex-fill
          .p-2.rounded.m-2.rounded.text-info(:class="currentTab == '-24' ? 'bg-info-opacity-50' : 'bg-info-opacity-25'" @click="setTab('-24')")
            small.small < 24h
            div
              strong.h4.mb-0.pb-0 {{totalLess24}}
      .mt-2(v-if="tasksLists.length > 0")
        .limit-scroll-50vh
          task-list-item(v-for="task in tasksLists" :key="'taskListItem' + task.id" :task="task" :reduced="true")
      
      
      
</template>
<script>
  import TaskListItem from '../tasks/TaskListItem.vue'
  export default {
    name: 'ScreenTotalActiveTickets',
    components: {
      TaskListItem
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        currentTab: ''
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      allTasks() {
        return this.$store.state.taskModule.tasks.filter((t) => t.finished == 0)
      },
      tasks() {
        return this.allTasks.filter(task => {
          let show = true
          if(this.filters.taskTypeIds) {
            show = show && this.filters.taskTypeIds.findIndex((tti) => tti == task.task_type_id) > -1
          }

          if(this.filters.showOnlyMy) {
            show = show && (task.users.findIndex(u => u.id == this.currentUser.id) > -1)
          }

          if(this.filters.showOnlyToday) {
            show = show && (task.today || (task.start_date && this.$moment(this.$moment(new Date()).format('YYYY-MM-DD')).isSameOrAfter(task.start_date)))
          }

          return show
        })
      },
      tasksPlus48() {
        return this.tasks.filter(task => {
          let windowTime = this.$options.filters.diffToNow(task.created_at)
          windowTime = this.$options.filters.timeInHoursShort(windowTime)

          return windowTime == '+48h'
      
        })
      },
      tasksPlus24() {
        return this.tasks.filter(task => {
          let windowTime = this.$options.filters.diffToNow(task.created_at)
          windowTime = this.$options.filters.timeInHoursShort(windowTime)

          return windowTime == '+24h'
      
        })
      },
      tasksLess24() {
        return this.tasks.filter(task => {
          let windowTime = this.$options.filters.diffToNow(task.created_at)
          windowTime = this.$options.filters.timeInHoursShort(windowTime)

          return windowTime != '+24h' && windowTime != '+48h'
      
        })
      },
      totalPlus48() {
        return this.tasksPlus48.length
      },
      totalPlus24() {
        return this.tasksPlus24.length
      },
      totalLess24() {
        return this.tasksLess24.length
      },
      tasksLists() {
        if(this.currentTab == '+48') {
          return this.tasksPlus48
        }
        if(this.currentTab == '+24') {
          return this.tasksPlus24
        }
        if(this.currentTab == '-24') {
          return this.tasksLess24
        }
        
        return []
      }
      
    },
    mounted() {
    },
    methods: {
      setTab(value) {
        if(this.currentTab == value) {
          this.currentTab = ''
        } else {
          this.currentTab = value
        }
      }
    }
  }
</script>
<style lang="scss">
</style>