<template lang="pug">
#emailViewItem
  .card-gc-border.mb-2
    .card-gc-body.border-info(style="overflow-x:auto;")
      strong(v-if="email.write_user_id") {{email.write_user.name}}:
      strong(v-else-if="email.account_id") {{ email.account.name }}
      strong(v-else) -
      br
      strong {{email.email_message_body.subject}}
      .pb-2(v-html="email.email_message_body.body")
      span.text-secondary {{email.sended_at  | moment('DD-MM-YYYY hh:mm:ss')}}
</template>
<script>


export default {
  name: 'EmailViewItem',
  props: {
    email: { type: Object, default: () => { } },
  },
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    
  }
}
</script>
<style scoped>
p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>
