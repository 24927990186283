<template lang="pug">
.show-email-message(v-if="currentEmailMessage && currentEmailMessage.id")
  .card-gc.mb-2
    .card-gc-body(:class="getBorderColor")
      .d-flex
        .flex-grow-1
          strong {{currentEmailMessage.email_message_body.subject}}
        div
          span.badge.bg-success.rounded-pill(v-if="currentEmailMessage.unread")
            font-awesome-icon.me-1(icon="envelope")
            span !
      div
        span {{currentEmailMessage.account.name}}
      .d-flex.mt-1.justify-content-between  
        div
          span.badge.bg-success.me-2 {{currentEmailMessage.sended_at | diffToNow | timeInHoursShort}}
          span.badge.bg-primary.me-2 Incidencias
          //span.badge.bg-warning.me-2(v-if="chatRoom.message_end") {{chatRoom.message_end.name}}
        .d-flex.align-items-center
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(v-if="!iAmOwner" @click="assignEmailMessage()")
            font-awesome-icon(icon="handshake") 
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(v-else @click="unassignEmailMessage()")
            font-awesome-icon(icon="handshake-slash")
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2
            font-awesome-icon(:icon="['fas', 'exchange-alt']")
          //button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2
            font-awesome-icon(:icon="['fas', 'user-plus']")
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(@click="setTab('tasks')")
            font-awesome-icon(:icon="['fa', 'tasks']")
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2
            font-awesome-icon(icon="archive")
          //button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2
            font-awesome-icon(icon="archive")
          button.btn.btn-link.text-dark.p-0.mt-0.mb-0.ms-2.me-0(@click="close()")
            font-awesome-icon.me-2(icon="times")
          //span.badge.bg-dark(v-if="chatRoom.owner_user" data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-html="true" :title="chatRoom.owner_user.name") {{chatRoom.owner_user.name | string2Initials }}
      //.d-flex.align-items-center.mt-1(v-if="chatRoom.account.opened_tasks && chatRoom.account.opened_tasks.length > 0")  
        font-awesome-icon.me-1(icon="tasks")
        span {{chatRoom.account.opened_tasks[0].task_description.name.substring(0, 40) }}
        span(v-if="chatRoom.account.opened_tasks[0].task_description.name.length > 40") ...
  .row
    .col
      .p-2
        strong.mb-2.h4 {{ currentEmailMessage.email_message_body.subject }}
        div(v-html="currentEmailMessage.email_message_body.body")
        hr
        input.form-control(v-model="to" @keyup.enter="addRecipient" placeholder="Destino")
        .pt-2
          span.badge.bg-primary.me-2(v-for="recipient in recipients") 
            span.me-1 {{ recipient }}
            font-awesome-icon(icon="times" @click="removeRecipient(recipient)")
        hr
        input.form-control(v-model="subject")
        hr
        VueEditor.mb-2.mt-2(v-model="message")
        button.btn.btn-primary.btn-lg(@click="sendEmail")
          font-awesome-icon.me-2(:icon="['fas', 'paper-plane']")
          span Enviar
    .col(v-if="tab == 'tasks'")
      .p-2
        .task-list-items
          task-list-item(v-for="task in accountTasks" :key="'listTaskListItem' + task.id" :task="task")


</template>
<script>
  import TaskListItem from '../tasks/TaskListItem.vue'
  export default {
    name: 'ShowEmailMessage',
    components: {
      TaskListItem
    },
    props: {
    },
    data () {
      return {
        tab: 'body',
        filter: '',
        extension: '',
        selectedChatId: 0,
        displayNotification: false,
        message: '',
        subject: '',
        to: '',
        recipients: []
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      getBorderColor() {
        let border = 'border-info'

        let indexOwner = this.currentEmailMessage.owners.findIndex(owner => owner.id == this.currentUser.id)
        if (indexOwner > -1) {
          border = 'border-primary'
        } else if (indexOwner == -1 && this.currentEmailMessage.owners.length > 0) {
          border = 'border-secondary'
        }

        return border
      },
      currentEmailMessage() {
        return this.$store.state.emailMessageModule.currentEmailMessage
      },
      iAmOwner() {
        if (this.currentEmailMessage) {
          let index = this.currentEmailMessage.owners.findIndex(owner => owner.id == this.currentUser.id)

          return index > -1
        } else {
          return false
        }
      },
      tasks() {
        return this.$store.state.taskModule.tasks
      },
      accountTasks() {
        return this.tasks.filter(t => t.account_id == this.currentEmailMessage.account_id)
      },
      
    },
    watch: {
      currentEmailMessage(val) {
        if (val) {
          this.recipients = []
          this.subject = val.email_message_body.subject
          val.email_message_recipients.forEach((emailMessageRecipient) => {
            this.recipients.push(emailMessageRecipient.email)
          })
        }
      }
    },
    mounted() {
      if (this.currentEmailMessage) {
        this.subject = this.currentEmailMessage.email_message_body.subject
        this.currentEmailMessage.email_message_recipients.forEach((emailMessageRecipient) => {
          this.recipients.push(emailMessageRecipient.email)
        })
      }
    },
    methods: {
      close() {
        this.cancelTab()
        this.$store.commit("setCurrentEmailMessage", null)
      },
      assignEmailMessage() {
        this.$store.dispatch("assignEmailMessage", {
          id: this.currentEmailMessage.id,
          value: 1,
          userId: this.currentUser.id
        })
      },
      unassignEmailMessage() {
        this.$store.dispatch("assignEmailMessage", {
          id: this.currentEmailMessage.id,
          value: 0,
          userId: this.currentUser.id
        })
      },
      setTab(tab) {
        if (this.tab == tab) {
          this.cancelTab()
        } else {
          this.tab = tab
        }
      },
      cancelTab() {
        this.tab = 'body'
        this.message = ''
      },
      addRecipient() {
        this.recipients.push(this.to)
        this.to = ''
      },
      removeRecipient(recipient) {
        let index = this.recipients.findIndex((r) => r == recipient)

        if (index > -1) {
          this.recipients.splice(index, 1)
        }
      },
      sendEmail() {
        let data = {
          body: this.message,
          recipients: this.recipients,
          subject: this.subject,
          email_message_id: this.currentEmailMessage.id,
          email_config_id: this.currentEmailMessage.email_config_id,
          account_id: this.currentEmailMessage.account_id,
        }

        this.$store.dispatch("sendEmailMessage", data).then((res) => {
          console.log(res)
          this.message = ''
        })
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>