<template lang="pug">
.screen-queues-full-history
  .card.mb-3
    .card-body
      .d-flex.justify-content-between
        h5.card-title.mb-1 {{ $t('Mis colas') }}
        
      input.form-control.mb-2(type="date" v-model="date")
      table.table
        thead
          tr.text-center
            th 
            th {{ $t('Perdidas') }}
            th {{ $t('Totales') }}
            th {{ $t('Espera') }}
            th {{ $t('Nivel Servicio') }}
            th {{ $t('Pickup rate') }}

        tbody
          tr(v-for="report in reports")
            td.text-end
              h5
                span.badge(:style="'background: ' + report.queue.color + ';'" v-if="report.queue.short_name") {{report.queue.short_name}}
                span.badge(:style="'background: ' + report.queue.color + ';'" v-else) {{report.queue.number}}
            td.text-center.pb-0.position-relative
              h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
                font-awesome-icon(icon="exclamation-triangle")
              h1.mt-1.mb-2.text-secondary {{report.total_lost_calls}}
            td.text-center.pb-0.position-relative
              h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
                font-awesome-icon(icon="angle-double-right")
              h1.mt-1.mb-2.text-info {{report.total_calls}}
            td.text-center.pb-0.position-relative
              h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
                font-awesome-icon(icon="clock")
              h1.mt-1.mb-2.text-warning {{report.wait_time}}
            td.text-center.pb-0.position-relative
              h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
                font-awesome-icon(icon="thumbs-up")
              h1.mt-1.mb-2.text-info {{report.target_wait_time}}
            td.text-center.pb-0.position-relative
              h5.text-info-opacity-20.position-absolute(style="top: 3px;left: 15px")
                font-awesome-icon(icon="clipboard-check")
              h1.mt-1.mb-2.text-dark {{report.pickup_rate}}
</template>
<script>
  export default {
    name: 'ScreenMyQueuesFullHistory',
    components: {
      
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        date: null,
        reports: []
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      queues() {
        return this.$store.state.queueModule.queues
      }
    },
    watch: {
      date(newVal) {
        this.getReports(newVal)
      }
    },
    mounted() {
        if(this.filters.queues_id) {
          this.getReports(null)
        }
    },
    methods: {
      getReports(date) {
        this.$store.dispatch("getQueuesDayReport", {
          date: date,
          queue_ids: this.filters.queues_id
        }).then((res) => {
          this.reports = res
        })
      } 
    }
  }
</script>
<style lang="scss">
</style>