<template lang="pug">
.report-total-call-by-call-end
  .card.mb-3
    .card-body
      .d-flex.justify-content-between
        h5.card-title {{$t(reportReportItem.name)}}
      .row
        .col
          table.table
            thead
              tr
                th {{$t('Mes')}}
                th {{$t('% SLA')}}
                th {{$t('% Lost calls')}}
                th {{$t('Time Management')}}
            tbody
              tr(v-for="(row, index) in data.dataTable")
                td {{months[index - 1]}}
                td {{row.sla}}%
                td {{row.lost}}%
                td {{row.duration | timeInHours}}
        .col
          template(v-if="series && series.length > 0 && labels && labels.length > 0")
            chart-columns(v-if="series.length > 0" :series="series" :categories="labels" :colors="colors")
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  export default {
    name: 'ReportTotalCallByCallEnd',
    components: {
      ChartColumns
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        showChart: true
      }
    },
    computed: {
      series() {
        if(this.data.dataChart && Object.values(this.data.dataChart).length > 0) {
          let series = []  
          series.push({
            name: "SLA",
            data: []
          })

          series.push({
            name: "Lost",
            data: []
          })
          
          Object.values(this.data.dataChart).forEach((row) => {
            series[0].data.push(row.sla)
            series[1].data.push(row.lost)
          })

          return series
        } else {
          return []
        }
      },
      labels() {
        return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>