import Vue from 'vue'
import emailConfigService from '../services/api/email_configs'

const emailMessageModule = {
    state: {
        emailConfigs: [],
        emailMessages: [],
        page: 1,
        limit: 10,
        start: 1,
        total: 1,
        currentEmailMessage: {}
    },
    mutations: {
        setEmailConfigs (state, emailConfigs) {
            state.emailConfigs = emailConfigs
        },
        setEmailMessages (state, emailMessages) {
            state.emailMessages = emailMessages.messages
            state.page = emailMessages.page
            state.limit = emailMessages.limit
            state.start = emailMessages.start
            state.total = emailMessages.total
        },
        setEmailConfigCurrentEmailMessage (state, currentEmailMessage) {
            state.currentEmailMessage = currentEmailMessage
        },
        saveEmailMessage(state, emailMessage) {
            let index = state.emailMessages.findIndex((em) => em.id == emailMessage.id)

            if(index == -1) {
                state.emailMessages.push(emailMessage)
            } else {
                Vue.set(state.emailMessages, index, emailMessage)
            }
        }
    },
    actions: {
        getMyEmailConfigs(context) {
            return new Promise((resolve, reject) => {
                emailConfigService.getMyEmailConfigs()
                    .then((res) => {
                        context.commit("setEmailConfigs", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getEmailConfigMessages(context, payload) {
            return new Promise((resolve, reject) => {
                emailConfigService.getMessages(payload.emailConfigId, payload.path, payload.page)
                    .then((res) => {
                        context.commit("setEmailMessages", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        changeUnreadEmailMessage(context, payload) {
            return new Promise((resolve, reject) => {
                emailConfigService.changeUnreadEmailMessage(payload.emailConfigId, payload.path, payload.emailMessageId, payload.unread)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        sendEmailConfirMessage(context, payload) {
            return new Promise((resolve, reject) => {
                emailConfigService.sendEmailMessage(payload.emailConfigId, payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getAllPreviousEmailMessage(context, payload) {
            return new Promise((resolve, reject) => {
                emailConfigService.getAllPreviousMessage(payload.emailConfigId, payload.emailMessageId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getAlllatersEmailMessage(context, payload) {
            return new Promise((resolve, reject) => {
                emailConfigService.getAlllatersMessage(payload.emailConfigId, payload.emailMessageId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        }
    }
}

export default emailMessageModule