<template lang="pug">
.chat-room-v2.pe-1   
  template(v-if="reduced")
    div(v-show="!selectedChatRoom.id")
      .bg-white(style="position:sticky;width:100%;top:0px;z-index:10")
        .d-grid
          .btn-group
            .btn(@click="tab = 'all'" :class="tab == 'all' ? 'btn-primary' : 'btn-outline-primary'")
              font-awesome-icon(icon="layer-group")
              br
              span All
            .btn(@click="tab = 'new'" :class="tab == 'new' ? 'btn-success' : 'btn-outline-success'")
              font-awesome-icon(icon="eye-slash")
              br
              span New
            .btn(@click="tab = 'my'" :class="tab == 'my' ? 'btn-warning' : 'btn-outline-warning'")
              font-awesome-icon(icon="handshake")
              br
              span My
            .btn(@click="tab = 'history'" :class="tab == 'history' ? 'btn-secondary' : 'btn-outline-secondary'")
              font-awesome-icon(icon="history")
              br
              span Old
        .d-grid
          input.form-control.rounded.mt-2.mb-2(type="text" placeholder="Buscar" v-model="filter")
        .d-grid
          .btn-group
            .btn(@click="windowTab = 'all'" :class="windowTab == 'all' ? 'btn-primary' : 'btn-outline-primary'")
              span All
            .btn(@click="windowTab = '-24h'" :class="windowTab == '-24h' ? 'btn-success' : 'btn-outline-success'")
              span < 24h
            .btn(@click="windowTab = '+24h'" :class="windowTab == '+24h' ? 'btn-warning' : 'btn-outline-warning'")
              span +24h
            .btn(@click="windowTab = '+48h'" :class="windowTab == '+48h' ? 'btn-danger' : 'btn-outline-secondary'")
              span +48h
      div(v-if="chatRooms.length > 0")
        chat-room-v2-list-item(v-for="chatRoom in chatRooms" :chatRoom="chatRoom" :reduced="reduced" :key="'chatRoom' + chatRoom.id")
    chat-room-v2-chat(v-show="selectedChatRoom && selectedChatRoom.id" :reduced="reduced")        
  template(v-else)
    .row
      .col-3
        .ps-2.pt-1(style="overflow-x: hidden;overflow-y: scroll;height: calc(100vh - 80px);")
          .bg-white(style="position:sticky;width:100%;top:0px;z-index:10")
            .d-grid.mb-2
              .btn-group
                .btn(@click="tab = 'all'" :class="tab == 'all' ? 'btn-primary' : 'btn-outline-primary'")
                  font-awesome-icon(icon="layer-group")
                  br
                  span All
                .btn(@click="tab = 'new'" :class="tab == 'new' ? 'btn-success' : 'btn-outline-success'")
                  font-awesome-icon(icon="eye-slash")
                  br
                  span New
                .btn(@click="tab = 'my'" :class="tab == 'my' ? 'btn-warning' : 'btn-outline-warning'")
                  font-awesome-icon(icon="handshake")
                  br
                  span My
                .btn(@click="tab = 'history'" :class="tab == 'history' ? 'btn-secondary' : 'btn-outline-secondary'")
                  font-awesome-icon(icon="history")
                  br
                  span Old
            .d-grid.mt-2
              .btn-group
                .btn(@click="windowTab = 'all'" :class="windowTab == 'all' ? 'btn-primary' : 'btn-outline-primary'")
                  span All
                .btn(@click="windowTab = '-24h'" :class="windowTab == '-24h' ? 'btn-success' : 'btn-outline-success'")
                  span < 24h
                .btn(@click="windowTab = '+24h'" :class="windowTab == '+24h' ? 'btn-warning' : 'btn-outline-warning'")
                  span +24h
                .btn(@click="windowTab = '+48h'" :class="windowTab == '+48h' ? 'btn-danger' : 'btn-outline-danger'")
                  span +48h
            .d-grid
              input.form-control.rounded.mt-2.mb-2(type="text" placeholder="Buscar" v-model="filter")
          div
            chat-room-v2-list-item(v-for="chatRoom in chatRooms" :chatRoom="chatRoom" :reduced="reduced" :key="'chatRoom' + chatRoom.id")
      .col-9.bg-white
        .p-1.chatv2(style="overflow-x: hidden;overflow-y: scroll;height: calc(100vh - 80px);")
          chat-room-v2-chat(v-show="selectedChatRoom && selectedChatRoom.id" :reduced="reduced")      
          template(v-if="!reduced && !selectedChatRoom.id && showFormSend")
            .row.justify-content-center
              .col-xxl-4.col-xl-5.col-lg-6.col-md-8.align-self-center.bg-white
                .mt-3
                  .bg-secondary-opacity-20.p-3
                    h5.card-title Enviar un mensaje nuevo
                    label Enviar por:
                    v-select(:options="whatsappNumbers" :reduce="whatsappNumber => whatsappNumber.id"  label="name" v-model="whatsappNumberId")

                    label Destino
                    input.form-control(v-model="phoneNumber")
                    template(v-if="rgpd == 1")
                      label Seleccione una plantilla
                      v-select(:options="messageTemplates" :reduce="messageTemplate => messageTemplate.id"  label="label" v-model="messageTempalateId")

                      template(v-if="selectedMessageTemplate.id")
                        div(v-if="editCustomMessage")
                          textarea.form-control.mt-3.mb-3(v-model="customMessage")
                        div(v-else)
                          .alert.bg-info-opacity-25.mt-3(v-html="formatedMessage" :class="messageTypeId == 2 ? 'mb-3' : ''")
                          button.btn.btn-link(v-if="messageTypeId == 2" @click="setEditCustomMessage(true)")
                            font-awesome-icon(icon="edit")

                        .mb-1.pb-1(v-for="(param, index) in selectedMessageTemplate.message_template_params")
                          label.label {{param.label}}
                          input.form-control(v-model="params[index]")
                        .d-grid.gap-2
                          button.btn.btn-primary(@click="sendTemplate()") Enviar plantilla
                        p.mt-3.alert.bg-success-opacity-25(v-if="successMessage") {{successMessage}}
                        p.mt-3.alert.bg-danger-opacity-25(v-if="errorMessage") {{errorMessage}}  
                    template(v-else)
                      p.mt-4.alert.bg-danger-opacity-25 La cuenta no tiene aceptada la RGPD, es necesario notificarlo para aceptarla 
                      .d-grid.gap-2(v-if="messageTemplates.length > 0")
                        button.btn.btn-primary(@click="sendTemplateRGPD()") Enviar plantilla
                      p.mt-3.alert.bg-success-opacity-25(v-if="successMessage") {{successMessage}}
                      p.mt-3.alert.bg-danger-opacity-25(v-if="errorMessage") {{errorMessage}}
          template(v-else-if="!reduced && !selectedChatRoom.id && !showFormSend")  
            .row
              .col
                .col-xxl-4.col-xl-5.col-lg-6.col-md-8.align-self-center.bg-white
                  .pt-3.mt-3.text-center
                    button.btn.btn-primary.btn-lg(@click="showFormSend = true")
                      font-awesome-icon.me-1(icon="send")
                      span Enviar un mensaje nuevo
</template>
<script>
  import ChatRoomV2ListItem from './ChatRoomV2ListItem.vue'
  import ChatRoomV2Chat from './ChatRoomV2Chat.vue'
  export default {
  name: 'ChatRoomV2',
  components: {
      ChatRoomV2ListItem,
      ChatRoomV2Chat,
    },
    props: {
      reduced: {type: Boolean, default: false},
    },
    data () {
      return {
        tab: 'all',
        windowTab: 'all',
        filter: '',
        messageTypeId: 1,
        phoneNumber: '',
        departmentCode: '',
        rgpd: 1,
        messageTempalateId: 0,
        params: [],
        successMessage: '',
        errorMessage: '',
        customMessage: '',
        editCustomMessage: false,
        showFormSend: false,
        messageTemplates: [],
        whatsappNumberId: null,
        whatsappNumbers: []
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
      chatRooms() {
        let chatRooms = this.$store.state.chatRoomModule.chatRooms.filter((cr) => {
          if (this.tab == 'all' && this.filter) {
            return cr.account.name.toLowerCase().includes(this.filter.toLowerCase()) || cr.from.toLowerCase().includes(this.filter.toLowerCase())
          } else if (this.tab == 'new' && this.filter) {
            return cr.unread > 0 && (cr.account.name.toLowerCase().includes(this.filter.toLowerCase()) || cr.from.toLowerCase().includes(this.filter.toLowerCase()))
          } else if (this.tab == 'new') {
            return cr.unread > 0
          } else if (this.tab == 'my' && this.filter) {
            return cr.owner_user_id == this.currentUser.id && (cr.account.name.toLowerCase().includes(this.filter.toLowerCase()) || cr.from.toLowerCase().includes(this.filter.toLowerCase()))
          } else if (this.tab == 'my') {
            return cr.owner_user_id == this.currentUser.id
          } else {
            return true
          }
        })

        if (this.windowTab != 'all') {
          chatRooms = chatRooms.filter((cr) => {
            let windowTime = this.$options.filters.diffToNow(cr.last_account_message)
            windowTime = this.$options.filters.timeInHoursShort(windowTime)
            if (this.windowTab == '-24h') {
              return windowTime != '+24h' && windowTime != '+48h'
            } else if (this.windowTab == '+24h') {
              return windowTime == '+24h'
            } else if (this.windowTab == '+48h') {
              return windowTime == '+48h'
            }

            return false
          })
        }

        return chatRooms.sort((a, b) => {
          if (a.last_message_at && !b.last_message_at) {
            return -1
          } else if (!a.last_message_at && b.last_message_at) {
            return 1
          } else if (a.last_message_at > b.last_message_at) {
            return -1
          } else if (a.last_message_at < b.last_message_at) {
            return 1
          }

          return 0
        })
      },
      /*filteredChatRooms() {
        if (this.chatRooms.length > 0) {
          let chatRooms = []
          if (this.tab == 'all') {
            chatRooms = this.chatRooms
          } else if (this.tab == 'new') {
            chatRooms = this.chatRooms.filter((cr) => cr.unread > 0)
          } else if (this.tab == 'my') {
            chatRooms = this.chatRooms.filter((cr) => cr.owner_user_id == this.currentUser.id)
          }

          if (this.filter) {
            chatRooms = chatRooms.filter((cr) => cr.account.name.toLowerCase().includes(this.filter.toLowerCase()) || cr.from.toLowerCase().includes(this.filter.toLowerCase()))
          }

          return chatRooms
        } else {
          return []
        }
      },
      sortedChatRooms() {
        if (this.filteredChatRooms.length > 0) {
          let sortedChatRooms = this.filteredChatRooms
          return sortedChatRooms.sort((a, b) => {
            if (a.last_message_at && !b.last_message_at) {
              return -1
            } else if (!a.last_message_at && b.last_message_at) {
              return 1
            } else if (a.last_message_at > b.last_message_at) {
              return -1
            } else if (a.last_message_at < b.last_message_at) {
              return 1
            }

            return 0
          })
        } else {
          return []
        }
      },*/
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedChatRoom
      },
      messages() {
        return this.$store.state.chatRoomModule.messages
      },
      selectedMessageTemplate() {
        if(this.messageTempalateId) {
          let index = this.messageTemplates.findIndex((mt) => mt.id == this.messageTempalateId)

          if(index > -1) {
            return this.messageTemplates[index]
          }
        }

        return {}
      },
      formatedMessage() {
        let formatedMessage = ''
        if(this.selectedMessageTemplate) {
          formatedMessage = this.selectedMessageTemplate.message

          let i = 1
          this.params.forEach((p) => {
            if(p) {
              formatedMessage = formatedMessage.replace('{{' + i + '}}', '<strong>' + p + '</strong>')
            }
            i++
          })
        }
        return formatedMessage
      }
    },
    watch: {
      messages() {
        if(!this.reduced) {
          setTimeout(() => { this.scrollToBottom() }, 500)
        }
      },
      messageTempalateId(newVal, oldVal) {
        let params = []
        if(oldVal != newVal) {
          if(newVal != 0) {
            let index = this.messageTemplates.findIndex((mt) => mt.id == newVal)

            if(index > -1) {
              let messageTemplate = this.messageTemplates[index]

              messageTemplate.message_template_params.forEach((param) => {
                if(param.default) {
                  params.push(param.default)
                } else {
                  params.push('')
                }
              })
            }
          }

          this.params = params
        }

      },
      whatsappNumberId(newVal) {
        let index = this.whatsappNumbers.findIndex((wn) => wn.id == newVal)

        if (index > -1) {
          let whatsappNumber = this.whatsappNumbers[index]

          if (this.currentUser.multi_department) {
            this.$store.dispatch("getDepartmentMessageTemplates", {
            messageTypeId: this.messageTypeId,
            departmentCode: whatsappNumber.department_id
          }).then((res) => {
            this.messageTemplates = res
          })
          }
        }
      }
    },
    mounted() {
      this.$store.dispatch("getMyWhatsappNumbers")
        .then((res) => {
          this.whatsappNumbers = res

          if(this.whatsappNumbers && this.whatsappNumbers[0]) {
            this.whatsappNumberId = this.whatsappNumbers[0].id
          }
        })
      setTimeout(()=> {
        if(this.$route.params.chatRoomId && this.$route.params.chatRoomId > 0) {
          //this.setSelectedChatId(this.$route.params.chatRoomId)
        } else {
          
          if(this.$route.params.messageTypeId) {
            this.messageTypeId = this.$route.params.messageTypeId
          }

          if(this.$route.params.phoneNumber) {
            this.showFormSend = true
            if(this.$route.params.phoneNumber.substring(0,1) == '+' || this.$route.params.phoneNumber.substring(0,2) == '00') {
              this.phoneNumber = this.$route.params.phoneNumber
            } else {
              if(this.companyConfigs && this.companyConfigs.sms_default_country_code) {
                this.phoneNumber = this.companyConfigs.sms_default_country_code + this.$route.params.phoneNumber
              } else {
                this.phoneNumber = '+34' + this.$route.params.phoneNumber
              }
            }
          }

          if(this.$route.params.departmentCode) {
            this.departmentCode = this.$route.params.departmentCode
          }

          if(this.$route.params.rgpd) {
            this.rgpd = this.$route.params.rgpd
          }

          if (this.messageTypeId > 0) {
            /*if (this.currentUser.multi_department && this.departmentCode) {
              this.$store.dispatch("getDepartmentMessageTemplates", {
                messageTypeId: this.messageTypeId,
                departmentCode: this.departmentCode
              }).then((res) => {
                this.messageTemplates = res
              })
            } else {*/
              this.$store.dispatch("getUserMessageTemplates", {
                messageTypeId: this.messageTypeId
              }).then((res) => {
                this.messageTemplates = res
              })
            //}
          } else {
            this.$store.dispatch("getUserMessageTemplates", {
              messageTypeId: 1
            }).then((res) => {
              this.messageTemplates = res
            })
          }
        }
      }, 3000)
    },
    methods: {
      scrollToBottom() {
        let container = this.$el.querySelector(".chatv2");
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      },
      sendToNewContact() {
        this.rgpd = 1
        this.messageTypeId = 1
        this.selectedChatId = 0
        this.phoneNumber = ''
      },
      setEditCustomMessage(value) {
        this.customMessage = this.formatedMessage
        this.editCustomMessage = value
      },
      sendTemplate() {
        this.successMessage = ''
        this.errorMessage = ''
        if(this.selectedMessageTemplate.id) {
          this.$store.dispatch("sendMessageTemplate", {
            phoneNumber: this.phoneNumber,
            message_content_type_id: 1,
            message_template_id: this.selectedMessageTemplate.id,
            params: this.params,
            department_code: this.departmentCode,
            message_type_id: this.messageTypeId,
            custom_message: this.customMessage,
            whatsapp_number_id: this.whatsappNumberId
            
          }).then((res) => {
            if(res.error) {
              this.errorMessage = 'No se pudo realizar el envio'
            } else {
              this.successMessage = 'Envio realizado correctamente'
            }

            this.cancelSendTemplate()
            
          })
        }
        
      },
      sendTemplateRGPD() {
        this.successMessage = ''
        this.errorMessage = ''

        let index = this.messageTemplates.findIndex((mt) => (mt.name == 'gpp_rgpd2' || mt.name == 'gdpr2'))

        if (index > -1) {
          let messageTemplate = this.messageTemplates[index]
          let params = []
          messageTemplate.message_template_params.forEach((param) => {
            if (param.default) {
              params.push(param.default)
            } else {
              params.push('')
            }
          })

          this.$store.dispatch("sendMessageTemplate", {
            phoneNumber: this.phoneNumber,
            message_content_type_id: 1,
            message_template_id: messageTemplate.id,
            params: params,
            department_code: this.departmentCode,
            message_type_id: 1

          }).then((res) => {
            if (res.error) {
              this.errorMessage = 'No se pudo realizar el envio'
            } else {
              this.successMessage = 'Envio realizado correctamente'
            }
            console.log(res)

          })
        } else {
          this.errorMessage = 'No disponemos de una plantilla para poder enviar la solicitud'
        }


      },
      cancelSendTemplate() {
        //this.rgpd = 0
        this.messageTypeId = 0
        this.selectedChatId = 0
        this.phoneNumber = ''
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>