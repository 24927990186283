<template lang="pug">
#taskTimeViewItem
  .card-gc-border.mb-2
    .card-gc-body.border-warning
      strong {{taskTime.user.name}}
      br
      span.me-1 Registro de tiempo duración:
      strong {{taskTime.duration | timeInHoursMin}}
      br
      span.small.text-dark {{taskTime.start | moment('DD-MM-YYYY hh:mm:ss')}}
</template>
<script>


export default {
  name: 'TaskTimeViewItem',
  props: {
    taskTime: { type: Object, default: () => { } },
  },
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>
