import Vue from 'vue'
import accountsService from '../services/api/accounts'

const accountModule = {
    state: {
        account: {},
        unfineshedTasks: [],
        fineshedTasks: []
        
    },
    mutations: {
        setAccount (state, account) {
            state.account = account
        },
        setUnfinishedTasks (state, unfineshedTasks) {
            state.unfineshedTasks = unfineshedTasks
        },
        addUnfinishedTasks (state, unfineshedTask) {
            state.unfineshedTasks.push(unfineshedTask)
        },
        updateUnfinishedTasks (state, unfineshedTask) {
            let index = state.unfineshedTasks.findIndex((t) => t.id == unfineshedTask.id)

            if(index > -1) {
                Vue.set(state.unfineshedTasks, index, unfineshedTask)
            }
        },
        removeUnfinishedTasks (state, unfineshedTask) {
            let index = state.unfineshedTasks.findIndex((t) => t.id == unfineshedTask.id)

            if(index > -1) {
                state.unfineshedTasks.splice(index, 1)
            }
        },
        setFinishedTasks (state, fineshedTasks) {
            state.fineshedTasks = fineshedTasks
        },
        addFinishedTasks (state, fineshedTask) {
            state.fineshedTasks.push(fineshedTask)
        },
        updateFinishedTasks (state, fineshedTask) {
            let index = state.fineshedTasks.findIndex((t) => t.id == fineshedTask.id)

            if(index == -1) {
                state.fineshedTasks.push(fineshedTask)
            } else {
                Vue.set(state.fineshedTasks, index, fineshedTask)
            }
        },
        removeFinishedTasks (state, fineshedTask) {
            let index = state.fineshedTasks.findIndex((t) => t.id == fineshedTask.id)

            if(index > -1) {
                state.fineshedTasks.splice(index, 1)
            }
        },
    },
    actions: {
        getAccountUninvoicedElements (context, payload) {
            return new Promise((resolve, reject) => {
                accountsService.getAccountUninvoicedElements(payload.accountId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getAccountByCodeNameCif (context, payload) {
            return new Promise((resolve, reject) => {
                accountsService.getAccountByCodeNameCif(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        mergeAccounts (context, payload) {
            return new Promise((resolve, reject) => {
                accountsService.mergeAccounts(payload.accountId, payload.accountIdToMerge)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getAccountTasks(context, payload) {
            return new Promise((resolve, reject) => {
                accountsService.getAccountTasks(payload.accountId, payload.finshed)
                    .then((res) => {
                        if(payload.finished == 1) {
                            context.commit("setFinishedTasks", res)
                        } else {
                            context.commit("setUnfinishedTasks", res)
                        }
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        exportAccountGoogle() {
            return new Promise((resolve, reject) => {
                accountsService.exportAccountGoogle()
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        }
        
    }
}

export default accountModule