<template lang="pug">
.gc-chat
  template(v-if="true")
    .chatv2(style="height:calc(100vh - 80px);position:relative;overflow-y:scroll;overflow-x: hidden")
      chat-room-v2(:reduced="false")
  template(v-else)
    .chat-chat-room-list-content
      chat-room-list(@setSelectedChatId="setSelectedChatId" :selectedChatId="selectedChatId")
    .chat-chat-room-chat-content-chat.chat-background(v-if="selectedChatId > 0")
      .p-2
        .border-bottom.p-3.bg-white
          .d-flex.justify-content-between
            strong.text-primary {{selectedChatRoom.account.name}}
            span.badge.rounded-pill(:key="'chatRoomWindow' + selectedChatRoom.id + counterKey" :class="getWindowBackground(selectedChatRoom.last_account_message)") {{selectedChatRoom.last_account_message | diffToNow | timeInHoursShort}}
        chat-room-chat(@setSelectedChatId="setSelectedChatId")
    .chat-chat-room-chat-content-chat.chat-background(v-if="selectedChatId == 0 && messageTypeId == 0")
      .row
        .col-4
        .col-4
          button.btn.btn-primary.mt-3.btn-lg(@click="sendToNewContact()") Enviar mensaje a un contacto nuevo
        .col-4
    .chat-chat-room-chat-content-chat.chat-background(v-if="selectedChatId == 0 && messageTypeId > 0")
      .row.justify-content-center
        .col-xxl-4.col-xl-5.col-lg-6.col-md-8.align-self-center.bg-white
          .card.mt-3
            .card-body
              label Enviar por:
              v-select(:options="whatsappNumbers" :reduce="whatsappNumber => whatsappNumber.id"  label="name" v-model="whatsappNumberId")

              label Destino
              input.form-control(v-model="phoneNumber")
              template(v-if="rgpd == 1")
                label Seleccione una plantilla
                v-select(:options="messageTemplates" :reduce="messageTemplate => messageTemplate.id"  label="label" v-model="messageTempalateId")

                template(v-if="selectedMessageTemplate.id")
                  div(v-if="editCustomMessage")
                    textarea.form-control.mt-3.mb-3(v-model="customMessage")
                  div(v-else)
                    .alert.bg-info-opacity-25.mt-3(v-html="formatedMessage" :class="messageTypeId == 2 ? 'mb-3' : ''")
                    button.btn.btn-link(v-if="messageTypeId == 2" @click="setEditCustomMessage(true)")
                      font-awesome-icon(icon="edit")

                  .mb-1.pb-1(v-for="(param, index) in selectedMessageTemplate.message_template_params")
                    label.label {{param.label}}
                    input.form-control(v-model="params[index]")
                  .d-grid.gap-2
                    button.btn.btn-primary(@click="sendTemplate()") Enviar plantilla
                  p.mt-3.alert.bg-success-opacity-25(v-if="successMessage") {{successMessage}}
                  p.mt-3.alert.bg-danger-opacity-25(v-if="errorMessage") {{errorMessage}}
              
              template(v-else)
                p.mt-4.alert.bg-danger-opacity-25 La cuenta no tiene aceptada la RGPD, es necesario notificarlo para aceptarla 
                .d-grid.gap-2(v-if="messageTemplates.length > 0")
                  button.btn.btn-primary(@click="sendTemplateRGPD()") Enviar plantilla
                p.mt-3.alert.bg-success-opacity-25(v-if="successMessage") {{successMessage}}
                p.mt-3.alert.bg-danger-opacity-25(v-if="errorMessage") {{errorMessage}}
              .d-grid.gap-2.mt-3
                button.btn.btn-light(@click="cancelSendTemplate()") Cancelar

</template>
<script>
  import ChatRoomV2 from '../../components/messages/ChatRoomV2.vue'
  import ChatRoomList from "../../components/messages/ChatRoomsList.vue"
  import ChatRoomChat from "../../components/messages/ChatRoomChat.vue"
  export default {
    name: 'Chat',
    components: {
      ChatRoomList,
      ChatRoomChat,
      ChatRoomV2
    },
    data () {
      return {
        selectedChatId: 0,
        messageTypeId: 0,
        phoneNumber: '',
        departmentCode: '',
        rgpd: 0,
        messageTempalateId: 0,
        params: [],
        successMessage: '',
        errorMessage: '',
        customMessage: '',
        editCustomMessage: false,
        messageTemplates: [],
        whatsappNumberId: null,
        whatsappNumbers: []
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
      chatRooms() {
        return this.$store.state.chatRoomModule.chatRooms
      },
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedChatRoom
      },
      selectedMessageTemplate() {
        if(this.messageTempalateId) {
          let index = this.messageTemplates.findIndex((mt) => mt.id == this.messageTempalateId)

          if(index > -1) {
            return this.messageTemplates[index]
          }
        }

        return {}
      },
      formatedMessage() {
        let formatedMessage = ''
        if(this.selectedMessageTemplate) {
          formatedMessage = this.selectedMessageTemplate.message

          let i = 1
          this.params.forEach((p) => {
            if(p) {
              formatedMessage = formatedMessage.replace('{{' + i + '}}', '<strong>' + p + '</strong>')
            }
            i++
          })
        }
        return formatedMessage
      }
    },
    watch: {
      messageTempalateId(newVal, oldVal) {
        let params = []
        if(oldVal != newVal) {
          if(newVal != 0) {
            let index = this.messageTemplates.findIndex((mt) => mt.id == newVal)

            if(index > -1) {
              let messageTemplate = this.messageTemplates[index]

              messageTemplate.message_template_params.forEach((param) => {
                if(param.default) {
                  params.push(param.default)
                } else {
                  params.push('')
                }
              })
            }
          }

          this.params = params
        }

      }
    },
    mounted() {
      this.$store.dispatch("getMyWhatsappNumbers")
        .then((res) => {
          this.whatsappNumbers = res

          if(this.whatsappNumbers && this.whatsappNumbers[0]) {
            this.whatsappNumberId = this.whatsappNumbers[0].id
          }
        })
      setTimeout(()=> {
        if(this.$route.params.chatRoomId && this.$route.params.chatRoomId > 0) {
          this.setSelectedChatId(this.$route.params.chatRoomId)
        } else {
          
          if(this.$route.params.messageTypeId) {
            this.messageTypeId = this.$route.params.messageTypeId
          }

          if(this.$route.params.phoneNumber) {
            if(this.$route.params.phoneNumber.substring(0,1) == '+' || this.$route.params.phoneNumber.substring(0,2) == '00') {
              this.phoneNumber = this.$route.params.phoneNumber
            } else {
              if(this.companyConfigs && this.companyConfigs.sms_default_country_code) {
                this.phoneNumber = this.companyConfigs.sms_default_country_code + this.$route.params.phoneNumber
              } else {
                this.phoneNumber = '+34' + this.$route.params.phoneNumber
              }
            }
          }

          if(this.$route.params.departmentCode) {
            this.departmentCode = this.$route.params.departmentCode
          }

          if(this.$route.params.rgpd) {
            this.rgpd = this.$route.params.rgpd
          }

          if(this.messageTypeId > 0) {
            this.$store.dispatch("getUserMessageTemplates", {
              messageTypeId: this.messageTypeId
            }).then((res) => {
              this.messageTemplates = res
            })
          } else {
            this.$store.dispatch("getUserMessageTemplates", {
              messageTypeId: 1
            }).then((res) => {
              this.messageTemplates = res
            })
          }
        }
      }, 3000)
    },
    methods: {
      getWindowBackground(lastAccountMessage) {
        let windowTime = this.$options.filters.diffToNow(lastAccountMessage)
        windowTime = this.$options.filters.timeInHoursShort(windowTime)
        
        let background = 'bg-success'
        if(windowTime == '+24h') {
          background = 'bg-dark';
        } else if(windowTime == '23h') {
          background = 'bg-danger';
        } else if(windowTime == '22h') {
          background = 'bg-warning';
        }

        return background;
      },
      sendToNewContact() {
        this.rgpd = 1
        this.messageTypeId = 1
        this.selectedChatId = 0
        this.phoneNumber = ''
      },
      setEditCustomMessage(value) {
        this.customMessage = this.formatedMessage
        this.editCustomMessage = value
      },
      setSelectedChatId(id) {
        this.selectedChatId = id

        if(this.selectedChatId > 0) {
          let index = this.chatRooms.findIndex((cr) => cr.id == this.selectedChatId)

          if(index > -1) {
            this.$store.commit("setSelectedChatRoom", this.chatRooms[index])
          } else {
            this.$store.commit("setSelectedChatRoom", {})
          }
        } else {
          this.$store.commit("setSelectedChatRoom", {})
        }
      },
      sendTemplate() {
        this.successMessage = ''
        this.errorMessage = ''
        if(this.selectedMessageTemplate.id) {
          this.$store.dispatch("sendMessageTemplate", {
            phoneNumber: this.phoneNumber,
            message_content_type_id: 1,
            message_template_id: this.selectedMessageTemplate.id,
            params: this.params,
            department_code: this.departmentCode,
            message_type_id: this.messageTypeId,
            custom_message: this.customMessage,
            whatsapp_number_id: this.whatsappNumberId
            
          }).then((res) => {
            if(res.error) {
              this.errorMessage = 'No se pudo realizar el envio'
            } else {
              this.successMessage = 'Envio realizado correctamente'
            }

            this.cancelSendTemplate()
            
          })
        }
        
      },
      sendTemplateRGPD() {
        this.successMessage = ''
        this.errorMessage = ''

        let index = this.messageTemplates.findIndex((mt) => (mt.name == 'gpp_rgpd2' || mt.name == 'gdpr2'))

        if(index > -1) {
          let messageTemplate = this.messageTemplates[index]
          let params = []
          messageTemplate.message_template_params.forEach((param) => {
            if(param.default) {
              params.push(param.default)
            } else {
              params.push('')
            }
          })
        
          this.$store.dispatch("sendMessageTemplate", {
            phoneNumber: this.phoneNumber,
            message_content_type_id: 1,
            message_template_id: messageTemplate.id,
            params: params,
            department_code: this.departmentCode,
            message_type_id: 1
            
          }).then((res) => {
            if(res.error) {
              this.errorMessage = 'No se pudo realizar el envio'
            } else {
              this.successMessage = 'Envio realizado correctamente'
            }
          })
        } else {
          this.errorMessage = 'No disponemos de una plantilla para poder enviar la solicitud'
        }

        
      },
      cancelSendTemplate() {
        this.rgpd = 0
        this.messageTypeId = 0
        this.selectedChatId = 0
        this.phoneNumber = ''
        this.messageTempalateId = 0
      }
    }
  }
</script>
<style lang="scss" scoped>
.chat-chat-room-list-content {
  position:fixed;
  width: 25%;
  height: 100%;
  background: #fff;
}
.dark-theme .chat-chat-room-list-content {
  position:fixed;
  width: 25%;
  height: 100%;
  background: #30304d;
}
.chat-chat-room-chat-content-chat {
  position:fixed;
  width: 75%;
  height: 100%;
  left: 25%;
}
.chat-background {
  background: url("/media/chat-background.jpg")
}
.dark-theme .chat-background {
  background: url("/media/chat-background-dark.jpg")
}
</style>