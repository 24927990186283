<template lang="pug">
.chat-room-v2-chat-item
  .row
    div(:class="!message.user ? 'col-10' : 'col-10 offset-2 text-end'")
      .d-flex(:class="!message.user ? '' : 'justify-content-end'")
        .message
          .ms-3
            strong.small(v-if="message.user") {{message.user.name}}
            strong.small(v-else) {{message.account.name}}
            .p-2.rounded(:class="!message.user ? 'bg-success-opacity-20' : 'bg-secondary-opacity-20'")
              template(v-if="messageContentTypeId == 1")
                div(v-html="message.message_content.content")
              template(v-else-if="messageContentTypeId == 2")
                .figure(v-if="messageContentContent.url")
                  a(:href="messageContentContent.url" target="_blank")
                    img(
                      :src="messageContentContent.url" 
                      style="max-width: 200px"
                    )
                    p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
                .figure(v-else)
                  a(:href="messageContentContent" target="_blank")
                    img(
                      :src="messageContentContent" 
                      style="max-width: 200px"
                    )
              template(v-if="messageContentTypeId === 3")
                p.has-text-centered(v-if="messageContentContent.url")
                  a(:href="messageContentContent.url" target="_blank")
                    a(
                      :href="messageContentContent.url" 
                      target="_blank"
                    )
                      font-awesome-icon(
                        icon="cloud-download-alt" 
                        size="4x"
                      )
                      br
                      span {{messageContentContent.url.substring(messageContentContent.url.lastIndexOf('/')).replace('/', '')}}
                    p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
                p.has-text-centered(v-else)
                  a(
                    :href="message.message_content.content" 
                    target="_blank"
                  )
                    font-awesome-icon(
                      icon="cloud-download-alt" 
                      size="4x"
                    )
                    br
                    span {{message.message_content.content.substring(message.message_content.content.lastIndexOf('/')).replace('/', '')}}
              template(v-else-if="messageContentTypeId == 4")
                a(:href="message.message_content.content" target="_blank").mb-0 {{message.message_content.content}}
              template(v-else-if="messageContentTypeId == 5")
                div(v-if="messageContentContent.url")
                  audio(style="max-width: 100%" controls)
                    source(:src="messageContentContent.url")
                  p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
                div(v-else)
                  audio(style="max-width: 100%" controls)
                    source(:src="messageContentContent")
              template(v-else-if="messageContentTypeId == 6")
                div(v-if="messageContentContent.url")
                  video(style="max-width: 100%" controls)
                    source(:src="messageContentContent.url")
                  p.mt-1.mb-0(v-if="messageContentContent.caption") {{messageContentContent.caption}}
                div(v-else)
                  video(style="max-width: 100%" controls)
                    source(:src="messageContentContent")
            font-awesome-icon.text-secondary(v-if="!message.user_id" icon="check")
            font-awesome-icon.me-2.text-secondary(v-if="!message.user_id" icon="check" style="margin-left: -8px;")
            span.small.text-dark
              span(v-if="more24h" :title="message.sended_at") {{message.sended_at | formatDate}}
              span(v-else :title="message.sended_at" :key="'message'+ message.id + counterKey") {{message.sended_at | diffToNow | timeInHoursShort}}
            font-awesome-icon.ms-2(v-if="message.user_id && message.sended_at" icon="check" :class="!message.read_at ? 'text-secondary' : 'text-info'" )
            font-awesome-icon.text-secondary(v-if="message.user_id && message.received_at && !message.read_at" icon="check" style="margin-left: -8px;")
            font-awesome-icon.text-info(v-else-if="message.user_id && message.read_at" icon="check" style="margin-left: -8px;")
  .row.mb-2(v-if="message.message_message_ends" v-for="message_message_end in message.message_message_ends")
    .col-10.offset-1
      .p-2.rounded.bg-primary-opacity-20.text-center
        strong {{message_message_end.message_end.name}}
        br
        small {{message_message_end.user.name}}
</template>
<script>
  export default {
    name: 'ChatRoomV2ChatItem',
    props: {
      reduced: { type: Boolean, default: false },
      message: { type: Object, default: function () { return {} } },
    },
    data () {
      return {
        
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      messageContentTypeId() {
        return this.message.message_content.message_content_type_id
      },
      messageContentContent() {
        if (this.message && this.message.message_content && this.message.message_content.content) {
          if (this.IsJsonString(this.message.message_content.content)) {
            return JSON.parse(this.message.message_content.content)
          } else {
            return this.message.message_content.content
          }
        } else {
          return ''
        }
      },
      more24h() {
        let windowTime = this.$options.filters.diffToNow(this.message.sended_at)
        windowTime = this.$options.filters.timeInHoursShort(windowTime)

        if (windowTime == '+48h') {
          return true
        } else if (windowTime == '+24h') {
          return true
        } else {
          return false
        }
      }
      
    },
    mounted() {
      
    },
    methods: {
      IsJsonString(str) {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>