<template lang="pug">
.task-item
  .p-2.mb-2.mt-2.rounded(:class="getTaskClass(task)")
    .task-body(:style="'border-left: 5px solid' + task.task_priority.color + ';'")
      font-awesome-icon.text-danger.blink.ps-2.float-end(v-if="!viewed" icon="eye" size="lg")
      h1.task-title(@click.stop="showTask(task.id)")
        .d-block {{task.task_description.name}}
        small.text-primary.me-2(v-if="task.task_group_id") {{task.task_group.name}}
        small.text-primary(v-if="task.account_id") {{task.account.name}} 
      .d-flex.justify-content-between
        .d-flex
          task-status.me-2(:task="dataTask")
          task-window.me-2(v-if="task.task_type_id == 2 && !task.finished" :task="dataTask")
          task-ends(:task="dataTask")
        task-code(:task="dataTask" :reduced="reduced")
      .task-actions
        .d-flex.justify-content-between
          .d-flex.justify-content-start
            task-type(:task="dataTask" :reduced="reduced")
            task-dates(:task="dataTask" :reduced="reduced")
            task-users(:task="dataTask")
            task-timer(:task="dataTask")
          .d-flex.justify-content-end
            h5.title-card.text-secondary.me-2
              font-awesome-icon.me-1(icon="calendar")
              span {{task.created_at | formatDate}} 
            h5.title-card.text-secondary.me-2(v-if="task.task_steps.length > 0")
              font-awesome-icon.me-1(icon="flag")
              span {{task.task_steps_finished.length}} / {{task.task_steps.length}} 
              span(v-if="!reduced") Etapas
            h5.title-card.text-secondary.me-2 
              font-awesome-icon.me-1(icon="comments")
              span.me-1 {{task.task_comments.length}} 
              span(v-if="!reduced") comentarios
            h5.title-card.text-secondary 
              font-awesome-icon.me-1(icon="paperclip")
              span.me-1 {{task.task_files.length}}
              span(v-if="!reduced") adjuntos
      .task-last-communication.small.text-secondary.mt-2.bg-secondary-opactity-50
        .d-flex.justify-content-between
          template(v-if="task.last_communication")
            font-awesome-icon.me-2.text-primary(v-if="task.last_communication.type == 'email'" icon="envelope")
            font-awesome-icon.me-2.text-primary(v-else-if="task.last_communication.type == 'call'" icon="phone")
            font-awesome-icon.me-2.text-primary(v-else-if="task.last_communication.type == 'whatsapp'" :icon="['fab','whatsapp']")
            span {{task.last_communication.date | formatFullTime}}
          template(v-else) 
            span Sin comunicación
          template(v-if="emailMessageId")
            a(@click.stop="assignEmailMessage()") asignar
</template>

<script>
  import {Modal} from 'bootstrap'
  import TaskTimer from './TaskTimer.vue'
  import TaskType from './TaskType.vue'
  import TaskPriority from './TaskPriority.vue'
  import TaskStatus from './TaskStatus.vue'
  import TaskUsers from './TaskUsers.vue'
  import TaskDates from './TaskDates.vue'
  import TaskWindow from './TaskWindow.vue'
  import TaskEnds from './TaskEnds.vue'
import TaskCode from './TaskCode.vue'
  export default {
    name: 'TaskListItem',
    components: {
      TaskTimer,
      TaskType,
      TaskPriority,
      TaskStatus,
      TaskUsers,
      TaskDates,
      TaskWindow,
      TaskEnds,
      TaskCode
    },
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, default: false},
      openModal: {type: Boolean, default: true},
      emailMessageId: {type: Number, default: null}
    },
    data () {
      return {
        currentDuration: 0,
        currentDurationInterval: null,
      }
    },
    computed: {
      dataTask() {
        let dataTask = this.task

        dataTask.name = this.task.task_description.name
        dataTask.description = this.task.task_description.description

        return dataTask
      },
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      startedTasks() {
        if(this.$store.state.taskModule.startedTasks) {
          return this.$store.state.taskModule.startedTasks
        } else {
          return []
        }
      },
      currentStaredTask() {
        let index = this.startedTasks.findIndex(x => x.id == this.task.id)

        if(index > -1) {
          return this.startedTasks[index]
        } else {
          return {}
        }
      },
      totalDuration() {
        return this.task.duration + this.currentDuration
      },
      lastSeen() {
        let lastSeen = null

        let index = this.task.users.findIndex(u => u.id == this.currentUser.id)

        if(index > -1) {
          lastSeen = this.task.users[0].pivot.last_seen
        }

        return lastSeen
      },
      viewed() {
        let viewed = false
        if(this.lastSeen && this.$moment(this.$moment(this.task.updated_at)).isSameOrBefore(this.lastSeen)) {
          viewed = true
        }

        return viewed
      }

    },
    methods: {
      showTask() {
        this.$store.commit("setTask", this.task)
        if (this.openModal) {
          this.$store.commit("setModalTaskId", this.task.id)
          let taskModal = document.getElementById('taskModal')
          if (taskModal.style.display != 'block') {
            new Modal(taskModal).show()
          }
        } else {
          this.$emit("selectTask", {task: this.task})
        }
      },
      getTaskClass() {
        let index = this.task.users.findIndex((user) => user.id == this.currentUser.id)
        let taskClass = 'bg-secondary-opacity-20'

        if(index == -1) {
          taskClass = 'bg-secondary-opacity-50 opacity-50'
        }

        return taskClass
      },
      toggleTodayTask() {
        let today = 1;
        if(this.task.today) {
          today = 0;
        }

        this.$store.dispatch("setTodayTask", {
          taskId: this.task.id,
          today: today
        })
      },
      startTask() {
        this.$store.dispatch("startTask", {
          taskId: this.task.id
        }).then(() => {
          this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
        })
      },
      endTask() {
        this.$store.dispatch("endTask", {
          taskId: this.task.id
        }).then(() => {
          clearInterval(this.currentDurationInterval)
          this.currentDuration = 0
        })
      },
      assignEmailMessage() {
        this.$store.dispatch("assignTaskEmailMessage", {
          taskId: this.task.id,
          emailMessageId: this.emailMessageId
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";
.card.card-custom-v2 .card-header {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card.card-custom-v2 .card-footer {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.task-body {
  padding-left: 10px;
}
h1.task-title {
  font-size: 1em;
  display: block;
  margin-bottom: 0px;
  font-weight: 400;
}
h1.task-title small {
  display:block;
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}
.task-body {
  padding-top: 5px;
  padding-bottom: 5px;
}
.task-actions {
  padding-top: 7px;
}
.task-actions h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
</style>