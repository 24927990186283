<template lang="pug">
.task-group
  span.text-primary(v-if="task.task_group_id" @click.stop="tooglePopup") {{task.task_group.name}}
  span.text-secondary(v-else @click.stop="tooglePopup") Sin lista
  .backgroundPopup(v-if="showForm" @click="closePopup")
  .popup-form(v-if="showForm")
    .card.card-custom-v2.shadow-lg
      .card-header.pt-1.pb-1 
        .d-flex.justify-content-between.align-items-center 
          span.text-primary.text-uppercase Lista de tareas 
          font-awesome-icon.text-secondary(icon="times" @click="closePopup")
      .card-body.p-2
        v-select(:options="taskGroups" :reduce="taskGroup => taskGroup.id"  label="name" placeholder="Todas" v-model="taskGroupId")
        
</template>

<script>
  export default {
    name: 'TaskUsers',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, default: true},
    },
    data () {
      return {
        taskGroupId: null,
        showForm: false,
        accountOptions: []
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },    
      taskGroups() {
        return this.$store.state.taskGroupModule.taskGroups
      }

    },
    beforeMount() {
      
    },
    watch: {
      taskGroupId (newVal) {
        if(newVal) {
          this.update(newVal)
        }
      }
    },
    methods: {
      tooglePopup() {
        this.showForm = !this.showForm
      },
      closePopup() {
        this.showForm = false
      },
      accountsAjaxSearch(search, loading) {
        let filter = {
              filter: search,
              moduleUrl: 'accounts',
              columns: 'id,name',
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.accountOptions = res
              loading(false)
          })
      },
      update(taskGroupId) {
        let task = this.task
        this.$set(task, 'task_group_id', taskGroupId)

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'tasks',
          id: this.task.id,
          data: task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.closePopup()
            this.$store.commit("updateTasks", res)
            this.$store.commit("updateUnfinishedTasks", res)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

small {
  display:block;
  font-weight: 300;
  font-size: 0.6em;
  text-transform: uppercase;
}
.backgroundPopup {
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.card.card-custom-v2 .card-header {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card.card-custom-v2 .card-footer {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.card.card-custom-v2 .card-header h5.title-card, .card.card-custom-v2 .card-footer h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.card.card-custom-v2 .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.card.card-custom-v2 .card-body h1.task-title {
  border-left: 3px solid $primary;
  padding-left: 10px;
  font-size: 1em;
  display: block;
  margin-bottom: 0px;
}
.card.card-custom-v2 .card-body h1.task-title small {
  display:block;
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}
.task-group {
  //max-width: 160px;
  position: relative;
}
h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.popup-form {
  position:absolute;
  top: 20px;
  z-index: 200;
  width: 160px;
  font-size: 0.85em;
}
.popup-form > .card {
  border: 1px solid $light;
}
</style>