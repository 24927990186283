<template lang="pug">
.gc-email-message
</template>
<script>
  export default {
    name: 'GcMessages',
    components: {
      
    },
    data () {
      return {
        filter: '',
        extension: '',
        selectedChatId: 0,
        displayNotification: false
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      emailMessages() {
        return this.$store.state.emailMessageModule.emailMessages
      },
      currentEmailMessage() {
        return this.$store.state.emailMessageModule.currentEmailMessage
      },
      
    },
    watch: {
      
    },
    mounted() {
      /*this.$store.dispatch("getAllEmailMessages")

      window.Echo.channel('App.Company.' + this.currentUser.company_id)
        .listen('NewEmailMessage', (e) => {
            this.processNewMessage(e.email_message)
        })
        .listen('UpdateEmailMessage', (e) => {
            this.processUpdateMessage(e.email_message)
        })*/
        
    },
    methods: {
      processNewMessage(emailMessage) {
        this.$store.commit("saveEmailMessage", emailMessage)

        let options = {
          body: emailMessage.email_message_body.subject,
          icon: "/media/whatsapp.png"
        }

        let n = new Notification("Nuevo Email", options);
        n.onclick = ((event) => {
          this.displayNotification = false
          event.preventDefault();
          window.focus()
          n.close()
        })
        n.onclose = (() => {
          this.displayNotification = false
        })
      },
      processUpdateMessage(emailMessage) {
        this.$store.commit("saveEmailMessage", emailMessage)

        if (this.currentEmailMessage && this.currentEmailMessage.id == emailMessage.id) {
          this.$store.commit("setCurrentEmailMessage", emailMessage)
        }
      },
    }
  }
</script>
<style lang="scss" scoped>

</style>