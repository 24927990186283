<template lang="pug">
  .screen-sumary-users
    .card.mb-3
      .card-body
        table.table.table-striped
          thead
            tr
              th {{$t('Usuario')}}
              th.text-primary {{$t('In total Success')}}
              th.text-primary {{$t('In duration')}}
              th.text-success {{$t('Out total Success')}}
              th.text-success {{$t('Out duration')}}
          tbody
            tr(v-for="user in sortedUsers")
              td {{user.name}}
              td.text-center.text-primary {{user.report_user.total_in_calls}}
              td.text-center.text-primary {{user.report_user.duration_in_calls | timeInHoursMin  }}
              td.text-center.text-success {{user.report_user.total_out_calls}}
              td.text-center.text-success {{user.report_user.duration_out_calls | timeInHoursMin}}

</template>
<script>
  import UserStatus from '../users/UserStatus.vue'
  export default {
    name: 'ScreenSumaryUsers',
    components: {
      UserStatus
    },
    data () {
      return {
        showUsers: true,
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      reportUsers() {
        return this.$store.state.userModule.reportUsers
      },
      users() {
        if(this.currentUser.department_id) {
          return this.$store.state.userModule.users.filter((u) => {
            return (u.department_id && u.department_id == this.currentUser.department_id);
          })
  
        } else {
          return this.$store.state.userModule.users
        }
      },
      fullUsers() {
        let fullUsers = []
        this.users.forEach((u) => {
          let index = this.reportUsers.findIndex((ru) => ru.user_id == u.id)

          if(index > -1) {
            this.$set(u, 'report_user', this.reportUsers[index])
          } else {
            this.$set(u, 'report_user', {
              user_id: this.user.id,
              total_in_calls: 0,
              total_out_calls: 0,
              total_internal_calls: 0,
              total_lost_calls: 0,
              duration_in_calls: 0,
              duration_out_calls: 0,
              duration_internal_calls: 0,

            })
          }

          fullUsers.push(u)
        })

        return fullUsers
      },
      sortedUsers () {
        let users = this.fullUsers
        return users.sort((a, b) => {
            
            if (a.name < b.name) {
                return -1
            } else if (a.name > b.name) {
                return 1
            }
            
            return 0
        })
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      userCalls() {
        let userCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.users.findIndex((u) => u.extension && u.extension.number == connected)

            if(index > -1) {
              if(!userCalls[this.users[index].id]) {
                userCalls[this.users[index].id] = {}
                this.$set(userCalls[this.users[index].id], 'calls', [])
              }

              userCalls[this.users[index].id].calls.push(call)
            }
          })
        })

        return userCalls
      },
      totalUsers() {
        return this.users.length
      },
      totalUsersOnline() {
        let i = 0
        this.users.forEach((u) => {
          if(u.active_user_session && !u.active_rest_time && !this.userCalls[u.id]) {
            i = i + 1
          }
        })

        return i
      },
      totalUsersInCall() {
        let i = 0
        this.users.forEach((u) => {
          if(this.userCalls[u.id]) {
            i = i + 1
          }
        })
    
        return i
      },
      totalUsersInRestTime() {
        let i = 0
        this.users.forEach((u) => {
          if(u.active_rest_time) {
            i = i + 1
          }
        })

        return i
      },
      totalUsersOffline() {
        return this.totalUsers - this.totalUsersOnline - this.totalUsersInCall - this.totalUsersInRestTime
      }
    },
    mounted() {
      this.$store.dispatch("getRegisters", {moduleUrl: "users"})
        .then((users) => {
          this.$store.commit("setUsers", users)
        })
        
    },
    methods: {
      
    }
  }
</script>
<style lang="scss">
</style>