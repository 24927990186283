<template lang="pug">
.report-task-history
  .card.mb-3
    .card-body
      h5.card-title {{$t(reportReportItem.name)}}
      //template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
        chart-donut(:series="series" :labels="labels" :colors="colors")
      table.table
        thead
          tr
            th {{$t('Tipo')}}
            th {{$t('Estado')}}
            th {{$t('Grupo')}}
            th {{$t('Final')}}
            th {{$t('Cuenta')}}
            th {{$t('Descripción')}}
            th {{$t('Fecha Apertura')}}
            th {{$t('Fecha Cierre')}}
            th.text-center {{$t('Tiempo de cierre')}}
            th.text-center {{$t('Llamadas')}}
            th.text-center {{$t('Mensajes')}}
            th.text-center
        tbody
          tr(v-for="row in data.dataTable")
            td {{row.task_type.name}}
            td
              span(v-if="row.task_status_id") {{row.task_status.name}}
              span(v-else) -
            td 
              span(v-if="row.task_group_id") {{row.task_group.name}}
              span(v-else) -
            td 
              span(v-if="row.task_end_id") {{row.task_end.name}}
              span(v-else) -
            td 
              span(v-if="row.account_id") {{row.account.name}}
              span(v-else) -
            td {{row.task_description.name}}
            td {{row.created_at | formatDate}}
            td 
              span(v-if="row.finished_at") {{row.finished_at | formatDate}}
              span(v-else) -
            td.text-center 
              span(v-if="row.opened_duration") {{row.opened_duration | timeInHoursShort}}
              span(v-else) -
            td.text-center {{row.calls.length}}
            td.text-center {{row.messages.length}}
            td.text-center
              a.text-dark(@click="showTask(row.id)")
                font-awesome-icon(icon="eye")
</template>
<script>
  import {Modal} from 'bootstrap'
  export default {
    name: 'ReportTaskHistory',
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        colors: ["#6259ca", "#09ad95", "#f7b731"],
        lastFilter: 0,
        showChart: true
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getTextColor(value) {
        if (value > 0) {
          return 'text-danger'
        } else if (value < 0) {
          return 'text-success'
        }

        return 'text-dark'
      },
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      },
      showTask(taskId) {
        this.$store.commit("setModalTaskId", taskId)
        let taskModal = document.getElementById('taskModal')
        if(taskModal.style.display != 'block') {
          new Modal(taskModal).show() 
        }
      }
    }
  }
</script>
<style lang="scss">
</style>