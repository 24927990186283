<template lang="pug">
.task-archived
  h5.title-card.text-secondary.me-2(:class="taskArchived == 1 ? 'text-danger' : 'text-secondary'" @click="toggleTaskArchived") 
    font-awesome-icon(icon="archive")
    template(v-if="!reduced")
    span.ms-1(v-if="taskArchived == 1") Archivada
    span.ms-1(v-else) Archivar
</template>

<script>
  export default {
    name: 'TaskArchived',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, deafult: false}
    },
    data () {
      return {
        taskArchived: 0
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      
    },
    beforeMount() {
      this.taskArchived = this.task.archived
    },
    methods: {
      toggleTaskArchived() {
        let title = 'Está seguro que desea reactivar esta tarea?'
        if(this.taskArchived) {
          title = 'Está seguro que desea archivar esta tarea?'
        }
        this.$swal.fire({
          title: title,
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result.isConfirmed) {
            if(this.taskArchived == 1) {
              this.taskArchived = 0
            } else {
              this.taskArchived = 1
            }
            
            let task = this.task
            this.$set(task, 'archived', this.taskArchived)

            this.$store.dispatch("updateRegister", {
              moduleUrl: 'tasks',
              id: this.task.id,
              data: task
            }).then((res) => {
              if(res.errors) {
                this.errors = res.errors
              }
            })
          }
        });
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.task-archive {
  cursor: pointer;
}
h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
</style>