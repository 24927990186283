<template lang="pug">
.user-calls-min
  .card
    .card-body
      .row
        .col-6
          h5.card-title Listado de llamadas
        .col-6
          p.text-end 
            span {{$t('Mostrando')}}&nbsp;
            strong {{limitStart}} - {{limitEnd}}
            span &nbsp;{{$t('de')}}&nbsp; 
            strong {{totalElements}}
      .row.pb-1.mb-0
        .col-4
          .row
            .col
              input.form-control.form-control-sm.mb-1(type="date" v-model="start")
          .row
            .col
              input.form-control.form-control-sm(type="date" v-model="end")
        .col-5
          .row
            .col
              input.form-control.form-control-sm.mb-1(type="text" v-model="from" placeholder="Número de origen")
          .row
            .col
              input.form-control.form-control-sm(type="text" v-model="to" placeholder="Número de destino")
        .col-2
          select.form-select.border-secondary.form-control-sm(aria-label='Default select example' v-model="page" @change="search()")
            option(v-for='i in totalPages' :key="'page' + i" :value='i') {{i}}
    .pt-0.ps-3.pe-3
      .limit-scroll-50vh
        call-list-item-min(v-for="call in registers" :call="call" :key="'callListItem' + call.id")
        //.card-gc.mb-2(v-for="call in registers")
          .card-gc-body(:class="getBorderColorStatus(call.call_status_id)" @click.stop="setModalCall(call)")
            .mb-0.pb-0 
              span.me-2.icon.text-primary(v-if="call.call_type_id == 1")
                font-awesome-icon(icon="arrow-right")
              span.me-2.icon.text-success(v-else-if="call.call_type_id == 2")
                font-awesome-icon(icon="arrow-left")
              span.me-2.icon.text-warning(v-else)
                font-awesome-icon(icon="redo")
              span {{[call.start, "unix"] | unixToDateTime}}
            .d-flex.justify-content-between.mb-1
              div
                span.me-2 {{call.from}} - {{call.to}}
              div
                span.me-2 W: {{call.duration_wait | timeInHoursMin}}
                span D: {{call.duration | timeInHoursMin}}
            .d-flex.justify-content-between.mb-1
              div
                strong.badge.me-2(:class="getBackgroundStatus(call.call_status_id)") {{call.call_status.name}}
                span.badge.bg-info(v-if="call.call_end_id") {{call.call_end.name}}
                span.badge.bg-secondary(v-else) Sin final
              div
                span.ms-1(v-for="user in call.answered_users") {{user.name}}
    
</template>
<script>
  import {Modal} from 'bootstrap'
  import CallListItemMin from './CallListItemMin.vue'
  export default {
    name: 'UserCallsMin',
    components: {
      CallListItemMin
    },
    props: { 
      accountId: Number
    },
    data () {
      return {
        start: null,
        end: null,
        from: '',
        to: '',
        page: 1,
        totalPages: 1,
        limit: 10,
        limitStart: 0,
        limitEnd: 0,
        totalElements: 0,
        sortColumn: 'start',
        sortDirection: 'DESC',
        registers: [],
        pointerFilter: null
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
    },
    watch: {
      start() {
        if(this.pointerFilter) {
            clearTimeout(this.pointerFilter)
          }
          this.pointerFilter = setTimeout(() => this.search(), 500)
      },
      end() {
        if(this.pointerFilter) {
            clearTimeout(this.pointerFilter)
          }
          this.pointerFilter = setTimeout(() => this.search(), 500)
      },
      from() {
        if(this.pointerFilter) {
            clearTimeout(this.pointerFilter)
          }
          this.pointerFilter = setTimeout(() => this.search(), 500)
      },
      to() {
        if(this.pointerFilter) {
            clearTimeout(this.pointerFilter)
          }
          this.pointerFilter = setTimeout(() => this.search(), 500)
      },
    },
    mounted() {
      this.search()
    },
    methods: {
      setModalCall(call) {
        this.$store.commit("setModalCall", call)
        let modalCall = document.getElementById('callModal')
        if(modalCall.style.display != 'block') {
          new Modal(modalCall).show() 
        }
      },
      getBackgroundStatus(callStatusId) {
        let callStatusClass = 'bg-primary'

        switch(callStatusId) {
          case 2:
            callStatusClass = 'bg-success'
            break
          case 3:
            callStatusClass = 'bg-warning'
            break
          case 4:
            callStatusClass = 'bg-danger'
            break
        }

        return callStatusClass
      },
      getBorderColorStatus(callStatusId) {
        let callStatusClass = 'border-primary'

        switch(callStatusId) {
          case 2:
            callStatusClass = 'border-success'
            break
          case 3:
            callStatusClass = 'border-warning'
            break
          case 4:
            callStatusClass = 'border-danger'
            break
        }

        return callStatusClass
      },
      search() {
        this.$store.dispatch("searchCalls", {
          page: this.page,
          data: {
            advancedFilter: {
              users_id: [this.currentUser.id],
              from: this.from,
              to: this.to,
              start: {
                start: this.start,
                end: this.end
              },

            },
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            limit: this.limit,
          }
        }).then((res) => {
          this.registers = res.data
          this.totalPages = res.total_pages
          this.limit = res.limit
          this.limitStart = res.limit_start + 1
          this.limitEnd = res.limit_start + res.limit
          this.totalElements = res.total
          if(this.limitEnd > this.totalElements) {
            this.limitEnd = this.totalElements
          }
        })
      }
    }
  }
</script>
<style lang="scss">
  
</style>