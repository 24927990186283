<template lang="pug">
.report-total-task-by-account
  .card.mb-3
    .card-body
      h5.card-title {{$t(reportReportItem.name)}}
      //template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
        chart-donut(:series="series" :labels="labels" :colors="colors")
      table.table
        thead
          tr
            th {{$t('Cuenta')}}
            th.text-center {{$t('Abiertos')}}
            th.text-center {{$t('Finalizados')}}
            th.bg-secondary-opacity-50.text-center {{$t('ASP')}}
            th.bg-secondary-opacity-50.text-center {{$t('FSP')}}
            th.bg-secondary-opacity-25.text-center {{$t('AMP')}}
            th.bg-secondary-opacity-25.text-center {{$t('FMP')}}
        tbody
          tr(v-for="row in data.dataTable")
            td {{row.name}}
            td.text-center {{row.created}}
            td.text-center {{row.finished}}
            td.bg-secondary-opacity-50.text-center(:class="getTextColor(row.last_week_created)") {{row.last_week_created}}%
            td.bg-secondary-opacity-50.text-center(:class="getTextColor(row.last_week_finished)") {{row.last_week_finished}}%
            td.bg-secondary-opacity-25.text-center(:class="getTextColor(row.last_month_created)") {{row.last_month_created}}%
            td.bg-secondary-opacity-25.text-center(:class="getTextColor(row.last_month_finished)") {{row.last_month_finished}}%
        tfoot
          tr
            th Total
            th {{ totalCreated }}
            th {{ totalFinished }}
            th
            th
            th
            th
</template>
<script>
  import ChartDonut from '../chart/ChartDonut.vue'
  export default {
    name: 'ReportTotalTaskByAccount',
    components: {
      ChartDonut
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        colors: ["#6259ca", "#09ad95", "#f7b731"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      totalCreated() {
        let total = 0

        this.data.dataTable.forEach((row) => {
          total = total + row.created
        })

        return total
      },
      totalFinished() {
        let total = 0

        this.data.dataTable.forEach((row) => {
          total = total + row.finished
        })

        return total
      }
      /*series() {
        if(this.data.dataChart && this.data.dataChart.series) {
          let series = []
          this.data.dataChart.series.forEach((s) => {
            series.push(parseInt(s))
          })
          return series
        } else {
          return []
        }
      },
      labels() {
        if(this.data.dataChart && this.data.dataChart.labels) {
          return this.data.dataChart.labels
        } else {
          return []
        }
      },*/
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getTextColor(value) {
        if (value > 0) {
          return 'text-danger'
        } else if (value < 0) {
          return 'text-success'
        }

        return 'text-dark'
      },
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>