// import Vue from 'vue'
import pbxCalendarGroupsService from '../services/api/pbx_calendar_groups'

const pbxCalendarGroupModule = {
    state: {
        pbxCalendarGroup: {},
    },
    mutations: {
        setPbxCalendarGroup (state, pbxCalendarGroup) {
            state.pbxCalendarGroup = pbxCalendarGroup
        }
    },
    actions: {
        pbxCalendarGroupGetAll () {
            return new Promise((resolve, reject) => {
                pbxCalendarGroupsService.pbxCalendarGroupGetAll()
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        pbxCalendarGroupGetStructure (context, payload) {
            return new Promise((resolve, reject) => {
                pbxCalendarGroupsService.pbxCalendarGroupGetStructure(payload.pbxCalendarGroupId)
                    .then((res) => {
                        context.commit("setPbxCalendarGroup", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default pbxCalendarGroupModule