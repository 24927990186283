<template lang="pug">
.task-users
  h5.title-card.text-secondary.me-3(@click="tooglePopup")
    font-awesome-icon.me-1(icon="users")
    template(v-if="task.users.length == 1")
      span(v-if="reduced") {{task.users[0].name | string2Initials}}
      span(v-else) {{task.users[0].name}}
    template(v-else)
      span.me-1(v-for="user in task.users" :title="user.name") {{user.name | string2Initials}}
  .backgroundPopup(v-if="showForm" @click="closePopup")
  .popup-form(v-if="showForm")
    .card.card-custom-v2.shadow-lg
      .card-header.pt-1.pb-1 
        .d-flex.justify-content-between.align-items-center 
          span.text-primary.text-uppercase Usuarios 
          font-awesome-icon.text-secondary(icon="times" @click="closePopup")
      .card-body.ps-0.pe-0.pt-1.pb-1
        ul.list-group.list-group-flush
          li.list-group-item(v-for="user in task.users" :key="'task' + task.id + 'user' + user.id") 
            .d-flex.justify-content-between 
              span {{user.name}}
              a.text-dark(@click="removeUser(user.id)") 
                font-awesome-icon(icon="trash")
      .card-footer
        v-select(:options="users" :reduce="user => user.id"  label="name" v-model="userId" placeholder="Seleccione un usuario" )
</template>

<script>
  export default {
    name: 'TaskUsers',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, default: true},
    },
    data () {
      return {
        userId: null,
        showForm: false,

      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      users() {
        return this.$store.state.userModule.users
      },
      
    },
    beforeMount() {
      
    },
    watch: {
      userId (newVal) {
        if(newVal) {
          let usersId = []
          let existUser = false
          this.task.users.forEach((u) => {
            usersId.push(u.id)

            if(u.id == newVal) {
              existUser = true
            }
          })

          if(!existUser) {
            usersId.push(newVal)

            this.update(usersId)
          }

        }
      }
    },
    methods: {
      tooglePopup() {
        this.showForm = !this.showForm
      },
      closePopup() {
        this.showForm = false
      },
      removeUser(userId) {
        let usersId = []
        this.task.users.forEach((u) => {
          if(u.id != userId) {
            usersId.push(u.id)
          }
        })

        this.update(usersId)
      },
      update(usersId) {
        let task = this.task
        this.$set(task, 'users_id', usersId)

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'tasks',
          id: this.task.id,
          data: task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.userId = null
            this.$store.commit("updateTasks", res)
            this.$store.commit("updateUnfinishedTasks", res)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.backgroundPopup {
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.card.card-custom-v2 .card-header {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card.card-custom-v2 .card-footer {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.card.card-custom-v2 .card-header h5.title-card, .card.card-custom-v2 .card-footer h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.card.card-custom-v2 .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.card.card-custom-v2 .card-body h1.task-title {
  border-left: 3px solid $primary;
  padding-left: 10px;
  font-size: 1em;
  display: block;
  margin-bottom: 0px;
}
.card.card-custom-v2 .card-body h1.task-title small {
  display:block;
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}
.task-users {
  max-width: 160px;
  position: relative;
}
h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.popup-form {
  position:absolute;
  top: 20px;
  z-index: 200;
  width: 160px;
  font-size: 0.85em;
}
.popup-form > .card {
  border: 1px solid $light;
}
</style>