<template lang="pug">
.chat-room-chat
  .d-flex.justify-content-between.border-bottom.p-2.bg-white
    div
      v-select.flex-grow-1(:options="messageEnds" :reduce="messageEnd => messageEnd.id"  label="name" v-model="messageEndId" placeholder="Seleccione un final para el chat" style="min-width: 250px")
    div
      button.btn.btn-link(@click="prev()" :title="$t('Salir')")
        font-awesome-icon(icon="arrow-left")
      button.btn.btn-link(v-if="selectedChatRoom.is_active && (!selectedChatRoom.owner_user_id || currentUser.id != selectedChatRoom.owner_user_id)" @click="capture()" :title="$t('Capturar')")
        font-awesome-icon(icon="handshake") 
      button.btn.btn-link(v-else-if="selectedChatRoom.is_active" @click="release" :title="$t('Liberar')")
        font-awesome-icon(icon="handshake-slash")
      button.btn.btn-link(v-if="selectedChatRoom.is_active" @click="setTransfer" :title="$t('Transferir')")
        font-awesome-icon(:icon="['fas', 'exchange-alt']")
      button.btn.btn-link(v-if="selectedChatRoom.is_active" @click="setAddOrMergeAccount(true)" :title="$t('Añadir o modificar cuenta')")
        font-awesome-icon(:icon="['fas', 'user-plus']")
      button.btn.btn-link(v-if="selectedChatRoom.is_active" @click="setShowTask(true)" :title="$t('Ver tareas')")
        font-awesome-icon(:icon="['fa', 'tasks']")
      button.btn.btn-link( @click="close" :title="$t('Cerrar')")
        font-awesome-icon(icon="archive")
  div
    .position-relative(v-if="!sendMessageTemplate && !sendFile && !showTransfer && !addOrMergeAccount && !showTask")
      .chat.p-3
        div(v-for="message in messages" :key="'messageItem' + message.id")
          chat-room-message-item(:message="message")
          
      .chat-form.bg-light.border-top.pt-2(v-if="selectedChatRoom.is_active")
        .input-group
          button.btn.btn-primary(@click="openMessageTemplate()")
            font-awesome-icon(:icon="['fas', 'comment-alt']")
          textarea.form-control.flex-grow-1(rows="2" v-model="text" @keyup.enter.stop="sendText" @paste="onPaste" placeholder="Escriba el mensaje y pulse enter para enviar" :readonly="!openedWindow")
          button.btn.btn-info(@click="setSendFile(true)" :disabled="!openedWindow")
            font-awesome-icon(icon="paperclip")
    .row(v-else-if="sendMessageTemplate")
      .col-lg-4.offset-lg-4.mt-3
        .card
          .card-body
            label {{ $t('Seleccione una plantilla') }}
            v-select(:options="messageTemplates" :reduce="messageTemplate => messageTemplate.id"  label="label" v-model="messageTempalateId")

            template(v-if="selectedMessageTemplate.id")
              .alert.bg-info-opacity-25.mt-3.mb-3(v-html="formatedMessage")

              .mb-1.pb-1(v-for="(param, index) in selectedMessageTemplate.message_template_params")
                label.label {{param.label}}
                input.form-control(v-model="params[index]")
              .d-grid.gap-2
                button.btn.btn-primary(@click="sendTemplate()") {{$t('Enviar plantilla')}}
    .row(v-else-if="showTransfer")
      .col-lg-4.offset-lg-4.mt-3
        .card
          .card-body
            label {{$t('Seleccione un usuario')}}
            v-select(:options="users" :reduce="user => user.id"  label="name" v-model="transferUserId")

            template(v-if="transferUserId")
              .d-grid.gap-2.mt-3
                button.btn.btn-primary.mb-3(@click="transferChatRoom()") {{$t('Transferir Chat')}}
    .row(v-else-if="sendFile")
      .col-lg-6.offset-lg-3.mt-3
        .card
          .card-body
            label {{$t('Introduzca el fichero a enviar')}}
            input.form-control(type="file" @change="handleFileUpload")

            .mt-3(v-if="path")
              .picture.mt-2.text-center(v-if="isPicture")
                img(:src="path" style="max-width:100%;max-height:200px;")
              textarea.form-control.mt-2(v-model="caption" placeholder="Introduzca un texto para enviar con la foto")
              
              .d-grid.gap-2.mt-3
                button.btn.btn-primary.mb-3(@click="sendMessageFile()") {{$t('Enviar Fichero')}}
    .row(v-else-if="addOrMergeAccount")
      .col-lg-8.offset-lg-2
        .card.mt-3
          .card-body
            add-and-replace-account(
              :accountIdToMerge="selectedChatRoom.account_id" 
              :accountNameToMerge="selectedChatRoom.account.name"
              @mergeAccount="mergeAccount")
    .row(v-else-if="showTask")
      .col-lg-12
        .card.mt-3
          .card-body
            chat-modal-tasks(:accountId="selectedChatRoom.account_id" :chatRoomId="selectedChatRoom.id")

</template>
<script>
  import ChatRoomMessageItem from './ChatRoomMessageItem.vue'
  import AddAndReplaceAccount from '../accounts/AddAndReplaceAccount.vue'
  import ChatModalTasks from '../accounts/ChatModalTasks.vue'
  import filesService from '../../services/api/files'
  export default {
    name: 'ChatRoomChat',
    components: {
      ChatRoomMessageItem,
      AddAndReplaceAccount,
      ChatModalTasks
    },
    data () {
      return {
        selectedChatId: 0,
        text: '',
        sendMessageTemplate: false,
        sendFile: false,
        messageTempalateId: 0,
        params: [],
        path: '',
        fileName: '',
        messageEndId: null,
        showTransfer: false,
        transferUserId: 0,
        caption: '',
        isPicture: false,
        addOrMergeAccount: false,
        showTask: false
      }
    },
    computed: {
      openedWindow() {
        if(this.selectedChatRoom.last_account_message) {
          let windowTime = this.$options.filters.diffToNow(this.selectedChatRoom.last_account_message)
          windowTime = this.$options.filters.timeInHoursShort(windowTime)

          if(windowTime == '+24h') {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      },
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      users() {
        return this.$store.state.userModule.users
      },
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedChatRoom
      },
      messageEnds() {
        return this.$store.state.messageEndModule.myMessageEnds
      },
      messages() {
        return this.$store.state.chatRoomModule.messages.slice().reverse()
      },
      messageTemplates() {
        return this.$store.state.messageTemplateModule.messageTemplates
      },
      selectedMessageTemplate() {
        if(this.messageTempalateId) {
          let index = this.messageTemplates.findIndex((mt) => mt.id == this.messageTempalateId)

          if(index > -1) {
            return this.messageTemplates[index]
          }
        }

        return {}
      },
      formatedMessage() {
        let formatedMessage = ''
        if(this.selectedMessageTemplate) {
          formatedMessage = this.selectedMessageTemplate.message

          let i = 1
          this.params.forEach((p) => {
            if(p) {
              formatedMessage = formatedMessage.replace('{{' + i + '}}', '<strong>' + p + '</strong>')
            }
            i++
          })
        }
        return formatedMessage
      }
    },
    watch: {
      selectedChatRoom: {
        deep: true,
        handler(newVal, oldVal) {
          if(newVal) {
            this.messageEndId = newVal.message_end_id
          }
          if(!oldVal || (newVal.id && newVal.id != oldVal.id)) {
            this.$store.commit("setMessages", [])
            this.getChatRoomMessages(newVal.id, 1).then(() => {
              setTimeout(() => {this.scrollToBottom()}, 500)
            })
            this.$store.dispatch("getUserMessageTemplates", {
              messageTypeId: 1
            })
          }
        }
      },
      messageTempalateId(newVal, oldVal) {
        let params = []
        if(oldVal != newVal) {
          if(newVal != 0) {
            let index = this.messageTemplates.findIndex((mt) => mt.id == newVal)

            if(index > -1) {
              let messageTemplate = this.messageTemplates[index]

              messageTemplate.message_template_params.forEach((param) => {
                if(param.default) {
                  params.push(param.default)
                } else {
                  params.push('')
                }
              })
            }
          }

          this.params = params
        }

      },
      messages() {
        setTimeout(() => {this.scrollToBottom()}, 500)
      },
      messageEndId(newVal) {
        if(newVal != this.selectedChatRoom.message_end_id) {
          this.setMessageEndId(newVal)
        }
      }
    },
    mounted() {
      this.resetMessages()
      if(this.selectedChatRoom.id) {
        this.messageEndId = this.selectedChatRoom.message_end_id
        this.getChatRoomMessages(this.selectedChatRoom.id, 1)
      }
    },
    methods: {
      setMessageEndId(messageEndId) {
        this.$store.dispatch("assignMessageEndToMessage", {
          message_end_id: messageEndId,
          chat_room_id: this.selectedChatRoom.id
        }).then((res) => {
          let index = this.$store.state.chatRoomModule.messages.findIndex((m) => m.id == res.message_id)

          if(index > -1) {
            let message = this.$store.state.chatRoomModule.messages[index]

            if(!message.message_message_ends) {
              this.$set(message, "message_message_ends", [])
            }
            message.message_message_ends.push(res)
            
            this.$store.commit("updateMessage", {
              message: message,
              index: index
            })
          }

          //this.messageEndId = null
        })
      },
      setTransfer() {
        this.showTransfer = true
      },
      transferChatRoom() {
        if(this.transferUserId) {
          this.$store.dispatch("transferChatRoom", {
            chatRoomId: this.selectedChatRoom.id,
            userId: this.transferUserId
          })
        }
      },
      prev() {
        if(this.showTask) {
          this.showTask = false
        } else if(this.sendMessageTemplate) {
          this.sendMessageTemplate = false
        } else if(this.sendFile) {
          this.sendFile = false
        } else if(this.showTransfer) {
          this.showTransfer = false
        } else {
          this.setSelectedChatId(0)
        }
      },
      setSelectedChatId(id) {
        this.$emit('setSelectedChatId', id)
      },
      resetMessages() {
        this.$store.commit("setMessages", [])
      },
      getChatRoomMessages(chatRoomId, page) {
        this.$store.dispatch("getChatRoomMessages", {
          chatRoomId: chatRoomId,
          page: page
        })
      },
      sendText() {
        if(this.text) {
          let text = this.text;
          this.text = ''
          this.$store.dispatch("chatRoomSendMessage", {
            chatRoomId: this.selectedChatRoom.id,
            data: {
              message_content_type_id: 1,
              message_content: text
            }
          }).then(() => {
            if(this.companyConfigs && this.companyConfigs.chatroom_capture_on_send && this.companyConfigs.chatroom_capture_on_send == 1) {
              //RC: Tenemos que capturar el chatroom
              this.$store.dispatch("captureChatRoom", {
                chatRoomId: this.selectedChatRoom.id,
              })
            }
          })
        }
      },
      sendTemplate() {
        if(this.selectedMessageTemplate.id) {
          this.$store.dispatch("chatRoomSendTemplate", {
            chatRoomId: this.selectedChatRoom.id,
            data: {
              message_template_id: this.selectedMessageTemplate.id,
              message_content_type_id: 1,
              params: this.params
            }
          }).then(() => {
            this.sendMessageTemplate = false
            this.sendMessageTemplateId = 0
          })
        }
        
      },
      openMessageTemplate() {
        this.sendMessageTemplate = true
      },
      capture() {
        
          this.$store.dispatch("captureChatRoom", {
            chatRoomId: this.selectedChatRoom.id,
          })
        
      },
      release() {
        
          this.$store.dispatch("releaseChatRoom", {
            chatRoomId: this.selectedChatRoom.id,
          })
        
      },
      close() {
        
          this.$store.dispatch("closeChatRoom", {
            chatRoomId: this.selectedChatRoom.id,
          }).then(() => {
            this.setSelectedChatId(0)
          })
        
      },
      scrollToBottom() {
          let container = this.$el.querySelector(".chat");
          if(container) {
              container.scrollTop = container.scrollHeight;
          }
      },
      setSendFile(sendFile) {
        this.sendFile = sendFile
      },
      handleFileUpload (e) {
        let file = e.target.files[0]
        
        if(file) {
          filesService.upload(file)
            .then((response) => {
                if(response) {
                  let image_extensions = new Array(".jpg",".jpeg",".png");
                  let extension = (response.substring(response.lastIndexOf("."))).toLowerCase();
                  if(image_extensions.includes(extension)) {
                    this.isPicture = true
                  } else {
                    this.isPicture = false
                  }
                  this.path = response
                  this.fileName = file.name
                }
            })
        }
      },
      sendMessageFile() {
        let image_extensions = new Array(".jpg",".jpeg",".png");

        let extension = (this.path.substring(this.path.lastIndexOf("."))).toLowerCase();

        let message_content_type_id = 3;
        if(image_extensions.includes(extension)) {
            message_content_type_id = 2;
        }

        this.$store.dispatch("chatRoomSendMessage", {
          chatRoomId: this.selectedChatRoom.id,
          data: {
            message_content_type_id: message_content_type_id,
            message_content: this.path + '|' + this.fileName,
            caption: this.caption
          }
        }).then(() => {
          this.path = ''
          this.fileName = ''
          this.caption = ''
          this.sendFile = false
        })
      },
      onPaste(ev) {
        this.retrieveImageFromClipboardAsBase64(ev, ((imageDataBase64) =>{
            // If there's an image, open it in the browser as a new window :)
            if(imageDataBase64){
                filesService.uploadPasteFile(imageDataBase64)
                .then((response) => {
                    if(response) {
                      this.path = response
                      this.fileName = response
                      this.caption = ''
                      this.sendFile = true
                      this.isPicture = true
                    }
                })
                
            }
        }));
      },
      retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
        if(pasteEvent.clipboardData == false){
            if(typeof(callback) == "function"){
                callback(undefined);
            }
        }

        var items = pasteEvent.clipboardData.items;

        if(items == undefined){
            if(typeof(callback) == "function"){
                callback(undefined);
            }
        }

        for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();

            // Create an abstract canvas and get context
            var mycanvas = document.createElement("canvas");
            var ctx = mycanvas.getContext('2d');
            
            // Create an image
            var img = new Image();

            // Once the image loads, render the img on the canvas
            img.onload = function(){
                // Update dimensions of the canvas with the dimensions of the image
                mycanvas.width = this.width;
                mycanvas.height = this.height;

                // Draw the image
                ctx.drawImage(img, 0, 0);

                // Execute callback with the base64 URI of the image
                if(typeof(callback) == "function"){
                    callback(mycanvas.toDataURL(
                        (imageFormat || "image/png")
                    ));
                }
            };

            // Crossbrowser support for URL
            var URLObj = window.URL || window.webkitURL;

            // Creates a DOMString containing a URL representing the object given in the parameter
            // namely the original Blob
            img.src = URLObj.createObjectURL(blob);
        }
      },
      setAddOrMergeAccount(value) {
        this.addOrMergeAccount = value
      },
      setShowTask(value) {
        this.showTask = value
      },
      mergeAccount() {
        this.setAddOrMergeAccount(false)

        this.$store.dispatch("getUserChatRooms")
          .then(() => {
            this.$emit('setSelectedChatId', this.selectedChatRoom.id)
          })
      }
    }
  }
</script>
<style lang="scss">
</style>