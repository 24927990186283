<template lang="pug">
.task-billable
  h5.title-card.text-secondary.me-2(:class="taskBillable == 1 ? 'text-success' : 'text-secondary'" @click="toggleTaskBillable") 
    font-awesome-icon(icon="money-bill")
    span.ms-1(v-if="!reduced") Facturable
</template>

<script>
  export default {
    name: 'TaskBillable',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, deafult: false}
    },
    data () {
      return {
        taskBillable: 0
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      
    },
    beforeMount() {
      this.taskBillable = this.task.billable
    },
    methods: {
      toggleTaskBillable() {
        if(this.taskBillable == 1) {
          this.taskBillable = 0
        } else {
          this.taskBillable = 1
        }
        
        this.update()
      },
      update() {
        let task = this.task
        this.$set(task, 'billable', this.taskBillable)

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'tasks',
          id: this.task.id,
          data: task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.task-billable {
  cursor: pointer;
}
h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
</style>