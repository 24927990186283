<template lang="pug">
.task-dates
  h5.title-card.text-secondary.me-3(@click="tooglePopup" :class="task.today ? 'text-warning' : ''")
    font-awesome-icon.me-1(icon="calendar")
    template(v-if="reduced")
      span(v-if="task.start_date") {{task.start_date | formatDateMin}}
      span(v-if="task.end_date") - {{task.end_date | formatDateMin}} 
      span(v-if="!task.start_date && !task.end_date") -
    template(v-else)
      span(v-if="task.start_date") {{task.start_date | formatDate}} {{task.start_hour | formatTime}}
      span(v-if="task.end_date") - {{task.end_date | formatDate}} {{task.end_hour | formatTime}}
      span(v-if="!task.start_date && !task.end_date") Sin fecha
  .backgroundPopup(v-if="showForm" @click="closePopup")
  .popup-form(v-if="showForm")
    .card.card-custom-v2.shadow-lg
      .card-header.pt-1.pb-1 
        .d-flex.justify-content-between.align-items-center 
          span.text-primary.text-uppercase Fechas
          font-awesome-icon.text-secondary(icon="times" @click="closePopup")
      .card-body.p-2
        strong Inicio
        br
        input.form-control.form-control-sm.mb-1(type="date" v-model="startDate")
        input.form-control.form-control-sm(type="time" v-model="startHour")
        hr
        strong Fin
        br
        input.form-control.form-control-sm.mb-1(type="date" v-model="endDate")
        input.form-control.form-control-sm(type="time" v-model="endHour")
        br
        .form-check.form-switch
          input.form-check-input#todayTask(type="checkbox" v-model="taskToday")
          label.form-check-label(for="todayTask") Planificar para hoy
      .card-footer
        .d-grid
          button.btn.btn-primary.btn-sm(@click="update") Guardar
</template>

<script>

  export default {
    name: 'TaskDates',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, deafult: false}
    },
    data () {
      return {
        startDate: null,
        startHour: null,
        endDate: null,
        endHour: null,
        showForm: false,
        taskToday: 0
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      
    },
    beforeMount() {
      this.startDate = this.task.start_date
      this.startHour = this.task.start_hour
      this.endDate = this.task.end_date
      this.endHour = this.task.end_hour
      this.taskToday = this.task.today
    },
    watch: {
    },
    methods: {
      tooglePopup() {
        this.showForm = !this.showForm
      },
      closePopup() {
        this.showForm = false
      },
      update() {
        let task = this.task
        this.$set(task, 'start_date', this.startDate)
        this.$set(task, 'start_hour', this.startHour)
        this.$set(task, 'end_date', this.endDate)
        this.$set(task, 'end_hour', this.endHour)
        this.$set(task, 'today', this.taskToday)

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'tasks',
          id: this.task.id,
          data: task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.userId = null
            this.$store.commit("updateTasks", res)
            this.$store.commit("updateUnfinishedTasks", res)
            this.closePopup()
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.backgroundPopup {
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.card.card-custom-v2 .card-header {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card.card-custom-v2 .card-footer {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.card.card-custom-v2 .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.card.card-custom-v2 .card-body h1.task-title {
  border-left: 3px solid $primary;
  padding-left: 10px;
  font-size: 1em;
  display: block;
  margin-bottom: 0px;
}
.card.card-custom-v2 .card-body h1.task-title small {
  display:block;
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}
.task-dates {
  position: relative;
}
.card.card-custom-v2 .card-header h5.title-card, .card.card-custom-v2 .card-footer h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.popup-form {
  position:absolute;
  top: 20px;
  z-index: 200;
  width: 160px;
  font-size: 0.85em;
}
.popup-form > .card {
  border: 1px solid $light;
}
</style>