<template lang="pug">
  .gc-user
    a#offcanvasUsersBtn(href="#" data-bs-toggle='offcanvas' data-bs-target='#offcanvasUsers' aria-controls='offcanvasUsers' v-if="companyConfigs && companyConfigs.module_users && companyConfigs.module_users == 1")
      font-awesome-icon(:icon="['fas', 'users']")
    #offcanvasUsers.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasUsersLabel')
      .offcanvas-header
        h5#offcanvasUsersLabel.offcanvas-title Usuarios
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body
        .alert.bg-warning-opacity-25(role="alert" v-if="channelInTransfer")
          .d-flex
            span Transferencia en curso, pulse la cruz para recuperar la llamada
            button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="cancelAttendedTransfer()")
        .alert.bg-primary-opacity-25(role="alert" v-if="channelToTransfer")
          .d-flex
            span Para transferir la llamada pulse sobre el usuario deseado
            button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="setChannelToTransfer(null)")
        .alert.bg-primary-opacity-25(role="alert" v-if="channelToRedirect")
          .d-flex
            span Para redirigir la llamada pulse sobre el usuario deseado
            button.btn-close.btn-close-dark.me-2.m-auto(type="button" aria-label="Close" @click="setChannelToRedirect(null)")
        .row(v-if="channelToTransfer || channelToRedirect")
          .col.m-1
            .input-group
              button.btn.btn-secondary
                font-awesome-icon(icon="filter")
              input.form-control(type="text" placeholder="Transferir directamente a" v-model="extension" @keyup.enter="transferExtension()")
        .row.mb-2(v-if="channelToRedirect || channelToTransfer")
          .col
            ul.nav.nav-tabs
              li.nav-item
                a.nav-link(href="#!" :class="tab == 'users' ? 'active' : 'text-dark'" @click="setTab('users')") Usuario
              li.nav-item
                a.nav-link(href="#!" :class="tab == 'queues' ? 'active' : 'text-dark'" @click="setTab('queues')") Colas
              li.nav-item
                a.nav-link(href="#!" :class="tab == 'accounts' ? 'active' : 'text-dark'" @click="setTab('accounts')") Cuentas
        template(v-if="(tab == 'users') || (!channelToRedirect && !channelToTransfer)")
          .row
            .col.m-1
              .input-group
                button.btn.btn-secondary
                  font-awesome-icon(icon="filter")
                input.form-control(type="text" placeholder="Buscar usuario" v-model="filter")
          .row
            .col.m-1
              .input-group
                filter-department(defaultKey="gc-users" @updateDepartmentIds="updateDepartmentIds" :showModal="false")
          .row
            .col
              user-status(
                v-for="user in sortedUsers" :key="'gc-user-user' + user.id"
                :user="user" 
                :calls="userCalls[user.id] ? userCalls[user.id].calls : []" 
                :showCalls="false" 
              )
        template(v-else-if="tab == 'queues'")
          .row
            .col.m-1
              .input-group
                button.btn.btn-secondary
                  font-awesome-icon(icon="filter")
                input.form-control(type="text" placeholder="Buscar usuario" v-model="filter")
          .row
            .col.m-1
              .input-group
                filter-department(defaultKey="gc-users" @updateDepartmentIds="updateDepartmentIds" :showModal="false")
          .row
            .col
              .d-grid.gap-2(v-for="queue in sortedQueues")
                button.btn.btn-primary.mb-2(@click="redirectChannelToTransfer(queue.number)") {{queue.number}} {{queue.name}}
        template(v-else-if="tab == 'accounts'")
          .row
            .col.m-1
              select.mb-2.form-select(v-model="accountTypeId" placeholder="")
                  option(value="0") Todos los tipos de cuenta
                  option(v-for="accountType in accountTypes" :value="accountType.id") {{accountType.label}}
              .input-group
                button.btn.btn-secondary
                  font-awesome-icon(icon="filter")
                input.form-control(type="text" placeholder="Buscar cuenta" v-model="filter")
          .row
            .col
              .card.mt-2.border(v-for="account in accounts")
                .card-body.pt-1.pb-1.ps-2.pe-2
                  span.badge.bg-primary(v-if="account.account_type_id") {{account.account_type.name}}
                  br
                  strong {{account.name}}
                  div(v-if="account.tags")
                    span.badge.me-2(v-for="tag in account.tags" :style="'background:' + tag.background + ';color:' + tag.color + ';'") {{tag.name}}
                .card-footer.p-0
                  .d-flex.border-top(v-for="accountPhone in account.account_phones")
                    .p-2.flex-grow-1 
                      span.mb-0.pb-0 {{accountPhone.fullphone}}
                      br
                      small.small {{accountPhone.name}}
                    .pt-2.pb-2.pe-3.ps-3.align-self-center
                      .btn-group
                        button.btn.btn-info.btn-sm(@click="redirectChannelToTransfer('0' + accountPhone.fullphone)")
                          font-awesome-icon(:icon="['fas', 'exchange-alt']")
                        
</template>
<script>
  import UserStatus from './UserStatus.vue'
  export default {
    name: 'GcUser',
    components: {
      UserStatus
    },
    data () {
      return {
        filter: '',
        extension: '',
        departmentsId: [],
        tab: 'users',
        accountTypeId: 3,
        accountTypes: [],
        accounts: [],
        page: 0,
        sortColumn: 'name',
        sortDirection: 1,
        limit: 50,
        totalPages: 0,
        limitStart: 0,
        limitEnd: 0,
        totalElements: 0,
      }
    },
    computed: {
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
      users() {
        return this.$store.state.userModule.users
      },
      filteredDepartmentUsers() {
        if(this.departmentsId.length > 0) {
          return this.users.filter((u) => {
            return (this.departmentsId.some( (d) => d == u.department_id));
          })  
        } else {
          return this.users
        }
      },
      filteredUsers() {
        return this.filteredDepartmentUsers.filter((u) => {
          return (u.name.toLowerCase().includes(this.filter.toLowerCase()) || (u.extension && u.extension.number.includes(this.filter)));
        })
      },
      sortedUsers () {
        let users = this.filteredUsers
        return users.sort((a, b) => {
            if(this.currentUser && a.id == this.currentUser.id) {
                return -1
            } else if(this.currentUser && b.id == this.currentUser.id) {
                return 1
            } else {
                if (a.name < b.name) {
                    return -1
                } else if (a.name > b.name) {
                    return 1
                }
            }
            return 0
        })
      },
      queues() {
        return this.$store.state.queueModule.queues
      },
      filteredDepartmentQueues() {
        if(this.departmentsId.length > 0) {
          return this.queues.filter((q) => {
            return (this.departmentsId.some( (d) => d == q.department_id));
          })  
        } else {
          return this.queues
        }
      },
      filteredQueues() {
        return this.filteredDepartmentQueues.filter((q) => {
          return (q.name.toLowerCase().includes(this.filter.toLowerCase()) || q.number.includes(this.filter));
        })
      },
      sortedQueues () {
        let queues = this.filteredQueues
        return queues.sort((a, b) => {
            if(this.currentUser && a.id == this.currentUser.id) {
                return -1
            } else if(this.currentUser && b.id == this.currentUser.id) {
                return 1
            } else {
                if (a.name < b.name) {
                    return -1
                } else if (a.name > b.name) {
                    return 1
                }
            }
            return 0
        })
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      calls() {
        return this.$store.state.callModule.calls
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      userCalls() {
        let userCalls = {}

        this.calls.forEach((call) => {
          call.connected.forEach((connected) => {
            let index = this.users.findIndex((u) => u.extension && u.extension.number == connected)

            if(index > -1) {
              if(!userCalls[this.users[index].id]) {
                userCalls[this.users[index].id] = {}
                this.$set(userCalls[this.users[index].id], 'calls', [])
              }

              userCalls[this.users[index].id].calls.push(call)
            }
          })
        })

        return userCalls
      }
    },
    watch: {
      filter() {
       if(this.filterPointer) {
         clearTimeout(this.filterPointer)
       }

       setTimeout(()=> {this.searchAccount()}, 500)
      },
      accountTypeId() {
       if(this.filterPointer) {
         clearTimeout(this.filterPointer)
       }

       setTimeout(()=> {this.searchAccount()}, 500)
      }
    },
    mounted() {
      this.$store.dispatch("getUserReportTodays")
      
      window.Echo.channel('App.Company.' + this.currentUser.company_id)
        .listen('UserLogin', (e) => {
            this.processUserLogin(e.userSession, e.user)
        })
        .listen('UserLogout', (e) => {
            this.processUserLogout(e.user)
        })
        .listen('UserUpdate', (e) => {
            this.processUserUpdate(e.user)
        })
        .listen('KeepAlive', () => {
          this.$store.dispatch("keepAlive")
        })
      window.Echo.channel('App.Pbx')
        .listen('UpdateUserReportToday', (e) => {
            this.processUpdateUserReportToday(e.userReportToday)
        })
        .listen('UpdateExtensionReportToday', (e) => {
            this.processUpdateExtensionReportToday(e.extensionReportToday)
        })

      this.$store.dispatch("getRegisters", {moduleUrl: "users"})
        .then((users) => {
          this.$store.commit("setUsers", users)
        })
        
      this.$store.dispatch("setCurrentUser")

      this.$store.dispatch("getRegistersList", {
        moduleUrl: 'account_types',
        columns: 'id,name',
        conditions: ''
      }).then((res) => {
        this.accountTypes = res
      })
    },
    methods: {
      setTab(tab) {
        this.tab = tab
      },
      searchAccount() {
        if(this.tab == 'accounts') {
          let advancedFilter = []

          if(this.accountTypeId && this.accountTypeId > 0) {
            advancedFilter.push({
              search_type_id: 1,
              search_table: "accounts",
              search_column: "account_type_id",
              filter: this.accountTypeId
            })
          }

          this.$store.dispatch("searchRegisters", {
            moduleUrl: 'accounts',
            page: this.page,
            data: {
              filter: this.filter,
              sortColumn: this.sortColumn,
              sortDirection: this.sortDirection,
              limit: this.limit,
              advancedFilter: advancedFilter
              
            }
          }).then((res) => {
            this.accounts = res.data
            this.totalPages = res.total_pages
            this.limit = res.limit
            this.limitStart = res.limit_start + 1
            this.limitEnd = res.limit_start + res.limit
            this.totalElements = res.total
            if(this.limitEnd > this.totalElements) {
              this.limitEnd = this.totalElements
            }
          })
        }
      },
      updateDepartmentIds(event) {
        this.departmentsId = event.departments_id
      },
      processUserLogin(userSession, eventUser) {
        let index = this.users.findIndex((u) => u.id == userSession.user_id)

        if(index > -1) {
          let user = eventUser

          this.$set(user, 'active_user_session', userSession)
          this.$store.commit("updateUser", user)
        }
      },
      processUserLogout(user) {
        if(user.id == this.currentUser.id) {
          this.$router.push('/logout')
        } else {

          let index = this.users.findIndex((u) => u.id == user.id)

          if(index > -1) {
            let user = this.users[index]

            this.$set(user, 'active_user_session', null)
            this.$set(user, 'extension', null)
            this.$set(user, 'secondary_extension', null)
            this.$set(user, 'extension_id', null)
            this.$set(user, 'secondary_extension_id', null)
            this.$store.commit("updateUser", user)
          }
        }
      },
      processUserUpdate(user) {
        let index = this.users.findIndex((u) => u.id == user.id)

        if(index > -1) {
          this.$store.commit("updateUser", user)
        }
      },
      cancelAttendedTransfer() {
        this.$store.dispatch("cancelAttendedtransferChannel", {
          channel: this.channelInTransfer,
        })
      },
      setChannelToTransfer() {
        this.$store.commit("setChannelToTransfer", '')
      },
      setChannelToRedirect() {
        this.$store.commit("setChannelToRedirect", '')
      },
      transferExtension() {
        if(this.channelToRedirect && this.extension) {
            let data = {
              channel: this.channelToRedirect,
              extension: this.extension
            }
            this.$store.dispatch("redirectChannel", data)
            this.$store.commit("setChannelToRedirect", '')
            this.extension = ''
        } else if(this.channelToTransfer && this.extension) {
            let data = {
              channel: this.channelToTransfer,
              extension: this.extension
            }
            this.$store.dispatch("attendedtransferChannel", data)
            this.$store.commit("setChannelToTransfer", '')
            this.extension = ''
        }
      },
      processUpdateUserReportToday(userReportToday) {
        this.$store.commit("updateReportUser", userReportToday)
        
        if(this.currentUser.id == userReportToday.user_id) {
          let currentUser = this.currentUser
          this.$set(currentUser, 'report_today', userReportToday)
          this.$store.commit("setCurrentUser", currentUser)
        }
      },
      processUpdateExtensionReportToday(extensionReportToday) {
        if(this.currentUser.extension_id == extensionReportToday.extension_id) {
          let currentUser = this.currentUser
          let extension = this.currentUser.extension
          this.$set(extension, 'report_today', extensionReportToday)
          this.$set(currentUser, 'extension', extension)
          this.$store.commit("setCurrentUser", currentUser)
        }
      },
      redirectChannelToTransfer(number) {
        if(this.channelToRedirect) {
            let data = {
              channel: this.channelToRedirect,
              extension: number
            }
            this.$store.dispatch("redirectChannel", data)
            this.$store.commit("setChannelToRedirect", '')
        } else if(this.channelToTransfer) {
            let data = {
              channel: this.channelToTransfer,
              extension: number
            }
            this.$store.dispatch("attendedtransferChannel", data)
            this.$store.commit("setChannelToTransfer", '')
        }
      },
    }
  }
</script>
<style lang="scss">
</style>