<template lang="pug">
.list-email-messages-item
  .card-gc.mb-2(:class="currentEmailMessage && emailMessage.id == currentEmailMessage.id ? 'bg-primary-opacity-50' : ''")
    .card-gc-body(:class="getBorderColor" @click="setCurrentEmailMessage()")
      .d-flex
        .flex-grow-1
          strong {{emailMessage.email_message_body.subject}}
        div
          span.badge.bg-success.rounded-pill(v-if="unread > 0")
            font-awesome-icon(icon="envelope")
      div
        span {{emailMessage.account.name}}
      .d-flex.mt-1.justify-content-between  
        div
          span.badge.bg-success.me-2(:key="'emailMessageSendedAt' + emailMessage.id + counterKey") {{emailMessage.sended_at | diffToNow | timeInHoursShort}}
          span.badge.bg-primary.me-2 {{emailMessage.email_config.name}}
          //span.badge.bg-warning.me-2(v-if="chatRoom.message_end") {{chatRoom.message_end.name}}
        div
          span.badge.bg-dark.me-1(v-for="owner in emailMessage.owners" data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-html="true" :title="owner.name") {{owner.name | string2Initials }}
      //.d-flex.align-items-center.mt-1(v-if="chatRoom.account.opened_tasks && chatRoom.account.opened_tasks.length > 0")  
        font-awesome-icon.me-1(icon="tasks")
        span {{chatRoom.account.opened_tasks[0].task_description.name.substring(0, 40) }}
        span(v-if="chatRoom.account.opened_tasks[0].task_description.name.length > 40") ...
  
</template>
<script>
  export default {
    name: 'ListEmailMessagesItem',
    components: {
      
    },
    props: {
      emailMessage: Object
    },
    data () {
      return {
        filter: '',
        extension: '',
        selectedChatId: 0,
        displayNotification: false
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      currentEmailMessage() {
        return this.$store.state.emailMessageModule.currentEmailMessage
      },
      getBorderColor() {
        let border = 'border-info'

        let indexOwner = this.emailMessage.owners.findIndex(owner => owner.id == this.currentUser.id)
        if (indexOwner > -1) {
          border = 'border-primary'
        } else if (indexOwner == -1 && this.emailMessage.owners.length > 0) {
          border = 'border-secondary'
        }

        return border
      },
      unread() {
        let index = this.emailMessage.users.findIndex((u) => u.id == this.currentUser.id)

        if (index > -1) {
          let user = this.emailMessage.users[index]
          return user.pivot.unread
        } else {
          return 0
        }
      }
      
    },
    watch: {
      
    },
    mounted() {
      
    },
    methods: {
      setCurrentEmailMessage() {
        this.$store.commit("setCurrentEmailMessage", this.emailMessage)
        this.$store.dispatch("markUnreadEmailMessage", {
          id: this.emailMessage.id,
          value: 0
        })
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>