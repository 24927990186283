import Vue from 'vue'
import Vuex from 'vuex'

import authModule from "./auth"
import moduleModule from "./modules"
import accountModule from "./accounts"
import legalDocumentModule from "./legal_documents"
import taskModule from "./tasks"
import taskGroupModule from "./task_groups"
import productModule from "./products"
import callModule from "./calls"
import userModule from "./users"
import restTimeModule from "./rest_times"
import extensionModule from "./extensions"
import departmentModule from "./departments"
import callEndModule from "./call_ends"
import messageEndModule from "./message_ends"
import callCommentModule from "./call_comments"
import queueModule from "./queues"
import dashboardModule from "./dashboards"
import reportModule from "./reports"
import chatRoomModule from "./chat_rooms"
import messageTemplateModule from "./message_templates"
import timeGroupModule from "./time_groups"
import timeGroupOptionModule from "./time_group_options"
import whatsappNumberModule from "./whatsapp_numbers"
import pbxCalendarGroupModule from "./pbx_calendar_groups"
import emailMessageModule from "./email_messages"
import emailConfigModule from "./email_configs"
import notificationModule from "./notifications"
import scheduleCallModule from "./schedule_calls"
import emojiModule from "./emoji"

import reportService from "../services/api/reports"
import companyConfigsService from "../services/api/company_configs"
import customButtonsService from "../services/api/custom_buttons"
import authService from "../services/api/auth"
import pbxService from "../services/api/pbx"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentUser: {},
        counterKey: 0,
        companyConfigs: {},
        customButtons: [],
        needApplyConfig: false
    },
    mutations: {
        setCompanyConfigs (state, configs) {
            state.companyConfigs = configs
        },
        setCustomButtons (state, customButtons) {
            state.customButtons = customButtons
        },
        setCurrentUser (state, currentUser) {
            state.currentUser = currentUser
        },
        setCounterKey (state, counterKey) {
            state.counterKey = counterKey
        },
        setNeedApplyConfig (state, needApplyConfig) {
            state.needApplyConfig = needApplyConfig
        }
    },
    actions: {
        setCurrentUser(context) {
            return new Promise((resolve, reject) => {
                authService.me()
                    .then(user => {
                        context.commit('setCurrentUser', user)
                        resolve(user)
                    })
                    .catch(reject)
            })
        },
        getReport (context, payload) {
            return new Promise((resolve, reject) => {
                reportService.getReportSample(payload)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        getMyCompanyConfigs (context) {
            return new Promise((resolve, reject) => {
                companyConfigsService.getMyCompanyConfigs()
                    .then((data) => {
                        context.commit("setCompanyConfigs", data)
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        getMyCustomButtons (context) {
            return new Promise((resolve, reject) => {
                customButtonsService.getMyCustomButtons()
                    .then((data) => {
                        context.commit("setCustomButtons", data)
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        pbxApplyConfig(context) {
            return new Promise((resolve, reject) => {
                pbxService.pbxApplyConfig()
                    .then((res) => {
                        context.commit("setNeedApplyConfig", false)
                        resolve(res)
                    })
                    .catch(reject)
            })
        }
        
    },
    modules: {
        authModule,
        moduleModule,
        accountModule,
        taskModule,
        legalDocumentModule,
        productModule,
        callModule,
        userModule,
        restTimeModule,
        extensionModule,
        departmentModule,
        callEndModule,
        messageEndModule,
        callCommentModule,
        queueModule,
        dashboardModule,
        reportModule,
        chatRoomModule,
        messageTemplateModule,
        timeGroupOptionModule,
        timeGroupModule,
        whatsappNumberModule,
        pbxCalendarGroupModule,
        emailMessageModule,
        emailConfigModule,
        taskGroupModule,
        notificationModule,
        scheduleCallModule,
        emojiModule
    }
})