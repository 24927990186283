<template lang="pug">
.extension-status
  .card.p-1.mt-2.small(:class="getExtensionStatusColor(extension)")
    .card-body.p-0.mb-0
      .row.g-0.m-0.p-0.d-flex.align-items-center(@click="redirectChannelToTransfer()")
        .col-md-3
          img.mx-auto.d-block(src="https://dummyimage.com/50x60/868e96/000000.png"  alt="")
        .col-md-9.p-1
          .d-flex.pb-0.mb-0
            .me-auto
              small 
                span(v-if="extension.extension_status_id > 0") {{extension.extension_status.name}}
                //span.badge.bg-warning(v-else-if="user.active_rest_time") {{user.active_rest_time.rest_time.name}}
                //span.badge.bg-success(v-else-if="user.active_user_session") Disponible
                //span.badge.bg-danger(v-else) Desconectado
            div
              .d-flex
                template(v-if="currentUser.extension_id && currentUser.extension_id != extension.id")
                  small
                      span {{extension.number}}
                template(v-else)
                  small
                    span {{extension.number}}
                .dropdown
                  a.btn.btn-link.p-0.mt-0.mb-0.ms-2(role="button" :id="'dropdownExtensionStatusMenuLink' + extension.id" data-bs-toggle="dropdown" aria-expanded="false")
                    font-awesome-icon(icon="angle-down")
                  ul.dropdown-menu.p-0(:aria-labelledby="'dropdownExtensionStatusMenuLink' + extension.id")
                    li
                      a.dropdown-item.small(@click="makeCall(currentUser.extension.number, extension.number)") Llamar
                    li
                      a.dropdown-item.small(@click="getExtensionStatusLog()") Registro de estado
          strong.d-block.mb-0 {{extension.name}}
          small.d-block.mb-1(v-if="extension.department_id") {{extension.department.name}}
          small.d-block.mb-1(v-else) &nbsp;
      div(v-if="showCalls")
        call-item(
          v-for="call in calls" 
          :call="call" 
          :extension="extension" 
        )
  .modal(:id="'extensionStatusModal' + extension.id")
    .modal-dialog.modal-dialog-scrollable
      .modal-content
        .modal-header
          .modal-title Estado de la extensión {{extension.number}} {{extension.name}}
          button.btn-close(type="button" data-bs-dismiss="modal" aria-label="Close")
        .modal-body
          table.table.table-striped
            thead
              tr
                th Fecha
                th Estado
            tbody
              tr(v-for="extensionStatusLogRegister in extensionStatusLog")
                td {{extensionStatusLogRegister.created_at | formatFullTime}}
                td 
                  span.badge(:class="getExtensionStatusColorLog(extensionStatusLogRegister.extension_status_id)") {{extensionStatusLogRegister.extension_status.name}}


</template>

<script>
  import CallItem from '../calls/CallItem.vue'
  import {Modal} from 'bootstrap'
  export default {
    name: 'UserStatus',
    components: {
      CallItem
    },
    props: { 
      extension: Object,
      calls: Array,
      showCalls: {type: Boolean, default: false },
    },
    data () {
      return {
        callHover: null,
        extensionStatusLog: []
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      currentUser() {
        return this.$store.state.currentUser
      }
    },
    beforeMount() {
      
    },
    methods: {
      getExtensionStatusLog() {
        this.$store.dispatch("getRegister", {
          moduleUrl: "extensions",
          id: this.extension.id
        }).then((res) => {
          this.extensionStatusLog = res.last50_extension_status_log

          let modalCall = document.getElementById('extensionStatusModal' + this.extension.id)
          if(modalCall.style.display != 'block') {
            new Modal(modalCall).show() 
          }
        })
      },
      setCallHover(call) {
        this.callHover = call
      },
      getUserBackground() {
        let background = 'bg-white';

        if(this.calls.length > 0) {
          background = 'bg-primary-opacity-25'
        }
        else if(this.user.active_rest_time) {
          background = 'bg-warning-opacity-25'
        }
        else if(this.user.active_user_session) {
          background = 'bg-success-opacity-25'
        } else {
          background = 'bg-danger-opacity-25'
        }

        return background
      },
      getExtensionStatusColor(extension) {
        let color = 'bg-secondary-opacity-25'

        switch(extension.extension_status_id) {
          case 1:
              color = 'bg-secondary-opacity-25';
            break;
          case 2:
              color = 'bg-secondary-opacity-25';
            break;
          case 3:
              color = 'bg-success-opacity-25';
            break;
          case 4:
              color = 'bg-primary-opacity-25';
            break;
          case 5:
              color = 'bg-warning-opacity-25';
            break;
          case 6:
              color = 'bg-danger-opacity-25';
            break;
          case 7:
              color = 'bg-info-opacity-25 blink';
            break;
          case 8:
              color = 'bg-primary-opacity-25 blink';
            break;
          case 9:
              color = 'bg-warning-opacity-25';
            break;
          case 10:
              color = 'bg-warning-opacity-25';
            break;
          case 11:
              color = 'bg-secondary-opacity-25';
            break;
          case 12:
              color = 'bg-secondary-opacity-25';
            break;
        }

        return color
      },
      getExtensionStatusColorLog(extensionStatusId) {
        let color = 'bg-secondary'

        switch(extensionStatusId) {
          case 1:
              color = 'bg-secondary';
            break;
          case 2:
              color = 'bg-secondary';
            break;
          case 3:
              color = 'bg-success';
            break;
          case 4:
              color = 'bg-primary';
            break;
          case 5:
              color = 'bg-warning';
            break;
          case 6:
              color = 'bg-danger';
            break;
          case 7:
              color = 'bg-info blink';
            break;
          case 8:
              color = 'bg-primary blink';
            break;
          case 9:
              color = 'bg-warning';
            break;
          case 10:
              color = 'bg-warning';
            break;
          case 11:
              color = 'bg-secondary';
            break;
          case 12:
              color = 'bg-secondary';
            break;
        }

        return color
      },
      redirectChannelToTransfer() {
        if(this.channelToRedirect && this.extension.number) {
            let data = {
              channel: this.channelToRedirect,
              extension: this.extension.number
            }
            this.$store.dispatch("redirectChannel", data)
            this.$store.commit("setChannelToRedirect", '')
        } else if(this.channelToTransfer && this.extension.number) {
            let data = {
              channel: this.channelToTransfer,
              extension: this.extension.number
            }
            this.$store.dispatch("attendedtransferChannel", data)
            this.$store.commit("setChannelToTransfer", '')
        }
      },
      makeCall(extension, phone) {
        this.$store.dispatch("makeCall", {
          phone: phone,
          extension: extension
        })
      }
    }
  }
</script>
<style lang="scss">
</style>