const emojiModule = {
    state: {
        emojis: []
    },
    mutations: {
        setEmojis (state, emojis) {
            state.emojis = emojis
        }
    },
    actions: {}
}

export default emojiModule