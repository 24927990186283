<template lang="pug">
.task-code
  template(v-if="contenteditable")
    .input-group
      input.form-control.form-control-sm(v-model="code")
      .btn.btn-primary.btn-sm(@click="update()")
        font-awesome-icon(icon="save" )
      .btn.btn-secondary.btn-sm( @click="cancelUpdate()")
        font-awesome-icon( icon="times")
  template(v-else)
    span.text-primary(v-if="task.code" :contenteditable="contenteditable" @input="onInput" @dblclick="contenteditable = true") {{task.code}}
    span.text-secondary(v-else @click="contenteditable = true") -
    font-awesome-icon.text-secondary.ms-1(icon="barcode")
  //span(v-if="task.code" @click.stop="tooglePopup") {{task.code}}
  //.backgroundPopup(v-if="showForm" @click="closePopup")
  //.popup-form(v-if="showForm")
    .card.card-custom-v2.shadow-lg
      .card-header.pt-1.pb-1 
        .d-flex.justify-content-between.align-items-center 
          span.text-primary.text-uppercase Fechas
          font-awesome-icon.text-secondary(icon="times" @click="closePopup")
      .card-body.p-2
        strong Código
        br
        input.form-control.form-control-sm.mb-1(type="text" v-model="code")
      .card-footer
        .d-grid
          button.btn.btn-primary.btn-sm(@click="update") Guardar
</template>

<script>

  export default {
    name: 'TaskCode',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, deafult: false}
    },
    data () {
      return {
        code: '',
        contenteditable: false,
        timerSave: null
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      
    },
    beforeMount() {
      this.code = this.task.code
    },
    watch: {
    },
    methods: {
      onInput(e) {
        this.code = e.target.innerText

        /*if (this.timerSave) {
          clearTimeout(this.timerSave)
        }
        this.timerSave = setTimeout(()=> {this.update()}, 1000)*/
      },
      tooglePopup() {
        this.showForm = !this.showForm
      },
      closePopup() {
        this.showForm = false
      },
      cancelUpdate() {
        this.code = this.task.code
        this.contenteditable = false
      },
      update() {
        let task = this.task
        this.$set(task, 'code', this.code)

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'tasks',
          id: this.task.id,
          data: task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.$store.commit("updateTasks", res)
            this.$store.commit("updateUnfinishedTasks", res)
            this.closePopup()
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.backgroundPopup {
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.card.card-custom-v2 .card-header {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card.card-custom-v2 .card-footer {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.card.card-custom-v2 .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.card.card-custom-v2 .card-body h1.task-title {
  border-left: 3px solid $primary;
  padding-left: 10px;
  font-size: 1em;
  display: block;
  margin-bottom: 0px;
}
.card.card-custom-v2 .card-body h1.task-title small {
  display:block;
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}
.task-code {
  position: relative;
}
.card.card-custom-v2 .card-header h5.title-card, .card.card-custom-v2 .card-footer h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.popup-form {
  position:absolute;
  top: 20px;
  z-index: 200;
  width: 160px;
  font-size: 0.85em;
}
.popup-form > .card {
  border: 1px solid $light;
}
</style>