<template lang="pug">
.screen-department-queues-full
  .card.mb-3
    .card-body
      .d-flex.justify-content-between
        h5.card-title.mb-1(v-if="filters.name") {{ filters.name }}
        h5.card-title.mb-1(v-else) {{ $t('Mis colas') }}
        div
          template(v-if="filters.hiddenTable")
            a.text-dark.me-2(@click="showTable = !showTable" href="#!")
              font-awesome-icon(:icon="['fas', 'window-minimize']")
          //template(v-if="showTable")
            a.text-dark(v-if="showQueue" @click="showQueue = !showQueue" href="#!")
              font-awesome-icon(icon="compress-alt")
            a.text-dark(v-else  @click="showQueue = !showQueue"  href="#!")
              font-awesome-icon(icon="expand-alt")

      table.table(v-if="showTable")
        thead
          tr.text-center
            th 
            th {{ $t('En Cola') }}
            th {{ $t('Perdidas') }}
            th {{ $t('Totales') }}
            th {{ $t('Espera') }}
            th {{ $t('Disponibles') }}
            th {{ $t('Nivel Servicio') }}
            th {{ $t('Pickup rate') }}

        tbody
          department-queue-full-penalty-0-so(v-for="department in filteredDepartments" :department="department")
        tfoot(v-if="filteredDepartments.length > 1")
          department-queue-full-total-penalty-0-so(:key="'totalQueues' + counterKey" :departments="filteredDepartments")
              
</template>
<script>
  import DepartmentQueueFullPenalty0So from '../departments/DepartmentQueueFullPenalty0So.vue'
  import DepartmentQueueFullTotalPenalty0So from '../departments/DepartmentQueueFullTotalPenalty0So.vue'
  export default {
    name: 'ScreenMyQueuesFullSo',
    components: {
      DepartmentQueueFullPenalty0So,
      DepartmentQueueFullTotalPenalty0So
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        showQueue: false,
        showTable: true,
        filteredDepartments: []
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        return this.$store.state.currentUser
      },
    },
    mounted() {
      this.getQueues()
    },
    methods: {
      getQueues() {
        this.$store.dispatch("getRegisters", {
          moduleUrl: 'departments'
        }).then((departments) => {
          this.filteredDepartments = []
          if (this.filters.departments_id) {
            this.filters.departments_id.forEach((departmentId) => {
              let index = departments.findIndex((d) => d.id == departmentId)

              if (index > -1) {
                this.filteredDepartments.push(departments[index])
              }
            })
          }

          setTimeout(() => {
            console.log("test")
            this.getQueues()
          }, 30000)
        })
      }
    }
  }
</script>
<style lang="scss">
</style>