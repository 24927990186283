<template lang="pug">
  .screen-my-calls
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title.mb-1 {{ $t('Mis llamadas') }}
          div
            a.text-dark.me-2(@click="refresh()")
              font-awesome-icon(icon="sync")
            a.text-dark(v-if="showCalls" @click="showCalls = !showCalls" href="#!")
              font-awesome-icon(icon="compress-alt")
            a.text-dark(v-else  @click="showCalls = !showCalls"  href="#!")
              font-awesome-icon(icon="expand-alt")
        chart-columns(v-if="series.length > 0" :series="series" :categories="labels" :colors="colors")
        table.table
          thead.text-center
            tr
              th(style="width:25%") 
                strong
                  font-awesome-icon.me-1(icon="arrow-right")
                br
                span {{ $t('ENTRADA') }}
              th(style="width:25%")
                strong
                  font-awesome-icon.me-1(icon="arrow-left")
                br
                span {{ $t('SALIDA') }}
              th(style="width:25%")
                strong
                  font-awesome-icon.me-1(icon="redo")
                br
                span {{ $t('INTERNA') }}
              th(style="width:25%")
                strong
                  font-awesome-icon.me-1(icon="exclamation-triangle")
                br
                span {{ $t('PERDIDA') }}
          tbody
            tr.text-center
              td
                h1.mb-0.text-primary {{currentUser.report_today.total_in_calls}}
              td
                h1.mb-0.text-success {{currentUser.report_today.total_out_calls}}
              td
                h1.mb-0.text-warning {{currentUser.report_today.total_internal_calls}}
              td
                h1.mb-0.text-secondary {{currentUser.report_today.total_lost_calls}}
            tr(v-if="showCalls" )
              td(colspan="4")
                table.table.mb-0
                  tr.mb-0(v-for="call in myTodayCalls")
                    td
                      span.icon.text-primary(v-if="call.call_type_id == 1")
                        font-awesome-icon(icon="arrow-right")
                      span.icon.text-success(v-else-if="call.call_type_id == 2")
                        font-awesome-icon(icon="arrow-left")
                      span.icon.text-warning(v-else)
                        font-awesome-icon(icon="redo")
                    td
                      strong {{call.call_status.name}}
                    td
                      strong(v-if="call.account_id") {{call.account.name}}
                      br(v-if="call.account_id")
                      span(v-if="call.call_type_id == 1") {{call.from}}
                      span(v-else-if="call.call_type_id == 2") {{call.to}}
                      span(v-else) {{call.from}} - {{call.to}}
                    td
                      span {{[call.start, "unix"] | unixToDateTime}}
                    td
                      span W: {{call.duration_wait | timeInHoursMin}}
                      br
                      span D: {{call.duration | timeInHoursMin}}
                    td
                      template(v-if="call.tags" v-for="tag in call.tags")
                        span(:style="'background:' + tag.background + ';color: ' + tag.color + ';'") {{tag.name}}
                        br
                    td
                      button.btn.btn-link.text-dark.btn-sm(@click.stop="setModalCall(call)")
                        font-awesome-icon(icon="eye")
              
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  import {Modal} from 'bootstrap'
  export default {
    name: 'ScreenMyCalls',
    components: {
      ChartColumns  
    },
    data () {
      return {
        showCalls: false,
        colors: ["#6259ca", "#f82649", "#09ad95",  "f7b731"],
        labels: ["Entrada", "Salientes", "Internas", "Perdidas"],
        series: []
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser
      },
      queues() {
        return this.$store.state.queueModule.queues
      },
      myTodayCalls() {
        return this.$store.state.callModule.myTodayCalls
      }
    },
    watch: {
      currentUser: {
        deep: true,
        handler(val) {
          this.series = []
          let series = []
          series.push({
            name: this.$t("Total Llamadas"),
            data: []
          })
          series[0].data.push(val.report_today.total_in_calls)
          series[0].data.push(val.report_today.total_out_calls)
          series[0].data.push(val.report_today.total_internal_calls)
          series[0].data.push(val.report_today.total_lost_calls)

          this.series = series
          //this.$store.dispatch("getMyTodayCalls")
        }
      }
    },
    mounted() {
        this.refresh()
    },
    methods: {
      refresh() {
        this.$store.dispatch("getMyTodayCalls")
      },
      setModalCall(call) {
        this.$store.commit("setModalCall", call)
        let modalCall = document.getElementById('callModal')
        if(modalCall.style.display != 'block') {
          new Modal(modalCall).show() 
        }
      },
    }
  }
</script>
<style lang="scss">
</style>
