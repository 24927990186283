<template lang="pug">
.task-create
  .input-group
    input.form-control(placeholder="Añadir nueva tarea" v-model="task.name" @keyup.enter="save")
    button.btn(:class="moreOptions ? 'btn-secondary' : 'btn-outline-secondary'" @click="clearOptions")
      font-awesome-icon(icon="times")
    button.btn.btn-outline-primary(@click="tooglePopup")
      font-awesome-icon(icon="cogs" :class="moreOptions ? 'blink' : ''")
  .backgroundPopup(v-if="showForm" @click.stop="closePopup")
  .popup-form(v-if="showForm")
    .card.card-custom-v2.shadow-lg
      .card-header.pt-1.pb-1 
        .d-flex.justify-content-between.align-items-center 
          span.text-primary.text-uppercase Más opciones
          font-awesome-icon.text-secondary(icon="times" @click="closePopup")
      .card-body.p-2
        .d-flex.mb-2
          .flex-fill
            label Listado
            v-select(:options="taskGroups" :reduce="taskGroup => taskGroup.id"  label="name" v-model="task.task_group_id")
        .d-flex.mb-2
          .flex-fill
            label Cuenta
            v-select(:options="accounts" :reduce="account => account.id"  label="name" v-model="task.account_id" @search="ajaxAccountSearch")
        .d-flex.mb-2
          .flex-fill
            label Tipo
            v-select(:options="taskTypes" :reduce="taskType => taskType.id"  label="label" v-model="task.task_type_id")
          .flex-fill
            Label Estado
            v-select(:options="taskStatuses" :reduce="taskStatus => taskStatus.id"  label="label" v-model="task.task_status_id")
        .d-flex.mb-2
          .p-1
            label Inicio
            br
            input.form-control.form-control-sm.mb-1(type="date" v-model="task.start_date")
            input.form-control.form-control-sm(type="time" v-model="task.start_hour")
          .p-1
            label Fin
            br
            input.form-control.form-control-sm.mb-1(type="date" v-model="task.end_date")
            input.form-control.form-control-sm(type="time" v-model="task.end_hour")
        .d-flex
          .p-1
            .form-check.form-switch
              input.form-check-input#todayTask(type="checkbox" v-model="task.today")
              label.form-check-label(for="todayTask") Planificar para hoy
      .card-footer
        .d-grid
          button.btn.btn-primary.btn-sm(@click="save") Guardar
</template>

<script>
  export default {
    name: 'TaskCreate',
    props: {
      reduced: {type: Boolean, deafult: false},
      accountId: {type: Number, default: 0},
      linkedid: {type: String, default: ''},
    },
    data () {
      return {
        task: {},
        showForm: false,
        accounts: []
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      taskTypes() {
        return this.$store.state.taskModule.taskTypeLists
      },
      taskPriorities() {
        return this.$store.state.taskModule.taskPrioritiesList
      },
      taskStatuses() {
        return this.$store.state.taskModule.taskStatusLists
      },
      taskGroups() {
        return this.$store.state.taskGroupModule.taskGroups
      },
      moreOptions() {
        if(this.task.start_date || this.task.end_date || this.task.task_group_id || this.task.task_status_id || this.task.task_type_id || this.task.account_id || this.task.today) {
          return true
        } else {
          return false
        }
      }
    },
    beforeMount() {
      this.task = {}

      if(this.accountId) {
        this.task.account_id = this.accountId
      }
    },
    watch: {
      accountId(val) {
        this.task.account_id = val
      }
    },
    methods: {
      clearOptions() {
        this.task = {}
      },
      tooglePopup() {
        this.showForm = !this.showForm
      },
      closePopup() {
        this.showForm = false
      },
      ajaxAccountSearch(search, loading) {
        if(search) {
          loading(true)
          this.$store.dispatch("searchRegisters", {
            moduleUrl: "accounts",
            page: 1,
            data: {
              filter: search,
              sortColumn: "name",
              sortDirection: 1,
              limit: 100,
            }
          }).then((res) => {
            this.accounts = res.data
            loading(false)
          })
        }
      },
      save() {
        if(this.task.name) {
          this.$store.dispatch('createRegister', {
            moduleUrl: 'tasks',
            data: this.task
          }).then((res) => {
            this.$store.commit("addTasks", res)
            this.task.name = ''

            if(this.linkedid) {
              this.$store.dispatch("callAssign", {
                  linkedid: this.linkedid,
                  task_id: res.id
                })
            }
          }) 
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.backgroundPopup {
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.card.card-custom-v2 .card-header {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card.card-custom-v2 .card-footer {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.card.card-custom-v2 .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.card.card-custom-v2 .card-body h1.task-title {
  border-left: 3px solid $primary;
  padding-left: 10px;
  font-size: 1em;
  display: block;
  margin-bottom: 0px;
}
.card.card-custom-v2 .card-body h1.task-title small {
  display:block;
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}
.btn-options, .task-create {
  position: relative;
}
.card.card-custom-v2 .card-header h5.title-card, .card.card-custom-v2 .card-footer h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.popup-form {
  position:absolute;
  top: 30px;
  right: 0px;
  z-index: 200;
  width: 320px;
  font-size: 0.85em;
}
.popup-form > .card {
  border: 1px solid $light;
}
</style>