<template lang="pug">
.screen-whatsapp
  .card
    .card-body
      h5.card-title Whatsapp
      .chatv2(style="height:calc(100vh - 220px);position:relative;overflow-y:scroll;overflow-x: hidden")
        chat-room-v2(:reduced="true")
              
      
</template>
<script>
  import ChatRoomV2 from '../messages/ChatRoomV2.vue'
  export default {
    name: 'ScreenChatRoom',
    components: {
      ChatRoomV2
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {}
    },
    computed: {
      messages() {
        return this.$store.state.chatRoomModule.messages
      },
    },
    watch: {
      messages() {
        setTimeout(() => { this.scrollToBottom() }, 500)
      },
    },
    mounted() {
      
    },
    methods: {
      scrollToBottom() {
        console.log("test")
        let container = this.$el.querySelector(".chatv2");
        if (container) {
          console.log("test 2")
          container.scrollTop = container.scrollHeight;
        }
      },
    }
  }
</script>
<style lang="scss" scoped>

</style>