<template lang="pug">
  #app(:class="darkTheme ? 'dark-theme' : ''")
    .content-app(:class="!showHeader ? 'collapse-header' : ''")
      .hover-mouse-header(@click="toogleHeader()")
      template(v-if="user && user.id")
        Header(:darkTheme="darkTheme" @toogleTheme="toogleTheme()")
      router-view
</template>

<script>
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  },
  data () {
    return {
      user: {},
      darkTheme: false,
      showHeader: false
    }
  },
  computed: {
    counterKey() {
      return this.$store.state.counterKey
    },
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  created() {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'es')
  
    if(localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'))
      this.user = user.user

      switch(user.user.language_id) {
        case 2:
          this.$i18n.locale = 'ca'
          break;
        case 3:
          this.$i18n.locale = 'en'
          break;
        case 4:
          this.$i18n.locale = 'it'
          break;
        case 5:
          this.$i18n.locale = 'pt'
          break;
        case 6:
          this.$i18n.locale = 'fr'
          break;
        default:
          this.$i18n.locale = 'es'
          break;
      }

      document.title = user.user.company.name + " | Global Call by Omkiner"

      this.$store.dispatch("me")
      this.$store.commit("setUser", user.user)
      this.$store.commit("setCurrentUser", user.user)
      this.$store.commit("setBackendUrl", user.app_url)
      
      this.$store.dispatch("getMyCompanyConfigs")
      this.$store.dispatch("getMyCustomButtons")
      this.$store.dispatch("getDepartmentsStructure")
      this.$store.dispatch("getDepartmentsList")

      if(user.user.collapse_header) {
        this.showHeader = false
      } else {
        this.showHeader = true
      }

      setInterval(()=> {
        this.$store.commit("setCounterKey", this.counterKey + 1);
      }, 1000)
    }

    Notification.requestPermission().then(function() {
      
    });
                
  },
  methods: {
    toogleHeader() {
      if(this.currentUser.collapse_header) {
        this.showHeader = !this.showHeader
      } else {
        this.showHeader = true
      }
    },
    toogleTheme() {
      this.darkTheme = !this.darkTheme
    }
  }
}
</script>

<style lang="scss">
  @import "vue-select/src/scss/vue-select.scss";
  @import './scss/main.scss';

  .collapse-header {
    padding-top: 0px;
  }  
</style>
