import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import 'bootstrap';
import CKEditor from 'ckeditor4-vue';
import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2';
import moment from "vue-moment";
import i18n from './lang'

//import FlowyPlugin from "@hipsjs/flowy-vue";
//import VueDragscroll from 'vue-dragscroll'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import VueApexCharts from 'vue-apexcharts'
import draggable from 'vuedraggable'
import VCalendar from 'v-calendar'
import Vue2Editor from "vue2-editor";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faBars,
  faAngleDown,
  faAngleUp,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faBell,
  faEnvelope,
  faStopwatch,
  faEdit,
  faTrash,
  faEllipsisV,
  faPhone,
  faCoffee,
  faEye,
  faPlusCircle,
  faUsers,
  faIdCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faTimes,
  faBriefcase,
  faTruckLoading,
  faTasks,
  faUserCog,
  faUserShield,
  faFileInvoice,
  faInbox,
  faArrowRight,
  faArrowLeft,
  faSave,
  faEuroSign,
  faBan,
  faPlay,
  faStop,
  faCalendar,
  faCalendarDay,
  faPaperclip,
  faTrafficLight,
  faFlag,
  faFlagCheckered,
  faLayerGroup,
  faColumns,
  faCogs,
  faPaste,
  faUpload,
  faUnlink,
  faCircle,
  faClock,
  faSitemap,
  faListOl,
  faVolumeUp,
  faFilePdf,
  faServer,
  faChartLine,
  faComments,
  faFilter,
  faRedo,
  faParking,
  faRetweet,
  faPhoneSlash,
  faHeadphonesAlt,
  faHeadset,
  faUndo,
  faExchangeAlt,
  faCopy,
  faMoon,
  faSun, 
  faHistory,
  faCheck,
  faCheckDouble,
  faTimesCircle,
  faExclamationTriangle,
  faExpandAlt,
  faCompressAlt,
  faPercent,
  faDesktop,
  faThumbsUp,
  faThumbsDown,
  faClipboardCheck,
  faPause,
  faBackward,
  faForward,
  faCommentAlt,
  faHandshake,
  faHandshakeSlash,
  faExternalLinkAlt,
  faArchive,
  faSync,
  faBoxOpen,
  faWindowMinimize,
  faCloudDownloadAlt,
  faSpinner,
  faVoicemail,
  faStar,
  faCheckCircle,
  faFileDownload,
  faHourglassHalf,
  faBug,
  faMoneyBill,
  faHourglass,
  faSmile,
  faEyeSlash,
  faHashtag,
  faTag,
  faBarcode,
  faPaperPlane,
  faSort,
  faExclamationCircle,
  faFileUpload,
  faEnvelopeOpen,
  faReply,
  faReplyAll,
  faShare,
  faSyncAlt
  
} from '@fortawesome/free-solid-svg-icons';

import {
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

  faReplyAll
library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faBars, faAngleUp, faAngleDown, faBell, faEnvelope, faStopwatch, faEdit, faTrash, faEllipsisV, faPhone, faCoffee, faEye, faPlusCircle, faUsers, faIdCard, faAngleDoubleLeft, faAngleDoubleRight, faTimes, faBriefcase, faTruckLoading, faTasks, faUserCog, faUserShield, faFileInvoice, faInbox, faArrowRight, faArrowLeft, faSave, faEuroSign, faBan, faPlay, faStop, faCalendar, faCalendarDay, faPaperclip, faTrafficLight, faFlag, faFlagCheckered, faLayerGroup, faColumns, faCogs, faPaste, faUpload, faUnlink, faCircle, faClock, faSitemap, faListOl, faVolumeUp, faFilePdf, faServer, faChartLine, faComments, faFilter, faRedo, faParking, faRetweet, faPhoneSlash, faHeadphonesAlt, faHeadset, faUndo, faExchangeAlt, faCopy, faMoon, faSun, faHistory, faCheck, faCheckDouble, faTimesCircle, faExclamationTriangle, faExpandAlt, faCompressAlt, faPercent, faDesktop, faThumbsUp, faThumbsDown, faClipboardCheck, faPause, faBackward, faForward, faCommentAlt, faHandshake, faHandshakeSlash, faExternalLinkAlt, faArchive, faSync, faBoxOpen, faWindowMinimize, faCloudDownloadAlt, faSpinner, faVoicemail, faStar, faCheckCircle, faFileDownload, faHourglassHalf, faBug, faMoneyBill, faHourglass, faSmile, faEyeSlash, faHashtag, faTag, faBarcode, faPaperPlane, faWhatsapp, faSort, faExclamationCircle, faFileUpload, faEnvelopeOpen, faReply, faReplyAll, faShare, faSyncAlt);

import 'sweetalert2/dist/sweetalert2.min.css';
//import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";

import Echo from 'laravel-echo';
import { np4Config } from './config.js'
window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'np4key',
  wsHost: np4Config.wsHost,
  wsPort: np4Config.wsPort,
  wssPort: np4Config.wssPort,
  disableStats: true,
  encrypted: true,
  enabledTransports: ['ws', 'wss'],
  disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming']
});

Vue.config.productionTip = false

Vue.use( CKEditor );
Vue.use( Vue2Editor );
Vue.use(VueSweetalert2);
Vue.use(moment)
//Vue.use(FlowyPlugin);
//Vue.use(VueDragscroll)
Vue.use(VueCustomTooltip)
Vue.use(VueApexCharts)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

import DemoNode from "./components/pbx/DemoNode.vue"
import DemoBlock from "./components/pbx/DemoBlock.vue"
import FilterDepartmentNode from "./components/departments/FilterDepartmentNode.vue"
import FilterDepartment from './components/departments/FilterDepartment.vue'
import ScreenUserStatusesLarge from './components/screens/ScreenUserStatusesLarge.vue'
import ScreenMyUserStatusesLarge from './components/screens/ScreenMyUserStatusesLarge.vue'
import ScreenMyQueuesFull from './components/screens/ScreenMyQueuesFull.vue'
import ScreenMyQueues from './components/screens/ScreenMyQueues.vue'
import ScreenQueues from './components/screens/ScreenQueues.vue'
import ScreenQueuesFull from './components/screens/ScreenQueuesFull.vue'
import ScreenMyQueuesFullPenalty0 from './components/screens/ScreenMyQueuesFullPenalty0.vue'
import ScreenMyQueuesPenalty0 from './components/screens/ScreenMyQueuesPenalty0.vue'
import ScreenMyHiddenQueuesPenalty0 from './components/screens/ScreenMyHiddenQueuesPenalty0.vue'
import ScreenQueuesPenalty0 from './components/screens/ScreenQueuesPenalty0.vue'
import ScreenQueuesFullPenalty0 from './components/screens/ScreenQueuesFullPenalty0.vue'
import ScreenHiddenQueuesFullPenalty0 from './components/screens/ScreenHiddenQueuesFullPenalty0.vue'
import ScreenQueuesHour from './components/screens/ScreenQueuesHour.vue'
import ScreenMyCalls from './components/screens/ScreenMyCalls.vue'
import ScreenMyExtensionCalls from './components/screens/ScreenMyExtensionCalls.vue'
import ScreenMyExtensionStatusesLarge from './components/screens/ScreenMyExtensionStatusesLarge.vue'
import ScreenActiveUsers from './components/screens/ScreenActiveUsers.vue'
import ScreenRestTimeUsers from './components/screens/ScreenRestTimeUsers.vue'

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-select', vSelect);
Vue.component('demo-node', DemoNode);
Vue.component('demo-block', DemoBlock);
Vue.component('filter-department-node', FilterDepartmentNode);
Vue.component('filter-department', FilterDepartment);
Vue.component('screen-user-statuses-large', ScreenUserStatusesLarge);
Vue.component('screen-my-user-statuses-large', ScreenMyUserStatusesLarge);
Vue.component('screen-my-extension-statuses-large', ScreenMyExtensionStatusesLarge);
Vue.component('screen-my-queues', ScreenMyQueues);
Vue.component('screen-my-queues-full', ScreenMyQueuesFull);
Vue.component('screen-queues', ScreenQueues);
Vue.component('screen-queues-full', ScreenQueuesFull);
Vue.component('screen-my-queues-penalty-0', ScreenMyQueuesPenalty0);
Vue.component('screen-my-hidden-queues-penalty-0', ScreenMyHiddenQueuesPenalty0);
Vue.component('screen-my-queues-full-penalty-0', ScreenMyQueuesFullPenalty0);
Vue.component('screen-queues-penalty-0', ScreenQueuesPenalty0);
Vue.component('screen-queues-full-penalty-0', ScreenQueuesFullPenalty0);
Vue.component('screen-hidden-queues-full-penalty-0', ScreenHiddenQueuesFullPenalty0);
Vue.component('screen-queues-hour', ScreenQueuesHour);
Vue.component('screen-my-calls', ScreenMyCalls);
Vue.component('screen-my-extension-calls', ScreenMyExtensionCalls);
Vue.component('screen-active-users', ScreenActiveUsers);
Vue.component('screen-rest-time-users', ScreenRestTimeUsers);


Vue.component('apexchart', VueApexCharts)
Vue.component('draggable', draggable)


numeral.register('locale', 'es', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },
  currency: {
      symbol: '€'
  }
});

//numeral.locale('es');
Vue.filter('string2Initials', function(value) {
  let initials = ''
  if(value) {
    let parts = value.split(' ')

    if(parts[0]) {
      initials = initials + parts[0].charAt(0)
    }
    if(parts.length > 1) {
      initials = initials + parts[(parts.length -1)].charAt(0)
    }
  }

  return initials
})
Vue.filter('numFormat', numFormat(numeral));

Vue.filter('timeInHours', function (value) {
  if (value) {
      let hours = parseInt(Math.floor(value / 3600));
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
      let seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);
      let dHours = (hours > 9 ? hours : '0' + hours);
      let dMins = (minutes > 9 ? minutes : '0' + minutes);
      let dSecs = (seconds > 9 ? seconds : '0' + seconds);
      return dHours + ":" + dMins + ":" + dSecs;
  } else {
      return '00:00:00';
  }
});

Vue.filter('formatDate', function (value) {
  if (value) {
      return Vue.moment(new Date(value)).format("DD-MM-YYYY")
  } else {
      return ''
  }
});

Vue.filter('formatDateMin', function (value) {
  if (value) {
      return Vue.moment(new Date(value)).format("DD-MMM")
  } else {
      return ''
  }
});

Vue.filter('format2Digits', function (value) {
  if (parseInt(value) < 10) {
      return '0' + value
  } else {
      return value
  }
});
Vue.filter('formatFullTime', function (value) {
  if (value) {
      return Vue.moment(new Date(value)).format("DD-MM-YYYY, H:mm:ss")
  } else {
      return ''
  }
});
Vue.filter('formatTime', function (value) {
  if (value) {
      let parts = value.split(":")

      return parts[0] + ':' + parts[1]
  } else {
      return ''
  }
});

Vue.filter('diffToNow', function (value) {
  let start = new Date(value);
  let end = new Date();

  return (end.getTime() / 1000) - (start.getTime() / 1000)
});

Vue.filter('timeInHours', function (value) {
  if (value) {
      let hours = parseInt(Math.floor(value / 3600));
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
      let seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);
      let dHours = (hours > 9 ? hours : '0' + hours);
      let dMins = (minutes > 9 ? minutes : '0' + minutes);
      let dSecs = (seconds > 9 ? seconds : '0' + seconds);
      return dHours + ":" + dMins + ":" + dSecs;
  } else {
      return '00:00:00';
  }
});

Vue.filter('timeInHoursMin', function (value) {
  if (value) {
      let hours = parseInt(Math.floor(value / 3600));
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
      let seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);

      let str = ""

      if(hours > 0) {
        str = str + hours +"h "
      }

      if(minutes > 0) {
        str = str + minutes +"m "
      }

      if(seconds > 0) {
        str = str + seconds +"s"
      }

      return str;
  } else {
      return '0s';
  }
});

Vue.filter('timeInHoursShort', function (value) {
  if (value) {
      let hours = parseInt(Math.floor(value / 3600));
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
      let seconds = parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);

      let str = ""

      if(hours > 48) {
        str = "+48h"
      } else if(hours > 24) {
        str = "+24h"
      } else if(hours > 0) {
        str = hours + "h"
      } else if(minutes > 0) {
        str = minutes +"m"
      } else if(seconds > 0) {
        str = seconds +"s"
      }

      return str;
  } else {
      return '0s';
  }
});

Vue.filter('unixToDateTime', function (value) {
  if (value) {
      return Vue.moment.unix(value[0]).format("DD/MM/YYYY HH:mm:ss")
  } else {
      return ''
  }
});

Vue.filter('unixToDate', function (value) {
  if (value) {
      return Vue.moment.unix(value[0]).format("DD/MM/YYYY")
  } else {
      return ''
  }
});

Vue.filter('unixToTime', function (value) {
  if (value) {
      return Vue.moment.unix(value[0]).format("HH:mm:ss")
  } else {
      return ''
  }
});

Vue.filter('unixToDateTimeEn', function (value) {
  if (value) {
      return Vue.moment.unix(value[0]).format("YYYY-MM-DD HH:mm:ss")
  } else {
      return ''
  }
});

Vue.filter('shortTileCapitalize', function(str) {
  let splitStr = str.toLowerCase().split(' ');
  let stringShot = '';
  
  for (var i = 0; i < splitStr.length; i++) {
    stringShot = stringShot + splitStr[i].charAt(0).toUpperCase();
  }
   
  return stringShot.substring(0,2); 
})

Vue.filter('hexToRgba', function(hex, opacity) {
  let c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',') +','+ opacity + ')';
  }

  return ''
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
