<template lang="pug">
.task-calendar
  vc-calendar(
    class="custom-calendar max-w-full"
    :masks="masks"
    :attributes="attributes"
    disable-page-swipe
    is-expanded
  )
    template(v-slot:day-content="{ day, attributes }")
      .border(style="height:150px;")
        .text-end.me-2
          span {{ day.day }}
        .p-1(style="height:120px;overflow-y: auto;")
          .mb-1.boder-radius.ps-1.pe-1(
            v-for="attr in attributes"
            :key="attr.key"
            :class="attr.customData.class" 
            @click="showTask(attr.customData.dataTask)"
          ) {{ attr.customData.title }}
      
</template>

<script>
  import { Modal } from 'bootstrap'
  export default {
    name: 'TaskCalendar',
    props: {
      tasks: {type: Array, default: function() { return []}}
    },
    data () {
      return {
        masks: {
          weekdays: 'WWW',
        }
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      attributes() {
        let attributes = []

        this.tasks.forEach((task) => {
          if (task.start_date) {
            let taskClass = 'bg-primary text-white'

            if (task.task_type_id == 2) {
              taskClass = 'bg-warning text-white'
            } else if (task.task_type_id == 3) {
              taskClass = 'bg-info text-white'
            } else if (task.task_type_id == 4) {
              taskClass = 'bg-success text-white'
            }

            let element = {
              key: task.id,
              customData: {
                title: task.task_description.name,
                class: taskClass,
                dataTask: task,
              },
              dates: new Date(task.start_date),
            }

            attributes.push(element)
          }
        })

        return attributes
      }
      
      
    },
    beforeMount() {
    },
    methods: {
      showTask(dataTask) {
        console.log(dataTask)
        this.$store.commit("setTask", dataTask)
        this.$store.commit("setModalTaskId", dataTask.id)
        let taskModal = document.getElementById('taskModal')
        if (taskModal.style.display != 'block') {
          new Modal(taskModal).show()
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.badge {
  font-weight: 400;
}
::-webkit-scrollbar {
  width: 8px;
}

.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;

  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }

  & .vc-weeks {
    padding: 0;
  }

  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }

  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;

    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }

    &:not(.on-bottom) {
      border-bottom: var(--day-border);

      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }

    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }

  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>