<template lang="pug">
.report-percentage-call-end-by-user
  .card.mb-3
    .card-body(style="overflow-x: auto")
      .d-flex.justify-content-between
        h5.card-title {{$t(reportReportItem.name)}}
      //  .btn-group
          button.btn.btn-sm(@click="showTab = 'in'" :class="showTab == 'in' ? 'btn-primary' : 'btn-outline-primary'") In
          button.btn.btn-sm(@click="showTab = 'out'" :class="showTab == 'out' ? 'btn-primary' : 'btn-outline-primary'") Out
          button.btn.btn-sm(@click="showTab = 'internal'" :class="showTab == 'internal' ? 'btn-primary' : 'btn-outline-primary'") Internal
      // template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
        chart-columns(v-if="series.length > 0" :series="series" :categories="labels" :colors="colors")
      
      table.table.text-center
        thead
          tr
            th {{$t('Usuario')}}
            th(v-for="header in data.headers") {{header}}
        tbody
          tr(v-for="row in data.dataTable")
            td {{row.name}}
            td(v-for="col in row.call_ends") 
              strong {{col.percentage}}%
              br
              small {{col.duration | timeInHours}}
</template>
<script>
  import ChartColumns from '../chart/ChartColumns.vue'
  export default {
    name: 'ReportPercentageCallEndByUser',
    components: {
      ChartColumns
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      series() {
        return []
      },
      labels() {
        return []
      },
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>