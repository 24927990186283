<template lang="pug">
.chat-room-v2-chat(v-if="selectedChatRoom && selectedChatRoom.id")
  .bg-white(style="position:sticky;width:100%;top:0px;z-index:10")
    .card-gc.mb-2
      .card-gc-body(:class="getBorderColor")
        strong {{selectedChatRoom.account.name}}
        .d-flex.align-items-center
          .flex-grow-1
            span.small {{selectedChatRoom.from}}
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(v-if="selectedChatRoom.is_active && (!selectedChatRoom.owner_user_id || currentUser.id != selectedChatRoom.owner_user_id)" @click="capture()" :title="$t('Capturar')")
            font-awesome-icon(icon="handshake") 
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(v-else-if="selectedChatRoom.is_active" @click="release" :title="$t('Liberar')")
            font-awesome-icon(icon="handshake-slash")
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(v-if="selectedChatRoom.is_active" @click="setShowTransfer()" :title="$t('Transferir')")
            font-awesome-icon(:icon="['fas', 'exchange-alt']")
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(v-if="selectedChatRoom.is_active" @click="setAddOrMergeAccount()" :title="$t('Añadir o modificar cuenta')")
            font-awesome-icon(:icon="['fas', 'user-plus']")
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(v-if="selectedChatRoom.is_active" @click="setShowTask()" :title="$t('Ver tareas')")
            font-awesome-icon(:icon="['fa', 'tasks']")
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(v-if="selectedChatRoom.is_active" @click="close" :title="$t('Cerrar')")
            font-awesome-icon(icon="archive")
          button.btn.btn-link.p-0.mt-0.mb-0.ms-2.me-2(v-else @click="open" :title="$t('Cerrar')")
            font-awesome-icon(icon="archive")
          button.btn.btn-link.text-dark.p-0.mt-0.mb-0.ms-2.me-0(@click="unselectChatRoom()")
            font-awesome-icon.me-2(icon="times")
        .pt-2(v-if="messageEnds && messageEnds.length > 0")
          v-select(:options="messageEnds" v-model="messageEnd"  label="name" placeholder="Seleccionar un final")
        div(v-else style="height:32px;")
        .d-flex.align-items-center.mt-1(v-if="selectedChatRoom.account.opened_tasks && selectedChatRoom.account.opened_tasks.length > 0")  
          font-awesome-icon.me-1(icon="tasks")
          span {{selectedChatRoom.account.opened_tasks[0].task_description.name.substring(0, 40) }}
          span(v-if="selectedChatRoom.account.opened_tasks[0].task_description.name.length > 40") ...
  template(v-if="sendMessageTemplate")
    .row()
      .col
        .card
          .card-body
            label {{ $t('Seleccione una plantilla') }}
            v-select(:options="messageTemplates" :reduce="messageTemplate => messageTemplate.id"  label="label" v-model="messageTempalateId")

            template(v-if="selectedMessageTemplate.id")
              .alert.bg-info-opacity-25.mt-3.mb-3(v-html="formatedMessage")

              .mb-1.pb-1(v-for="(param, index) in selectedMessageTemplate.message_template_params")
                label.label {{param.label}}
                input.form-control(v-model="params[index]")
              .d-grid.gap-2
                button.btn.btn-primary(@click="sendTemplate()") {{$t('Enviar plantilla')}}
            .d-grid.gap-2
              button.btn.btn-secondary(@click="cancelTabs()") Cancelar
  template(v-else-if="showTransfer")
    .row
      .col.mt-3
        .card
          .card-body
            label {{$t('Seleccione un usuario')}}
            v-select(:options="users" :reduce="user => user.id"  label="name" v-model="transferUserId")

            template(v-if="transferUserId")
              .d-grid.gap-2.mt-3
                button.btn.btn-primary.mb-3(@click="transferChatRoom()") {{$t('Transferir Chat')}}
            .d-grid.gap-2.mt-3
              button.btn.btn-secondary(@click="cancelTabs()") Cancelar
  template(v-else-if="sendFile")
    .row
      .col-lg-6.offset-lg-3.mt-3
        .card
          .card-body
            label {{$t('Introduzca el fichero a enviar')}}
            input.form-control(type="file" @change="handleFileUpload")

            .mt-3(v-if="path")
              .picture.mt-2.text-center(v-if="isPicture")
                img(:src="path" style="max-width:100%;max-height:200px;")
              textarea.form-control.mt-2(v-model="caption" placeholder="Introduzca un texto para enviar con la foto")

              .d-grid.gap-2.mt-3
                button.btn.btn-primary.mb-3(@click="sendMessageFile()") {{$t('Enviar Fichero')}}
            .d-grid.gap-2.mt-3
              button.btn.btn-secondary(@click="cancelTabs()") Cancelar
  .row(v-else-if="addOrMergeAccount")
    .col
      .card
        .card-body
          add-and-replace-account(
            :accountIdToMerge="selectedChatRoom.account_id" 
            :accountNameToMerge="selectedChatRoom.account.name"
            @mergeAccount="mergeAccount")
          .d-grid.gap-2.mt-3
            button.btn.btn-secondary(@click="cancelTabs()") Cancelar
  .row(v-else-if="showTask")
    .col-lg-12
      .card.mt-3
        .card-body
          chat-modal-tasks(:accountId="selectedChatRoom.account_id" :chatRoomId="selectedChatRoom.id")
        .d-grid.gap-2.mt-3
          button.btn.btn-secondary(@click="cancelTabs()") Cancelar
  .row(v-else-if="showEmojis")
    .col-lg-12
      .card.mt-3
        .card-body
          .row
            .col-2.mb-2(v-for="emoji in emojis")
              button.btn.btn-light(@click="addEmoji(emoji.value)") {{emoji.value}}
        .d-grid.gap-2.mt-3
          button.btn.btn-secondary(@click="cancelTabs()") Cancelar
  template(v-else v-for="message in messages")
    chat-room-v2-chat-item(:message="message" :key="'messageItem' + message.id")
  .bg-white(style="position:sticky;bottom:0px;z-index:10")
    .d-flex.mt-2.border.rounded.align-items-center.p-2
      .p-1
        button.btn.btn-link.text-dark.p-0.m-0(@click="sendMessageTemplate = !sendMessageTemplate")
          font-awesome-icon(icon="comment-alt")
        br
        button.btn.btn-link.text-dark.p-0.m-0(@click="setShowEmojis")
          font-awesome-icon.mt-2(icon="smile")
      .flex-grow-1
        textarea.form-control.border-0(placeholder="Escribe un mensaje" v-model="text" @keyup.enter.stop="sendText" @paste="onPaste" :readonly="!openedWindow")
      .p-1
        font-awesome-icon(icon="paperclip" @click="sendFile = !sendFile")
          
</template>
<script>
  import filesService from '../../services/api/files'
import ChatRoomV2ChatItem from './ChatRoomV2ChatItem.vue'
import AddAndReplaceAccount from '../accounts/AddAndReplaceAccount.vue'
import ChatModalTasks from '../accounts/ChatModalTasks.vue'
  export default {
    name: 'ChatRoomV2Chat',
    components: {
      ChatRoomV2ChatItem,
      AddAndReplaceAccount,
      ChatModalTasks
    },
    props: {
      reduced: { type: Boolean, default: false },
    },
    data () {
      return {
        messageEnd: null,
        sendMessageTemplate: false,
        messageTempalateId: null,
        showTransfer: false,
        showEmojis: false,
        transferUserId: null,
        sendFile: false,
        isPicture: false,
        caption: '',
        text: '',
        addOrMergeAccount: false,
        showTask: false,
        path: ''
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      users() {
        return this.$store.state.userModule.users
      },
      emojis() {
        return this.$store.state.emojiModule.emojis
      },
      chatRooms() {
        return this.$store.state.chatRoomModule.chatRooms
      },
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedChatRoom
      },
      messages() {
        return this.$store.state.chatRoomModule.messages.slice().reverse()
      },
      messageEnds() {
        return this.$store.state.messageEndModule.myMessageEnds
      },
      messageTemplates() {
        return this.$store.state.messageTemplateModule.messageTemplates
      },
      selectedMessageTemplate() {
        if (this.messageTempalateId) {
          let index = this.messageTemplates.findIndex((mt) => mt.id == this.messageTempalateId)

          if (index > -1) {
            return this.messageTemplates[index]
          }
        }

        return {}
      },
      formatedMessage() {
        let formatedMessage = ''
        if (this.selectedMessageTemplate) {
          formatedMessage = this.selectedMessageTemplate.message

          let i = 1
          this.params.forEach((p) => {
            if (p) {
              formatedMessage = formatedMessage.replace('{{' + i + '}}', '<strong>' + p + '</strong>')
            }
            i++
          })
        }
        return formatedMessage
      },
      openedWindow() {
        if (this.selectedChatRoom.last_account_message) {
          let windowTime = this.$options.filters.diffToNow(this.selectedChatRoom.last_account_message)
          windowTime = this.$options.filters.timeInHoursShort(windowTime)

          if (windowTime == '+48h') {
            return false
          } else if (windowTime == '+24h') {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      },
      getBorderColor() {
        let border = 'border-info'
        if (this.selectedChatRoom.owner_user_id && this.selectedChatRoom.owner_user_id == this.currentUser.id) {
          border = 'border-primary'
        } else if (this.selectedChatRoom.owner_user_id && this.selectedChatRoom.owner_user_id != this.currentUser.id) {
          border = 'border-secondary'
        }

        return border
      },
    },
    mounted() {
      //setTimeout(() => { this.scrollToBottom() }, 500)
      this.cancelTabs()
    },
    watch: {
      selectedChatRoom: {
        deep: true,
        handler(newVal, oldVal) {
          if(oldVal && newVal && oldVal.id != newVal.id) {
            this.cancelTabs()
          }
        }
      },
      messages() {
        //setTimeout(() => { this.scrollToBottom() }, 500)
      },
      messageEnd(newVal) {
        if (newVal && newVal.id != this.selectedChatRoom.message_end_id) {
          this.setMessageEndId(newVal.id)
        } else if(!newVal && this.selectedChatRoom.message_end_id) {
          this.setMessageEndId(null)
        }
      },
      messageTempalateId(newVal, oldVal) {
        let params = []
        if (oldVal != newVal) {
          if (newVal != 0) {
            let index = this.messageTemplates.findIndex((mt) => mt.id == newVal)

            if (index > -1) {
              let messageTemplate = this.messageTemplates[index]

              messageTemplate.message_template_params.forEach((param) => {
                if (param.default) {
                  params.push(param.default)
                } else {
                  params.push('')
                }
              })
            }
          }

          this.params = params
        }

      },
    },
  methods: {
      addEmoji(emojiValue) {
        this.text = this.text + emojiValue
      },  
      setSendMessageTemplate() {
        this.cancelTabs()
        this.sendMessageTemplate = true
        this.$store.commit("setSelectedChatRoomAction", 'template')
      },
      setShowTransfer() {
        this.cancelTabs()
        this.showTransfer = true
        this.$store.commit("setSelectedChatRoomAction", 'transfer')
      },
      setSendFile() {
        this.cancelTabs()
        this.sendFile = true
        this.$store.commit("setSelectedChatRoomAction", 'file')
      },
      setShowTask() {
        this.cancelTabs()
        this.showTask = true
        this.$store.commit("setSelectedChatRoomAction", 'task')
      },
      setAddOrMergeAccount() {
        this.cancelTabs()
        this.addOrMergeAccount = true
        this.$store.commit("setSelectedChatRoomAction", 'merge')
      },
    setShowEmojis() {
      if (this.showEmojis) {
        this.cancelTabs()
      } else {
        this.cancelTabs()
        this.showEmojis = true
        this.$store.commit("setSelectedChatRoomAction", 'emojis')
      }
      },
      cancelTabs() {
        this.addOrMergeAccount = false
        this.sendMessageTemplate = false
        this.showTransfer = false
        this.sendFile = false
        this.isPicture = false
        this.showTask = false
        this.showEmojis = false
        this.transferUserId = null
        this.$store.commit("setSelectedChatRoomAction", 'chat')
      },
      getWindowBackground(lastAccountMessage) {
        let windowTime = this.$options.filters.diffToNow(lastAccountMessage)
        windowTime = this.$options.filters.timeInHoursShort(windowTime)

        let background = 'bg-success'
        if (windowTime == '+48h') {
          background = 'bg-dark';
        } else if (windowTime == '+24h') {
          background = 'bg-dark';
        } else if (windowTime == '23h') {
          background = 'bg-danger';
        } else if (windowTime == '22h') {
          background = 'bg-warning';
        }

        return background;
      },
      unselectChatRoom() {
        this.cancelTabs();
        this.$store.commit("setSelectedChatRoom", {})
      },
      setMessageEndId(messageEndId) {
        this.$store.dispatch("assignMessageEndToMessage", {
          message_end_id: messageEndId,
          chat_room_id: this.selectedChatRoom.id
        }).then((res) => {
          let index = this.$store.state.chatRoomModule.messages.findIndex((m) => m.id == res.message_id)

          if (index > -1) {
            let message = this.$store.state.chatRoomModule.messages[index]

            if (!message.message_message_ends) {
              this.$set(message, "message_message_ends", [])
            }
            if(res.id) {
              message.message_message_ends.push(res)
            }

            this.$store.commit("updateMessage", {
              message: message,
              index: index
            })
          }

          //this.messageEndId = null
        })
      },
      sendText() {
        if (this.text) {
          let text = this.text;
          this.text = ''
          this.$store.dispatch("chatRoomSendMessage", {
            chatRoomId: this.selectedChatRoom.id,
            data: {
              message_content_type_id: 1,
              message_content: text
            }
          }).then(() => {
            this.cancelTabs()
            if (this.companyConfigs && this.companyConfigs.chatroom_capture_on_send && this.companyConfigs.chatroom_capture_on_send == 1) {
              //RC: Tenemos que capturar el chatroom
              this.$store.dispatch("captureChatRoom", {
                chatRoomId: this.selectedChatRoom.id,
              })
            }
          })
        }
      },
      sendTemplate() {
        if (this.selectedMessageTemplate.id) {
          this.$store.dispatch("chatRoomSendTemplate", {
            chatRoomId: this.selectedChatRoom.id,
            data: {
              message_template_id: this.selectedMessageTemplate.id,
              message_content_type_id: 1,
              params: this.params
            }
          }).then(() => {
            this.cancelTabs()
            this.sendMessageTemplate = false
            this.sendMessageTemplateId = 0
          })
        }

      },
      capture() {

        this.$store.dispatch("captureChatRoom", {
          chatRoomId: this.selectedChatRoom.id,
        })

      },
      release() {

        this.$store.dispatch("releaseChatRoom", {
          chatRoomId: this.selectedChatRoom.id,
        })

      },
      close() {

        this.$store.dispatch("closeChatRoom", {
          chatRoomId: this.selectedChatRoom.id,
        }).then(() => {
//          this.setSelectedChatId(0)
        })

      },
      open() {

        this.$store.dispatch("uncloseChatRoom", {
          chatRoomId: this.selectedChatRoom.id,
        }).then(() => {
          this.setSelectedChatId(0)
        })

      },
      transferChatRoom() {
        if (this.transferUserId) {
          this.$store.dispatch("transferChatRoom", {
            chatRoomId: this.selectedChatRoom.id,
            userId: this.transferUserId
          })
        }
      },
      scrollToBottom() {
        console.log("test")
        let container = this.$el.querySelector(".chatv2");
        if (container) {
          console.log("test 2")
          container.scrollTop = container.scrollHeight;
        }
      },
      handleFileUpload(e) {
        let file = e.target.files[0]

        if (file) {
          filesService.upload(file)
            .then((response) => {
              if (response) {
                let image_extensions = new Array(".jpg", ".jpeg", ".png");
                let extension = (response.substring(response.lastIndexOf("."))).toLowerCase();
                if (image_extensions.includes(extension)) {
                  this.isPicture = true
                } else {
                  this.isPicture = false
                }
                this.path = response
                this.fileName = file.name
              }
            })
        }
      },
      sendMessageFile() {
        let image_extensions = new Array(".jpg", ".jpeg", ".png");

        let extension = (this.path.substring(this.path.lastIndexOf("."))).toLowerCase();

        let message_content_type_id = 3;
        if (image_extensions.includes(extension)) {
          message_content_type_id = 2;
        }

        this.$store.dispatch("chatRoomSendMessage", {
          chatRoomId: this.selectedChatRoom.id,
          data: {
            message_content_type_id: message_content_type_id,
            message_content: this.path + '|' + this.fileName,
            caption: this.caption
          }
        }).then(() => {
          this.path = ''
          this.fileName = ''
          this.caption = ''
          this.sendFile = false
        })
      },
      onPaste(ev) {
        this.retrieveImageFromClipboardAsBase64(ev, ((imageDataBase64) => {
          // If there's an image, open it in the browser as a new window :)
          if (imageDataBase64) {
            filesService.uploadPasteFile(imageDataBase64)
              .then((response) => {
                if (response) {
                  this.path = response
                  this.fileName = response
                  this.caption = ''
                  this.sendFile = true
                  this.isPicture = true
                }
              })

          }
        }));
      },
      retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
        if (pasteEvent.clipboardData == false) {
          if (typeof (callback) == "function") {
            callback(undefined);
          }
        }

        var items = pasteEvent.clipboardData.items;

        if (items == undefined) {
          if (typeof (callback) == "function") {
            callback(undefined);
          }
        }

        for (var i = 0; i < items.length; i++) {
          // Skip content if not image
          if (items[i].type.indexOf("image") == -1) continue;
          // Retrieve image on clipboard as blob
          var blob = items[i].getAsFile();

          // Create an abstract canvas and get context
          var mycanvas = document.createElement("canvas");
          var ctx = mycanvas.getContext('2d');

          // Create an image
          var img = new Image();

          // Once the image loads, render the img on the canvas
          img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image
            mycanvas.width = this.width;
            mycanvas.height = this.height;

            // Draw the image
            ctx.drawImage(img, 0, 0);

            // Execute callback with the base64 URI of the image
            if (typeof (callback) == "function") {
              callback(mycanvas.toDataURL(
                (imageFormat || "image/png")
              ));
            }
          };

          // Crossbrowser support for URL
          var URLObj = window.URL || window.webkitURL;

          // Creates a DOMString containing a URL representing the object given in the parameter
          // namely the original Blob
          img.src = URLObj.createObjectURL(blob);
        }
      },
      mergeAccount() {
        this.$store.dispatch("getUserChatRooms").then((res) => {
          let index = res.findIndex((cr) => cr.id == this.selectedChatRoom.id)
          this.$store.commit("setSelectedChatRoom", {})

          if (index > -1) {
            this.$store.commit("setSelectedChatRoom", res[index])
          } else {
            this.close()
          }
        })
        this.cancelTabs()
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>