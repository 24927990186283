<template lang="pug">
.my-calls-component
  .my-calls#my-calls(v-if="currentUser.extension_id" :class="condensed ? 'condensed' : ''")
    //.d-grid.gap-2
    //  button.btn.btn-outline-secondary(v-if="condensed" @click="condensed = !condensed")
    //    font-awesome-icon(icon="arrow-left")
    //  button.btn.btn-outline-secondary(v-else-if="myCalls.length > 0" @click="condensed = !condensed")
    //    font-awesome-icon(icon="arrow-right")
    .my-calls-items(v-if="!condensed" v-for="(call, index) in myCalls" :key="'divmyCall-' + call.Linkedids[0]") 
      my-call-item(:call="call" :index="index" :key="'myCall-' + call.Linkedids[0]")

    #offcanvasMyCallsUserStatus.offcanvas.offcanvas-end(tabindex='-1' aria-labelledby='offcanvasMyCallsUserStatusLabel')
      .offcanvas-header
        h5#offcanvasMyCallsUserStatusLabel.offcanvas-title Estados de las usuarios
        a(type='button' data-bs-dismiss='offcanvas' aria-label='Close')
          font-awesome-icon(icon='times')
      .offcanvas-body


</template>
<script>
  import MyCallItem from './MyCallItem.vue'
  export default {
    name: 'MyCalls',
    components: {
      MyCallItem
    },
    data () {
      return {
        condensed: false
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      myCalls() {
        return this.$store.state.callModule.myCalls
      },
      reverseMyCalls() {
        return this.myCalls.slice().reverse()
      },
      totalMyCalls() {
        return this.myCalls.length
      },
      currentUser() {
        return this.$store.state.currentUser
      },
    },
    watch: {
      totalMyCalls(newVal, oldVal) {
        if(newVal > oldVal) {
          console.log('test')
          let container = this.$el.querySelector(".my-calls");
          if (container) {
            console.log('test 2')
            container.scrollTop = container.scrollHeight * -1;
            console.log(container.scrollTop)
          }
        }
      }
    },
    mounted() {
      this.$store.dispatch("getCurrentUserCallEnds")
      /*setTimeout(()=> {
        let container = this.$el.querySelector(".my-calls");
        if (container) {
          console.log(container.scrollHeight)
          container.scrollTop =  container.scrollHeight * -1;
          }
      }, 5000)*/
    },
    methods: {
    }
  }
</script>
<style lang="scss">
/*.my-calls {
    position: fixed;
    z-index: 100;
  }
  .calls-notifications {
    //overflow: auto;
    z-index: 1001;
    right: 0px;
    //bottom: 0px;
    max-height: 50%;
    position: fixed;
    padding-bottom: 20px;
    padding-right: 15px;
    width: 400px;
  }*/
    .my-calls.condensed {
      right: -360px;
    }

    .my-calls {
      position: fixed;
      z-index:100;
      bottom:0px;
      right:0px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;
      align-content: flex-start;
      /* demo */
      width: 400px;
      max-height: 50vh;
      overflow-y: auto;
    }
  
    .my-calls .my-calls-items {
      width: 100%;
      margin-bottom: 10px;
    }
</style>
