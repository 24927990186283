<template lang="pug">
.screen-active-tasks
  .card 
    .card-body
      .d-flex.justify-content-between.align-items-center
        h5.card-title.mb-1.ps-2 {{this.filters.title}}
        .screen-task-filters
          button.btn.btn-sm.pt-0.pb-0(:class="hasFilters ? 'blink text-danger' : 'text-secondary'" @click="tooglePopup()") 
            font-awesome-icon(icon="filter")
          .backgroundPopup(v-if="showForm" @click="closePopup")
          .popup-form(v-if="showForm")
            .card.card-custom-v2.shadow-lg
              .card-header.pt-1.pb-1 
                .d-flex.justify-content-between.align-items-center 
                  span.text-primary.text-uppercase Fechas
                  font-awesome-icon.text-secondary(icon="times" @click="closePopup")
              .card-body.p-2
                .mb-2
                  input.form-control.form-control-sm(type="text" v-model="filter" placeholder="Buscar...")
                .form-check.form-switch
                  input.form-check-input#showOnlyToday(type="checkbox" v-model="showOnlyToday")
                  label.form-check-label(for="showOnlyToday") Ver sólo de hoy
                .form-check.form-switch
                  input.form-check-input#todayTask(type="checkbox" v-model="showFinished")
                  label.form-check-label(for="todayTask") Ver finalizadas
                .form-check.form-switch
                  input.form-check-input#showOnlyMy(type="checkbox" v-model="showOnlyMy")
                  label.form-check-label(for="showOnlyMy") Ver sólo mis tareas
      div(style="clear:both;")
      task-create.mt-2.mb-2(v-if="filters.canCreate")
      .limit-scroll-50vh
        task-list-item(v-for="task in sortedTasks" :key="'taskListItem' + task.id" :task="task" :reduced="true")
      
</template>
<script>
  import TaskListItem from '../tasks/TaskListItem.vue'
  import TaskCreate from '../tasks/TaskCreate.vue'
  export default {
    name: 'ScreenActiveTasks',
    components: {
      TaskListItem,
      TaskCreate
    },
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        showFinished: false,
        showForm: false,
        showOnlyToday: false,
        showOnlyMy: false,
        filter: ''
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      allTasks() {
        return this.$store.state.taskModule.tasks
      },
      tasks() {
        return this.allTasks.filter(task => {
          let show = true
          if(this.filter) {
            show = show && task.task_description.name.toLowerCase().includes(this.filter.toLowerCase()) || task.task_description.description.toLowerCase().includes(this.filter.toLowerCase())
          }

          if(this.filters.taskTypeIds) {
            show = show && this.filters.taskTypeIds.findIndex((tti) => tti == task.task_type_id) > -1
          }

          if(this.filters.showOnlyMy || this.showOnlyMy) {
            show = show && (task.users.findIndex(u => u.id == this.currentUser.id) > -1)
          }

          if(this.filters.showOnlyToday || this.showOnlyToday) {
            show = show && (task.today || (task.start_date && this.$moment(this.$moment(new Date()).format('YYYY-MM-DD')).isSameOrAfter(task.start_date)))
          }

          show = show && task.finished == this.showFinished
          
          return show
        })
      },
      sortedTasks() {
        let sortedTasks = this.tasks

        if(this.filters.sortByCreate) {
          return sortedTasks.sort((a, b) => {
            if(a.today && !b.today) return -1
            if(!a.today && b.today) return 1
            if(a.created_at > b.created_at) return 1
            if(a.created_at < b.created_at) return -1
            
            return 0
          })
        } else {
          return sortedTasks.sort((a, b) => {
            if(a.today && !b.today) return -1
            if(!a.today && b.today) return 1
            if(a.start_date && b.start_date && a.start_date > b.start_date) return 1
            if(a.start_date && b.start_date && a.start_date < b.start_date) return -1
            if(a.start_date && !b.start_date ) return -1
            if(!a.start_date && b.start_date ) return 1
            if(a.task_priority.weight > b.task_priority.weight) return -1
            if(a.task_priority.weight < b.task_priority.weight) return 1
            
            return 0
          })
        }
      },
      hasFilters() {
        return this.showFinished || this.showOnlyToday || this.filter || this.showOnlyMy
      }
      
    },
    mounted() {
      
    },
    methods: {
      tooglePopup() {
        this.showForm = !this.showForm
      },
      closePopup() {
        this.showForm = false
      },
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.backgroundPopup {
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.card.card-custom-v2 .card-header {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card.card-custom-v2 .card-footer {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.card.card-custom-v2 .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.card.card-custom-v2 .card-body h1.task-title {
  border-left: 3px solid $primary;
  padding-left: 10px;
  font-size: 1em;
  display: block;
  margin-bottom: 0px;
}
.card.card-custom-v2 .card-body h1.task-title small {
  display:block;
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}
.screen-task-filters {
  position: relative;
}
.card.card-custom-v2 .card-header h5.title-card, .card.card-custom-v2 .card-footer h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.popup-form {
  position:absolute;
  top: 20px;
  right: 20px;
  z-index: 2000;
  width: 160px;
  font-size: 0.85em;
}
.popup-form > .card {
  border: 1px solid $light;
}
</style>