<template lang="pug">
.notifications-item
  .card-gc.bg-light.mt-2.me-4.shadow
    .card-gc-body(:class="'border-' + notification.color")
      button.btn.btn-link.p-0.m-0.float-end(@click="spliceNotification(notification)")
        font-awesome-icon(icon="times")
      font-awesome-icon.me-1(:icon="notification.icon" :class="'text-' + notification.color")
      strong.mb-0 {{notification.title}}
      .crop-text-2
        span {{notification.body}}
</template>

<script>
  export default {
    name: 'NotificationItem',
    props: {
      notification: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        
      }
    },
    computed: {
    },
    mounted() {
      setTimeout(() => {
        this.spliceNotification(this.notification)
      }, 10000)
    },
    watch: {
    },
    methods: {
      spliceNotification(notification) {
        this.$store.commit("spliceNotifications", notification)
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>