import appService from "./appService";

const pbxCalendarGroupService = {
    pbxCalendarGroupGetAll() {
        return appService.get('/pbx_calendar_groups')
            .then((res) => res.data)
    },
    pbxCalendarGroupGetStructure(id) {
        return appService.get('/pbx_calendar_groups/' + id + '/get_structure')
            .then((res) => res.data)
    }
}

export default pbxCalendarGroupService;