import Vue from 'vue'
import callsService from '../services/api/calls'

const callModule = {
    state: {
        searchCalls: [],
        channels: [],
        bridges: [],
        calls: [],
        myCalls: [],
        myTodayCalls: [],
        modalCall: {},
        channelToTransfer: '',
        channelToRedirect: '',
        channelInTransfer: ''
    },
    mutations: {
        setSearchCalls(state, searchCalls) {
            state.searchCalls = searchCalls
        },
        setModalCall(state, modalCall) {
            state.modalCall = modalCall
        },
        setChannels (state, channels) {
            state.channels = channels
        },
        addChannel (state, channel) {
            let index = state.channels.findIndex(x => x.channel === channel.channel)

            if(index == -1) {
                state.channels.push(channel)
            }
        },
        updateChannel (state, channel) {
            let index = state.channels.findIndex(x => x.channel === channel.channel)

            if(index > -1) {
                Vue.set(state.channels, index, channel)
            }
        },
        deleteChannel (state, channel) {
            let index = state.channels.findIndex(x => x.channel === channel.channel)

            if(index > -1) {
                state.channels.splice(index,1)
            }
        },
        deleteChannelByIndex (state, index) {
            if(index > -1) {
                state.channels.splice(index,1)
            }
        },
        setBridges (state, bridges) {
            state.bridges = bridges
        },
        addBridge (state, bridge) {
            let index = state.bridges.findIndex(x => x.id === bridge.id)

            if(index == -1) {
                state.bridges.push(bridge)
            }
        },
        updateBridge (state, bridge) {
            let index = state.bridges.findIndex(x => x.id === bridge.id)

            if(index > -1) {
                Vue.set(state.bridges, index, bridge)
            }
        },
        deleteBridge (state, bridge) {
            let index = state.bridges.findIndex(x => x.id === bridge.id)

            if(index > -1) {
                state.bridges.splice(index,1)
            }
        },
        deleteBridgeByIndex (state, index) {
            if(index > -1) {
                state.bridges.splice(index,1)
            }
        },
        setCalls(state, calls) {
            state.calls = calls
        },
        addCall(state, call) {
            state.calls.push(call)
        },
        updateCall(state, payload) {
            if(payload.index > -1) {
                Vue.set(state.calls, payload.index, payload.call)
            }
        },
        deleteCallByIndex(state, index) {
            if(index > -1) {
                state.calls.splice(index, 1)
            }
        },
        setMyCalls(state, myCalls) {
            state.myCalls = myCalls
        },
        addMyCall(state, call) {
            state.myCalls.push(call)
        },
        updateMyCall(state, payload) {
            if(payload.index > -1) {
                Vue.set(state.myCalls, payload.index, payload.call)
            }
        },
        deleteMyCallByIndex(state, index) {
            if(index > -1) {
                state.myCalls.splice(index, 1)
            }
        },
        setMyTodayCalls(state, myTodayCalls) {
            state.myTodayCalls = myTodayCalls
        },
        addMyTodayCall(state, call) {
            state.myTodayCalls.push(call)
        },
        updateMyTodayCall(state, payload) {
            if(payload.index > -1) {
                Vue.set(state.myTodayCalls, payload.index, payload.call)
            }
        },
        deleteMyTodayCallByIndex(state, index) {
            if(index > -1) {
                state.myTodayCalls.splice(index, 1)
            }
        },
        setChannelToTransfer (state, channelToTransfer) {
            state.channelToTransfer = channelToTransfer
        },
        setChannelToRedirect (state, channelToRedirect) {
            state.channelToRedirect = channelToRedirect
        },
        setChannelInTransfer (state, channelInTransfer) {
            state.channelInTransfer = channelInTransfer
        },
    },
    actions: {
        searchCalls(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.search(payload.page, payload.data)
                    .then((res) => {
                        context.commit("setSearchCalls", res.data)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        exportCallsCsv(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.exportCsv(payload.data)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        hangupChannel(context, channel) {
            return new Promise((resolve, reject) => {
                callsService.hangupChannel({channel: channel})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        parkChannel(context, channel) {
            return new Promise((resolve, reject) => {
                callsService.parkChannel({channel: channel})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        redirectChannel(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.redirectChannel(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        attendedtransferChannel(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.attendedtransferChannel(payload)
                    .then((res) => {
                        context.commit("setChannelInTransfer", payload.channel)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        cancelAttendedtransferChannel(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.cancelAttendedtransferChannel(payload)
                    .then((res) => {
                        context.commit("setChannelInTransfer", '')
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getInitialState(context) {
            return new Promise((resolve, reject) => {
                callsService.getInitialState()
                    .then((res) => {
                        context.commit("setChannels", res.channels)
                        context.commit("setCalls", res.calls)
                        context.commit("setBridges", res.bridges)

                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        makeCall(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.makeCall(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getMyTodayCalls(context) {
            return new Promise((resolve, reject) => {
                callsService.getMyTodayCalls()
                    .then((res) => {
                        context.commit("setMyTodayCalls", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getMyCallsWithoutEnd() {
            return new Promise((resolve, reject) => {
                callsService.getMyCallsWithoutEnd()
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getMyExtensionTodayCalls(context) {
            return new Promise((resolve, reject) => {
                callsService.getMyExtensionTodayCalls()
                    .then((res) => {
                        context.commit("setMyTodayCalls", res)
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getAccountLastCalls(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.getAccountLastCalls(payload.accountId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getActionsAfterOpenCall(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.getActionsAfterOpenCall(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getCallRecord(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.getCallRecord(payload.callRecordId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getCallRecordMp3(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.getCallRecordMp3(payload.callRecordId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        getCallLog(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.getCallLog(payload.callId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        callAssign(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.callAssign(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
        activeCallAddTag(context, payload) {
            return new Promise((resolve, reject) => {
                callsService.activeCallAddTag(payload.linkedid, payload.tagId)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(reject)
            })
        },
    }
}

export default callModule