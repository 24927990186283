<template lang="pug">
  .report-total-call-by-wait-step
    .card.mb-3
      .card-body
        .d-flex.justify-content-between
          h5.card-title {{$t(reportReportItem.name)}}
        
        template(v-if="showChart && series && series.length > 0 && labels && labels.length > 0")
          chart-donut(:series="series" :labels="labels" :colors="colors")
       
        table.table
          thead
            tr
              th {{$t('Rango')}}
              th {{$t('Total')}}
          tbody
            tr(v-for="row in data.dataTable")
              td {{row.report_wait_step}}
              td {{row.total}}
              
</template>
<script>
  import ChartDonut from '../chart/ChartDonut.vue'
  export default {
    name: 'ReportTotalCallByWaitStep',
    components: {
      ChartDonut
    },
    props: {
      reportReportItem: {type: Object, default: function() {return {} }},
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        data: {},
        showTab: 'in',
        colors: ["#09ad95", "#f7b731", "#f82649", "#6259ca", "#45aaf2"],
        lastFilter: 0,
        showChart: true
      }
    },
    computed: {
      series() {
        if(this.data.dataChart && this.data.dataChart.series) {
          return this.data.dataChart.series
        } else {
          return []
        }
      },
      labels() {
        if(this.data.dataChart && this.data.dataChart.labels) {
          return this.data.dataChart.labels
        } else {
          return []
        }
      }
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          if(newVal.counter != this.lastFilter) {
            this.getData()
            this.lastFilter = newVal.counter
          }
        }
      }
    },
    mounted() {
    },
    methods: {
      getData() {
        this.showChart = false
        this.$store.dispatch("getReportReportItemData", {
          reportReportItemId: this.reportReportItem.id,
          data: {
            filters: this.filters
          }
        }).then((res) => {
          this.data = res
          this.showChart = true
        })
      }
    }
  }
</script>
<style lang="scss">
</style>