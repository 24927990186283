<template lang="pug">
#commentViewItem
  .card-gc-border.mb-2
    .card-gc-body.border-secondary
      strong {{comment.user.name}}
      div(v-html="comment.comment")
      span.small.text-dark {{comment.created_at | moment('DD-MM-YYYY hh:mm:ss')}}
</template>
<script>


export default {
  name: 'commentViewItem',
  props: {
    comment: { type: Object, default: () => { } },
  },
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    newComment(comment) {
      this.$emit("newComment", comment)
    }
  }
}
</script>
<style scoped>
p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>
