<template lang="pug">
.call-modal
  #callModal.modal.fade(tabindex='-1' aria-labelledby='callModalLabel' aria-hidden='true')
    .modal-dialog.modal-xl
      .modal-content
        .p-2.rounded.bg-white
          .d-flex.justify-content-between
            .p-2
              div(v-if="call.account")
                h1.h5.mb-0.pb-0 {{call.account.name}}
                  button.btn.btn-link.text-primary(@click="openAccount()")
                    font-awesome-icon(icon="eye")
                small.small.d-block {{call.account.corporate_name}}
              div(v-else-if="call.call_type_id != 3 || call.callType != 'internal'") 
                .input-group.input-group-sm
                  input.form-control(v-model="newAccountName" placeholder="Añadir el contacto")
                  button.btn.btn-primary.btn-sm(@click="createNewAccount()")
                    font-awesome-icon(icon="plus-circle")
              div(v-else)
                h1.h5.mb-0.pb-0 Sin contacto
              .d-flex(v-if="call.id")
                .me-2 
                  span.icon.text-primary(v-if="call.call_type_id == 1")
                    font-awesome-icon(icon="arrow-right")
                  span.icon.text-success(v-else-if="call.call_type_id == 2")
                    font-awesome-icon(icon="arrow-left")
                  span.icon.text-warning(v-else)
                    font-awesome-icon(icon="redo")
                span {{call.from}} -  {{call.to}}
              .d-flex(v-else)
                .me-2 
                  span.icon.text-primary(v-if="call.callType == 'in'")
                    font-awesome-icon(icon="arrow-right")
                  span.icon.text-success(v-else-if="call.callType == 'out'")
                    font-awesome-icon(icon="arrow-left")
                  span.icon.text-warning(v-else)
                    font-awesome-icon(icon="redo")
                span {{from}} -  {{to}}

            button#callModalClose.btn-close.align-self-center.me-2(type="button" data-bs-dismiss="modal" aria-label="Close")
          .border-top.border-bottom.p-2.mb-2 
            .d-flex.justify-content-between.align-items-center
              .d-flex
                .me-2(v-if="call.call_status_id")
                  span.badge(:class="getBackgroundStatus(call.call_status_id)") {{call.call_status.name}} 
                .me-2(v-else)
                  span.badge.bg-primary {{myCallState}}
                .me-2 
                  font-awesome-icon.me-1(icon="calendar") 
                  span {{[call.start, "unix"] | unixToDate}}
                .me-2 
                  font-awesome-icon.me-1(icon="clock") 
                  span {{[call.start, "unix"] | unixToTime}} - {{[call.start + call.duration, "unix"] | unixToTime}} ( W:{{call.duration_wait, "unix" | timeInHoursMin}} D:{{call.duration, "unix" | timeInHoursMin}})
          .row 
            .col-8 
              ul.nav.nav-underline
                li.nav-item
                  a.nav-link(href="#!" :class="tab == 'sumary' ? 'active' : 'text-dark'" @click="setTab('sumary')") Resumen
                li.nav-item(v-if="call.account_id")
                  a.nav-link(href="#!" :class="tab == 'lastcalls' ? 'active' : 'text-dark'" @click="setTab('lastcalls')") Últimas llamadas
                li.nav-item(v-if="call.account_id")
                  a.nav-link(href="#!" :class="tab == 'tasks' ? 'active' : 'text-dark'" @click="setTab('tasks')") Tareas
              .p-2(v-if="tab == 'sumary'")
                template(v-if="call.text")
                  h5.h5 Información 
                  .mb-3(style="height:300px;overflow-y:auto" v-html="call.text")
                h5.h5 Registro de la llamada
                table.table.table-bordered.table-sm
                  tbody
                    tr(v-for="callLog in sortedCallLog")
                      template(v-if="callLog.type == 'call_queue'")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}} ({{callLog.duration | timeInHoursMin }})
                          br
                          strong.text-primary entramos en la cola {{callLog.name}} ({{callLog.number}})
                      template(v-else-if="callLog.type == 'call_connected' && callLog.answered == '0'")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}} ({{callLog.duration_ring | timeInHoursMin }} - {{callLog.hangup_status}})
                          br
                          span suena {{callLog.name}} ({{callLog.number}})
                          template(v-if="callLog.user")
                            br
                            font-awesome-icon.me-2(icon="user")
                            span {{callLog.user}}
                      template(v-else-if="callLog.type == 'call_connected' && callLog.answered")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}} ({{callLog.duration | timeInHoursMin }})
                          br
                          strong.text-success conectamos con {{callLog.name}} ({{callLog.number}})
                          template(v-if="callLog.user")
                            br
                            font-awesome-icon.me-2(icon="user")
                            span {{callLog.user}}
                      template(v-else-if="callLog.type == 'call_ivr'")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}} 
                          br
                          span.text-info Menú {{callLog.name}} ({{callLog.option}})
                      template(v-else-if="callLog.type == 'call_voicemail' && callLog.path")
                        td
                          strong.text-danger Mensaje en el buzón de voz
                      template(v-else-if="callLog.type == 'call_voicemail' && !callLog.path")
                        td
                          strong.text-danger Buzón de voz sin mensaje
                      template(v-else-if="callLog.type == 'call_end'")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}}
                          br
                          strong.text-danger asignamos el final {{callLog.name}}
                          template(v-if="callLog.user")
                            br
                            font-awesome-icon.me-2(icon="user")
                            span {{callLog.user}}
                      template(v-else-if="callLog.type == 'call_parking'")
                        td
                          small {{[callLog.start, "unix"] | unixToDateTime}}
                          br
                          strong.text-warning Aparcamos la llamada
              .p-2(v-if="tab == 'lastcalls'")
                call-list-item-min(v-for="lastCall in lastCalls" :call="lastCall" :key="'callListItemModal' + lastCall.id")
              .p-2(v-else-if="tab == 'tasks'")
                call-modal2-tasks(:accountId="call.account_id" :linkedid="callLinkedid")
                
            .col-4
              .d-flex.justify-content-center.pb-2
                .me-2(v-if="myCallState == 'Retenida' || myCallState == 'Activa' || isRinging")
                  button.btn.btn-primary(v-if="companyConfigs && companyConfigs.call_hangup && companyConfigs.call_hangup == 1" @click="hangupOwnChannel()" title="Colgar llamada")
                    font-awesome-icon(:icon="['fas', 'phone-slash']")
                .me-2(v-else)
                  button.btn.btn-secondary(v-if="companyConfigs && companyConfigs.call_hangup && companyConfigs.call_hangup == 1" title="Colgar llamada")
                    font-awesome-icon(:icon="['fas', 'phone-slash']")
                
                .me-2(v-if="myCallState == 'Activa' || isRinging")
                  button.btn.btn-primary(v-if="companyConfigs && companyConfigs.call_blind_transfer && companyConfigs.call_blind_transfer == 1" @click="redirectMainChannel()" title="Transferencia ciega")
                    font-awesome-icon(:icon="['fas', 'retweet']")
                .me-2(v-else)
                  button.btn.btn-secondary(v-if="companyConfigs && companyConfigs.call_blind_transfer && companyConfigs.call_blind_transfer == 1" title="Transferencia ciega")
                    font-awesome-icon(:icon="['fas', 'retweet']")
                
                .me-2(v-if="myCallState == 'Activa' || isRinging")
                  button.btn.btn-primary(v-if="companyConfigs && companyConfigs.call_attended_transfer && companyConfigs.call_attended_transfer == 1" @click="attendedTransferOwnChannel()" title="Transferencia atendida")
                    font-awesome-icon(:icon="['fas', 'exchange-alt']")
                .me-2(v-else)
                  button.btn.btn-secondary(v-if="companyConfigs && companyConfigs.call_attended_transfer && companyConfigs.call_attended_transfer == 1" title="Transferencia atendida")
                    font-awesome-icon(:icon="['fas', 'exchange-alt']")
                
                .me-2(v-if="myCallState == 'Activa' || isRinging")
                  button.btn.btn-primary(v-if="companyConfigs && companyConfigs.call_park && companyConfigs.call_park == 1" @click="parkMainChannel()" title="Aparcar llamada")
                    font-awesome-icon(:icon="['fas', 'parking']")
                .me-2(v-else)
                  button.btn.btn-secondary(v-if="companyConfigs && companyConfigs.call_park && companyConfigs.call_park == 1" title="Aparcar llamada")
                    font-awesome-icon(:icon="['fas', 'parking']")

              .input-select.mb-2
                v-select(:options="callCallEnds" :reduce="callEnd => callEnd.id"  label="name" v-model="callEndId" placeholder="Seleccione un final")
              .input-textarea.mb-2
                textarea.form-control(v-model="comment" rows="3" placeholder="Añadir un comentario")
                .d-grid.gap-2.mt-2
                  button.btn.btn-primary(@click="saveComment") Añadir comentario  
              .comment.mb-1(v-for="comment in comments")
                .comment-content.bg-primary-opacity-25
                  p.mb-0 {{comment.comment}}
                .comment-footer
                  .d-flex.justify-content-between
                    div
                      strong {{comment.user.name}}
                    div
                      span {{comment.created_at}}
</template>

<script>
  import {Offcanvas} from 'bootstrap'
  import jQuery from 'jquery'
  import CallModal2Tasks from './CallModal2Tasks.vue'
  import AddAndReplaceAccount from '../accounts/AddAndReplaceAccount.vue'
  import CallListItemMin from './CallListItemMin.vue'
  export default {
    name: 'CallModal',
    components: {
      CallListItemMin,
      CallModal2Tasks,
      AddAndReplaceAccount,
    },
    data () {
      return {
        trunks: ['T1a', 'T1b', 'T2a', 'T2b', 'T3a', 'T3b', 'T4a', 'T4b', 'T1A', 'T1B', 'T2A', 'T2B', 'T3A', 'T3B', 'T4A', 'T4B', 'TrunkMVL1', 'TrunkMVL2', 'TrunkMVL3', 'TrunkTD', 'SBCVF'],
        isInTransfer: false,
        isRinging: true,
        callEndId: 0,
        comment: '',
        comments: [],
        lastCalls: [],
        tab: 'sumary',
        commentsLinkedid: '',
        lastCallsAccountId: 0,
        callLog: [],
        newAccountName: ''
      }
    },
    computed: {
      sortedCallLog() {
        if(this.callLog.length > 0) {
          let callLog = this.callLog
          return callLog.sort((a, b) => {
            if (a.start < b.start) {
              return -1
            } else if (a.start > b.start) {
              return 1
            } else if(a.type == 'call_ivr' && b.type != 'call_ivr') {
              return -1
            } else if(a.type != 'call_ivr' && b.type == 'call_ivr') {
              return 1
            } else if(a.type == 'call_queue' && b.type != 'call_queue') {
              return -1
            } else if(a.type != 'call_queue' && b.type == 'call_queue') {
              return 1
            } else if(a.type == 'call_connected' && b.type == 'call_connected' && a.answered == 0 && b.answered == 1) {
              return -1
            } else if(a.type == 'call_connected' && b.type == 'call_connected' && a.answered == 1 && b.answered == -1) {
              return 1
            }
            
            return 0
          })
        } else {
          return []
        }
      },
      call() {
        return this.$store.state.callModule.modalCall
      },
      counterKey() {
        return this.$store.state.counterKey
      },
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
      currentUser() {
        return this.$store.state.currentUser
      },
      channelToRedirect() {
        return this.$store.state.callModule.channelToRedirect
      },
      channelToTransfer() {
        return this.$store.state.callModule.channelToTransfer
      },
      channelInTransfer() {
        return this.$store.state.callModule.channelInTransfer
      },
      from() {
        return this.call.CallerIDNum
      },
      to() {
        return this.call.Exten
      },
      callState() {
        if(this.call && this.call.channels) {
          if(this.call.channels.every((c) => c.ChannelStateDesc == 'Up')) {
            return 'Activa'
          } else if(this.call.hold) {
            return 'Retenida'
          } else {
            return 'Inactiva'
          }
        } else {
          return 'Inactiva'
        }
      },
      myChannels() {
        let channels = []
        if(this.call && this.call.channels) {
          this.call.channels.forEach((channel) => {
            if(channel.name == this.currentUser.extension.number) {
              channels.push(channel)
            }
          })
        }

        return channels
      },
      myCallState() {
        if(this.myChannels.length == 0) {
          return 'Inactiva'
        } else if(this.call.hold) {
          return 'Retenida'
        } else if(this.myChannels.every((c) => c.ChannelStateDesc == 'Up')) {
          return 'Activa'
        } else if (this.isRinging){
          return 'Sonando'
        } else {
          return 'Inactiva'
        }
      },
      myCallEnds() {
        return this.$store.state.callEndModule.myCallEnds
      },
      callCallEnds() {
        let myCallEnds = []
        if(this.call.id) {
          myCallEnds = this.myCallEnds[this.call.call_type.name.toLowerCase()]

          if(this.call.call_end_id && myCallEnds.findIndex(ce => ce.id == this.call.call_end_id) == -1 ) {
            myCallEnds.push(this.call.call_end)
          }
        } else {
          myCallEnds = this.myCallEnds[this.call.callType]
        }

        return myCallEnds
      },
      callLinkedid() {
        if(this.call.Linkedids) {
          return this.call.Linkedids[0]
        } else if(this.call.call_linkedids) {
          this.call.call_linkedids[0].linkedid
        }

        return ''
      }
    },
    watch: {
      myCallState(newVal) {
        if(newVal == 'Activa' || newVal == 'Inactiva') {
          this.isRinging = false
        }

        if(newVal == 'Inactiva') {
          let dateEn = this.$options.filters.unixToDateTimeEn([this.call.start, "unix"])
          this.$set(this.call, 'duration', this.$options.filters.diffToNow(dateEn))
        } else {
          this.$set(this.call, 'duration', 0)
        }
      },
      channelInTransfer(newVal, oldVal) {
        if(newVal != oldVal) {
          if(this.call.channels.some((c) => c.channel == newVal)) {
            this.isInTransfer = true
          }
        }
      },
      callEndId(newVal) {
        if(newVal && (!this.call.call_end_id || newVal != this.call.call_end_id)) {
          if(this.call.Linkedids) {
            
            this.$store.dispatch("assignCallEndToCall", {
              call_end_id: newVal,
              linkedid: this.call.Linkedids[0]
            }).then((res) => {
              if(res.error) {
                this.$swal.fire({
                  title: 'No se pudo guardar el final de la llamada',
                  icon: 'warning',
                  showCancelButton: false
                })
              } else {
                let auxCall = this.call
                this.$set(auxCall, 'call_end', res.call_end)
                this.$set(auxCall, 'call_end_id', res.call_end.id)
                this.$store.commit("setModalCall", auxCall)
              }
            })
          } else if(this.call.call_linkedids) {
            this.$store.dispatch("assignCallEndToCall", {
              call_end_id: newVal,
              linkedid: this.call.call_linkedids[0].linkedid
            }).then((res) => {
              if(res.error) {
                this.$swal.fire({
                  title: 'No se pudo guardar el final de la llamada',
                  icon: 'warning',
                  showCancelButton: false
                })
              } else {
                let auxCall = this.call
                this.$set(auxCall, 'call_end', res.call_end)
                this.$set(auxCall, 'call_end_id', res.call_end.id)
                this.$store.commit("setModalCall", auxCall)
              }
            })
          }
          
        }
      },
      call: {
        deep: true,
        handler(newVal) {
          this.callEndId = newVal.call_end_id

          if(newVal.id) {
            this.isRinging = false
          }

          let linkedid = '';
          if(newVal.Linkedids) {
            linkedid = newVal.Linkedids[0]
          } else if(newVal.call_linkedid) {
            linkedid = newVal.call_linkedid[0].linkedid
          } else if(newVal.call_linkedids) {
            linkedid = newVal.call_linkedids[0].linkedid
          } else {
            this.commentsLinkedid = ''
          }

          if(linkedid && linkedid != this.commentsLinkedid) {
            this.commentsLinkedid = linkedid
            this.$store.dispatch("getCallComments", {
              linkedid: linkedid
            }).then((res) => {
              this.comments = res
            })
          }

          let accountId = 0
          if(newVal.account_id) {
            accountId = newVal.account_id
          } else if(newVal.account && newVal.account.id) {
            accountId = newVal.account.id
          } else {
            accountId = 0
            this.lastCalls = []
            this.lastCallsAccountId = 0
          }

          if(accountId && this.lastCallsAccountId != accountId) {
            this.lastCalls = []
            this.lastCallsAccountId = accountId
            this.$store.dispatch("getAccountLastCalls", {
              accountId: accountId
            }).then((res) => {
              this.lastCalls = res
            })
          }

          this.callLog = []
          if(newVal.id) {
            this.getCallLog(newVal.id)
          }

          if(newVal.account_id) {
            //RC: Recuperamos las tareas  de la cuenta
            //this.$store.dispatch("getAccountUnfinishedTasks", {accountId: newVal.account.id})
          } else {
            this.setTab("sumary")
          }
        }
      }
    },
    mounted() {
      let modalCall = document.getElementById('callModal')
      modalCall.addEventListener('hidden.bs.modal', () => {
        this.commentsLinkedid = ''
      })
    },
    methods: {
      getCallLog(callId) {
        this.$store.dispatch("getCallLog", {
          callId: callId
        }).then((res) => {
          this.callLog = res
        })
      },
      getBackgroundStatus(callStatusId) {
        let callStatusClass = 'bg-primary'

        switch(callStatusId) {
          case 2:
            callStatusClass = 'bg-success'
            break
          case 3:
            callStatusClass = 'bg-warning'
            break
          case 4:
            callStatusClass = 'bg-danger'
            break
        }

        return callStatusClass
      },
      setTab(tab) {
        this.tab = tab
      },
      saveComment() {
        if(this.comment) {
          let linkedid = '';
          if(this.call.Linkedids) {
            linkedid = this.call.Linkedids[0]
          } else if(this.call.call_linkedid) {
            linkedid = this.call.call_linkedid[0].linkedid
          } else if(this.call.call_linkedids) {
            linkedid = this.call.call_linkedids[0].linkedid
          }

          this.$store.dispatch("assignCommentToCall", {
            comment: this.comment,
            linkedid: linkedid
          }).then((res) => {
            if(res.error) {
              this.$swal.fire({
                title: 'No se pudo guardar el comentario',
                icon: 'warning',
                showCancelButton: false
              })
            } else {
              this.comment = ''
              this.$store.dispatch("getCallComments", {
                linkedid: linkedid
              }).then((res) => {
                this.comments = res
              })
            }
          })
        }
      },
      getMainChannel() {
        let getMainChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          let indexTrunk = this.trunks.findIndex(x => x == channelName)

          if(indexTrunk > -1) {
            getMainChannel = channel.channel
          }
        })

        return getMainChannel
      },
      getOwnChannel() {
        let getOwnChannel = ''

        this.call.channels.forEach((channel) => {
          let indexSlash = channel.channel.indexOf('/')
          let indexDash = channel.channel.indexOf('-')

          // let channelType = channel.channel.substring(0, indexSlash)
          let channelName = channel.channel.substring(indexSlash + 1, indexDash)
          // let channelUnique = channel.channel.substring(indexDash + 1)

          if(channelName == this.currentUser.extension.number) {
            getOwnChannel = channel.channel
          }
        })

        return getOwnChannel
      },
      hangupOwnChannel() {
        let ownChannel = this.getOwnChannel()

        if(ownChannel) {
          this.isRinging = false
          this.$store.dispatch("hangupChannel", ownChannel)
        }
      },
      parkMainChannel() {
        let mainChannel = this.getMainChannel()

        if(mainChannel) {
          this.isRinging = false
          this.$store.dispatch("parkChannel", mainChannel)
        }
      },
      redirectMainChannel() {
        let mainChannel = this.getMainChannel()

        if(!this.channelToRedirect) {
          if(mainChannel) {
            this.$store.commit("setChannelToRedirect", mainChannel)

            jQuery('#callModalClose').trigger('click')

            let offcanvasUsers = document.getElementById('offcanvasUsers')
            if(offcanvasUsers.style.visibility != 'visible') {
              new Offcanvas(offcanvasUsers).show()
            }
          }
        } else if(mainChannel == this.channelToRedirect) {
          this.$store.commit("setChannelToRedirect", '')
        }
      },
      attendedTransferOwnChannel() {
        let mainChannel = this.getOwnChannel()

        if(!this.channelToTransfer) {
          if(mainChannel) {
            this.$store.commit("setChannelToTransfer", mainChannel)
            this.isInTransfer = true

            jQuery('#callModalClose').trigger('click')

            let offcanvasUsers = document.getElementById('offcanvasUsers')
            if(offcanvasUsers.style.visibility != 'visible') {
              new Offcanvas(offcanvasUsers).show()
            }
          }
        } else if(mainChannel == this.channelToTransfer) {
          this.$store.commit("setChannelToTransfer", '')
          this.isInTransfer = false
        }
      },
      mergeAccount(event) {
        let phone = this.call.from
        if(this.call.callType == 'in') {
          phone = this.call.CallerIDNum
        } else if(this.call.callType == 'out') {
          phone = this.Exten.substring(1)
        } else if(this.call.call_type_id == 'out') {
          phone = this.call.to.substring(1)
        }

        this.$store.dispatch("createRegister", {
          moduleUrl: 'account_phones',
          data: {
            country_id: 1,
            phone: phone,
            account_id: event.id
          }
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            if(this.call.Linkedids) {
              this.$store.dispatch("callAssign", {
                linkedid: this.call.Linkedids[0],
                account_id: event.id,
                account_phone_id: res.id
              })
            } else if(this.call.call_linkedids) {
              this.$store.dispatch("callAssign", {
                linkedid: this.call.call_linkedids[0].linkedid,
                account_id: event.id,
                account_phone_id: res.id
              })
            }
          }
        })

        this.setTab("sumary")
      },
      openAccount() {
        this.$router.push("/accounts/" + this.call.account.id)
        jQuery('#callModalClose').trigger('click')
        
      },
      createNewAccount() {
        if(this.newAccountName != '') {
          let dataAccount = {name: this.newAccountName}
         
            this.$store.dispatch("createRegister", {
            moduleUrl: 'accounts',
            data: dataAccount
          }).then((resAccount) => {
            let phone = this.call.from
            if(this.call.callType == 'in') {
              phone = this.call.CallerIDNum
            } else if(this.call.callType == 'out') {
              phone = this.Exten
            } else if(this.call.call_type_id == 2) {
              phone = this.call.to
            }

            this.$store.dispatch("createRegister", {
              moduleUrl: 'account_phones',
              data: {
                country_id: 1,
                phone: phone,
                account_id: resAccount.id
              }
            }).then((resPhone) => {
              let linkedid = ''
              if(this.call.id) {
                linkedid = this.call.call_linkedids[0].linkedid
              } else {
                linkedid = this.call.Linkedids[0]
              }

              let call = this.call

              this.$set(call, 'account', resAccount)
              this.$store.commit("setModalCall", call)

              this.newAccountName = ''

              this.$store.dispatch("callAssign", {
                linkedid: linkedid,
                account_id: resAccount.id,
                account_phone_id: resPhone.id
              })
            })
          })
        }
      }
    }
  }
</script>
<style lang="scss">
  
</style>