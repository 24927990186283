<template lang="pug">
.notifications
  notification-item(v-for="notification in notifications" :key="'notification' + notification.id" :notification="notification")
</template>

<script>
  import NotificationItem from './NotificationItem.vue'
  export default {
    name: 'GcNotification',
    components: {
      NotificationItem
    },
    data () {
      return {
      }
    },
    computed: {
      notifications() {
        return this.$store.state.notificationModule.notifications
      },
    },
    beforeMount() {
    },
    watch: {
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.notifications {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 300px;
  z-index: 20000;
}

</style>