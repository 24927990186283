import appService from "./appService";

const emailMessagesService = {
    getAll() {
        return appService.get('/email_messages')
            .then((res) => res.data)
    },
    sendEmailMessage(data) {
        return appService.post('/email_messages/send', data)
            .then((res) => res.data)
    },
    assignEmailMessage(id, value, userId = 0) {
        return appService.patch('/email_messages/' + id + '/assign/' + value + '/' + userId)
            .then((res) => res.data)
    },
    markUnreadEmailMessage(id, value) {
        return appService.patch('/email_messages/' + id + '/unread/' + value)
            .then((res) => res.data)
    },
}

export default emailMessagesService;