import appService from "./appService";

const messageTemplatesService = {
    getUserMessageTemplates(messageTypeId) {
        return appService.get('/message_templates/' + messageTypeId + '/own')
            .then((res) => res.data)
    },
    getDepartmentMessageTemplates(messageTypeId, departmentCode) {
        return appService.get('/message_templates/' + messageTypeId + '/department/' + departmentCode)
            .then((res) => res.data)
    },
}

export default messageTemplatesService;