<template lang="pug">
.chat-room-v2-send-form        
  .row.justify-content-center
    .col
      .mt-3
        .bg-secondary-opacity-20.p-3
          h5.card-title Enviar un mensaje nuevo
          label Enviar por:
          v-select(:options="whatsappNumbers" :reduce="whatsappNumber => whatsappNumber.id"  label="name" v-model="whatsappNumberId")

          label Destino
          input.form-control(v-model="phoneNumber")
          template(v-if="rgpd == 1")
            label Seleccione una plantilla
            v-select(:options="messageTemplates" :reduce="messageTemplate => messageTemplate.id"  label="label" v-model="messageTempalateId")

            template(v-if="selectedMessageTemplate.id")
              div(v-if="editCustomMessage")
                textarea.form-control.mt-3.mb-3(v-model="customMessage")
              div(v-else)
                .alert.bg-info-opacity-25.mt-3(v-html="formatedMessage" :class="messageTypeId == 2 ? 'mb-3' : ''")
                button.btn.btn-link(v-if="messageTypeId == 2" @click="setEditCustomMessage(true)")
                  font-awesome-icon(icon="edit")

              .mb-1.pb-1(v-for="(param, index) in selectedMessageTemplate.message_template_params")
                label.label {{param.label}}
                input.form-control(v-model="params[index]")
              .d-grid.gap-2
                button.btn.btn-primary(@click="sendTemplate()") Enviar plantilla
              p.mt-3.alert.bg-success-opacity-25(v-if="successMessage") {{successMessage}}
              p.mt-3.alert.bg-danger-opacity-25(v-if="errorMessage") {{errorMessage}}  
</template>
<script>
  export default {
    name: 'ChatRoomV2SendForm',
    props: {
      messageTypeId: {type: Number, default: 1},
    },
    data () {
      return {
        phoneNumber: '',
        departmentCode: '',
        rgpd: 1,
        messageTempalateId: 0,
        params: [],
        successMessage: '',
        errorMessage: '',
        customMessage: '',
        editCustomMessage: false,
        messageTemplates: [],
        whatsappNumberId: null,
        whatsappNumbers: []
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      companyConfigs() {
        return this.$store.state.companyConfigs
      },
      selectedMessageTemplate() {
        if(this.messageTempalateId) {
          let index = this.messageTemplates.findIndex((mt) => mt.id == this.messageTempalateId)

          if(index > -1) {
            return this.messageTemplates[index]
          }
        }

        return {}
      },
      formatedMessage() {
        let formatedMessage = ''
        if(this.selectedMessageTemplate) {
          formatedMessage = this.selectedMessageTemplate.message

          let i = 1
          this.params.forEach((p) => {
            if(p) {
              formatedMessage = formatedMessage.replace('{{' + i + '}}', '<strong>' + p + '</strong>')
            }
            i++
          })
        }
        return formatedMessage
      }
    },
    watch: {
      messageTempalateId(newVal, oldVal) {
        let params = []
        if(oldVal != newVal) {
          if(newVal != 0) {
            let index = this.messageTemplates.findIndex((mt) => mt.id == newVal)

            if(index > -1) {
              let messageTemplate = this.messageTemplates[index]

              messageTemplate.message_template_params.forEach((param) => {
                if(param.default) {
                  params.push(param.default)
                } else {
                  params.push('')
                }
              })
            }
          }

          this.params = params
        }

      }
    },
    mounted() {
      this.$store.dispatch("getMyWhatsappNumbers")
        .then((res) => {
          this.whatsappNumbers = res

          if(this.whatsappNumbers && this.whatsappNumbers[0]) {
            this.whatsappNumberId = this.whatsappNumbers[0].id
          }
        })
        
        if(this.messageTypeId > 0) {
          this.$store.dispatch("getUserMessageTemplates", {
            messageTypeId: this.messageTypeId
          }).then((res) => {
            this.messageTemplates = res
          })
        } else {
          this.$store.dispatch("getUserMessageTemplates", {
            messageTypeId: 1
          }).then((res) => {
            this.messageTemplates = res
          })
        }
        
    },
    methods: {
      setEditCustomMessage(value) {
        this.customMessage = this.formatedMessage
        this.editCustomMessage = value
      },
      sendTemplate() {
        this.successMessage = ''
        this.errorMessage = ''
        if(this.selectedMessageTemplate.id) {
          this.$store.dispatch("sendMessageTemplate", {
            phoneNumber: this.phoneNumber,
            message_content_type_id: 1,
            message_template_id: this.selectedMessageTemplate.id,
            params: this.params,
            department_code: this.departmentCode,
            message_type_id: this.messageTypeId,
            custom_message: this.customMessage,
            whatsapp_number_id: this.whatsappNumberId
            
          }).then((res) => {
            if(res.error) {
              this.errorMessage = 'No se pudo realizar el envio'
            } else {
              this.successMessage = 'Envio realizado correctamente'
            }

            this.cancelSendTemplate()
            
          })
        }
        
      },
      sendTemplateRGPD() {
        this.successMessage = ''
        this.errorMessage = ''

        let index = this.messageTemplates.findIndex((mt) => (mt.name == 'gpp_rgpd2' || mt.name == 'gdpr2'))

        if (index > -1) {
          let messageTemplate = this.messageTemplates[index]
          let params = []
          messageTemplate.message_template_params.forEach((param) => {
            if (param.default) {
              params.push(param.default)
            } else {
              params.push('')
            }
          })

          this.$store.dispatch("sendMessageTemplate", {
            phoneNumber: this.phoneNumber,
            message_content_type_id: 1,
            message_template_id: messageTemplate.id,
            params: params,
            department_code: this.departmentCode,
            message_type_id: 1

          }).then((res) => {
            if (res.error) {
              this.errorMessage = 'No se pudo realizar el envio'
            } else {
              this.successMessage = 'Envio realizado correctamente'
            }
            console.log(res)

          })
        } else {
          this.errorMessage = 'No disponemos de una plantilla para poder enviar la solicitud'
        }


      },
      cancelSendTemplate() {
        //this.rgpd = 0
        this.messageTypeId = 0
        this.selectedChatId = 0
        this.phoneNumber = ''
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>