<template lang="pug">
.task-end
  span.badge.pt-1.pb-1.bg-primary(v-if="task.task_end_id" @click="tooglePopup") {{task.task_end.name}}
  span.badge.pt-1.pb-1.bg-secondary(v-else @click="tooglePopup") Sin final
  .backgroundPopup(v-if="showForm" @click="closePopup")
  .popup-form(v-if="showForm")
    .card.card-custom-v2.shadow-lg
      .card-header.pt-1.pb-1 
        .d-flex.justify-content-between.align-items-center 
          span.text-primary.text-uppercase Finales 
          font-awesome-icon.text-secondary(icon="times" @click="closePopup")
      .card-body.p-2
        v-select(:options="taskEnds" :reduce="taskEnd => taskEnd.id"  label="name" v-model="taskEndId" placeholder="Seleccione un final" )
        
</template>

<script>
  export default {
    name: 'TaskUsers',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, default: true},
    },
    data () {
      return {
        taskEndId: null,
        showForm: false,
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      taskEnds() {
        let taskEnds = this.$store.state.taskModule.taskEnds

        return taskEnds.sort((a, b) => {
          if (parseInt(a.position) < parseInt(b.position)) return -1
          if (parseInt(a.position) > parseInt(b.position)) return 1
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1

          return 0
        })
      }       
    },
    beforeMount() {
      
    },
    watch: {
      taskEndId (newVal) {
        if(newVal) {
          this.update(newVal)
        }
      }
    },
    methods: {
      tooglePopup() {
        this.showForm = !this.showForm
      },
      closePopup() {
        this.showForm = false
      },
      update(taskEndId) {
        let task = this.task
        this.$set(task, 'task_end_id', taskEndId)

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'tasks',
          id: this.task.id,
          data: task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.closePopup()
            this.$store.commit("updateTasks", res)
            this.$store.commit("updateUnfinishedTasks", res)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.backgroundPopup {
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.card.card-custom-v2 .card-header {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card.card-custom-v2 .card-footer {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.card.card-custom-v2 .card-header h5.title-card, .card.card-custom-v2 .card-footer h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.card.card-custom-v2 .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.card.card-custom-v2 .card-body h1.task-title {
  border-left: 3px solid $primary;
  padding-left: 10px;
  font-size: 1em;
  display: block;
  margin-bottom: 0px;
}
.card.card-custom-v2 .card-body h1.task-title small {
  display:block;
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}
.task-end {
  //max-width: 160px;
  position: relative;
}
h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
.popup-form {
  position:absolute;
  top: 20px;
  z-index: 200;
  width: 160px;
  font-size: 0.85em;
}
.popup-form > .card {
  border: 1px solid $light;
}
</style>