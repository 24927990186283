<template lang="pug">
.task-remainder
  h5.card-title.mb-2.text-primary Recordatorios 
  .row
    .col
      .input-group.mb-2
        input.form-control.form-control-sm(type="number" step="1" min="0" v-model="gapUnits")
        select.select.select-sm(v-model="notificationType")
          option(v-for="(label, value) in notificationTypes" :value="value") {{ label }}
        select.select.select-sm(v-model="gapType")
          option(v-for="(label, value) in gapTypes" :value="value") {{ label }}
        button.btn.btn-primary(@click="createRemainder()") 
          font-awesome-icon.me-1(icon="plus-circle")
          span Añadir
  table.table.table-striped
    thead
      tr
        th Tipo
        th Avisar
        th 
    tbody
      tr(v-for="taskRemainder in task.task_remainders")
        td {{notificationTypes[taskRemainder.notification_type]}}
        td {{ taskRemainder.gap + ' ' + gapTypes[taskRemainder.gap_type] }} antes
        td
          button.btn.btn-link.text-danger.m-0.p-0(@click="deleteRemainder(taskRemainder.id)")
            font-awesome-icon(icon="trash")
</template>

<script>
  export default {
    name: 'TaskRemainder',
    props: {
      task: {type: Object, default: function() { return {}}},
      reduced: {type: Boolean, default: true},
    },
    data () {
      return {
        showCreate: false,
        email: false,
        notification: true,
        gapTypes: {
          'minutes': 'Minutos',
          'hours': 'Horas',
          'days': 'Días',
          'month': 'Meses',
        },
        notificationTypes: {
          'notification': 'Notificación',
          'email': 'Email'
        },
        notificationType: 'notification',
        gapUnits: 0,
        gapType: 'minutes'
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },    
    },
    beforeMount() {
      this.resetData()
    },
    watch: {
      task () {
        this.resetData()
      }
    },
    methods: {
      resetData() {
        this.gapUnits = 10
        this.gapType = 'minutes'
        this.notificationType = 'notification'
      },
      createRemainder() {

        let data = {
          task_id: this.task.id,
          gap: this.gapUnits,
          gap_type: this.gapType,
          notification_type: this.notificationType,
        }

        this.$store.dispatch("createRegister", {
          moduleUrl: 'task_remainders',
          data: data
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.resetData()
          }
        })
      },
      deleteRemainder(id) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este elemento?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if (result) {
            this.$store.dispatch("deleteRegister", {
              moduleUrl: 'task_remainders',
              id: id
            }).then(() => {
              this.search()
            })
          }
        });
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";


</style>