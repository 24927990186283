<template lang="pug">
.task-view.p-2(v-if="task && task.id" style="position:relative;height:calc(100vh - 130px);width:100%;")
  .border-bottom(style="height: 50%;overflow-x:hidden;overflow-y:auto;")
    .task-body(:style="'border-left: 5px solid' + task.task_priority.color")
      input.form-control(v-if="editableField == 'name'" v-model="task.name" @keyup.enter="update()")
      h1.task-title(v-else @dblclick="setEditableField('name')")
        .d-block {{task.task_description.name}}
        //small.text-primary.me-2(v-if="task.task_group_id") {{task.task_group.name}}
      .d-flex
        task-group.me-2(:task="task" :key="'taskGroup' + task.id")
        span.me-2 |
        task-account.me-2(:task="task" :key="'taskAccount' + task.id")
      .d-flex.justify-content-between
        .d-flex
          task-status.me-2(:task="task" :key="'taskStatus' + task.id")
          task-priority.me-2(:task="task" :key="'taskPriority' + task.id")
          task-window.me-2(v-if="task.task_type_id == 2 && !task.finished" :task="task" :key="'taskWindow' + task.id")
          task-ends(:task="task" :key="'taskEnds' + task.id")
        .d-flex
          task-code.me-2.align-self-center(:task="task" :reduced="reduced" :key="'taskCode' + task.id")
          a.btn.btn-sm.btn-outline-info.me-2(href="#communications")
            font-awesome-icon.me-1(icon="comments") 
            span Comunicaciones
          a.btn.btn-sm.btn-outline-primary(href="!#" @click.prevent="editTask(task.id)")
            font-awesome-icon.me-1(icon="edit") 
            span Editar
      
    .task-actions.border-bottom.border-top.mt-2.pb-2
      .d-flex.justify-content-between
        .d-flex.justify-content-start
          task-type(:task="task" :reduced="reduced" :key="'taskType' + task.id")
          task-dates(:task="task" :reduced="reduced" :key="'taskDates' + task.id")
          task-users(:task="task" :reduced="reduced" :key="'taskUsers' + task.id")
          task-timer(:task="dataTask" :key="'taskTimer' + task.id")
        .d-flex.justify-content-end
          h5.title-card.text-secondary.me-2
            font-awesome-icon.me-1(:icon="['fa', 'calendar']")
            span {{ task.created_at | formatDate}}
          h5.title-card.text-secondary.me-2(@click="copy(task.id)")
            font-awesome-icon.me-1(:icon="['fas', 'hashtag']")
            span {{ task.id }}
          h5.title-card.text-secondary.me-2(v-if="task.task_steps.length > 0")
            font-awesome-icon.me-1(icon="flag")
            span {{task.task_steps_finished.length}} / {{task.task_steps.length}} 
            span Etapas
          h5.title-card.text-secondary.me-2 
            font-awesome-icon.me-1(icon="comments")
            span.me-1 {{task.task_comments.length}} 
            span comentarios
          h5.title-card.text-secondary.me-2
            font-awesome-icon.me-1(icon="paperclip")
            span.me-1 {{task.task_files.length}}
            span adjuntos
          task-billable(:task="dataTask" :key="'taskBillable' + task.id")
          task-archived(:task="dataTask" :key="'taskArchived' + task.id")
    .p-2.border-bottom
      .row
        .col
          h5.card-title.text-primary.mb-1 Descripción
          div(v-if="editableField == 'description'" @dblclick="setEditableField('description')")
            VueEditor.mb-2.mt-2(v-model="task.description")
            //ckeditor.mb-2.mt-2(:config="{}" v-model="task.description")
            button.btn.btn-primary(@click="update") Guardar
          div(v-else-if="task.task_description.description" @dblclick="setEditableField('description')" v-html="task.task_description.description")
          div(v-else @dblclick="setEditableField('description')")
            p Sin descripción
          .p-2.border-bottom
            h5#task_steps.card-title.text-primary.mb-1 Etapas de la tarea
            .task-steps.d-flex.align-items-center.p-2.border-bottom(v-for="taskStep in task.task_steps")
              a.me-2(href="#!" :class="taskStep.finished_at ? 'text-success' : 'text-secondary'" @click="toggleFinishedAtTaskStep(taskStep)")
                font-awesome-icon(icon="check-circle" size="lg")
              input.form-control.form-control-sm.flex-grow-1(v-if="editStepId && editStepId == taskStep.id" type="text" v-model="editStepDescription" @keyup.enter="updateStep" )
              h1.task-title.flex-grow-1(v-else :class="taskStep.finished_at ? 'text-success text-strike' : ''" @dblclick="editStep(taskStep)") {{taskStep.description}}
              button.btn.btn-link.mt-0.mb-0.me-0.ms-2.p-0.text-dark(v-if="editStepId && editStepId == taskStep.id" type="button" @click="editStepId = null")
                font-awesome-icon(icon="times")
              button.btn.btn-link.mt-0.mb-0.me-0.ms-2.p-0.text-danger(v-else type="button" @click="deleteTaskStep(taskStep.id)")
                font-awesome-icon(icon="trash")
            .input-group.mt-2
              input.form-control(type="text" placeholder="Añadir una nueva etapa" v-model="stepDescription" @keyup.enter="createTaskStep")
              button.btn.btn-outline-primary(type="button" @click="createTaskStep") Añadir
          .p-2
            h5#task_attachment.card-title.text-primary.mb-1 Adjuntos
            .d-flex.pb-2.mb-2.border-bottom.flex-wrap
              a.btn.btn-outline-secondary.text-center.me-2.mb-2(v-for="taskFile in task.task_files" :href="taskFile.path" target="_blank" :title="taskFile.name") 
                font-awesome-icon(icon="file-download" size="3x")
                small.d-block.text-truncate(style="width: 100px;") {{taskFile.name}}
              a.btn.btn-primary.text-center.me-2 
                font-awesome-icon(icon="plus-circle" size="3x")
                small.d-block.text-truncate(style="width: 100px;") Añadir
          .row(v-if="false")
            .col
              div
                input.form-control(type="file" @change="handleFileUpload" multiple)
        .col(v-if="false")
          h5.card-title.text-primary.mb-1 Información Adicional
          .mb-2
            label.label Fuente 
            br
            strong instagram/social
          .mb-2
            label.label Canal de contacto 
            br
            strong IG Privado
          .mb-2
            label.label Nombre del formulario
            br
            strong Promo dia_Madre
          .mb-2
            label.label Promoción
            br
            strong Corporal_Miha
          .mb-2
            label.label Servicio 1
            br
            strong Cirugía Genérico / 1º Visita
          .mb-2
            label.label Servicio 2
            br
            strong -
          .mb-2
            label.label Servicio 3
            br
            strong -
          .mb-2
            label.label Servicio 4
            br
            strong -
          .mb-2
            label.label Servicio 5
            br
            strong -
          .mb-2
            label.label Fecha agendada
            br
            strong 26/03/2023
          .mb-2
            label.label Centro Agendado
            br
            strong Sant Cugat
          .p-2.border-bottom(v-if="false")
            task-remainder(:task="dataTask" :key="'taskRemainders' + task.id")
          .p-2
  .p-2.border-bottom#communications(style="height: 50%;position:relative;overflow-y:hidden;overflow-x:hidden;")
    .row(style="height: 100%;position:relative;")
      .col(style="height: 100%;overflow-x:hidden;overflow-y:scroll;position:relative;") 
        timeline-show(:timeline="timeline")
      .col(style="height: 100%;overflow-x:hidden;overflow-y:scroll;position:relative;") 
        h5.card-title.text-primary Enviar una comunicación
        .d-grid.gap-2(v-if="communicationTab == ''")
          button.btn.btn-lg.btn-outline-primary(@click="communicationTab = 'phones'")
            font-awesome-icon.me-1(icon="phone") 
            span Llamar
          button.btn.btn-lg.btn-outline-success(@click="communicationTab = 'whatsapp'")
            font-awesome-icon.me-1(:icon="['fab', 'whatsapp']")
            span Whatsapp
          button.btn.btn-lg.btn-outline-info(@click="communicationTab = 'email'")
            font-awesome-icon.me-1(icon="envelope") 
            span Email
          button.btn.btn-lg.btn-outline-secondary(@click="communicationTab = 'comment'")
            font-awesome-icon.me-1(icon="comments") 
            span Comentario
          button.btn.btn-lg.btn-outline-warning(@click="communicationTab = 'task-time'")
            font-awesome-icon.me-1(:icon="['fas', 'stopwatch']")
            span Registro de tiempo
          button.btn.btn-lg.btn-outline-warning(@click="communicationTab = 'history'")
            font-awesome-icon.me-1(icon="bars")
            span Otras tareas
        .d-grid.gap-2(v-if="communicationTab == 'comment'")
          form-comment(:parentId="task.id" parentKey="task_id" :module="taskCommentModule" @newComment="addTaskComment")
          button.btn.btn-outline-secondary(@click="communicationTab = ''") Cancelar
        .d-grid.gap-2(v-if="communicationTab == 'phones'")
          button.btn.btn-outline-primary(v-for="phone in account.account_phones" :key="'accountPhone' + phone.id" @click="makeCall(phone)")
            strong {{phone.fullphone}}
            br
            small.small {{phone.name}}
          button.btn.btn-outline-secondary(@click="communicationTab = ''") Cancelar
        template(v-if="communicationTab == 'whatsapp'")
          template(v-if="selectedChatRoom && selectedChatRoom.id")
            chat-room-v2-chat(:reduced="true")
          tempalte(v-else)
            chat-room-v2-chat-send-form(:messageTypeId="1")
          .d-grid.gap-2.mt-2
            button.btn.btn-outline-secondary(@click="communicationTab = ''") Cancelar
        template(v-if="communicationTab == 'email'")
          .mb-2
            v-select(:options="emailConfigs" v-model="emailConfigId" placeholder="" :reduce="emailConfig => emailConfig.id" label="name")
          .mb-2
            v-select(:options="emailDestinationsOptions" v-model="emailDestinations" placeholder="Añada un destinatario" :taggable="true" :multiple="true")
          .mb-2
            VueEditor.form-control.mb-2(v-model="emailBody")
          .d-grid.gap-2
            button.btn.btn-primary(@click="sendEmailMessage()") Enviar correo
            button.btn.btn-outline-secondary(@click="communicationTab = ''") Cancelar
        template(v-if="communicationTab == 'history'")
          .mb-2
            task-list-item(v-for="task in currentAccountTasks" :key="'listTaskListItem' + task.id" :task="task")
          .d-grid.gap-2
            button.btn.btn-outline-secondary(@click="communicationTab = ''") Cancelar
</template>

<script>
  import TimelineShow from '../timeline/Show.vue'
  import ViewComment from '../comments/View.vue'
  import FormComment from '../comments/Form.vue'
  import filesService from '../../services/api/files'
  import TaskTimer from './TaskTimer.vue'
  import TaskWindow from './TaskWindow.vue'
  import TaskType from './TaskType.vue'
  import TaskPriority from './TaskPriority.vue'
  import TaskStatus from './TaskStatus.vue'
  import TaskUsers from './TaskUsers.vue'
  import TaskDates from './TaskDates.vue'
  import TaskBillable from './TaskBillable.vue'
  import TaskArchived from './TaskArchived.vue'
  import TaskEnds from './TaskEnds.vue'
  import TaskAccount from './TaskAccount.vue'
  import TaskGroup from './TaskGroup.vue'
  import TaskRemainder from './TaskRemainder.vue'
  import TaskCode from './TaskCode.vue'
  import TaskListItem from './TaskListItem.vue'
  import ChatRoomMessageItem from '../messages/ChatRoomMessageItem.vue'
  import ChatRoomV2Chat from '../messages/ChatRoomV2Chat.vue'
  import ChatRoomV2ChatSendForm from '../messages/ChatRoomV2SendForm.vue'
  //import jQuery from 'jquery'

  export default {
    name: 'TaskView',
    props: {
      taskId: {type: Number, default: 0},
    },
    components: {
      TimelineShow,
      ViewComment,
      FormComment,
      TaskTimer,
      TaskWindow,
      TaskType,
      TaskPriority,
      TaskStatus,
      TaskUsers,
      TaskDates,
      TaskBillable,
      TaskArchived,
      TaskEnds,
      TaskAccount,
      TaskGroup,
      TaskRemainder,
      TaskCode,
      TaskListItem,
      ChatRoomMessageItem,
      ChatRoomV2Chat,
      ChatRoomV2ChatSendForm
    },
  beforeMount() {
    this.isEditName = false
    this.editableNameDescription = false
    this.editStepId = null
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res
        })
      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'task_comments'})
        .then((res) => {
          this.taskCommentModule = res
        })

      this.$store.dispatch("getRegisters", { moduleUrl: 'email_configs' })
        .then((res) => {
          this.emailConfigs = res
          this.emailConfigId = res[0].id
        })

      this.task = this.currentTask

      this.emailDestinations = []

      if(this.task.task_email_message_recipients && this.task.task_email_message_recipients.length > 0) {
        this.task.task_email_message_recipients.forEach((recipient) => {
          this.emailDestinations.push(recipient.email)
        })
      }

      if(this.task.account_id) {
        this.getAccount(this.task.account_id)
      }

      this.getTaskCommunications()
      this.setTaskLastSeen()

      this.$store.dispatch("getUserMessageTemplates", {
        messageTypeId: 1
      }).then((res) => {
        this.messageTemplates = res
      })

      if(this.myCurrentStartedTask) {
          this.currentDuration = this.$options.filters.diffToNow(this.myCurrentStartedTask.start)

          if(!this.currentDurationInterval) {
            this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
          }
        }
    },
    beforeDestroy() {
      if(this.currentDurationInterval) {
        clearInterval(this.currentDurationInterval)
        this.currentDuration = 0
        this.currentDurationInterval = null
        this.isEditName = false
        this.editableNameDescription = false
        this.editStepId = null
      }
    },
    data () {
      return {
        currentModule: {},
        taskCommentModule: {},
        moduleUrl: 'tasks',
        task: {},
        currentDuration: 0,
        currentDurationInterval: null,
        isEditName: false,
        currentTab: 'comments',
        communicationTab: '',
        taskTypes: [],
        taskStatuses: [],
        taskPriorities: [],
        taskGroups: [],
        editableNameDescription: false,
        editableDates: false,
        taskName: '',
        taskDescription: '',
        stepDescription: '',
        editableField: '',
        editStepId: null,
        editStepDescription: '',
        account: {},
        messageTemplates: [],
        messageTemplateId: null,
        phoneNumber: '',
        params: [],
        successMessage: '',
        errorMessage: '',
        emailDestinationsOptions: [],
        emailDestinations: [],
        emailBody: '',
        communications: [],
        reduced: false,
        emailConfigs: [],
        emailConfigId: 1
      }
    },
    computed: {
      timeline() {
        let timeline = []
        timeline = this.communications

        this.task.task_comments.forEach(comment => {
          let index = timeline.findIndex((tm) => tm.type == 'comment' && tm.data.id == comment.id)
          if(index == -1) {
            timeline.push({
              type: 'comment',
              start: this.$moment(comment.created_at).unix(),
              data: comment
            })
          } else {
            this.$set(timeline, index, {
              type: 'comment',
              start: this.$moment(comment.created_at).unix(),
              data: comment
            })
          }
        })

        this.task.task_times.forEach(taskTime => {
          let index = timeline.findIndex((tm) => tm.type == 'taskTime' && tm.data.id == taskTime.id)
          if (index == -1) {
            timeline.push({
              type: 'taskTime',
              start: this.$moment(taskTime.start).unix(),
              data: taskTime
            })
          } else {
            this.$set(timeline, index, {
              type: 'taskTime',
              start: this.$moment(taskTime.start).unix(),
              data: taskTime
            })
          }
        })

        return timeline.sort((a, b) => {
          if (a.start < b.start) {
            return 1
          } else if (a.start > b.start) {
            return -1
          }
          return 0
        })

      },
      sortedCommunications() {
        if(this.communications.length > 0) {
          let communications = this.communications
          return communications.sort((a, b) => {
            if (a.start < b.start) {
              return 1
            } else if (a.start > b.start) {
              return -1
            }
            
            return 0
          })
        } else {
          return []
        }
      },
      chatRooms() {
        return this.$store.state.chatRoomModule.chatRooms
      },
      selectedChatRoom() {
        return this.$store.state.chatRoomModule.selectedChatRoom
      },
      tasks() {
        return this.$store.state.taskModule.tasks
      },
      currentTask() {
        if(this.tasks.length > 0) {
          let index = this.tasks.findIndex(t => t.id == this.taskId)

          if(index > -1) {
            return this.tasks[index]
          } else {
            return {}
          }
        } else {
          return this.$store.state.taskModule.task
        }
      },
      currentAccountTasks() {
        if (this.currentTask && this.currentTask.account_id) {
          return this.tasks.filter(t => t.account_id == this.currentTask.account_id && t.id != this.currentTask.id)
        }

        return []
      },
      dataTask() {
        let dataTask = this.task

        dataTask.name = this.task.task_description.name
        dataTask.description = this.task.task_description.description

        return dataTask
      },
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      startedTasks() {
        if(this.$store.state.taskModule.startedTasks) {
          return this.$store.state.taskModule.startedTasks
        } else {
          return []
        }
      },
      currentStaredTask() {
        let index = this.startedTasks.findIndex(x => x.id == this.taskId)

        if(index > -1) {
          return this.startedTasks[index]
        } else {
          return {}
        }
      },
      myCurrentStartedTask() {
        let myCurrentStartedTask = {}
        if(this.currentStaredTask && this.currentStaredTask.task_times) {
          let index = this.currentStaredTask.task_times.findIndex(x => x.user_id == this.currentUser.id && !x.duration)

          if(index > -1) {
            myCurrentStartedTask = this.currentStaredTask.task_times[index]
          }
        }

        return myCurrentStartedTask
      },
      totalDuration() {
        return this.task.duration + this.currentDuration
      },
      selectedMessageTemplate() {
        if(this.messageTemplateId) {
          let index = this.messageTemplates.findIndex((mt) => mt.id == this.messageTemplateId)

          if(index > -1) {
            return this.messageTemplates[index]
          }
        }

        return {}
      },
      formatedMessage() {
        let formatedMessage = ''
        if(this.selectedMessageTemplate) {
          formatedMessage = this.selectedMessageTemplate.message

          let i = 1
          this.params.forEach((p) => {
            if(p) {
              formatedMessage = formatedMessage.replace('{{' + i + '}}', '<strong>' + p + '</strong>')
            }
            i++
          })
        }
        return formatedMessage
      }
    },
    watch: {
      currentTask(value, oldValue) {
        this.isEditName = false
        this.editableNameDescription = false
        this.editStepId = null
        this.editableField = ''
        this.stepDescription = ''

        this.task = value

        this.emailDestinations = []

        if(this.task.task_email_message_recipients && this.task.task_email_message_recipients.length > 0) {
          this.task.task_email_message_recipients.forEach((recipient) => {
            this.emailDestinations.push(recipient.email)
          })
        }

        if(!oldValue || oldValue.id != value.id) {
          if(this.task.account_id) {
            this.getAccount(this.task.account_id)
          }

          this.getTaskCommunications()
          this.setTaskLastSeen()
        }
      },
      myCurrentStartedTask(value) {
        if(value) {
          this.currentDuration = this.$options.filters.diffToNow(value.start)

          if(!this.currentDurationInterval) {
            this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
          }
        } else {
          this.currentDuration = 0
          clearInterval(this.currentDurationInterval)
          this.currentDurationInterval = null
        }
      },
      selectedChatRoom(value) {
        console.log(value)
        if (!value || !value.id) {
          this.communicationTab = ''
        }
      }
    },
    methods: {
      async copy(s) {
        await navigator.clipboard.writeText(s);
      },
      setEditableField(field) {
        this.editableField = field
      },
      setTab(tab) {
        this.currentTab = tab
      },
      updateNameDescription() {
        this.task.name = this.taskName
        this.task.description = this.taskDescription
        this.update()
      },
      updatePartial(key, value) {
        this.task[key] = value
        this.update()
      },
      update() {
        this.$store.dispatch("updateRegister", {
          moduleUrl: this.currentModule.url,
          id: this.task.id,
          data: this.task
        }).then((res) => {
          if(res.errors) {
            this.errors = res.errors
          } else {
            this.task = res

            this.$store.commit("updateTasks", res)
            this.$store.commit("updateUnfinishedTasks", res)

            this.editableField = ''
          }
        })
      },
      editTask(taskId) {
        this.$emit("closeModal")
        this.$router.push('/tasks/' + taskId + '/edit')
      },
      deleteTask(taskId) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este element?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result) {
            this.$emit("closeModal")
            this.$store.dispatch("deleteRegister", {
              moduleUrl: 'tasks',
              id: taskId
            }).then((res) => {
              this.$store.commit("deleteTasks", res)
            })
          }
        });
      },
      addTaskComment(comment) {
        this.task.task_comments.push(comment)
      },
      handleFileUpload (e) {
          Array.from(e.target.files).forEach((file) => {
            if(file) {
              filesService.upload(file)
                .then((response) => {
                    if(response) {
                      let data = {
                        task_id: this.taskId,
                        name: file.name,
                        path: response
                      }

                      this.$store.dispatch('createRegister', {
                        moduleUrl: 'task_files',
                        data: data
                      })
                      .then((res) => {
                        if(res.id) {
                          this.task.task_files.push(res)
                        }
                      })
                    }
                })
            }
          })
      },
      editStep(taskStep) {
        this.editStepDescription = taskStep.description
        this.editStepId = taskStep.id
      },
      updateStep() {
        let index = this.task.task_steps.findIndex(ts => ts.id == this.editStepId)

        if(index > -1) {
          let taskStep = this.task.task_steps[index]
          this.$set(taskStep, 'description', this.editStepDescription)
          this.$store.dispatch("updateRegister", {
            moduleUrl: "task_steps",
            id: this.editStepId,
            data: taskStep
          }).then(() => {
            this.$set(this.task.task_steps, index, taskStep)
            this.editStepId = null
            this.editStepDescription = ''

            this.$store.commit("updateTasks", this.task)
            this.$store.commit("updateUnfinishedTasks", this.task)
            
          })
        }
      },
      createTaskStep() {
        if(this.stepDescription) {
          let data = {
            task_id: this.task.id,
            description: this.stepDescription,
            finished: null 
          }

          this.$store.dispatch('createRegister', {
            moduleUrl: 'task_steps',
            data: data
          })
          .then((res) => {
            if(res.id) {
              //this.task.task_steps.push(res)
              this.stepDescription = ''
            }
          })
        }
      },
      toggleFinishedAtTaskStep(taskStep) {
        if(taskStep.finished_at) {
          taskStep.finished_at = null
        } else {
          taskStep.finished_at = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }

        this.$store.dispatch("updateRegister", {
          moduleUrl: 'task_steps',
          id: taskStep.id,
          data: taskStep
        }).then(() => {
          let index = this.task.task_steps.findIndex(ts => ts.id == taskStep.id)

          if(index > -1) {
            this.$set(this.task.task_steps, index, taskStep)
          }
        })
      },
      deleteTaskStep(taskStepId) {
        this.$swal.fire({
          title: 'Está seguro que desea eliminar este element?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if(result.isConfirmed) {
            this.$store.dispatch("deleteRegister", {
              moduleUrl: 'task_steps',
              id: taskStepId
            }).then(() => {
              let index = this.task.task_steps.findIndex(ts => ts.id == taskStepId)

              if(index > -1) {
                this.task.task_steps.splice(index, 1)
              }
            })
          }
        });
      },
      getAccount(accountId) {
        this.$store.dispatch("getRegister", {
          moduleUrl: 'accounts',
          id: accountId
        }).then((res) => {
          this.account = res
          this.emailDestinationsOptions = []
          this.account.account_emails.forEach((e) => {
            this.emailDestinationsOptions.push(e.email)
          })
        })
      },
      getTaskCommunications() {
        this.$store.dispatch("getTaskCommunications", {
          taskId: this.taskId,
        }).then((res) => {
          this.communications = res

          let index = this.communications.findIndex(c => c.type == 'message')

          if (index > -1) {
            let chatRoomId = this.communications[index].message.chat_room_id

            if (chatRoomId) {
              let indexChatRoom = this.chatRooms.findIndex((cr) => cr.id == chatRoomId)

              if (indexChatRoom > -1) {
                this.$store.commit("setSelectedChatRoom", this.chatRooms[indexChatRoom])
              }
            }
          } else {
            let indexChatRoom = this.chatRooms.findIndex((cr) => cr.account_id == this.task.account_id)

            console.log(indexChatRoom)
            if (indexChatRoom > -1) {
              this.$store.commit("setSelectedChatRoom", this.chatRooms[indexChatRoom])
            }
          }
        })
      },
      makeCall(accountPhone) {
        this.$store.dispatch("makeCall", {
          phone: '0' + accountPhone.fullphone,
          extension: this.currentUser.extension.number
        })
      },
      sendTemplate() {
        this.successMessage = ''
        this.errorMessage = ''
        if(this.selectedMessageTemplate.id) {
          this.$store.dispatch("sendMessageTemplate", {
            phoneNumber: this.phoneNumber,
            message_content_type_id: 1,
            message_template_id: this.selectedMessageTemplate.id,
            params: this.params,
            message_type_id: 1,
            
          }).then((res) => {
            if(res.error) {
              this.errorMessage = 'No se pudo realizar el envio'
            } else {
              this.successMessage = 'Envio realizado correctamente'
            }

            this.getTaskCommunications()
            this.cancelSendTemplate()
            
          })
        }
        
      },
      sendEmailMessage() {
        let data = {
          recipients: this.emailDestinations,
          body: this.emailBody,
          subject: this.task.task_description.name,
          task_id: this.task.id,
          email_config_id: this.emailConfigId
        }

        this.$store.dispatch("sendEmailMessage", data).then(() => {
          this.emailBody = ''
          this.getTaskCommunications()
        })
      },
      setTaskLastSeen() {
        this.$store.dispatch("setTaskLastSeen", {
          taskId: this.task.id
        })
      }
    }
  }
</script>
<style scoped lang="scss">
@import "../../scss/colors.scss";
.card.card-custom-v2 .card-header {
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card.card-custom-v2 .card-footer {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.task-body {
  padding-left: 10px;
}
h1.task-title {
  font-size: 1.5em;
  display: block;
  margin-bottom: 0px;
  font-weight: 400;
}
h1.task-title small {
  display:block;
  font-weight: 300;
  font-size: 0.6em;
  text-transform: uppercase;
}
.task-body {
  padding-top: 5px;
  padding-bottom: 5px;
}
.task-actions {
  padding-top: 7px;
}
.task-actions h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}

.task-steps h1.task-title {
  font-size: 1.1em;
}
  /*@import "../../scss/colors.scss";
  .task-title {
    border-left: 3px solid $primary;
    padding-left: 10px;
  }
  .task-title h1 {
    font-size: 1.3em;
    display: block;
    margin-bottom: 0px;
  }
  .task-title small {
    font-weight: 300;
    text-transform: uppercase;
  }

  h5.title-card {
    font-size: 0.85em;
    margin-bottom: 0px;
    font-weight: 400;
  }*/
</style>