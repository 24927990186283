<template lang="pug">
.task-timer
  h5.title-card.text-danger(v-if="myCurrentStartedTask && myCurrentStartedTask.id" @click="endTask")
    font-awesome-icon.me-1(:icon="['fa', 'stop']")
    span {{totalDuration | timeInHoursMin}} ({{currentDuration | timeInHoursMin}})
  h5.title-card.text-success(v-else @click="startTask")
    font-awesome-icon.me-1(:icon="['fas', 'play']")
    span {{totalDuration | timeInHoursMin}}
</template>

<script>
  export default {
    name: 'TaskTimer',
    props: {
      task: {type: Object, default: function() { return {}}},
    },
    data () {
      return {
        currentDuration: 0,
        currentDurationInterval: null,
      }
    },
    computed: {
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      startedTasks() {
        if(this.$store.state.taskModule.startedTasks) {
          return this.$store.state.taskModule.startedTasks
        } else {
          return []
        }
      },
      currentStaredTask() {
        let index = this.startedTasks.findIndex(x => x.id == this.task.id)

        if(index > -1) {
          return this.startedTasks[index]
        } else {
          return {}
        }
      },
      myCurrentStartedTask() {
        let myCurrentStartedTask = {}
        if(this.currentStaredTask && this.currentStaredTask.task_times) {
          let index = this.currentStaredTask.task_times.findIndex(x => x.user_id == this.currentUser.id && !x.duration)

          if(index > -1) {
            myCurrentStartedTask = this.currentStaredTask.task_times[index]
          }
        }

        return myCurrentStartedTask
      },
      totalDuration() {
        return parseInt(this.task.duration) + parseInt(this.currentDuration)
      }
    },
    watch: {
      myCurrentStartedTask(val, oldVal) {
        if(!val.id && oldVal.id) {
          clearTimeout(this.currentDurationInterval);
        } else if(val.id && !oldVal.id) {
          console.log("test")
          if(this.currentDurationInterval) {
            clearInterval(this.currentDurationInterval)
            this.currentDurationInterval = null
            this.currentDuration = 0
          }
          
          this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
        }
      }
    },
    beforeMount() {
      if(this.myCurrentStartedTask && this.myCurrentStartedTask.id) {
          this.currentDuration = this.$options.filters.diffToNow(this.myCurrentStartedTask.start)

          if(!this.currentDurationInterval) {
            this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
          }
        }
    },
    beforeDestroy() {
      if(this.currentDurationInterval) {
        clearInterval(this.currentDurationInterval)
        this.currentDuration = 0
        this.currentDurationInterval = null
      }
    },
    methods: {
      startTask() {
        this.$store.dispatch("startTask", {
          taskId: this.task.id
        }).then((task) => {
          this.task = task

          if(this.currentDurationInterval) {
            clearInterval(this.currentDurationInterval)
            this.currentDurationInterval = null
            this.currentDuration = 0
          }
          this.currentDurationInterval = setInterval(()=> {
            this.currentDuration++
          }, 1000)
        })
      },
      endTask() {
        this.$store.dispatch("endTask", {
          taskId: this.task.id
        }).then((task) => {
          this.task = task
          
          if(this.currentDurationInterval) {
            clearInterval(this.currentDurationInterval)
            this.currentDurationInterval = null
          }
          this.currentDuration = 0
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

.task-timer {
  cursor: pointer;
}
h5.title-card {
  font-size: 0.85em;
  margin-bottom: 0px;
  font-weight: 400;
}
</style>