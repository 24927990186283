<template lang="pug">
.last-schedule-calls
  .card 
    .card-body
      .d-flex.justify-content-between.align-items-center
        h5.card-title.mb-1.ps-2 {{this.filters.title}}
      .table-responsive
        table.table
          thead
            tr
              th {{$t('Fecha')}}
              th {{$t('Número')}}
              th {{$t('Destino')}}
              th {{$t('Estado')}}
              th {{$t('Intentos')}}
              th
          tbody
            tr(v-for="call in filteredCalls")
              td {{call.created_at | formatFullTime}}
              td {{call.phone}}
              td(v-if="call.queue_id") {{call.queue.name}}
              td(v-else-if="call.user_id") {{call.user.name}}
              td(v-else-if="call.extension_id") {{call.extension.name}}
              td(v-else) -
              td(v-if="call.validate == 1 && call.originated_at")
                span.badge.bg-success {{$t('Correcta')}}
              td(v-else-if="call.validate == 1 && !call.originated_at")
                span.badge.bg-danger {{$t('Eliminada')}}
              td(v-else-if="call.validate == 0 && call.attempts < 3")
                span.badge.bg-secondary {{$t('Pendiente')}}
              td(v-else) {{$t('Fallida')}}
              td {{call.attempts}}
              td
                button.btn.btn-link.text-dark.pt-0.pb-0(v-if="call.validate == 0 && call.attempts < 3" @click="cancelScheduleCall(call.id)")
                  font-awesome-icon(icon="trash")
            
      
</template>
<script>
  export default {
    name: 'ScreenActiveTasks',
    props: {
      filters: {type: Object, default: function() {return {} }},
    },
    data () {
      return {
        calls: []
      }
    },
    computed: {
      counterKey() {
        return this.$store.state.counterKey
      },
      currentUser() {
        if(localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'))
          return user.user
        } else {
          return {}
        }
      },
      filteredCalls() {
        let filteredCalls = []
        if(this.filters.departments_id) {
          this.calls.forEach((call) => {
            let index = this.filters.departments_id.findIndex((d) => d == call.queue.department_id)

            if(index > -1) {
              filteredCalls.push(call)
            }
          })
        } else {
          filteredCalls =  this.calls;
        }

        return filteredCalls
      }
    },
    mounted() {
      this.getLastCalls()
    },
    methods: {
      getLastCalls() {
        this.$store.dispatch("getLastsScheduleCalls")
          .then((res) => { this.calls = res })
      },
      cancelScheduleCall(id) {
        this.$swal.fire({
          title: this.$t("Está seguro que desea eliminar este elmento?"),
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("cancelScheduleCall", { id: id })
              .then((res) => { this.calls = res })
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../scss/colors.scss";

</style>